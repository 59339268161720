import { Component, ElementRef, OnInit, VERSION, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../_services/auth.service';
import { Country } from 'country-state-city';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  registerform: any = {
    username: null,
    last_name: null,
    company_name: null,
    country: null,
    contact: null,
    company_address: null,
    gst: null
  };
  ifChecked: Boolean = false;
  isSuccessful = false;
  isSignUpFailed = false;
  registerErrorMessage = '';
  role: any = ['customer'][0];
  @ViewChild('country')
  country!: ElementRef;
  name = 'Angular ' + VERSION.major;
  countries = Country.getAllCountries();
  selectedCountry: any;
  message: any;
  errorMessage: any;


  constructor(private authService: AuthService, private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  onCountryChange($event: any): void {
    //this.states = State.getStatesOfCountry(JSON.parse(this.country.nativeElement.value).isoCode);
    this.selectedCountry = JSON.parse(this.country.nativeElement.value);
    this.registerform.country = JSON.parse(this.country.nativeElement.value).name;
    //this.cities = this.selectedState = this.selectedCity = null;
  }

  registerUser(): void {
     const { username, last_name, company_name, country, contact, email, password, company_address, gst } = this.registerform;
    if (this.registerform.username !== null && this.registerform.username !== "" && this.registerform.contact !== null && this.registerform.contact !== "" &&
      this.registerform.email !== null && this.registerform.email !== "" && this.registerform.password !== null && this.registerform.password !== "") {
      if (this.ifChecked == true) {
        this.authService.registerUser(username, this.role, last_name, company_name, country, contact, email, password, company_address, gst).subscribe(
          data => {
            this.isSuccessful = true;
            this.isSignUpFailed = false;
            this.toastr.success('Registration Success Please Verify your email id', '')
          },
          err => {
            this.toastr.error('Someone already has that email/mobile number. Try another?', '')
          }
        );
      } else {
        this.toastr.error('You must have to agree with terms and condition', '');
      }
    }
    else {
      this.toastr.error('You must have to fill all the mendatory fields marked with *', '');

    }

  }
}
