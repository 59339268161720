import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { TokenStorageService } from '../_services/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthguardGuard implements CanActivate {

  constructor(private router: Router, private tokenStorageService: TokenStorageService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const allowedRoles = route.data.roles as string[];
     const userRoles = this.tokenStorageService.getUser()?.roles; 
    const rolesFromToken:any[] = allowedRoles;  
      if (userRoles && rolesFromToken.some(role => userRoles.includes(role))) {
      return true;
    }

    this.router.navigate(['/']);
    return false;
  }
}
