import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from '../_services/token-storage.service';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-artist-dashboard',
  templateUrl: './artist-dashboard.component.html',
  styleUrls: ['./artist-dashboard.component.css']
})
export class ArtistDashboardComponent implements OnInit {

  content: any;
  currentUser: any;
  contact: any;
  id: any;
  firstName: any;
  lastName: any;
  email: any;
  loader: boolean = true;
  customerId: any;
  artistId: any;
  totalWords: any;
  recordingPrice: any;
  customerBookingDetails: any;
  referenceId: any;
  page: number = 1;

  constructor(private token: TokenStorageService, private userService: UserService, private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    this.loader = false;
    this.currentUser = this.token.getUser();
    this.contact = this.token.getUser().contact;
    this.id = this.token.getUser().id;
    this.email = this.token.getUser().email;
    this.firstName = this.token.getUser().username;
    this.lastName = this.token.getUser().lastname;
    this.getCustomerBookingDetails();
    this.route.paramMap.subscribe(params => {
      this.referenceId = params.get('referenceId');
    });
  }

  getCustomerBookingDetails() {
    this.loader = true;
    this.userService.getCustomerBookingDetailsById(this.id).subscribe(data => {
      this.customerBookingDetails = data;
      if (this.customerBookingDetails !== null && this.customerBookingDetails !== "" && this.customerBookingDetails !== undefined) {
        this.loader = false;
        this.customerBookingDetails.forEach((element: {
        }) => {
        });
      }
    },
      err => {
        console.log(err);
      }
    );
  }

  viewDetails(referenceId: any) {
    this.router.navigate(['/artistbookingdetails', { referenceId: referenceId }]);
  }

}
