<div class="dashboardbanner">
  <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
  <div class="bannerheading">
    <h1>Contact Us</h1>
  </div>
</div>

<div class="fullwidth  bg_purple">
  <!--bof: Contact Us-->
  <div class="w1200">
    <div class="contactus">
      <div class="contactleft">
        <h2>Contact Info</h2>
        <div class="c_detail">
          <div class="row">
            <i class="fa fa-map-marker" aria-hidden="true"></i>
            <div class="contact_detail">
              <strong>The Voice Mall</strong>
              <p> G-26, LGF, Kalkaji, New Delhi,<br>
                Delhi 110019</p>
            </div>
          </div>
          <div class="row">
            <i class="fa fa-envelope-open" aria-hidden="true"></i>
            <div class="contact_detail">
              <a href="#">Info@thevoicemall.com</a>
            </div>
          </div>
          <div class="row">
            <i class="fa fa-volume-control-phone" aria-hidden="true"></i>
            <div class="contact_detail">
              <a href="#">+91 9811241103</a>
            </div>
          </div>
          <div class="row">
            <iframe width="100%" height="200" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
              id="gmap_canvas"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d112158.69444054578!2d77.12619436249997!3d28.54094600000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce36b54be5a33%3A0xb2acb637757ff673!2sThe%20Voice%20Mall!5e0!3m2!1sen!2sus!4v1663222357104!5m2!1sen!2sus"
              allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <a href='https://www.embedmap.net/'>add google map to website</a>
            <script type='text/javascript'
              src='https://embedmaps.com/google-maps-authorization/script.js?id=345011262a18439e620041ac8688ce2c1c9e203c'></script>
          </div>
        </div>
      </div>
      <div class="contactright">
        <strong>Send a message</strong>
        <form *ngIf="!isContact" name="form" (ngSubmit)="f.form.valid && contactus()" #f="ngForm" novalidate>
          <div class="formwrap">
            <div class="wrap">
              <div class="wrap-divide">
                <input type="text" placeholder="Enter First Name*" [(ngModel)]="contactForm.first_name"
                  [ngModelOptions]="{standalone: true}" #first_name="ngModel" required minlength="3" maxlength="20">
                <div class="alert alert-danger" *ngIf="first_name.errors && f.submitted">
                  <div *ngIf="first_name.errors.required">Username is required</div>
                  <div *ngIf="first_name.errors.minlength">
                    Username must be at least 3 characters
                  </div>
                  <div *ngIf="first_name.errors.maxlength">
                    Username must be at most 20 characters
                  </div>
                </div>
              </div>

              <div class="wrap-divide">
                <input type="text" placeholder="Enter Last Name" [(ngModel)]="contactForm.last_name"
                  [ngModelOptions]="{standalone: true}" required="">
                <span class="bottomline"></span>
              </div>
            </div>

            <div class="wrap">
              <div class="wrap-divide">
                <input type="email" placeholder="Enter Email Id*" [(ngModel)]="contactForm.email"
                  [ngModelOptions]="{standalone: true}" #email="ngModel" required email>
                <div class="alert alert-danger" *ngIf="(f.submitted || email.touched) && !email.valid">

                <div class="alert alert-danger" *ngIf="email?.errors?.required">Email is required</div>
                <div class="alert alert-danger" *ngIf="email?.errors?.email">Email must be a valid email address</div>

                </div>
                <span class="bottomline"></span>
              </div>

              <div class="wrap-divide">
                <input type="number" placeholder="Enter Phone Number*" [(ngModel)]="contactForm.contact"
                  #contact="ngModel" [ngModelOptions]="{standalone: true}" onKeyPress="if(this.value.length==12) return false;"  required>
                <div class="alert alert-danger" *ngIf="contact.errors && f.submitted">
                  <div *ngIf="contact.errors.required">Contact number is required</div>
                </div>
                <span class="bottomline"></span>
              </div>
            </div>

            <div class="wrap">
              <textarea [(ngModel)]="contactForm.message" [ngModelOptions]="{standalone: true}"
                placeholder="Type Your Message Here.." name="message" required></textarea>
                <div class="alert alert-danger" *ngIf="f.submitted && !contactForm.message">
                  Message is required
                </div>
              <span class="bottomline"></span>
            </div>

            <div class="wrap">
              <input type="submit" value="Send">
            </div>

          </div>
        </form>
      </div>
    </div>
  </div>
  <!--eof: Contact Us-->
</div>