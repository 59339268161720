import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-payment-review',
  templateUrl: './payment-review.component.html',
  styleUrls: ['./payment-review.component.css']
})
export class PaymentReviewComponent implements OnInit {
  myParam: any;
  sampleList: any;
  languageList: any;
  categoryList: any;
  content: any;
  language: any;
  categories: any;
  subCategories: any;
  word: any;

  //Start from here For word count
  wordCount: any;
  @ViewChild("text")
  text!: ElementRef;
  words: any;
  wordCounter() {
    //alert(this.text.nativeElement.value)
    this.wordCount = this.text ? this.text.nativeElement.value.split(/\s+/) : 0;
    this.words = this.wordCount ? this.wordCount.length : 0;
  }
  //end 

  constructor(private userService: UserService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.myParam = params.get('queryParams');
      this.language = params.get('language');
      this.categories = params.get('categories');
      this.subCategories = params.get('subCategories');
      this.word = params.get('words');
    });
    this.getprofileDetails();
  }


  //fetching Edit Voice Details 
  getprofileDetails() {
    this.userService.getprofileDetails(this.myParam).subscribe(data => {
      this.sampleList = data;
      if (this.sampleList !== undefined && this.sampleList !== null && this.sampleList !== "") {
        this.languageList = data.languageList;
        this.categoryList = data.categoryList;
        this.sampleList = data.sampleList;
      }
    },
      err => {
        console.log(err);
      }
    );                  
  }

  paymentFinal(language: any, categories: any, subCategories: any, word: any) {
    this.router.navigate(['/paymentfinal', { queryParams: this.myParam, language: language, categories: categories, subCategories: subCategories, word: word }]);
  }

}
