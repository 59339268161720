<app-loader *ngIf="loader"></app-loader>

<div id="maincontainer">
    <!-- Banner -->
    <div class="dashboardbanner">
        <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
        <div class="bannerheading">Artist</div>
    </div>

    <div class="fullwidth bg_purple">
        <!--bof: myrecording-->
        <div class="w1200">
            <div class="dashboardwedget">
                <app-admin-sidebar></app-admin-sidebar>
                <!-- bof:wedgetright -->
                <div class="wedgetright">
                    <h3>Artist Dashboard</h3>
                    <div class="dashwed">
                        <ul class="homelink">
                            <li><a class="active" routerLink="/all-artist-details"><i
                                        class="fa fa-home fa-2x dashboardicon" aria-hidden="true"></i></a></li>
                            <li><a routerLink="/all-artist-details">Artist Dashboard</a></li>
                        </ul>
                    </div>
                    <div class="artistboard">
                        <div class="searchrow">
                            <div class="pagesdroplist">
                                <span>Total Artist: {{this.NoOfArtist}}</span>
                            </div>
                            <div class="searchinput"><input type="text" id="myInput" [(ngModel)]="searchParam"    placeholder="Search">
                            </div>
                        </div>

                        <!--boftablestructure  -->
                        <div class="atrtable">
                            <div class="row">
                                <div class="col"><span class="first1">Artist Id</span></div>
                                <div class="col"><span class="first2">Artist Name</span></div>
                                <div class="col"><span class="first3">Contact No</span></div>
                                <div class="col"><span class="first4">Email Address</span></div>
                                <div class="col"><span class="first3">Registration Date</span></div>
                                <div class="col"><span class="first3">Verification Status</span></div>
                                <div class="col"><span class="three9" style="width: 210px  !important;">Operation </span></div>
                                <div class="col"><span class="three9" style="width: 250px  !important;">Action</span></div>
                            </div>
                            <ng-container>
                            <div id="myList"
                                *ngFor="let data of allArtistDetailsfiltered | paginate: { itemsPerPage: 10, currentPage: page }">
                                <div class="row">
                                    <div class="col"><span class="first1"
                                            style="white-space: pre-wrap;">VM{{data.id}}</span></div>
                                    <div class="col"><span class="first2"
                                            style="white-space: pre-wrap;">{{data.username}} {{data.last_name}}</span>
                                    </div>
                                    <div class="col"><span class="first3"
                                            style="white-space: pre-wrap;">{{data.contact}}</span></div>
                                    <div class="col"><span class="first4"
                                            style="white-space: pre-wrap;">{{data.email}}</span></div>
                                    <div class="col"><span class="first3"
                                            style="white-space: pre-wrap;">{{data.createdDate | date}}</span></div>
                                    <div class="col" *ngIf="data.enabled == true">
                                        <span class="first3" style="white-space: pre-wrap; color: green;">Active</span>
                                    </div>
                                    <div class="col" *ngIf="data.enabled == false">
                                        <span class="first3" style="white-space: pre-wrap; color: red;">Pending</span>
                                    </div>

                                    <div class="col"><span class="three9" style="white-space: pre-wrap;width: 210px  !important;">
                                            <a class="viewprofilebtn" (click)="onClickId(data)"><i
                                                    class="fa fa-window-maximize" aria-hidden="true"></i>View Full
                                                Artist Details</a>
                                        </span></div>

                                        <div class="col" >                                    
                                            <span class="three9" style="white-space: pre-wrap;width: 250px  !important;" *ngIf="data.is_admin_approved !== 1 && data.is_admin_approved !== 2">
                                                <a class="activebtn" style="height: 31px !important;" (click)="ChangeUserStatus(data.id, 1, '')"><i>&#10004;</i> Approve</a>
                                                <a class="rejectbtn"  (click)="ChangeUserStatus(data.id, 2, '')" ><i>&#10006;</i> Reject</a>
                                            </span>
                                            <span *ngIf="data.is_admin_approved == 1" class="three9" style="white-space: pre-wrap;width: 250px  !important;color: green;" >Approved</span>
                                            <span *ngIf="data.is_admin_approved == 2" class="three9" style="white-space: pre-wrap;width: 250px  !important;color: red;" >Rejected</span>
                                       
                                        </div>
                                </div>
                                
                               
                            </div>
                        </ng-container>
                        </div>


                        <!-- <div class="paginations">
                            <pagination-controls (pageChange)="page = $event"></pagination-controls>
                        </div> -->
                        <div class="paginations">
                            <div class="custom-pagination-wrapper">
                                <button class="pagination-button margincorrect" [ngClass]="{ 'active': isFirstPage() }" (click)="goToFirstPage()">First</button>                    
                                <pagination-controls (pageChange)="onPageChange($event)" ></pagination-controls>                                                                        
                                <button class="pagination-button margincorrect" [ngClass]="{ 'lastactive': isLastPage(), 'lastdeactive': !isLastPage() }" (click)="goToLastPage()">Last</button>
                            </div>    
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--eof: myrecording-->

    </div>
</div>
<!-- start pop up-->
<div class="overlay" *ngIf="popup">
    <div class="content">
        <!--bof: ProfilePopup-->
        <div class="viewprofilesection">
            <div class="viewprofiles">
                <span class="material-icons-outlined revisionclose" (click)="popup = false">close</span>
                <h2>View Details</h2>
                <div class="insideview">
                    <ul>
                        <li><b>Artist Id :</b><span>VM{{editProfileForm.userId}}</span></li>
                        <li><b>About :</b><span>{{editProfileForm.about}}</span></li>
                    </ul>
                    <ul>
                        <li><b>Address :</b><span>{{editProfileForm.address1}}</span></li>
                        <li class="profilestate"><b>Country :</b><span>{{editProfileForm.country}}</span></li>
                        <li class="profilestate"><b>State :</b><span>{{editProfileForm.state}}</span></li>
                        <li class="profilestate"><b>City :</b><span>{{editProfileForm.city}}</span></li>
                        <li class="profilestate"><b>Zipcode :</b><span>{{editProfileForm.pinCode}}</span></li>
                        <li class="profilestate"><b>Gender :</b><span>{{editProfileForm.gender}}</span></li>
                        <li class="profilestate"><b>date Of birth :</b><span>{{editProfileForm.dob}}</span></li>
                        <li><b>Category :</b><span>{{editVoiceProfileForm.category}}</span></li>
                        <li><b>Language :</b><span>{{editVoiceProfileForm.language}}</span></li>
                        <li><b>Recording Setup :</b><span>{{editVoiceProfileForm.recording_Setup}}</span></li>
                        <li><b>Voice Age :</b><span>{{editVoiceProfileForm.voice_age}}</span></li>
                        <li><b>Accent :</b><span>{{editVoiceProfileForm.accents}}</span></li>
                        <li><b>Role :</b><span>{{editVoiceProfileForm.role}}</span></li>
                        <li><b>Style :</b><span>{{editVoiceProfileForm.style}}</span>
                        </li>
                        <li><b>Corporate IVR :</b></li>
                        <li>
                            <p>Minimum order value upto 5 prompts</p> (INR) :<span>{{Data.value1}}</span>
                        </li>
                        <li>
                            <p>Upto 15 prompts</p> (INR) :<span>{{Data.value2}}</span>
                        </li>
                        <li>
                            <p>Upto 50 prompts</p> (INR) :<span>{{Data.value77}}</span>
                        </li>

                        <li><b>E - learning :</b></li>
                        <li>
                            <p>Minimum order value Upto 1500 words</p> (INR) :<span>{{Data.value4}}</span>
                        </li>
                        <li>
                            <p>Upto 4500 words</p> (INR) :<span>{{Data.value5}}</span>
                        </li>
                        <li>
                            <p>Upto 7800 words</p> (INR) :<span>{{Data.value6}}</span>
                        </li>

                        <li><b>Radio Spot :</b></li>
                        <li>
                            <p>Price for 10 second spot</p> (INR) :<span>{{Data.value9}}</span>
                        </li>
                        <li>
                            <p>Price for 20 second spot</p> (INR) :<span>{{Data.value10}}</span>
                        </li>
                        <li>
                            <p>Price for 30 second spot</p> (INR) :<span>{{Data.value11}}</span>
                        </li>
                        <li>
                            <p>Price for 60 second spot</p> (INR) :<span>{{Data.value12}}</span>
                        </li>

                        <li><b>Audiobooks :</b></li>
                        <li>
                            <p>Mimumum order value upto 4500</p> (INR) :<span>{{Data.value14}}</span>
                        </li>
                        <li>
                            <p>Upto 7800 words</p> (INR) :<span>{{Data.value15}}</span>
                        </li>
                        <li>
                            <p>Upto 11700 words</p> (INR) :<span>{{Data.value16}}</span>
                        </li>

                        <li><b>Jingle :</b></li>
                        <li>
                            <p>Price for 10 second jingle</p> (INR) :<span>{{Data.value21}}</span>
                        </li>
                        <li>
                            <p>Price for 20 second jingle</p> (INR) :<span>{{Data.value22}}</span>
                        </li>
                        <li>
                            <p>Price for 30 second jingle</p> (INR) :<span>{{Data.value23}}</span>
                        </li>
                        <li>
                            <p>Price for 60 second jingle</p> (INR) :<span>{{Data.value24}}</span>
                        </li>

                        <li><b>Audio recording for explainer Video :</b></li>
                        <li>
                            <p>Minimum order value upto 200 words</p> (INR) :<span>{{Data.value31}}</span>
                        </li>
                        <li>
                            <p>Upto 500 words</p> (INR) :<span>{{Data.value32}}</span>
                        </li>
                        <li>
                            <p>Upto 1000 words</p> (INR) :<span>{{Data.value33}}</span>
                        </li>
                        <li>
                            <p>Upto 2000 words</p> (INR) :<span>{{Data.value34}}</span>
                        </li>

                        <li><b>TVC :</b></li>
                        <li>
                            <p>Price for 10 seconds TVC</p> (INR) :<span>{{Data.value37}}</span>
                        </li>
                        <li>
                            <p>Price for 20 seconds TVC</p> (INR) :<span>{{Data.value38}}</span>
                        </li>
                        <li>
                            <p>Price for 30 seconds TVC</p> (INR) :<span>{{Data.value39}}</span>
                        </li>
                        <li>
                            <p>Price for 40 seconds TVC</p> (INR) :<span>{{Data.value40}}</span>
                        </li>
                        <li>
                            <p>Price for 50 seconds TVC</p> (INR) :<span>{{Data.value41}}</span>
                        </li>
                        <li>
                            <p>Price for 60 seconds TVC</p> (INR) :<span>{{Data.value42}}</span>
                        </li>

                        <li><b>Advertisement (Social media, internal & other uses) :</b></li>
                        <li>
                            <p>Minumium order value upto 150 words</p> (INR) :<span>{{Data.value44}}</span>
                        </li>
                        <li>
                            <p>Upto 300 words</p> (INR) :<span>{{Data.value45}}</span>
                        </li>
                        <li>
                            <p>Upto 600 words</p> (INR) :<span>{{Data.value46}}</span>
                        </li>
                        <li>
                            <p>Upto 900 words</p> (INR) :<span>{{Data.value47}}</span>
                        </li>
                        <li>
                            <p>Upto 1200 words</p> (INR) :<span>{{Data.value48}}</span>
                        </li>


                        <li><b>Documentary :</b></li>
                        <li>
                            <p>Minumium order value upto 600 words</p> (INR) :<span>{{Data.value51}}</span>
                        </li>
                        <li>
                            <p>Upto 1200 words</p> (INR) :<span>{{Data.value52}}</span>
                        </li>
                        <li>
                            <p>Upto 1800 words</p> (INR) :<span>{{Data.value53}}</span>
                        </li>
                        <li>
                            <p>Upto 2400 words</p> (INR) :<span>{{Data.value54}}</span>
                        </li>
                        <li>
                            <p>Upto 3000 words</p> (INR) :<span>{{Data.value55}}</span>
                        </li>

                        <li><b>Animation :</b></li>
                        <li>
                            <p>Minimum order value upto 200 words</p> (INR) :<span>{{Data.value58}}</span>
                        </li>
                        <li>
                            <p>Upto 500 words</p> (INR) :<span>{{Data.value59}}</span>
                        </li>
                        <li>
                            <p>Upto 1000 words</p> (INR) :<span>{{Data.value60}}</span>
                        </li>
                        <li>
                            <p>Upto 2000 words</p> (INR) :<span>{{Data.value61}}</span>
                        </li>

                        <li><b>Voice assistance :</b></li>
                        <li>
                            <p>Minimum order value upto 200 words</p> (INR):<span>{{Data.value64}}</span>
                        </li>
                        <li>
                            <p>Upto 500 words</p> (INR) :<span>{{Data.value65}}</span>
                        </li>
                        <li>
                            <p>Upto 1000 words</p> (INR) :<span>{{Data.value66}}</span>
                        </li>
                        <li>
                            <p>Upto 2000 words</p> (INR) :<span>{{Data.value67}}</span>
                        </li>

                        <li><b>Podcast :</b></li>
                        <li>
                            <p>Minimum order value Upto 1500 words</p> (INR) :<span>{{Data.value70}}</span>
                        </li>
                        <li>
                            <p>Upto 4500 words</p> (INR) :<span>{{Data.value71}}</span>
                        </li>
                        <li>
                            <p>Upto 7800 words</p> (INR) :<span>{{Data.value72}}</span>
                        </li>
                        <li><b>TAT :</b></li>
                        <li>
                            <p>Turn around Time(in hours)</p><span>{{Data.value76}}</span>
                        </li>
                    </ul>
                    <div class="row">
                        <ul>
                            <!-- <li><b>Available 24x7 :</b><span>{{availabilityForm.is24Available}}</span></li> -->
                            <li><b>Available Day :</b><span>

                                <span *ngIf="availabilityForm.daySunday">Sunday<span>{{availabilityForm.daySunday}}</span></span>
                                <span *ngIf="availabilityForm.dayMonday">Monday<span>{{availabilityForm.dayMonday}}</span></span>
                                <span *ngIf="availabilityForm.dayTuesday">Tuesday<span>{{availabilityForm.dayTuesday}}</span></span>
                                <span *ngIf="availabilityForm.dayWednesday">Wednesday<span>{{availabilityForm.dayWednesday}}</span></span>
                                <span *ngIf="availabilityForm.dayThursday">Thursday<span>{{availabilityForm.dayThursday}}</span></span>
                                <span *ngIf="availabilityForm.dayFriday">Friday<span>{{availabilityForm.dayFriday}}</span></span>
                                <span *ngIf="availabilityForm.daySaturday">Saturday<span>{{availabilityForm.daySaturday}}</span></span>


                            </span></li>
                            <!-- <li><b>Time Availability :</b><span>{{availabilityForm.timeAvailability}}</span></li> -->
                            <!-- <li><b>Available hours :</b><span>{{availabilityForm.startTime}}
                                    {{availabilityForm.endTime}}</span></li> -->
                            <li><b>Microphone used :</b><span>{{equipmentExperienceForm.microphoneUsed}}</span></li>
                            <li><b>Recording/Editing Software
                                    :</b><span>{{equipmentExperienceForm.recordingEditing}}</span></li>
                            <li><b>Special Equipments used :</b><span>{{equipmentExperienceForm.socialEquipment}}</span>
                            </li>
                            <li><b>Experience :</b><span>{{equipmentExperienceForm.experience}}</span></li>
                            <li><b>Clients worked for :</b><span>{{equipmentExperienceForm.clientWorkedFor}}</span></li>
                            <li><b>Education :</b><span>{{equipmentExperienceForm.education}}</span></li>
                            <li><b>Services Offered :</b><span>{{equipmentExperienceForm.serviceOffered}}</span></li>
                        </ul>
                    </div>
                    <strong>Social Media Profile</strong>
                    <div class="row">
                        <ul>
                            <li><b>Social Media Profile (Facebook)
                                    :</b><span>{{equipmentExperienceForm.socialMediaLinkFB}}</span></li>
                            <li><b>Social Media Profile (Twitter)
                                    :</b><span>{{equipmentExperienceForm.socialMediaLinkTwitter}}</span></li>
                            <li><b>Social Media Profile (LinkedIn)
                                    :</b><span>{{equipmentExperienceForm.socialMediaLinkLinked}}</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!--eof: ProfilePopup-->
    </div>
</div>
