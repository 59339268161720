import { Component, ElementRef, ViewChildren, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Toast, ToastrService } from 'ngx-toastr';
import { OrderService } from '../_services/order.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { UserService } from '../_services/user.service';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-client-booking-details',
  templateUrl: './client-booking-details.component.html',
  styleUrls: ['./client-booking-details.component.css']
})
export class ClientBookingDetailsComponent implements OnInit {

  content: any;
  script: any;
  language: any;
  category: any;
  updatedDate: any;
  uploadArtistVoiceDetails: any;
  popup = false;
  satisfiedPopup = false;
  bookingStatus: any;
  currentUser: any;
  contact: any;
  id: any;
  firstName: any;
  lastName: any;
  email: any;
  loader: boolean = true;
  customerId: any;
  artistId: any;
  totalWords: any;
  recordingPrice: any;
  instruction: any;
  customerBookingDetails: any;
  referenceId: any;
  status: boolean = true;
  comments: any;
  revisionStatus: boolean = false;
  orderStatus: boolean = false;
  userRatingValue: any;
  orderId: any;
  ratingComments: any;
  ratingStatus: boolean = false;
  reviewAndRatingList: any;
  acceptedStatus: any;
AllChats:any;
dataNotFound!:boolean;

  constructor(private token: TokenStorageService, private userService: UserService, private route: ActivatedRoute,
    private orderService: OrderService, private toastr: ToastrService,private http:HttpClient) { }

  ngOnInit(): void {
    this.loader = false;
    this.currentUser = this.token.getUser();
    this.contact = this.token.getUser().contact;
    this.id = this.token.getUser().id;
    this.email = this.token.getUser().email;
    this.firstName = this.token.getUser().username;
    this.lastName = this.token.getUser().lastname;
    this.route.paramMap.subscribe(params => {
      this.referenceId = params.get('referenceId');
    });
    this.getCustomerBookingDetailsOnReferenceId();
    this.getuploadArtistVoice();
    this.getReviewAndRating();
    this.getAllChatByProjectId();
  }

  @ViewChildren('audio') audioElms!: ElementRef[];

  ngAfterViewInit() {
    console.log(this.audioElms)
  }

  onPaly(elm: HTMLAudioElement) {
    this.audioElms.forEach(({ nativeElement: e }) => {
      if (e !== elm) {
        e.pause();
      }
    })
  }


getAllChatByProjectId(){
  try {
    if(this.referenceId !== null && this.referenceId !== undefined && this.referenceId !== ''){
       this.userService.GetAllChatsByProjectId(this.referenceId).subscribe((response)=>{
        console.log("response",response);
        if(response && response !== null){          
          this.AllChats = response;
          console.log("All Chat DAta",this.AllChats);
        }else{
          this.dataNotFound = true;
        }
       },
       (error:HttpErrorResponse)=>{
        this.toastr.error(error.error.message);
        console.log("Error in getting chats by order id ", error);
       }
       )
     }else{
      this.toastr.error("something went wrong in found order id")
     }
  } catch (error) {
   console.log("Error in getting chat by project",error); 
  }
}

ChatMessag:any = {
  msg:null,
}


insertChat(){
try {
  // alert(this.ChatMessag.msg);
  if(this.referenceId !== null &&  this.referenceId !== undefined &&  this.referenceId !== '' && this.ChatMessag.msg !== null && this.ChatMessag.msg !== undefined && this.ChatMessag.msg !== ''){
    const ChatData = {
      order_id : this.referenceId,
      msg:this.ChatMessag.msg,
      type:"Customer"
    }
    this.userService.SaveChat(ChatData).subscribe((response)=>{
   if(response){
    console.log("chat data  ",ChatData)
    console.log("response ",response)
    this.toastr.success("Chat Addedd Successfully");
    this.ChatMessag.msg = '';
   }
    })
  }else{
    this.toastr.error("Please write message first");
  }
} catch (error) {
  console.log("Error in insert chat by customer",error);
}
}



  getCustomerBookingDetailsOnReferenceId() {
    this.loader = true;
    this.userService.getCustomerBookingDetailsOnReferenceId(this.referenceId).subscribe(data => {
      this.customerBookingDetails = data;
      if (this.customerBookingDetails !== null && this.customerBookingDetails !== "") {
        this.loader = false;
        this.artistId = this.customerBookingDetails.artistId;
        this.category = this.customerBookingDetails.category;
        this.language = this.customerBookingDetails.language;
        this.recordingPrice = this.customerBookingDetails.finalPrice + this.customerBookingDetails.tax;
        this.script = this.customerBookingDetails.script;
        this.updatedDate = this.customerBookingDetails.updatedDate;
        this.bookingStatus = this.customerBookingDetails.status;
        this.instruction = this.customerBookingDetails.instruction;
        this.customerId = this.customerBookingDetails.customerId;
        this.acceptedStatus = this.customerBookingDetails.status;
      }
    },
      err => {
        console.log(err);
      }
    );
  }

  //update Status to customer for order complete
  orderComplete() {
    this.loader = true;
    this.orderService.orderComplete(this.referenceId, this.status).subscribe(data => {
      this.loader = false;
      this.toastr.success('Congratulation your project has been completed!! ', '')
      window.location.reload();
    },
      err => {
        console.log(err);
      }
    );
  }


  //Review and rating from customer side when completed the project.
  reviewAndRating() {
    this.orderService.reviewAndRating(this.userRatingValue, this.referenceId, this.ratingComments, this.artistId, this.customerId, this.ratingStatus).subscribe(data => {
      this.loader = false;
      this.orderComplete();
      setTimeout(()=>{                     
        window.location.reload();
      }, 500);
    },
      err => {
        console.log(err);
      }
    );
  }

  //Get Review and rating based on artist id 
  getReviewAndRating() {
    this.userService.getReviewAndRating(this.id).subscribe(data => {
      this.reviewAndRatingList = data;
      if (this.reviewAndRatingList !== null && this.reviewAndRatingList !== "") {
        this.loader = false;
        this.reviewAndRatingList.forEach((element: {}) => {
        });
      }
    },
      err => {
        console.log(err);
      }
    );
  }

  //start from here to get Uploaded voice from artis side
  getuploadArtistVoice() {
    this.loader = true;
    this.orderService.getuploadArtistVoice(this.referenceId).subscribe(data => {
      this.uploadArtistVoiceDetails = data;
      if (this.uploadArtistVoiceDetails !== null && this.uploadArtistVoiceDetails !== "") {
        this.loader = false;
        this.uploadArtistVoiceDetails.forEach((element: {
        }) => {
        });
      }
    },
      err => {
        console.log(err);
      }
    );
  }

  //Revision Comments 
  revisionComments() {
    this.loader = true;
    this.orderService.revisionComments(this.referenceId, this.comments, this.artistId, this.id, this.revisionStatus).subscribe(data => {
      this.loader = false;
      this.toastr.success('Your Revision comments has been sent successfully!! ', '')
      window.location.reload();
    },
      err => {
        console.log(err);
      }
    );
  }
}
