<!-- Banner -->
<div class="fullwidth bg_purple">
    <!--bof: myrecording-->
    <div class="w1200">
        <div class="wedget">
            <!-- bof:wedgetleft -->
            <app-sidebar></app-sidebar>
            <!-- bof:wedgetright -->
            <div class="wedgetright">
                <!--Transtion history-->
                <div class="tab_content t_history">
                    <h3>Payment history</h3>
                    <div class="transhistory">
                        <div class="row historyhead">
                            <div class="col"><span class="transid">Order ID</span></div>
                            <div class="col"><span class="totalWord">Total word</span></div>
                            <div class="col"><span class="artistid">Customer ID</span></div>
                            <div class="col"><span class="amounthistory">Amount</span></div>
                            <div class="col"><span class="datehistory">Date</span></div>
                            <div class="col"><span class="statushistory">Status</span></div>
                        </div>
                        <div id="myList" *ngFor="let data of ProjectPaymentDetails">
                            <div class="row">
                                <div class="col"><span class="transid">{{data.referenceId}}</span></div>
                                <div class="col"><span class="totalWord">{{data.totalWord}}</span></div>
                                <div class="col"><span class="artistid">VM{{data.customerId}}</span></div>
                                <div class="col"><span class="amounthistory"> ₹ {{data.artistPrice}}</span></div>
                                <div class="col"><span class="datehistory">{{data.updatedDate |
                                        date:'dd/MM/yyyy'}}</span></div>
                                <div class="col" *ngIf="data.paymentStatus == true"><span
                                        class="statushistory completed">Paid</span></div>
                                <div class="col" *ngIf="data.paymentStatus == false"><span
                                        class="statushistory pending">In progress</span></div>
                            </div>
                        </div>
                        <h1 *ngIf="ProjectPaymentDetails?.length == 0">No Data found !</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--eof: myrecording-->
</div>