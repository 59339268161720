<div class="maincontainer">
    <div class="dashboardbanner">
        <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
        <div class="bannerheading">
            My Profile
        </div>
    </div>

    <div class="fullwidth  bg_purple">
        <div class="w1200">
            <div class="dashboardwedget">
                <app-admin-sidebar></app-admin-sidebar>
                <div class="wedgetright">
                    <h3>Change Password</h3>
                    <div class="dashwed">
                        <ul class="homelink">
                            <li><a class="active" routerLink="/user-password"><i class="fa fa-home fa-2x dashboardicon"
                                        aria-hidden="true"></i></a></li>
                            <li><a routerLink="/user-password">Change Password</a></li>
                        </ul>
                    </div>
                    <div class="artistboard">
                        <div class="wedgetform">
                            <div class="form1">
                                <form  #userform="ngForm"    class="formpanel">
                                    <div class="formwrap">
                                      <label for="f_name">Current Password </label> <span style="color: red;">*</span>
                                      <input type="text" name="correntP"    id="f_name" placeholder="Current Password"required [(ngModel)]="PasswordData.CPassword" [ngModelOptions]="{standalone:true}" [maxlength]="100">
                                      <div class="alert" style="color: red;" *ngIf="CurrentPassswordHide">{{ CurrentPassswordError }}</div>
                                    </div>
                                    <div class="formwrap">
                                        <label for="lname">New Password </label> <span style="color: red;">*</span>
                                        <input type="text" name="paasword" id="lname"   placeholder="New Password"required  [(ngModel)]="PasswordData.Password"  [ngModelOptions]="{standalone:true}" [maxlength]="100">
                                        <div class="alert" style="color: red;" *ngIf="NewPasswordHide">{{ NewPasswordError }}</div>
                                      </div>
                                    <div class="formwrap">
                                        <label for="email">Confirm New Password </label> <span style="color: red;">*</span>
                                        <input type="text" name="cp" id="email"   placeholder="Confirm New Password" required [(ngModel)]="PasswordData.ConfirmPassword" [ngModelOptions]="{standalone:true}" [maxlength]="100">
                                        <div class="alert" style="color: red;" *ngIf="ConfirmPasswordHide">{{ ConfirmPasswordError }}</div>
                                    </div>
                                    

                                    <div class="formwrapfull">
                                        <div class="submitdiv">
                                          <input type="submit" value="Update" (click)="changePassword()" >
                                          <i class="fa fa-angle-right" aria-hidden="true"></i>
                                        </div> 
                                        <strong style="top: 14px !important;">Items marked with <span>*</span> are mandatory</strong>
                                      </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>



        </div>


    </div>