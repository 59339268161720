import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../_services/user.service';
import { Ng2SearchPipe, Ng2SearchPipeModule } from 'ng2-search-filter';

@Component({
  selector: 'app-view-roles',
  templateUrl: './view-roles.component.html',
  styleUrls: ['./view-roles.component.css'],
})
export class ViewRolesComponent implements OnInit {
  loader: boolean = true;
  AllRolesDeteils: any;
  AllRolesfiltred: any;
  set searchParam(value: any) {
    if (value != '' && value !== null)
      this.AllRolesfiltred = Ng2SearchPipe.prototype.transform(
        this.AllRolesDeteils,
        value
      );
    else 
    this.AllRolesfiltred = this.AllRolesDeteils;
    this.page = 1;
    this.NoOFRoles = this.AllRolesfiltred.length;
  }

  page: number = 1;
  NoOFRoles: any;

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.loader = false;
    this.getAllRolesList();
  }


  goToFirstPage(){
  this.page = 1;
}

goToLastPage(){
const totalPages =  Math.ceil(this.AllRolesfiltred.length / 10);
this.page = totalPages;
}

isFirstPage(): boolean {
  return this.page != 1;
}

isLastPage(): any {
  if(this.page == Math.ceil(this.AllRolesfiltred.length / 10)){
    return false;
  } else {
    return true;
  }    

}



onPageChange(event: number): void {
  this.page = event;
}



  getAllRolesList() {
    try {
      this.userService.getAllRolesList().subscribe((response) => {
        if (response) {
          this.AllRolesDeteils = response;
          this.AllRolesfiltred = response;
          this.NoOFRoles = this.AllRolesDeteils.length;
        } else {
          console.log('something went wrong in role found');
        }
      });
    } catch (error) {
      console.log('Error', error);
    }
  }

  editRole(id: number) {
    this.router.navigate(['edit-role/',id]);
  }

  deleteRoleById(id: number) {
    try {
      if (id !== null && id !== undefined) {
        //  this.userService.deleteRoleById(id).subscribe((response)=>{
        //   if(response.status == 200){
        //     this.toastr.success("Role Delete Successfully",'');
        //     window.location.reload();
        //   }else{
        //     this.toastr.error("Something went wrong",'');
        //   }
        //  })
      }
    } catch (error) {
      console.log('Error', error);
    }
  }
}
