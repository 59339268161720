import { Component, OnInit } from '@angular/core';
import { UserService } from '../_services/user.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {
  loader:boolean =true;
  GetAllRoles: any;
  UserNameHide!: boolean;
  UserNameError: any = '';
  EmailHide!: boolean;
  EmailError: any = '';
  ContactHide!: boolean;
  ContactError: any = '';
  RoleHide!: boolean;
  RoleError: any = '';
  LastNameHide!: boolean;
  LastNameError: any = '';
  roleName: any[] = [];
  UserId:any;
  UserSelectedData:any;

  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private router: Router,
    private activeroute: ActivatedRoute,
    private http:HttpClient
  ) {}

  ngOnInit(): void {
    this.UserData.Role = 'Please Select Role';
    this.loader = false;  
    this.activeroute.params.subscribe((param)=>{
      this.UserId = +param['id'];
     })
     this.GetUserDataById();
     this.getAllRoles();
  }

  UserData: any = {
    Fname: null,
    Lname: null,
    Email: null,
    Contact: null,
    Role: null,
  };

  getAllRoles() {
    try {
      this.userService.getAllRolesList().subscribe((response) => {
        if (response) {
          this.GetAllRoles = response;   
         } else {
          console.log('Something went wrong');
        }
      },
      (error:HttpErrorResponse)=>{
        if(error.status == 400 || error.status !== null){
          console.log("Error in get All Roles ",error);
          this.toastr.error(error.error.message,'');
        }
      }
      );
    } catch (error) {
      console.log('Error in fetching roles');
    }
  }

GetUserDataById(){
try {
  if(this.UserId !== null && this.UserId !== undefined && this.UserId !== ''){
     this.userService.GetUserByID(this.UserId).subscribe((response)=>{
      if(response){
       this.UserSelectedData = response
       this.UserData.Fname =  this.UserSelectedData.username;         
       this.UserData.Lname =  this.UserSelectedData.last_name;         
       this.UserData.Email =  this.UserSelectedData.email;         
       this.UserData.Contact =  this.UserSelectedData.contact;   
       const rolesGet =       this.UserSelectedData.roles;
       this.UserData.Role =  rolesGet[0].name; 
      }else{
        console.log("something went wrong");
      }
     },
     (error:HttpErrorResponse)=>{
      if(error.status == 400 || error.status !== null){
        console.log("Error in get User by User id ", error);
        this.toastr.error(error.error.message,'');
      }      
     }
     );
  }  
} catch (error) {
  console.log("Error in fecting user data by user id ", error);
}

}


  EditUser() {
    try {
      // alert(this.UserData.Fname);
      // alert(this.UserData.Lname);
      // alert(this.UserData.Email);
      // alert(this.UserData.Contact);
      // alert(this.UserData.Role);
       this.validate();
       if (
        this.UserData.Fname !== null && this.UserData.Fname !== '' &&
        this.UserData.Lname !== null && this.UserData.Lname !== '' &&
        this.UserData.Email !== null && this.UserData.Email !== '' &&
        this.UserData.Contact !== null && this.UserData.Contact !== '' &&
        this.UserData.Role !== null && this.UserData.Role !== 'Please Select Role' && this.UserData.Role !== ''
      ) {
        this.roleName = this.UserData.Role;
        const EditUser = {
          user_id: this.UserId,
          username: this.UserData.Fname,
          role: [this.roleName],
          last_name: this.UserData.Lname,
          contact: this.UserData.Contact,
          email: this.UserData.Email,
        };
          this.userService.EditUSER(EditUser).subscribe((response) => {
          if (response) {
             setTimeout(()=>{
            this.toastr.success('User Update Successfully'); 
             this.router.navigate(['/view-users']);
              },200)      
          }
        },
        (error:HttpErrorResponse)=>{
          if(error.status == 400 || error.status !== null){
            console.log("Error in edit user", error);
            this.toastr.error(error.error.message,'');
          }
        }
        );
      } else {
        this.toastr.error('please select all mandatory fields');
      }
    } catch (error:any) {
      this.toastr.error(error.error,'');
      console.log('Error in save user', error);
    }
  }

  validate() {
    if (
      this.UserData.Fname == null ||
      this.UserData.Fname == '' ||
      this.UserData.Fname == undefined
    ) {
      this.UserNameHide = true;
      this.UserNameError = 'First name is mandatory !';
    } else {
      this.UserNameHide = false;
    }
    if (
      this.UserData.Lname == null ||
      this.UserData.Lname == '' ||
      this.UserData.Lname == undefined
    ) {
      this.LastNameHide = true;
      this.LastNameError = 'Last name is mandatory !';
    } else {
      this.LastNameHide = false;
    }
    if (
      this.UserData.Email == null ||
      this.UserData.Email == '' ||
      this.UserData.Email == undefined
    ) {
      this.EmailHide = true;
      this.EmailError = 'Email is mandatory !';
    } else {
      this.EmailHide = false;
    }
    if (
      this.UserData.Contact == null ||
      this.UserData.Contact == '' ||
      this.UserData.Contact == undefined
    ) {
      this.ContactHide = true;
      this.ContactError = 'Contact is mandatory !';
    } else {
      this.ContactHide = false;
    }
    if (
      this.UserData.Role == null ||
      this.UserData.Role == '' ||
      this.UserData.Role == undefined || this.UserData.Role == 'Please Select Role'
    ) {
      this.RoleHide = true;
      this.RoleError = 'Role is mandatory !';
    } else {
      this.RoleHide = false;
    }
  }


}
