<div class="maincontainer">
        <div class="dashboardbanner">
                <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
                <div class="bannerheading">Revenue Report</div>
        </div>

        <div class="fullwidth bg_purple">
                <!--bof: myrecording-->
                <div class="w1200">
                        <div class="dashboardwedget">
                                <app-admin-sidebar></app-admin-sidebar>
                                <!-- bof:wedgetright -->
                                <div class="wedgetright">
                                        <h3>Revenue Report</h3>
                                        <div class="dashwed">
                                                <ul class="homelink">
                                                        <li><a class="active" routerLink="/revenue-report"><i
                                                                                class="fa fa-home fa-2x dashboardicon"
                                                                                aria-hidden="true"></i></a></li>
                                                        <li><a routerLink="/revenue-report">Revenue
                                                                        Report</a></li>
                                                </ul>
                                        </div>
                                        <div class="artistboard">
                                                <div class="searchrow">
                                                        <div class="pagesdroplist">
                                                                <label for="" style="  float: left;  font-size: 13px;">Please Select Date Range</label> <br> <br>
                                                                <mat-form-field appearance="fill"   >                                                                       
                                                                        <mat-date-range-input [rangePicker]="picker">
                                                                                <input matStartDate readonly
                                                                                        style="font-size: 14px;"
                                                                                        [(ngModel)]="SelectDate.StartDate"
                                                                                        [ngModelOptions]="{standalone:true}">
                                                                                <input matEndDate readonly
                                                                                        style="font-size: 14px;"  (dateChange)="onEndDateSelected($event)"
                                                                                        [(ngModel)]="SelectDate.EndDate"
                                                                                        [ngModelOptions]="{standalone:true}"  >
                                                                        </mat-date-range-input>
                                                                        <!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
                                                                        <mat-datepicker-toggle matSuffix
                                                                                [for]="picker"></mat-datepicker-toggle>
                                                                        <mat-date-range-picker style="font-size:30px;"
                                                                                #picker></mat-date-range-picker>
                                                                </mat-form-field>


                                                        </div>

                                                </div>


                                                <div class="searchrow">
                                                        <div class="pagesdroplist">
                                                                <span>Total Records: {{ Totalrecord }}
                                                                </span>
                                                        </div>
                                                        <div class="searchinput"><input type="text" id="myInput"
                                                                        [(ngModel)]="searchParam" placeholder="Search">
                                                        </div>
                                                </div>

                                                <!--boftablestructure  -->
                                                <div class="atrtable">
                                                        <div class="row">
                                                                <div class="col"><span class="first1 centerClass"
                                                                                style="width:33px    !important;">S
                                                                                No</span></div>
                                                                <div class="col"><span class="first1 centerClass"
                                                                                style="width: 134px  !important;">Order
                                                                                Date</span></div>
                                                                <div class="col"><span class="first2 centerClass"
                                                                                style="width: 87px !important;">Customer
                                                                                Id</span>
                                                                </div>
                                                                <div class="col"><span class="first3 centerClass"
                                                                                style="width: 82px !important;">Artist
                                                                                Id</span>
                                                                </div>
                                                                <div class="col"><span class="first4 centerClass"
                                                                                style="width: 107px !important;">
                                                                                Order Id</span>
                                                                </div>

                                                                <div class="col"><span class="first3 centerClass"
                                                                                style="width: 60px !important;">Tax
                                                                                INR</span>
                                                                </div>
                                                                <div class="col"><span class="first3 centerClass"
                                                                                style="width: 104px !important;">Billing
                                                                                Amount INR</span>
                                                                </div>
                                                                <div class="col"><span class="first3 centerClass"
                                                                                style="width: 70px !important;">Total
                                                                                INR</span>
                                                                </div>

                                                        </div>
                                                        <div id="myList"
                                                                *ngFor="let chatData of AllFiltredRevenuereport | paginate : {itemsPerPage:10, currentPage:page}; let i = index"
                                                                [attr.data-index]="i+(page-1)*10+1">
                                                                <div class="row">
                                                                        <div class="col"><span
                                                                                        class="first1 centerClass"
                                                                                        style="width: 33px    !important;">{{
                                                                                        i+(page-1)*10+1 }}</span></div>
                                                                        <div class="col"><span
                                                                                        class="first3 centerClass"
                                                                                        style="width: 134px !important;">{{
                                                                                        chatData.updatedDate }}</span>
                                                                        </div>

                                                                        <div class="col"><span
                                                                                        class="first2 centerClass"
                                                                                        style="width: 87px !important;">VM{{
                                                                                        chatData.customerId }}</span>
                                                                        </div>
                                                                        <div class="col"><span
                                                                                        class="first3 centerClass"
                                                                                        style="width: 82px !important;">VM{{
                                                                                        chatData.artistId }}</span>
                                                                        </div>

                                                                        <div class="col"><span
                                                                                        class="first4 centerClass"
                                                                                        style="width: 107px !important;">{{
                                                                                        chatData.referenceId }}</span>
                                                                        </div>

                                                                        <div class="col"><span
                                                                                        class="first4 centerClass"
                                                                                        style="width: 60px !important;">{{
                                                                                        chatData.tax }}</span></div>

                                                                        <div class="col"><span
                                                                                        class="first4 centerClass"
                                                                                        style="width: 104px !important;">{{
                                                                                        chatData.finalPrice }}</span>
                                                                        </div>

                                                                        <div class="col"><span
                                                                                        class="first4 centerClass"
                                                                                        style="width: 70px !important;">{{
                                                                                        chatData.finalPrice +
                                                                                        chatData.tax }}</span></div>

                                                                </div>

                                                        </div>

                                                </div>

                                                <div class="paginations">
                                                        <div class="custom-pagination-wrapper">
                                                                <button class="pagination-button margincorrect"
                                                                        [ngClass]="{ 'active': isFirstPage() }"
                                                                        (click)="goToFirstPage()">First</button>
                                                                <pagination-controls
                                                                        (pageChange)="onPageChange($event)"></pagination-controls>
                                                                <button class="pagination-button margincorrect"
                                                                        [ngClass]="{ 'lastactive': isLastPage(), 'lastdeactive': !isLastPage() }"
                                                                        (click)="goToLastPage()">Last</button>
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                        </div>
                        <!--eof: myrecording-->
                </div>
        </div>
</div>