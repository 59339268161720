import { Component, OnInit } from '@angular/core';
import { UserService } from '../_services/user.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Ng2SearchPipe } from 'ng2-search-filter'; 
import { event } from 'jquery';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  loader:boolean = true;
  AllUsersDeatils:any;
  AllfiltredUsers:any;
  set searchParam(value:any){
    if(value !== '' && value !== null)
    this.AllfiltredUsers = Ng2SearchPipe.prototype.transform(this.AllUsersDeatils, value);
   else 
   this.AllfiltredUsers =this.AllUsersDeatils;
   this.page = 1 ;
   this.totUser =  this.AllfiltredUsers.length;
  }
    
  page:number = 1;
  totUser:any;

  constructor(private userService:UserService, private router:Router,private toastr:ToastrService) { }

  ngOnInit(): void {
    this.loader =false;
    this.GetAllUsers();
   }

   goToFirstPage(){
    this.page = 1 ;
  }
 
  goToLastPage(){
    const totalpages = Math.ceil(this.AllfiltredUsers.length / 10);
    this.page = totalpages;
  }
  
  isFirstPage(): boolean{
  return this.page != 1;
}

isLastPage(): any{
  if(this.page == this.AllfiltredUsers.length / 10 ){
    return false
  }else{
    return true;
  }
}

 

onPageChange(event: number): void {
  this.page = event;
}




  GetAllUsers(){
    try {
      this.userService.getAllUsers().subscribe((response)=>{
        if(response){
         this.AllUsersDeatils = response;
         this.AllfiltredUsers = response;
         this.totUser = this.AllUsersDeatils.length;
        }else{
          console.log("Something went wrong");
        }
      })
    } catch (error) {
      console.log("All User Error", error);
    }
  }




  edituser(id:number){
    this.router.navigate(['/edit-user/',id]);    
  }

// please change api this is not correct  api
  deleteUser(id:number){
    try {
      this.userService.deleteRoleById(id).subscribe((response)=>{
         if(response){
           this.toastr.success("User Delete Successfully",'');           
         }else{
             this.toastr.error("Something went wrong",'');         }
      })
    } catch (error) {
      console.log("Error In user Delete",error);
    }
  }

}
