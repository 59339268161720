<app-loader *ngIf="loader"></app-loader>
<!--bof:STAR REVIEW-->
<div class="starbase">
    <div class="starreview">
        <span class="material-icons-outlined satisfiedclose">close</span>
        <strong>Review &amp; Rating</strong>
        <div class="reviewpart">
            <p>Hi, We would like you to rate the artist</p>
            <div class="starrow">
                <i class="fa fa-star-o" aria-hidden="true"></i>
                <i class="fa fa-star-o" aria-hidden="true"></i>
                <i class="fa fa-star-o" aria-hidden="true"></i>
                <i class="fa fa-star-o" aria-hidden="true"></i>
                <i class="fa fa-star-o" aria-hidden="true"></i>
            </div>
            <p>We would like you to review the artist too.</p>
            <textarea></textarea>
            <input type="Submit" value="Submit">
        </div>
    </div>
</div>
<!--eof:STAR REVIEW-->

<!--bof: Revision Remarks-->
<div class="revision">
    <div class="revisionview">
        <span class="material-icons-outlined revisionclose">close</span>
        <strong>Revision Comments</strong>
        <textarea></textarea>
        <input type="Submit" value="Submit">
    </div>
</div>
<!--eof: Revision Remarks-->

<!-- Banner -->
<div class="banner clientbanner">
    <img src="/assets/images/banner.jpg" alt="Banner">
</div>

<div class="fullwidth bg_purple">
    <!--bof:artistrecording-->
    <!--bof: myrecording-->
    <div class="w1200">
        <div class="wedget">
            <!-- bof:wedgetleft -->
            <app-sidebar></app-sidebar>
            <!-- bof:wedgetright -->
            <div class="wedgetright">
                <!--Notification-->
                <div class="w1200">
                    <h3 class="invoiceheading">Order Details. </h3>
                    <div class="invoicebx">
                        <div class="invoicedetail">
                            <div class="imgpart"><img
                                    src="/assets/images/imginvoice.jpg"
                                    alt="invoice"></div>
                            <div class="detailpart">
                                <div class="iconwithdetail"><i
                                        class="fa fa-user-circle-o imgnone"
                                        aria-hidden="true"></i>
                                    VM{{this.artistId}}</div>
                                <!-- <div class="iconwithdetail iconinline"><i class="anchorarow"></i> Male</div>
                                <div class="iconwithdetail iconinline"><i class="birthdate"></i> 29</div> -->
                                <div class="iconwithdetail"><i
                                        class="caticon"></i> Category :
                                    {{this.category}}</div>
                                <div class="iconwithdetail"><i
                                        class="languateicon"></i> Language :
                                    {{this.language}}
                                </div>
                                <div class="iconwithdetail"><i
                                        class="recordicon"></i> Recording : Rs.
                                    {{this.recordingPrice}}</div>
                                <div class="iconwithdetail"><i
                                        class="recordicon"></i> Order Id :
                                    {{this.referenceId}}
                                </div>
                            </div>
                        </div>

                        <!--Part 1-->
                        <div class="loopreview">
                            <div class="invoicedescription">
                                <strong>Script uploaded date:
                                    <span>{{this.updatedDate | date: 'dd/MM/yyyy
                                        hh:mma'}}</span></strong>
                                <p>{{this.script}}
                                </p>
                                <strong>Instruction: </strong>
                                <p>{{this.instruction}}
                                </p>
                                <div
                                    *ngFor="let data of uploadArtistVoiceDetails">
                                    <div class="invoicedescription">
                                        <div *ngIf="data.status === true">
                                            <strong>Recording Uploaded date:
                                                <span>{{data.updatedDate |
                                                    date:'dd/MM/yyyy
                                                    hh:mma'}}</span></strong>
                                            <audio controls #audio
                                                (play)="onPaly(audio)"
                                                class="audio">
                                                <source
                                                    src="https://api.pinnaklebiz.com/thevoicemall/sample/{{data.uploadedVoice}}">
                                                <!-- <source src="/assets/voice/{{data.uploadedVoice}}"> -->
                                            </audio>
                                        </div>
                                    </div>

                                    <div class="invoicebtngrp"
                                        *ngIf="data.status === true && (this.acceptedStatus === false || this.acceptedStatus === null)">
                                        <ul>
                                            <li>
                                                <a class="satisfied"
                                                    (click)="satisfiedPopup = true"><i
                                                        class="fa fa-thumbs-up"
                                                        aria-hidden="true">
                                                    </i> Satisfied</a>
                                            </li>
                                            <li><a class="notsatisfied"
                                                    (click)="popup = true"><i
                                                        class="fa fa-thumbs-down"
                                                        aria-hidden="true">
                                                    </i> Not Satisfied</a></li>
                                        </ul>
                                    </div>
                                </div>

                                <!--bof:STAR REVIEW-->
                                <div class="overlay" *ngIf="satisfiedPopup">
                                    <div class="popup">

                                        <a class="close"
                                            (click)="satisfiedPopup = false">&times;</a>
                                        <strong>Review &amp; Rating</strong>
                                        <p>Hi, We would like you to rate the
                                            artist</p>
                                        <div class="rate">
                                            <input type="radio" id="star5"
                                                name="rate" value="5"
                                                [(ngModel)]='userRatingValue'
                                                [ngModelOptions]="{standalone: true}" />
                                            <label for="star5" title="text">5
                                                stars</label>
                                            <input type="radio" id="star4"
                                                name="rate" value="4"
                                                [(ngModel)]='userRatingValue'
                                                [ngModelOptions]="{standalone: true}" />
                                            <label for="star4" title="text">4
                                                stars</label>
                                            <input type="radio" id="star3"
                                                name="rate" value="3"
                                                [(ngModel)]='userRatingValue'
                                                [ngModelOptions]="{standalone: true}" />
                                            <label for="star3" title="text">3
                                                stars</label>
                                            <input type="radio" id="star2"
                                                name="rate" value="2"
                                                [(ngModel)]='userRatingValue'
                                                [ngModelOptions]="{standalone: true}" />
                                            <label for="star2" title="text">2
                                                stars</label>
                                            <input type="radio" id="star1"
                                                name="rate" value="1"
                                                [(ngModel)]='userRatingValue'
                                                [ngModelOptions]="{standalone: true}" />
                                            <label for="star1" title="text">1
                                                star</label>
                                        </div>
                                        <p>We would like you to review the
                                            artist too.</p>
                                        <textarea maxlength="200"
                                            class="form-control"
                                            placeholder="what is your view?"
                                            [(ngModel)]='ratingComments'
                                            [ngModelOptions]="{standalone: true}"></textarea>
                                        <button
                                            class="btn btn-success send px-5 button"
                                            (click)="reviewAndRating()">Submit
                                            <i
                                                class="fa fa-long-arrow-right ml-1"></i></button>
                                    </div>
                                </div>
                                <!--eof:STAR REVIEW-->

                                <div class="overlay" *ngIf="popup">
                                    <div class="popup">
                                        <h2>Revision Comments</h2>
                                        <a class="close"
                                            (click)="popup = false">&times;</a>
                                        <div class="content">
                                            <textarea
                                                class="form-control crevision"
                                                placeholder="what is your view?"
                                                rows="4" [(ngModel)]='comments'
                                                [ngModelOptions]="{standalone: true}"></textarea>
                                        </div>
                                        <button
                                            class="btn btn-success send px-5 button"
                                            (click)="revisionComments()">Send
                                            message <i
                                                class="fa fa-long-arrow-right ml-1"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--  ------------------------------------------------------------- -->
                        <!--  Message box code start -->
                        <h1>Conversation</h1>
                      
                     <div class="container">
                        <div class="main">
                            <p *ngIf="dataNotFound" style="text-align: center; padding-top: 98px;">Start sending messages</p>
                            <div class="direct-chat-messages"
                                style=" padding:12px"  *ngFor="let userChat of AllChats" >
                                
                                 <!-- Message to the right -->
                                <div class="direct-chat-msg right" *ngIf="userChat.user_type == 'Customer'">
                                    <div class="direct-chat-info clearfix">
                                       
                                        <span
                                            class="direct-chat-timestamp pull-right"
                                            style="margin-top: 37px  ;">{{ userChat.date_time  }}</span>
                                            
                                        <span
                                            class="direct-chat-name pull-right"
                                            style="margin-top: 37px ;">&nbsp;&nbsp;&nbsp;</span>
                                            
                                            <span
                                            class="direct-chat-name pull-right"
                                            style="margin-top: 37px  ;  font-weight: bold;">You</span>
                                    </div>
                                     <div class="direct-chat-text"
                                        style="margin: 5px 0px 15px 19%;background: #dbf7c5;">
                                        {{ userChat.message }}
                                    </div>
                                </div>


                                <!-- Message. Default to the left -->
                                <div class="direct-chat-msg" *ngIf="userChat.user_type == 'Artist'">
                                    <div class="direct-chat-info clearfix">
                                        <!--   <span class="direct-chat-name  "></span>  -->
                                        <span class="direct-chat-name "
                                            style="font-weight: bold;">Artist</span>
                                        <span
                                            class="direct-chat-name  ">&nbsp;&nbsp;&nbsp;</span>
                                        <span
                                            class="direct-chat-timestamp  ">{{ userChat.date_time }}</span>
                                    </div>
                                    <div class="direct-chat-text"
                                        style="margin: 5px 23% 15px 0px; background: whitesmoke;">
                                        {{ userChat.message }}
                                    </div>
                                </div>
                                </div>
                            </div>
                        <div >
                                <textarea type="text" name id class="sentmsg" [(ngModel)]="ChatMessag.msg"  [ngModelOptions]="{standalone:true}"
                                    placeholder="Please drop message"></textarea>
                                <button type="submit"
                                    class="sentbutton" (click)="insertChat()">Send</button>
                            </div>
                        
                        </div>
                      
                        <!--  ------------------------------------------------------------- -->
                        <!--  Message box code End -->
                        <br> <br>


                        
                        <div class="sampledash linespace">
                            <h2><span><i class="reviewicon"></i>Review and
                                    Rating</span></h2>
                            <div class="row">
                                <div class="col2"
                                    *ngFor="let reviewList of reviewAndRatingList">
                                    <strong>{{reviewList.username}}</strong>
                                    <span class="fa fa-star"
                                        [ngClass]="{'positive' : reviewList.userRatingValue >= 1}"></span>
                                    <span class="fa fa-star"
                                        [ngClass]="{'positive' : reviewList.userRatingValue >= 2}"></span>
                                    <span class="fa fa-star"
                                        [ngClass]="{'positive' : reviewList.userRatingValue >= 3}"></span>
                                    <span class="fa fa-star"
                                        [ngClass]="{'positive' : reviewList.userRatingValue >= 4}"></span>
                                    <span class="fa fa-star"
                                        [ngClass]="{'positive' : reviewList.userRatingValue >= 5}"></span>
                                    <p>{{reviewList.ratingComments}}</p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!--Notification-->
                </div>
            </div>
        </div>
        <!--eof: myrecording-->
    </div>