import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  constructor(private userService: UserService, private toastr: ToastrService, private router: Router) { }

  ngOnInit(): void {
  }

  isContact = false;
  isContactFailed = false;
  ContactErrorMessage = '';

  contactForm: any = {
    first_name: null,
    last_name: null,
    email: null,
    contact: null,
    message: null
  };

  contactus(): void {
    const { first_name, last_name, email, contact, message } = this.contactForm;

    // Check if the message is blank
    if (!message || message.trim() === '') {
      this.ContactErrorMessage = 'Message should not be blank. It is mandatory.';
      this.isContactFailed = true;
      return;
    }

    this.userService.contact(first_name, last_name, email, contact, message).subscribe(
      data => {
        this.isContactFailed = false;
        this.toastr.success('Thank you. Our admin will contact you in 24-48 hours', '');
      },
      err => {
        this.ContactErrorMessage = err.error.message;
        this.isContactFailed = true;
      }
    );
  }

  reload() {
    window.location.reload();
  }
}
