<app-loader *ngIf="loader"></app-loader>

<!-- Banner -->
<div class="dashboardbanner">
  <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
  <div class="bannerheading">Price, Delivery Time & Availability</div>
</div>

<div class="fullwidth bg_purple">
  <!--bof: myrecording-->
  <div class="w1200">
    <div class="pointgrid">
      <div class="pointcols">
        <span><i class="active" routerLink="/editprofile"></i></span>
        <strong>Personal Info</strong>
      </div>
      <div class="pointcols">
        <span><i class="active" (click)="reloadEditVoice()"></i></span>
        <strong>Voice Profile</strong>
      </div>
      <div class="pointcols">
        <span><i class="active" routerLink="/pricedelivery"></i></span>
        <strong>Price, Delivery time &amp; Availability</strong>
      </div>
      <div class="pointcols">
        <span><i (click)="reloadEqipExp()"></i></span>
        <strong>Equipments &amp; Experience</strong>
      </div>
      <div class="pointcols">
        <span><i routerLink="/voicesample"></i></span>
        <strong>Voice &amp; Sample</strong>
      </div>
    </div>
    <div class="wedget">
      <!-- bof:wedgetleft -->
      <app-sidebar></app-sidebar>
      <!-- bof:wedgetright -->
      <div class="wedgetright">
        <div class="wedgetform">
          <!-- <h3>Price, Delivery time &amp; Availability</h3> -->
          <h2>Note:</h2>
          <ul class="decoration">
            <li><h4>Please fill in the price in atleast one category. </h4></li>
            <li>
              <h4 *ngIf="country == 'India'">Price should be in INR</h4>
              <h4 *ngIf="country !== 'India'">Price should be in USD</h4>
            </li>
            <li><h4>Prices can't be removed it can only be changed</h4></li>
          </ul>
          
          <!--bof:formsection-->
          <div class="form1 delivery_form">
            <form [formGroup]="userForm" (ngSubmit)="submit(userForm.value)">
              <!-- <div formGroupName="fields"> -->
              <div formArrayName="price_data">
                <div let i="index">
                  <div class="formpanel">
                    <label class="collapsible">Corporate IVR</label>
                    <!-- <h4>(One prompt in Corporate IVR would consist of 30 words.)</h4> -->
                    <div class="colapsecontent">
                      <div class="form_row">
                        <div class="formwrap">
                          <p>Minimum order value upto 5 prompts</p>
                          <input type="text" [(ngModel)]="Data.value1" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Upto 15 prompts</p>
                          <input type="text" [(ngModel)]="Data.value2" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Upto 50 prompts</p>
                          <input type="text" [(ngModel)]="Data.value77" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <!-- <div class="formwrap">
                          <p>Above 51 prompts</p>
                          <input type="text" [(ngModel)]="Data.value78" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)">
                        </div> -->

                      </div>
                    </div>
                    <label class="collapsible">E-learning</label>
                    <div class="colapsecontent">
                      <div class="form_row">
                        <div class="formwrap">
                          <p>Minimum order value Upto 1500 words</p>
                          <input type="text" [(ngModel)]="Data.value4" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Upto 4500 words</p>
                          <input type="text" [(ngModel)]="Data.value5" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Upto 7800 words</p>
                          <input type="text" [(ngModel)]="Data.value6" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <!-- <div class="formwrap">
                          <p>Price for 7800+ words</p>
                          <input type="text" [(ngModel)]="Data.value7" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)">
                        </div> -->
                      </div>
                    </div>

                    <label class="collapsible">Radio Spot</label>
                    <div class="colapsecontent">
                      <div class="form_row">
                        <div class="formwrap">
                          <p>Price for 10 second spot</p>
                          <input type="text" [(ngModel)]="Data.value9" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Price for 20 second spot</p>
                          <input type="text" [(ngModel)]="Data.value10" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Price for 30 second spot</p>
                          <input type="text" [(ngModel)]="Data.value11" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Price for 60 second spot</p>
                          <input type="text" [(ngModel)]="Data.value12" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                      </div>
                    </div>
                    <label class="collapsible">Audiobooks</label>
                    <div class="colapsecontent">
                      <div class="form_row">
                        <div class="formwrap">
                          <p>Mimumum order value upto 4500</p>
                          <input type="text" [(ngModel)]="Data.value14" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Upto 7800 words</p>
                          <input type="text" [(ngModel)]="Data.value15" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Upto 11700 words</p>
                          <input type="text" [(ngModel)]="Data.value16" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <!-- <div class="formwrap">
                          <p> Price for upto 15600 words</p>
                          <input type="text" [(ngModel)]="Data.value17" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Price for upto 20000 words</p>
                          <input type="text" [(ngModel)]="Data.value18" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Price for succeeding words</p>
                          <input type="text" [(ngModel)]="Data.value19" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)">
                        </div> -->
                      </div>
                    </div>

                    <label class="collapsible">Jingle</label>
                    <div class="colapsecontent">
                      <div class="form_row">
                        <div class="formwrap">
                          <p>Price for 10 second jingle</p>
                          <input type="text" [(ngModel)]="Data.value21" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Price for 20 second jingle</p>
                          <input type="text" [(ngModel)]="Data.value22" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Price for 30 second jingle</p>
                          <input type="text" [(ngModel)]="Data.value23" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Price for 60 second jingle</p>
                          <input type="text" [(ngModel)]="Data.value24" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                      </div>
                    </div>

                    <!-- <label>Mimicry</label>
                    <div class="form_row">
                      <div class="formwrap">
                        <p>Mimicry of Bollywood Celebrities</p>
                        <input type="text" [(ngModel)]="Data.value26" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Mimicry of Hollywood Celebrities</p>
                        <input type="text" [(ngModel)]="Data.value27" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Mimicry of Cartoon/Animation characters</p>
                        <input type="text" [(ngModel)]="Data.value28" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p>Mimicry of an animal/bird/reptiles</p>
                        <input type="text" [(ngModel)]="Data.value29" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                      <div class="formwrap">
                        <p class="tat">TAT</p>
                        <input type="text" [(ngModel)]="Data.value30" [ngModelOptions]="{standalone: true}" maxlength="6">
                      </div>
                    </div> -->

                    <label class="collapsible">Audio recording for Explainer Video</label>
                    <div class="colapsecontent">
                      <div class="form_row">
                        <div class="formwrap">
                          <p>Minimum order value upto 200 words </p>
                          <input type="text" [(ngModel)]="Data.value31" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Upto 500 words</p>
                          <input type="text" [(ngModel)]="Data.value32" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Upto 1000 words</p>
                          <input type="text" [(ngModel)]="Data.value33" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Upto 2000 words</p>
                          <input type="text" [(ngModel)]="Data.value34" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <!-- <div class="formwrap">
                          <p>Price for succeeding words</p>
                          <input type="text" [(ngModel)]="Data.value35" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)">
                        </div> -->
                      </div>
                    </div>


                    <label class="collapsible">TVC</label>
                    <div class="colapsecontent">
                      <div class="form_row">
                        <div class="formwrap">
                          <p>Price for 10 seconds TVC</p>
                          <input type="text" [(ngModel)]="Data.value37" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Price for 20 seconds TVC</p>
                          <input type="text" [(ngModel)]="Data.value38" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Price for 30 seconds TVC</p>
                          <input type="text" [(ngModel)]="Data.value39" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Price for 40 seconds TVC</p>
                          <input type="text" [(ngModel)]="Data.value40" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Price for 50 seconds TVC</p>
                          <input type="text" [(ngModel)]="Data.value41" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Price for 60 seconds TVC</p>
                          <input type="text" [(ngModel)]="Data.value42" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                      </div>
                    </div>

                    <label class="collapsible">Advertisement (Social media, internal & other uses)</label>
                    <div class="colapsecontent">
                      <div class="form_row">
                        <div class="formwrap">
                          <p>Minumium order value upto 150 words</p>
                          <input type="text" [(ngModel)]="Data.value44" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Upto 300 words</p>
                          <input type="text" [(ngModel)]="Data.value45" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Upto 600 words</p>
                          <input type="text" [(ngModel)]="Data.value46" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Upto 900 words</p>
                          <input type="text" [(ngModel)]="Data.value47" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Upto 1200 words</p>
                          <input type="text" [(ngModel)]="Data.value48" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <!-- <div class="formwrap">
                          <p>Price for 1200+ words</p>
                          <input type="text" [(ngModel)]="Data.value49" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)">
                        </div> -->
                      </div>
                    </div>

                    <label class="collapsible">Documentary</label>
                    <div class="colapsecontent">
                      <div class="form_row">
                        <div class="formwrap">
                          <p>Minumium order value upto 600 words</p>
                          <input type="text" [(ngModel)]="Data.value51" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Upto 1200 words</p>
                          <input type="text" [(ngModel)]="Data.value52" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Upto 1800 words</p>
                          <input type="text" [(ngModel)]="Data.value53" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Upto 2400 words</p>
                          <input type="text" [(ngModel)]="Data.value54" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Upto 3000 words</p>
                          <input type="text" [(ngModel)]="Data.value55" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <!-- <div class="formwrap">
                          <p>3001+ words</p>
                          <input type="text" [(ngModel)]="Data.value56" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)">
                        </div> -->
                      </div>
                    </div>

                    <label class="collapsible">Animation</label>
                    <div class="colapsecontent">
                      <div class="form_row">
                        <div class="formwrap">
                          <p>Minimum order value upto 200 words</p>
                          <input type="text" [(ngModel)]="Data.value58" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Upto 500 words</p>
                          <input type="text" [(ngModel)]="Data.value59" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Upto 1000 words</p>
                          <input type="text" [(ngModel)]="Data.value60" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Upto 2000 words</p>
                          <input type="text" [(ngModel)]="Data.value61" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <!-- <div class="formwrap">
                          <p>Price for succeeding words</p>
                          <input type="text" [(ngModel)]="Data.value62" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)">
                        </div> -->
                      </div>
                    </div>

                    <label class="collapsible">Voice assistance</label>
                    <div class="colapsecontent">
                      <div class="form_row">
                        <div class="formwrap">
                          <p>Minimum order value upto 200 words</p>
                          <input type="text" [(ngModel)]="Data.value64" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Upto 500 words</p>
                          <input type="text" [(ngModel)]="Data.value65" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Upto 1000 words</p>
                          <input type="text" [(ngModel)]="Data.value66" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Upto 2000 words</p>
                          <input type="text" [(ngModel)]="Data.value67" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <!-- <div class="formwrap">
                          <p>Price for succeeding words</p>
                          <input type="text" [(ngModel)]="Data.value68" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)">
                        </div> -->
                      </div>
                    </div>

                    <label class="collapsible">Podcast</label>
                    <div class="colapsecontent">
                      <div class="form_row">
                        <div class="formwrap">
                          <p>Minimum order value Upto 1500 words</p>
                          <input type="text" [(ngModel)]="Data.value70" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Upto 4500 words</p>
                          <input type="text" [(ngModel)]="Data.value71" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Upto 7800 words</p>
                          <input type="text" [(ngModel)]="Data.value72" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)" (keyup)="numberOnly($event)">
                        </div>
                        <!-- <div class="formwrap">
                          <p>Price for upto 7800-15600 words</p>
                          <input type="text" [(ngModel)]="Data.value73" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Price for upto 15601-19500 words</p>
                          <input type="text" [(ngModel)]="Data.value74" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)">
                        </div>
                        <div class="formwrap">
                          <p>Price for succeeding words.</p>
                          <input type="text" [(ngModel)]="Data.value75" [ngModelOptions]="{standalone: true}"
                            maxlength="6" (keypress)="numberOnly($event)">
                        </div> -->

                      </div>
                    </div>
                    <div class="formwrap">
                      <label>Turn around Time(in hours)<span>*</span></label>
                      <input type="text" [(ngModel)]="Data.value76" [ngModelOptions]="{standalone: true}" maxlength="6"
                        (keypress)="numberOnly($event)" (keyup)="numberOnly($event)" required  value="24"/>
                        <span style="color: red;">{{ ErrorMessag }}</span>
                    </div>
                    <!-- <div class="formwrapfull">
                      <div class="rowavaility">
                        <label>Availability</label>
                        <p>Select your working days and Hours</p>
                        <div class="row">
                          <div class="available24x7">Available 24x7</div>
                          <div class="availablerightpart">Are you a workaholic like us? If yes select this option
                            <label class="availityswitch textmiddle">
                              <input type="checkbox" id="avail_day" [(ngModel)]="availabilityForm.is24Available"
                                [ngModelOptions]="{standalone: true}">
                              <span class="slider round"></span></label>
                          </div>
                        </div>
                      </div>
                    </div> -->

                    <div class="formwrapfull">
                      <div class="rowavaility weekoff">
                        <label>Day Availability</label>
                        <p>Select your working days</p>
                        <div class="rowscroll">
                          <div class="row">
                            <div class="col">Sun <label class="availityswitch textmiddle">
                                <input type="checkbox" [(ngModel)]="daySunday"
                                  [ngModelOptions]="{standalone: true}"><span class="slider round"></span></label></div>
                            <div class="col">Mon <label class="availityswitch textmiddle">
                                <input type="checkbox" [(ngModel)]="dayMonday"
                                  [ngModelOptions]="{standalone: true}"><span class="slider round"></span></label></div>
                            <div class="col">Tue <label class="availityswitch textmiddle">
                                <input type="checkbox" [(ngModel)]="dayTuesday"
                                  [ngModelOptions]="{standalone: true}"><span class="slider round"></span></label></div>
                            <div class="col">Wed <label class="availityswitch textmiddle">
                                <input type="checkbox" [(ngModel)]="dayWednesday"
                                  [ngModelOptions]="{standalone: true}"><span class="slider round"></span></label></div>
                            <div class="col">Thu <label class="availityswitch textmiddle">
                                <input type="checkbox" [(ngModel)]="dayThursday"
                                  [ngModelOptions]="{standalone: true}"><span class="slider round"></span></label></div>
                            <div class="col">Fri <label class="availityswitch textmiddle">
                                <input type="checkbox" [(ngModel)]="dayFriday"
                                  [ngModelOptions]="{standalone: true}"><span class="slider round"></span></label></div>
                            <div class="col">Sat <label class="availityswitch textmiddle">
                                <input type="checkbox" [(ngModel)]="daySaturday"
                                  [ngModelOptions]="{standalone: true}"><span class="slider round"></span></label></div>
                          </div>
                        </div>
                      </div>

                      <!-- <div class="formwrapfull weekoff">
                        <div class="rowavaility">
                          <label>Time Availability</label>
                          <p>Select your working Hours</p>
                          <div class="row">
                            <div class="available24x7">Available 24x7</div>
                            <div class="availablerightpart">Choose this if you don't take a weekly hours <label
                                class="availityswitch textmiddle">
                                <input type="checkbox" id="avail_time" [(ngModel)]="availabilityForm.timeAvailability"
                                  [ngModelOptions]="{standalone: true}"><span class="slider round"></span></label></div>
                          </div>
                        </div>
                      </div>
                      <p class="weekoff timeoff">Working hours</p>
                      <div class="form_row weekoff">
                             <div class="formwrap">
                              <input class="vTimeStart" type="time">
                             </div>
                             <div class="formwrap">
                              <input class="vTimeEnd" type="time">
                             </div>
                             </div>

                      <table id="myTable" class="timeoff">
                        <tr class="t-row form_row weekoff">
                          <td class="formwrap">
                            <input type="time" class="start vTimeStart" [(ngModel)]="availabilityForm.startTime"
                              [ngModelOptions]="{standalone: true}" />
                          </td>
                          <td class="formwrap">
                            <input type="time" class=" end vTimeEnd" [(ngModel)]="availabilityForm.endTime"
                              [ngModelOptions]="{standalone: true}" />
                          </td>
                        </tr>
                      </table> -->
                      <div class="formwrapfull lastdiv">
                        <div class="submitdiv">
                          <input type="submit" value="Save and Next" (click)="priceData()">
                          <i class="fa fa-angle-right" aria-hidden="true"></i>
                        </div>
                        <strong style="top: 0;">Items marked with <span>*</span> are mandatory</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- </div>  -->
            </form>
            <!--eof:formsection-->
          </div>
          <!--eof: form-->
        </div>
      </div>
    </div>
    <!--eof: myrecording-->
  </div>
</div>