import { Component, OnInit } from '@angular/core';
import { UserService } from '../_services/user.service';
import { Ng2SearchPipe, Ng2SearchPipeModule } from 'ng2-search-filter';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-all-artist-details',
  templateUrl: './all-artist-details.component.html',
  styleUrls: ['./all-artist-details.component.css']
})
export class AllArtistDetailsComponent implements OnInit {
  allArtistDetails: any;
  allArtistDetailsfiltered: any;
  editProfileForm: any;
  editVoiceProfileForm: any;
  availabilityForm: any;
  equipmentExperienceForm: any;
  popup = false;
  loader: boolean = true;
  set searchParam(value:any){
    if(value != null && value !='')
    this.allArtistDetailsfiltered=Ng2SearchPipe.prototype.transform(this.allArtistDetails,value);
  else
  this.allArtistDetailsfiltered = this.allArtistDetails;

  this.page = 1
  this.NoOfArtist = this.allArtistDetailsfiltered.length;
  }
  page: number = 1;
  NoOfArtist: any;


  constructor(private userService: UserService,private toastr :ToastrService,private http:HttpClient) { }

  ngOnInit(): void {
    this.loader = false
    this.getallArtistDetails();
  }


  goToFirstPage() {
    this.page = 1; // Assuming 1-based indexing
}

goToLastPage() {
    // Calculate the last page based on the total number of items and items per page
    const totalPages = Math.ceil(this.allArtistDetailsfiltered.length / 10); // Assuming 45 items per page    
    this.page = totalPages;
}






  getallArtistDetails() {
    this.userService.getallArtistDetails().subscribe((data: any) => {
      this.allArtistDetails = data;
      this.allArtistDetailsfiltered = data;
      console.log("My All Artist Data ",this.allArtistDetails);
      this.NoOfArtist = data.length;
      this.allArtistDetails.forEach((element: {}) => {
      });
    },
      err => {
        console.log(err);
      }
    );
  }

  public onClickId(data: any) {
    this.loader = true;
    this.userService.getEditProfile(data.id).subscribe(data => {
      this.editProfileForm = data;
      if (this.editProfileForm !== null && this.editProfileForm !== "" && this.editProfileForm !== undefined) {
        this.popup = true;
        ((element: {
        }) => {
        });
      }
    },
      err => {
        console.log(err);
      }
    );
    this.loader = false;
    this.getEditVoiceProfile(data);
    this.getpriceDeliveryTime(data);
    this.getAvailability(data);
  }

  //fetching Edit Voice Details 
  getEditVoiceProfile(data: any) {
    this.userService.getEditVoiceProfile(data.id).subscribe(data => {
      this.editVoiceProfileForm = data;
      if (this.editVoiceProfileForm !== null && this.editVoiceProfileForm !== "" && this.editVoiceProfileForm !== undefined) {
        this.popup = true;
        ((element: {
          language: string, category: string, recording_Setup: string, voice_age: string,
          accents: string, role: string, style: string, service: string, userId: string
        }) => {
        });

      }
    },
      err => {
        console.log(err);
      }
    );
  }

  //Get priceDeliveryTime Details
  getpriceDeliveryTime(data: any) {
    this.userService.getpriceDeliveryTime(data.id).subscribe(data => {
      this.Data = data;
      if (this.Data !== null && this.Data !== "" && this.Data !== undefined) {
        this.popup = true;
        ((element: { value1: string, value2: string }) => {
        });
      }
    },
      err => {
        console.log(err);
      }
    );
  }

  Data: any = {
    value1: null,
    value2: null,
    value77: null,
    value78: null,
    value4: null,
    value5: null,
    value6: null,
    value7: null,
    value9: null,
    value10: null,
    value11: null,
    value12: null,
    value14: null,
    value15: null,
    value16: null,
    value17: null,
    value18: null,
    value19: null,
    value21: null,
    value22: null,
    value23: null,
    value24: null,
    value26: null,
    value27: null,
    value28: null,
    value29: null,
    value30: null,
    value31: null,
    value32: null,
    value33: null,
    value34: null,
    value35: null,
    value37: null,
    value38: null,
    value39: null,
    value40: null,
    value41: null,
    value42: null,
    value44: null,
    value45: null,
    value46: null,
    value47: null,
    value48: null,
    value49: null,
    value51: null,
    value52: null,
    value53: null,
    value54: null,
    value55: null,
    value56: null,
    value58: null,
    value59: null,
    value60: null,
    value61: null,
    value62: null,
    value64: null,
    value65: null,
    value66: null,
    value67: null,
    value68: null,
    value70: null,
    value71: null,
    value72: null,
    value73: null,
    value74: null,
    value75: null,
    value76: null
  }

  //Get Availability Details
  getAvailability(data: any) {
    this.userService.getAvailability(data.id).subscribe(
      (dataFromService: any) => {
        this.availabilityForm = dataFromService;

        if (this.availabilityForm) {
          // Check the specific condition
          if (this.availabilityForm.is24Available) {
            // If is24Available is true, display the days and other information
            this.popup = true;

            // Additional code to execute when is24Available is true
            console.log("is24Available is true");
            // You can add more code here, for example:
            // this.doSomething();
          } else {
            // If is24Available is false, handle it accordingly (you can set popup to false or do something else)
            this.popup = false;
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }



  isFirstPage(): boolean {
    return this.page != 1;
  }

  isLastPage(): any {        
    if(this.page == Math.ceil(this.allArtistDetailsfiltered.length / 10)){
      return false;
    } else {
      return true;
    }    
  }

  onPageChange(event: number): void {
    this.page = event;
  }




  //Fetch EquipmentExperience Details
  getEquipmentExperience(data: any) {
    this.userService.getEquipmentExperience(data.id).subscribe(data => {
      this.equipmentExperienceForm = data;
      if (this.equipmentExperienceForm !== null && this.equipmentExperienceForm !== "" && this.equipmentExperienceForm !== undefined) {
        this.popup = true;
        ((element: {
          microphoneUsed: string, recordingEditing: string, socialEquipment: string, experience: string,
          serviceOffered: string, clientWorkedFor: string, education: string, socialMediaLinkFB: string, socialMediaLinkTwitter: string, socialMediaLinkLinked: string, userId: string
        }) => {
        });
      }
    },
      err => {
        console.log(err);
      }
    );
  }

  ChangeUserStatus(user_id:any, status:any,remark:any){
    try {
      if(user_id !== null && user_id !== '' && user_id !== undefined && status !== null && status !== undefined && status !== ''){
        const URL  = '?user_id='+user_id+'&status='+status+'&remark='+remark;    
        console.log("All Data " , URL);
           this.userService.changeUserStatus(URL).subscribe((response)=>{
              if(response){
                this.toastr.success("Status Changes Successsfully",'');
                setTimeout(()=>{
                  window.location.reload();
                },200);
             }else{
              this.toastr.error("Somehting went wrong",'');
             }
           },
           (error:HttpErrorResponse)=>{
            if(error.status == 400 || error.status !== null){
              console.log("I have error in call of change user status ",error);
             this.toastr.error(error.error.message,'');
            }
           }
           )
      }else{
        this.toastr.error("something went wrong",'');
      }
    } catch (error) {
      console.log("Error in approve or reject user", error);
    }
  }

}
