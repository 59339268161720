import { Component, ElementRef, ViewChildren, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from '../_services/token-storage.service';
import { UserService } from '../_services/user.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-sample-details',
  templateUrl: './sample-details.component.html',
  styleUrls: ['./sample-details.component.css']
})
export class SampleDetailsComponent implements OnInit {
  content: any;
  userId: any;
  data: any;
  myParam: any;
  sampleListItems: any;   
  sampleList: any;
  languageList: any;
  categoryList: any;
  profilePic: any;
  artistUserId: any;
  loader: boolean = true;
  roles: any;
  artistTat: any;
  artistId: any;
  popup = false;


  constructor(private userService: UserService, private route: ActivatedRoute, private router: Router, private tokenStorage: TokenStorageService,private toastr:ToastrService) { }

  ngOnInit(): void {
    this.tokenStorage.getToken();
    this.roles = this.tokenStorage.getUser().roles;
    this.artistId = this.tokenStorage.getUser().id;
    this.loader = false;
    this.route.paramMap.subscribe(params => {
      this.myParam = params.get('queryParams');
    });
    this.getprofileDetails();
  }

  @ViewChildren('audio') audioElms!: ElementRef[];

  ngAfterViewInit() {
    console.log(this.audioElms)
  }

  onPaly(elm: HTMLAudioElement) {
    this.audioElms.forEach(({ nativeElement: e }) => {
      if (e !== elm) {
        e.pause();
      }
    })
  }

  //fetching Edit Voice Details 
  getprofileDetails() {
    this.userService.getprofileDetailsById(this.artistId).subscribe(data => {
      this.sampleList = data;
      console.log(this.sampleList);
      if (this.sampleList !== undefined && this.sampleList !== null && this.sampleList !== "") {
        this.languageList = data.languageList;
        this.categoryList = data.categoryList;
        this.sampleList = data.sampleList;
        this.profilePic = data.profilePic;
        this.artistUserId = data.artistUserId;
        this.artistTat = data.tat;
      }
    },
      err => {
        console.log(err);
      }
    );
  }


  deleteSample(items: any) {
    this.userService.remove(items.id).subscribe((data:any) => {
   console.log(data);
   if(data){        
        this.toastr.success('Voice sample has been deleted successfully','');
        setTimeout(() => {
        window.location.reload();
        },500)
        
        
   }
    })

  }


}
