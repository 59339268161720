<!-- Banner -->

<div class="dashboardbanner">
  <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
  <div class="bannerheading">
    <h1>Customer Benefit</h1>
  </div>
</div>

<div class="fullwidth  bg_purple">
  <!--bof: Contact Us-->
  <div class="w1200">
    <div class="aboutus">
      <h3>Customer</h3>
      <div class="FirstContainer">
        <div class="imagediv">
          <img src="./assets/images/10135.jpg" alt="" class="imagemanage">
        </div>
        <div class="textdiv">
          <h1 class="hsize">Let’s Join</h1> <br>
          <button class="button" routerLink="/customer-register">Sign-Up</button>
        </div>
      </div>

      <h3>The Vision</h3>

         <div class="text2div">
          <div class="fortext">
            <p>
              The <strong>Voice mall</strong> is a platform, carefully designed as a one-stop solution for all the voice
              over.</p>
              <ul class="artist_benifit">
                <p><li>video production</li></p>
                <p><li>content writing</li></p>
                <p><li>translation needs of clients from all sorts of backgrounds</li></p>
                <p><li>ou could be searching for a voice over artist</li></p>
                <p><li>want to create an audio book</li></p>
                <p><li>The <strong>Voice mall</strong> has <strong> plenty of professional voice over recording services</strong>by
                  which you can get what you want in the easiest ways possible.</li></p>
              </ul>
            </div>
          <div class="forimage"><img src="./assets/images/65.jpeg" alt="" class="imagemanage2"></div>
         </div>
         <ul class="artist_benifit1">
          <p><li>video production</li></p>
          <p><li>content writing</li></p>
          <p><li>translation needs of clients from all sorts of backgrounds</li></p>
          <p><li>ou could be searching for a voice over artist</li></p>
          <p><li>want to create an audio book</li></p>
          <p><li>The <strong>Voice mall</strong> has <strong> plenty of professional voice over recording services</strong>by
            which you can get what you want in the easiest ways possible.</li></p>
        </ul>
      <p>
        The <strong>Voice mall</strong> team has worked a day in and day out to create this user-friendly platform. We
        have helped more than 10000 professional voiceovers, video production artists, content writers, and
        translators
        to get to a stage where they can showcase their skills.
        These artists can provide voice over in more than 40 languages. Our platform also features a one-of-a-kind
        rating and review system. This allows our clients to get initial insights on what to expect from the
        <strong>professional voice</strong> talents at the <strong>Voice mall.</strong>
      </p>

      <h3>Our Values</h3>
      <p>
        <strong> 1. Flexibility:</strong> Our entire team at the <strong>Voice mall</strong> stays flexible to tackle
        the
        most unexpected challenges.
        Through our flexibility only we have successfully served countless clients in the most seamless ways possible.
      </p>
      <p>
        <strong> 2. Quality: </strong>We believe in promoting quality in all aspects of our work. We promote utmost
        professionalism in order to enhance the quality of the projects happening through our platform.
      </p>
      <p>
        <strong> 3. Cost-Effectiveness: </strong>We try our best to provide this seamless hiring and working environment
        to our clients as well as our artists at the minimum costs possible. Even though our services are at lower costs
        than other service providers, we do not compromise on the quality of the assistance we are providing.

      </p>
      <p>
        <strong> 4. Innovation:</strong>Since the inception of the <strong>Voice mall,</strong> all our team leaders
        focused on bringing innovation to the table. Innovative technologies like AI, Machine Learning, etc. are largely
        employed during the development of our tools.
      </p>
      <p>
        <strong> 5. Problem Resolving: </strong> As a service-providing platform, we face several challenges and
        problems daily. Our team is highly dedicated to finding the solutions to these challenges as swiftly as
        possible. Our customer service team is also dedicated to resolving the issues our users face.
      </p>

      <p><br>We at <strong>Voice mall,</strong> one of <strong>the top voice over agencies in India</strong> are waiting
        to be the most reliable companion in your voice over the journey. Contact us now!
      </p>
    </div>
  </div>
  <!--eof: Contact Us-->
</div>