import { Component, ElementRef, OnInit, ViewChildren } from '@angular/core';
import { UserService } from '../_services/user.service';
import { Ng2SearchPipe } from 'ng2-search-filter';
import { OrderService } from '../_services/order.service';
import { ToastrService } from 'ngx-toastr';




@Component({
  selector: 'app-all-voice-samples',
  templateUrl: './all-voice-samples.component.html',
  styleUrls: ['./all-voice-samples.component.css']
})
export class AllVoiceSamplesComponent implements OnInit {
  allArtistDetails: any;
  allArtistDetailsfiltered: any;
  editProfileForm: any;
  editVoiceProfileForm: any;
  availabilityForm: any;
  equipmentExperienceForm: any;
  popup = false;
  loader: boolean = true;
  set searchParam(value:any){
    if(value != null && value !='')
    this.allArtistDetailsfiltered=Ng2SearchPipe.prototype.transform(this.allArtistDetails,value);
  else
  this.allArtistDetailsfiltered = this.allArtistDetails;

  this.page = 1
  this.TotVoiceSample = this.allArtistDetailsfiltered.length;
  }
  page: number = 1;
  TotVoiceSample: any;
  @ViewChildren('audio') audioElms!: ElementRef[];


  constructor(private userService: UserService,private orderService: OrderService,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.loader = false
    this.getallArtistDetails();
  }


  goToFirstPage() {
    this.page = 1; // Assuming 1-based indexing
}

goToLastPage() {
    // Calculate the last page based on the total number of items and items per page
    const totalPages = Math.ceil(this.allArtistDetailsfiltered.length / 10); // Assuming 45 items per page    
    this.page = totalPages;
}



isFirstPage(): boolean {
  return this.page != 1;
}

isLastPage(): any {        
  if(this.page == Math.ceil(this.allArtistDetailsfiltered.length / 10)){
    return false;
  } else {
    return true;
  }    
}

onPageChange(event: number): void {
  this.page = event;
}





onPaly(elm: HTMLAudioElement) {
  this.audioElms.forEach(({ nativeElement: e }) => {
    if (e !== elm) {
      e.pause();
    }
  })
}

deleteSample(data: any) {
   this.userService.remove(data.id).subscribe((response:any) => {
    this.toastr.success('Voice sample has been deleted successfully!', '');
    setTimeout(() => {
      window.location.reload();
      },500)
  });
 }

  getallArtistDetails() {
    this.orderService.getAlluploadArtistVoice().subscribe((data: any) => {
      this.allArtistDetails = data;
      this.allArtistDetailsfiltered = data;
      this.TotVoiceSample = this.allArtistDetailsfiltered.length;
      this.allArtistDetails.forEach((element: {}) => {
      });
    },
      err => {
        console.log(err);
      }
    );
  }

 
    //update Status to customer for order complete
    approveSample(data: any) {
      this.orderService.approveSample(data.id).subscribe(data => {
        this.toastr.success('Sample has been updated Successfully!! ', '')
        window.location.reload();
      },
        err => {
          console.log(err);
        }
      );
    }
}
