<!-- Banner -->

<div class="dashboardbanner" onsubmit="pop()">
    <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
    <div class="bannerheading">
        <h1>Privacy Policy</h1>
    </div>
</div>

<div class="fullwidth  bg_purple">
    <!--bof: Contact Us-->
    <div class="w1200">
        <div class="aboutus">
            <h3> Why do we gather information from our users?</h3>
            <p>
                The whole purpose behind gathering information from our users is to improve the quality and the
                user-friendliness of our services. Whenever someone uses our website and signs up, we collect some of
                the most basic information like the name of the user, phone number, e-mail, birth date, etc. This
                information helps in profiling the users as well as in identification as well.
            </p>
            <h3> How safe is the information we collect?</h3>
            <p>
                We make sure to follow the best and the most secure practices and protocols while handling this
                information, gathered from our users. We also make sure that the information collected by us is not
                inappropriately used anywhere. The E-mail addresses and the phone number are collected to send details
                of the transaction; a user does on our website.
            </p>
            <h3>How safe are the bank credentials you enter?</h3>
            <p>
                The bank details like the credit card and debit card details collected during the transaction are
                collected for the sole purpose of completing the concerning payments. We follow the most secure
                practices and protocols while handling sensitive and financial data.
            </p>
            <h3>How much control does a user have over this data collection?</h3>
            <p>
                The user has all the necessary control over what he/she is sharing with us. The
                <strong>Voicemall</strong> directly asks for consent from the users on making use of their information
                is sending personalized promotional offers. Our users also have all the control over opting out of their
                promotional e-mails and SMS. We provide all the necessary DND control to our customers. Even after all
                these efforts from our side, if something bothers any user they are free to contact our customer service
                team.
            </p>
            <h3>Do we disclose your information to third-party service providers?</h3>
            <p>
                No, we do not share or disclose the information of our clients with any third party. We strictly follow
                the concerning laws given by the authorities if there is a need of sharing info or data with third
                parties.
            </p>
            <h3>Our Payment procedure protocols</h3>
            <p>
                As far as online payment is concerned, every transaction occurs through secure payment gateways. Our
                servers do not store any financial information like card details, etc. We just restore the receipt of
                the transaction for future use. We use secure payment protocols like Payment Card Industry Data Security
                Standard (PCI-DSS). As a result, all the transactions occurring on our site is under the protocols set
                by the PCI Security Standards Council. We provide several payment options to increase the convenience of
                our users.
            </p>
            <h3>Modification of the Privacy Policy</h3>
            <p>
                The <strong>Voicemall</strong> has all the rights to change the privacy policy without giving any prior
                notice to our users. The modifications will be considered enforced the moment the updated privacy policy
                gets published on the website. We request all our users to pay a frequent visit to the Privacy Policy
                page of our website to keep themselves aware of such modifications.
            </p>
            <h3>Queries regarding Privacy Policy</h3>
            <p>
                Any user can contact our customer service team if they want to delete their personal information or have
                queries regarding our privacy policy.
            </p>
        </div>
    </div>
    <!--eof: Contact Us-->
</div>