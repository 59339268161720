import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../_services/auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  email: any;
  password: any;

  constructor(private authService: AuthService, private router: Router,
    private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  forgotform: any = {
    email: null,
    password: null
  };

  forgotPassword(): void {
    const { email, password } = this.forgotform;
    this.authService.changePassword(email, password).subscribe();
    if (this.forgotform.email !== null && this.forgotform.email !== "" && this.forgotform.password !== null && this.forgotform.password !== "") {
      this.router.navigate(['/']);
      this.toastr.info('Your Password has been changed !!', '')
    } else {
      this.toastr.error('Email id and new password can not be empty !!', '')
    }
  }
}
