<!-- Banner -->

<div class="dashboardbanner">
    <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
    <div class="bannerheading">
        <h1>How We Work</h1>
    </div>
</div>

<div class="fullwidth  bg_purple">
    <!--bof: Contact Us-->
    <div class="w1200">
        <div class="aboutus">

            <h3> Overview</h3>
            <p>
                The <strong>Voicemall</strong> is a technology-based online platform solely dedicated to creating a
                secure environment where professional voiceover artists, video producers, content writers, and
                translators can communicate with their clients and vice versa.
                We have helped countless clients from all kinds of backgrounds like radio, movie, television, YouTube,
                etc. in finding the best voiceover artist for their projects.
            </p>
            <p>
                The hiring process is very easy. You can hire an artist just by the click of a button. We have provided
                ample <strong>amount</strong> of opportunities to add all the instructions as well.
                You can easily add deadlines, feedback, special instructions, etc.
            </p>
            <p>
                We prioritize satisfying our clients at all costs as a result the whole process is designed in a way
                that the project gets completed in the most effective ways possible.
                Our whole team stays ready 24x7 to eliminate any issue our users or artists face.
            </p>
            <h3> A Guide to the workings of the <strong>Voicemall </strong>Platform-</h3>
            <p> <strong>1. Searching:</strong> By utilizing the advanced search tool developed by our developers, any
                client can easily search for the most suitable voice over artists.
                You just have to input details related to the gender, age, language, etc. of the artist you are
                searching for. We feature some of the most professional voiceover artists from India as well as other
                countries.
                You can also add technical skills to the search filters to produce better results.
                <strong>Voicemall</strong> is the best voice over hiring platform making the searching process as easy
                as possible. Just log on to our website and register to avail of these services.
            </p>
            <p>
                <strong> 2. Sample Browsing and Listening: </strong>The Voicemall being the most comprehensive voice
                over the platform in India has featured more than 10000 artists.
                These artists have also uploaded their voice demos on their profiles for the clients.
                After searching for artists on our platform, you can easily listen to the voice demo of each artist and
                select the best artist for your project.
                Want a female artist for a Marathi script? Do or <strong>Want</strong> a male artist for your radio
                jingle? Just enter these details and browse through numerous search results.
                As it will be evident from the voice demos, we promote high-quality work on our platform.
            </p>
            <p>
                <strong> 3. Provide Word Count Details: </strong>As far as getting the initial estimate of the prices is
                concerned, you can type the complete script or just enter the number of words. Our AI-backed tool will
                help you in getting the most accurate estimation, according to the normal charges of the artists.
                By doing this you can easily find out whether the desired artist is in your budget or not.
            </p>
            <p>
                <strong> 4. Instructions for Artists: </strong>We have provided a dedicated area for adding all the
                instructions for your projects. You can add whatever detail you find necessary, and all the guidelines
                you want to be followed by the artist. We have designed our platform in such a way these instructions
                will definitely be taken into account by the artist. You can add details like the quality and the format
                in which you want the recording to be submitted.
            </p>
            <p>
                <strong> 5. Payment:</strong>After registering on the <strong>Voicemall</strong> portal for free, you
                can search for artists online. After finding a suitable artist you can easily hire him/her and share all
                the necessary details and the script. As far as payment is concerned, after finalizing the artist you
                can easily initiate the payment process in order to start the project. These funds that you add to our
                portal will securely stay in an escrow account until the artist delivers the satisfactory project.
                This is done to ensure the safety and security of our clients and also to promote professionalism.
            </p>
            <p>
                <strong> 6. Review and Feedback: </strong>We have added an ample <strong>amount</strong> of
                opportunities for adding feedback and sharing quality reviews with your artist.
                You can freely give feedback as many times as you want. The artist will try his best to make all the
                necessary changes in order to get paid. In case, the artist is unable to provide satisfactory work,
                we shall try our best to get your work done with another artist (subject to terms and conditions).


            </p>
            <h3> Costs of Our Services:</h3>
            <p>
                The <strong>Voicemall</strong> platform is the most cost-effective voice-over hiring platform, solely
                designed to make the artist search process easy. At <strong>Voicemall</strong> there will be no charges
                in registering and searching for an artist. You can freely search as much as you want.
                But, the costs of hiring the artists will totally depend on the artist and also on the nature, and the
                extent of the project.
            </p>
            <p>
                For the convenience of our clients, we have enabled the clients to easily compare the charges of the
                artists as well. All the artists featured on our platform are highly professional and experienced in
                their fields.
            </p>
            <p>
                <strong>If you have any queries related to our services, feel free to contact our customer support
                    team.</strong>
            </p>
        </div>
    </div>
    <!--eof: Contact Us-->
</div>