<!-- Banner -->

<div class="dashboardbanner" onsubmit="pop()">
    <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
    <div class="bannerheading">
        <h1>Disclaimer</h1>
    </div>
</div>

<div class="fullwidth  bg_purple">
    <!--bof: Contact Us-->
    <div class="w1200">
        <div class="aboutus">
            <h3> Disclaimer</h3>
            <p> The thoughts we share on this website are entirely our own and do not reflect those of any other party.
                While we make every effort to ensure that the material is accurate and up to date,
                The <strong>Voicemall</strong> makes no guarantees or assurances of any kind, either stated or implied,
                regarding the website or its services' fullness, accuracy, dependability, sustainability or
                availability.
            </p>
            <p>
                You acknowledge and agree to abide by the terms of service by using the website, creating an account, or
                clicking to accept or agree to them.
                You must not access or use the site if you do not want to accept the terms of service or the privacy
                policy.
            </p>
            <p>
                There are some restrictions on what you can read or download from the <strong>The Voicemall</strong>
                website.
            </p>
            <p>
                The only permitted use of the content is informational.<br>
                No part of the text may be changed, altered, disseminated, or reprinted.<br>
                As a user, you accept all risks and liabilities related to using the website, and we do not guarantee
                that any content received from this official site will be error-free, virus-free, or compatible with
                your machine.

            </p>
            <p>
                Your permission to use our services may be terminated at any time, for any reason, and without prior
                notice,
                and we reserve the right to change or erase any online content.
            </p>
        </div>
    </div>
    <!--eof: Contact Us-->
</div>