import { Component, OnInit } from '@angular/core';
import { UserService } from '../_services/user.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css'],
})
export class AddUserComponent implements OnInit {
  GetAllRoles: any;
  UserNameHide!: boolean;
  UserNameError: any = '';
  EmailHide!: boolean;
  EmailError: any = '';
  ContactHide!: boolean;
  ContactError: any = '';
  RoleHide!: boolean;
  RoleError: any = '';
  LastNameHide!: boolean;
  LastNameError: any = '';
  roleName: any[] = [];

  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.UserData.Role = 'Please Select Role';
    this.getAllRoles();
  }

  UserData: any = {
    Fname: null,
    Lname: null,
    Email: null,
    Contact: null,
    Role: null,
  };

  getAllRoles() {
    try {
      this.userService.getAllRolesList().subscribe((response) => {
        if (response) {
          this.GetAllRoles = response;              
         } else {
          console.log('Something went wrong');
        }
      },
      (error:HttpErrorResponse)=>{
        if(error.status == 400 || error.status !== null){
          console.log("Error in get All Roles ", error);
          this.toastr.error(error.error.message);
        }
      }
      );
    } catch (error) {
      console.log('Error in fetching roles');
    }
  }

  SaveUser() {
    try {
      this.validate();
       if (
        this.UserData.Fname !== null && this.UserData.Fname !== '' &&
        this.UserData.Lname !== null && this.UserData.Lname !== '' &&
        this.UserData.Email !== null && this.UserData.Email !== '' &&
        this.UserData.Contact !== null && this.UserData.Contact !== '' &&
        this.UserData.Role !== null && this.UserData.Role !== 'Please Select Role' && this.UserData.Role !== ''
      ) {
        this.roleName = this.UserData.Role;
        const CreateUser = {
          username: this.UserData.Fname,
          role: [this.roleName],
          last_name: this.UserData.Lname,
          contact: this.UserData.Contact,
          email: this.UserData.Email,
        };
         this.userService.AddNewUser(CreateUser).subscribe((response) => {
          if (response) {
            setTimeout(()=>{
                 this.toastr.success('User create Successfully');   
                 this.router.navigate(['/view-users']);  
              },500)      
          }
        },
        (error : HttpErrorResponse)=>{
          if(error.status == 400 || error.status !== null){
            console.log("Error in save user",error);
            this.toastr.error(error.error.message);
          }         
        }
        );
      } else {
        this.toastr.error('please fill all mandatory fields');
      }
    } catch (error:any) {
      this.toastr.error(error.error,'');
      console.log('Error in save user', error);
    }
  }

  validate() {
    if (
      this.UserData.Fname == null ||
      this.UserData.Fname == '' ||
      this.UserData.Fname == undefined
    ) {
      this.UserNameHide = true;
      this.UserNameError = 'First name is mandatory !';
    } else {
      this.UserNameHide = false;
    }
    if (
      this.UserData.Lname == null ||
      this.UserData.Lname == '' ||
      this.UserData.Lname == undefined
    ) {
      this.LastNameHide = true;
      this.LastNameError = 'Last name is mandatory !';
    } else {
      this.LastNameHide = false;
    }
    if (
      this.UserData.Email == null ||
      this.UserData.Email == '' ||
      this.UserData.Email == undefined
    ) {
      this.EmailHide = true;
      this.EmailError = 'Email is mandatory !';
    } else {
      this.EmailHide = false;
    }
    if (
      this.UserData.Contact == null ||
      this.UserData.Contact == '' ||
      this.UserData.Contact == undefined
    ) {
      this.ContactHide = true;
      this.ContactError = 'Contact is mandatory !';
    } else {
      this.ContactHide = false;
    }
    if (
      this.UserData.Role == null ||
      this.UserData.Role == '' ||
      this.UserData.Role == undefined || this.UserData.Role == 'Please Select Role'
    ) {
      this.RoleHide = true;
      this.RoleError = 'Role is mandatory !';
    } else {
      this.RoleHide = false;
    }
  }
}
