<div class="maincontainer">
    <div class="dashboardbanner">
        <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
        <div class="bannerheading">
            Edit User
        </div>
    </div>

    <div class="fullwidth  bg_purple">
        <div class="w1200">
            <div class="dashboardwedget">
                <app-admin-sidebar></app-admin-sidebar>
                <div class="wedgetright">
                    <h3>User</h3>
                    <div class="dashwed">
                        <ul class="homelink">
                            <li><a class="active" routerLink="/add-user"><i class="fa fa-home fa-2x dashboardicon"
                                        aria-hidden="true"></i></a></li>
                            <li><a routerLink="/add-user">Edit User</a></li>
                        </ul>
                    </div>
                    <div class="artistboard">
                        <div class="wedgetform">
                            <div class="form1">

                                <form #userform="ngForm" class="formpanel">
                                    <div class="formwrap">
                                        <label for="f_name">First Name </label> <span style="color: red;">*</span>
                                        <input type="text" name="f_name"  
                                            value="{{ UserSelectedData.username }}" id="f_name" placeholder="First Name"
                                            required [(ngModel)]="UserData.Fname" [ngModelOptions]="{standalone:true}"  [maxlength]="30" >
                                        <div class="alert" style="color: red;" *ngIf="UserNameHide">{{ UserNameError }}
                                        </div>
                                    </div>
                                    <div class="formwrap">
                                        <label for="lname">Last Name </label> <span style="color: red;">*</span>
                                        <input type="text" name="l_name" id="lname" placeholder="Last Name" required
                                            [(ngModel)]="UserData.Lname" [ngModelOptions]="{standalone:true}" [maxlength]="30">
                                        <div class="alert" style="color: red;" *ngIf="LastNameHide">{{ LastNameError }}
                                        </div>
                                    </div>
                                    <div class="formwrap">
                                        <label for="email">Email </label> <span style="color: red;">*</span>
                                        <input type="email" name="email" id="email" placeholder="Email" required
                                            [(ngModel)]="UserData.Email" [ngModelOptions]="{standalone:true}" [maxlength]="50" readonly>
                                        <div class="alert" style="color: red;" *ngIf="EmailHide">{{ EmailError }}</div>
                                    </div>
                                    <div class="formwrap">
                                        <label for="contact">Contact No </label> <span style="color: red;">*</span>
                                        <input type="text" name="contact" id="contact" required placeholder="Contact"
                                            [(ngModel)]="UserData.Contact" [ngModelOptions]="{standalone:true}" [maxlength]="12">
                                        <div class="alert" style="color: red;" *ngIf="ContactHide">{{ ContactError }}
                                        </div>
                                    </div>
                                    <div class="formwrap">
                                        <label for="roles">Role </label> <span style="color: red;">*</span>
                                        <select name="roles" id="roles" required [(ngModel)]="UserData.Role"
                                            [ngModelOptions]="{standalone:true}">
                                            <option selected disabled>{{ UserData.Role }}</option>
                                            <option *ngFor="let roleData of GetAllRoles" value="{{ roleData.name }}">{{
                                                roleData.name }}</option>
                                        </select>
                                        <div class="alert" style="color: red;" *ngIf="RoleHide">{{ RoleError }}</div>
                                    </div>

                                    <div class="formwrapfull">
                                        <div class="submitdiv">
                                            <input type="submit" value="Edit" (click)="EditUser()">
                                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                                        </div>
                                        <strong style="top: 14px !important;">Items marked with <span>*</span> are
                                            mandatory</strong>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>



        </div>


    </div>