<!-- bof:wedgetleft -->
<div class="wedgetleft addoverflow">
  <ul class="tabs">
    <li [ngClass]="{'active' : selectTab === '/admin'}"><a (click)="admindashboard()"><i
          class="fa fa-home fa-2x dashboardicon" aria-hidden="true"></i> <span>Dashboard</span> </a></li>

    <li *ngIf="pdata1.includes('manage_customer')" [ngClass]="{'active': selectTab == '/all-customer-details'}"><a
        (click)="userdashboard()"><i class="usersicon"></i> <span>Manage Customer</span>

        <span *ngIf="notify[0].customer_new" style="padding-left: 90px;"><span class="spancss">{{ notify[0].customer_new
            }}</span></span>

      </a>
    </li>

    <li *ngIf="pdata1.includes('manage_artist')" [ngClass]="{'active' : selectTab === '/all-artist-details'}"><a
        (click)="artistdashboard()"><i class="musersicon"></i><span> Manage Artist</span><span
          *ngIf="notify[0].artist_new" style="padding-left: 122px;"><span class="spancss">{{ notify[0].artist_new
            }}</span></span></a></li>

    <li *ngIf="pdata1.includes('users_engagement')" [ngClass]="{'active' : selectTab === '/users-chat'}"><a
        (click)="userEngagementChat()"><i class="chaticon1"></i><span> Conversation</span>  
        <span *ngIf="notify[0].chat_new" style="padding-left: 124px;"><span class="spancss">{{ notify[0].chat_new
            }}</span></span> </a></li>

    <li *ngIf="pdata1.includes('bulk_email')" [ngClass]="{'active' : selectTab === '/bulk-email'}" style="display: none;"><a
        (click)="bulkEmail()"><i class="chaticon1"></i><span> Bulk Email</span> </a></li>


    <li *ngIf="pdata1.includes('order_request')" [ngClass]="{'active' : selectTab === '/recording-request'}"><a
        (click)="recordingdashboard()"><i class="recordsicon"></i><span> Manage Recording Request</span><span
          *ngIf="notify[0].order_new" style="padding-left: 29px;"><span class="spancss">{{ notify[0].order_new
            }}</span></span></a></li>

    <li *ngIf="pdata1.includes('artist_sample')" [ngClass]="{'active' : selectTab === '/all-voice-samples'}"><a
        (click)="sampledashboard()"><i class="voiceicon"></i><span> Manage Voice Sample</span><span
          *ngIf="notify[0].sample_new" style="padding-left: 66px;"><span class="spancss">{{ notify[0].sample_new
            }}</span></span></a></li>

    <li *ngIf="pdata1.includes('contact')" [ngClass]="{'active' : selectTab === '/all-user-contact-details'}"><a
        (click)="contactdashboard()"><i class="contacticon"></i><span> Manage Contact Us</span><span
          *ngIf="notify[0].contact_us_new" style="padding-left: 84px;"><span class="spancss">{{ notify[0].contact_us_new
            }}</span></span></a></li>

    <li *ngIf="pdata1.includes('revised_comment')" [ngClass]="{'active' : selectTab === '/revised-comments'}"><a
        (click)="revisedComments()"><i class="voiceicon"></i><span> Revised Comments</span><span
          *ngIf="notify[0].revision_comment_new" style="padding-left: 85px;"><span class="spancss">{{
            notify[0].revision_comment_new
            }}</span></span></a></li>




    <li *ngIf="pdata1.includes('revised_sample')" [ngClass]="{'active' : selectTab === '/revised-samples'}"><a
        (click)="revisedSamples()"><i class="voiceicon"></i><span> Revised Samples</span><span
          *ngIf="notify[0].recording_new" style="padding-left: 103px;"><span class="spancss">{{ notify[0].recording_new
            }}</span></span></a></li>



    <li *ngIf="pdata1.includes('review_rating')" [ngClass]="{'active' : selectTab === '/customer-review-rating'}"><a
        (click)="reviewAndRating()"><i class="reviewicon"></i><span> Manage Review & Rating</span><span
          *ngIf="notify[0].review_rating_new" style="padding-left: 46px;"><span class="spancss">{{
            notify[0].review_rating_new }}</span></span></a>
    </li>



    <li class="menu-item" [class.active]="activeSubMenu" *ngIf="pdata1.includes('roles') || pdata1.includes('user')">
      <a (click)="toggleSubMenu()">
        <span class="menu_content"><span class="menu_title"><span class="myImage myImage2" style="background-position: -391px -84px !important;width: 30px; height: 30px;"></span>Settings</span>
          <i class="mdropdownicon addcss addcss1" ></i> </span>
      </a>
      <ul class="submenu">
        <li [ngClass]="{'active' : selectTab === '/view-roles'}" *ngIf="pdata1.includes('roles')">
          <a (click)="viewRoles()" class="role_user" style="padding-left: 60px;">
            <i class="usersicon"></i>
            <span>Roles</span>
          </a>
        </li>

        <li [ngClass]="{'active' : selectTab === '/view-users'}" *ngIf="pdata1.includes('user')">
          <a (click)="users()" class="role_user" style="padding-left: 60px;">
            <i class="usersicon1"></i>
            <span>Users</span>
          </a>
        </li>

        <li [ngClass]="{'active' : selectTab === '/user-password'}">
          <a (click)="changePassword()" class="chnage_password" style="padding-left: 60px;">
            <b class="myImage myImage2 test"
              style="background-position: -114px -194px  !important;width: 30px; height: 30px;display: none;"></b>
            <span>
              <span class="myImage myImage2"
                style="background-position: -114px -194px  !important;width: 22px; height: 30px;"></span>Change
              Password</span>
          </a>
        </li>


      </ul>
    </li>

    <li class="menu-item" [class.active]="activeSubMenu1" *ngIf="pdata1.includes('artits_payment') || pdata1.includes('revenue_repoort')">
      <a (click)="toggleSubMenu1()">
        <span class="menu_content"><span><span class="myImage myImage2"
          style="background-position: -266px -84px !important;width: 30px; height: 30px;"></span>Reports</span>
          <i class="mdropdownicon addcss addcss1" style="margin-left: 15px;margin-top: -6px;"></i>
         </span>
      </a>
      <ul class="submenu1">
        <li *ngIf="pdata1.includes('revenue_repoort')" [ngClass]="{'active' : selectTab === '/revenue-report'}">
          <a  (click)="RevenueReport()" class="role_user" style="padding-left: 60px;">
            <i class="usersicon"></i>
            <span>Revenue</span>
          </a>
        </li>
        <li *ngIf="pdata1.includes('artits_payment')" [ngClass]="{'active' : selectTab === '/all-artist-payment-details'}">
          <a (click)="Artistpayment()"><i   class="paymenticon"></i><span  > Artist Payment</span></a>
        </li>
      </ul>
    </li>

    <!-- 
        <li><a href="#tab2"><i class="profileicon"></i><span> Mangge Studio Profile</span></a></li>
        <li><a href="#tab4"><i class="promoicon"></i><span> Manage Promo codes</span></a></li>
        <li><a href="#tab6"><i class="reviewicon"></i><span> Manage Review & Rating</span></a></li>
        <li><a href="#tab7"><i class="pictureicon"></i><span> Manage Studio Picture Sample</span></a>
        </li>
        <li><a href="#tab8"><i class="videoicon"></i><span> Manage Studio Video Sample</span></a></li>
        <li><a href="#tab9"><i class="paymenticon"></i><span> Manage Payment</span></a></li>
        <li><a href="#tab11"><i class="usercommenticon"></i><span> Manage User comment</span></a></li>
        <li class="managedropdown"><a href="#"><i class="mdropdownicon"></i><span> Manage Dropdowns
                </span></a>
            <ul class="managedropdownbase">
                <li><a href="#tab13"><i class="musersicon"></i><span> Manage CTA Queries</span></a></li>
                <li><a href="#tab14"><i class="musersicon"></i><span> Manage Studio CTA
                            Queries</span></a></li>

                <li><a href="#tab16"><i class="musersicon"></i><span> Artist Report</span></a></li>
                <li><a href="#tab17"><i class="mlike"></i><span> Unsuccessful Transactions</span></a>
                </li>
            </ul>
        </li> -->
  </ul>
</div>