import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../_services/user.service';
import { Router } from '@angular/router';
import { TokenStorageService } from '../_services/token-storage.service';
declare var $: any;
@Component({
  selector: 'app-edit-voice-profile',
  templateUrl: './edit-voice-profile.component.html',
  styleUrls: ['./edit-voice-profile.component.css']
})
export class EditVoiceProfileComponent implements OnInit {
  languageList: any;
  serviceList: any;
  content: any;
  categoryList: any;
  accentList: any;
  voiceRoleList: any;
  voiceAgeList: any;
  recordingSetupList: any;
  styleList: any;
  loader: boolean = true;
  //Fetch User log in details
  currentUser: any;
  contact: any;
  id: any;
  userId: any;
  service: string = "Voice Over";
  language: any = [];
  category: any = [];
  recording_Setup: any = [];
  voice_age: any = [];
  accents: any = [];
  role: any = [];
  style: any = [];


  constructor(private userService: UserService, private toastr: ToastrService, private router: Router,
    private token: TokenStorageService) { }

  ngOnInit(): void {
   
    this.currentUser = this.token.getUser();
    this.contact = this.token.getUser().contact;
    this.id = this.token.getUser().id;
    this.getEditVoiceProfile();
    //Fetching UserLogIn Details.
    this.getLanguageList();
    this.getCategoryList();
    this.getRecordingSetupList();
    this.getVoiceAgeList();
    this.getAccentList();
    this.getVoiceRoleList();
    this.getStyleList();
    this.loader = false;   
    setTimeout(() => {
      this.loadScript();
       }, 1000);
  }

  isEditVoice = false;
  isEditVoiceFailed = false;
  EditVoiceErrorMessage = '';

  editVoiceProfileForm: any = {
    language: null,
    category: null,
    recording_Setup: null,
    voice_age: null,
    accents: null,
    role: null,
    style: null
  };

  reloadCurrentRoute() {
    this.router.navigate(['/editvoice']);
  }

  reloadEqipExp() {
    this.router.navigate(['/equipment']);
  }


  newFunction() {    
//  this is for mobile and desktop view condition beacuse some code is not working in mobile view --- for language
 if(/Mobi/.test(navigator.userAgent)){
  const langu: any[] = this.editVoiceProfileForm.language;
      langu.forEach(langudata => {      
     this.language.push(langudata);
    });
 }else{
  var inputs = $("#language1 .search-choice");
    for (var i = 0; i < inputs.length; i++) {
      this.language.push(inputs[i].innerText)
    }
}


if(/Mobi/.test(navigator.userAgent)){
  const cate: any[] = this.editVoiceProfileForm.category;
      cate.forEach(catedata => {      
      this.category.push(catedata);
    });
 
}else{
  var inputs = $("#language2 .search-choice");
    for (var i = 0; i < inputs.length; i++) {
      this.category.push(inputs[i].innerText)
    }  
}

 
if(/Mobi/.test(navigator.userAgent)){
  const recording: any[] = this.editVoiceProfileForm.recording_Setup;
        recording.forEach(crecordingdata => {      
       this.recording_Setup.push(crecordingdata);
     }); 
}else{
  var inputs = $("#language3 .search-choice");
  for (var i = 0; i < inputs.length; i++) {
    this.recording_Setup.push(inputs[i].innerText)
  }
}

 
//  this is for mobile and desktop view condition beacuse some code is not working in mobile view --- for Voice age
 if(/Mobi/.test(navigator.userAgent)){
     const vAge: any[] = this.editVoiceProfileForm.voice_age;
     if(vAge != null){
      vAge.forEach(vagedata => {      
        this.voice_age.push(vagedata);
      }); 
    }else{
      this.voice_age = null;
    }
}else{
    var inputs = $("#language4 .search-choice");  
   for (var i = 0; i < inputs.length; i++) {
   this.voice_age.push(inputs[i].innerText)
 } 
}

  //  this is for mobile and desktop view condition beacuse some code is not working in mobile view --- for accents 
if(/Mobi/.test(navigator.userAgent)){
  const accent: any[] = this.editVoiceProfileForm.accents;
   if(accent != null){
    accent.forEach(accentdata => {      
      this.accents.push(accentdata);
    });
  }else{
    this.accents = null;
  } 
 
}else{
  var inputs = $("#language5 .search-choice");
    for (var i = 0; i < inputs.length; i++) {
      this.accents.push(inputs[i].innerText)
    }
}

// this is for mobile and desktop view condition beacuse some code is not working in mobile view --- for role 
if(/Mobi/.test(navigator.userAgent)){
  const roles: any[] = this.editVoiceProfileForm.role;
   if(roles != null){
      roles.forEach(rolesData => {      
        this.role.push(rolesData);
      });
    }else{
      this.role = null;
    }
}else{  
  var inputs = $("#language6 .search-choice");
  for (var i = 0; i < inputs.length; i++) {
    this.role.push(inputs[i].innerText)
  }
} 

// this is for mobile and desktop view condition beacuse some code is not working in mobile view --- for style 
if(/Mobi/.test(navigator.userAgent)){
  const styl: any[] = this.editVoiceProfileForm.style;
   if(styl != null){
      styl.forEach(stylydata => {      
        this.style.push(stylydata);
      });
    }else{
      this.style = null;
    }
  }else{  
  var inputs = $("#language7 .search-choice");
    for (var i = 0; i < inputs.length; i++) {
      this.style.push(inputs[i].innerText)
    }
} 

  
 }
  


  editVoiceProfile(): void { 
     this.newFunction();       
        if(this.language.length !==0 && this.category.length !==0 && this.recording_Setup.length !==0){
       this.userService.editVoiceProfile(this.language, this.category, this.recording_Setup, this.voice_age, this.accents, this.role, this.style, this.service, this.userId = this.id).subscribe(
        data => {
           this.isEditVoice = true;
          this.isEditVoiceFailed = false;
          window.scroll(0, 0);
           this.router.navigate(['/pricedelivery']);
          this.toastr.success('Voice Profile have been updated successfully', '')
        },
        err => {
          this.EditVoiceErrorMessage = err.error.message;
          this.isEditVoiceFailed = true;
        }
      );
    }else{
      this.toastr.error('Please select mandatory fields', '')

    }
   
  }

  //fetching Edit Voice Details 
  getEditVoiceProfile() {
    this.userService.getEditVoiceProfile(this.userId = this.id).subscribe(data => {
      this.editVoiceProfileForm = data;
      if (this.editVoiceProfileForm !== null && this.editVoiceProfileForm !== "") {
        ((element: {
          language: string, category: string, recording_Setup: string, voice_age: string,
          accents: string, role: string, style: string, service: string, userId: string
        }) => {
        });
      }
    },
      err => {
        console.log(err);
      }
    );



  }

  getLanguageList() {
    this.loader = true;
    this.userService.getLanguageList().subscribe(data => {
      this.languageList = data;
      if (this.languageList !== null && this.languageList !== "") {
        this.loader = false;
        this.languageList.forEach((element: { language: string; }) => {
        });
      }
    },
      err => {
        console.log(err);
      }
    );
  }

  getCategoryList() {
    this.loader = true;
    this.userService.getCategoryList().subscribe(data => {
      this.categoryList = data;
      if (this.categoryList !== null && this.categoryList !== "") {
        this.loader = false;
        this.categoryList.forEach((element: { category: string; }) => {
        });
      }
    },
      err => {
        console.log(err);
      }
    );
  }

  getRecordingSetupList() {
    this.loader = true;
    this.userService.getRecordingSetupList().subscribe(data => {
      this.recordingSetupList = data;
      if (this.recordingSetupList !== null && this.recordingSetupList !== "") {
        this.loader = false;
        this.recordingSetupList.forEach((element: { recording_Setup: string; }) => {
        });
      }
    },
      err => {
        console.log(err);
      }
    );
  }

  getVoiceAgeList() {
    this.loader = true;
    this.userService.getVoiceAgeList().subscribe(data => {
      this.voiceAgeList = data;
      if (this.voiceAgeList !== null && this.voiceAgeList !== "") {
        this.loader = false;
        this.voiceAgeList.forEach((element: { voice_age: string; }) => {
        });
      }
    },
      err => {
        console.log(err);
      }
    );
  }

  getVoiceRoleList() {
    this.loader = true;
    this.userService.getVoiceRoleList().subscribe(data => {
      this.voiceRoleList = data;
      if (this.voiceRoleList !== null && this.voiceRoleList !== "") {
        this.loader = false;
        this.voiceRoleList.forEach((element: { role: string; }) => {
        });
      }
    },
      err => {
        console.log(err);
      }
    );
  }

  getAccentList() {
    this.loader = true;
    this.userService.getAccentList().subscribe(data => {
      this.accentList = data;
      if (this.accentList !== null && this.accentList !== "") {
        this.loader = false;
        this.accentList.forEach((element: { accents: string; }) => {
        });
      }
    },
      err => {
        console.log(err);
      }
    );
  }

  getStyleList() {
    this.loader = true;
    this.userService.getStyleList().subscribe(data => {
      this.styleList = data;
      if (this.styleList !== null && this.styleList !== "") {
        this.loader = false;
        this.styleList.forEach((element: { style: string; }) => {
        });
      }
    },
      err => {
        console.log(err);
      }
    );
  }

  public loadScript() {
     let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = '/assets/js/chosen.jquery.min.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }
}
