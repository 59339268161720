import { Component, OnInit } from '@angular/core';
import { UserService } from '../_services/user.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-bulk-email',
  templateUrl: './bulk-email.component.html',
  styleUrls: ['./bulk-email.component.css'],
})
export class BulkEmailComponent implements OnInit {
  title = "angular";
  public Editor = ClassicEditor;
  EmailToHide!: boolean;
  SubjectHide!: boolean;
  EmailMSGHide!: boolean;
  EmailToError: any;
  SubjectError: any;
  EmailMSGError: any;
  constructor(private userService:UserService,private toastr:ToastrService, private http:HttpClient, private router:Router) {}

  ngOnInit(): void {
    this.BULKSMS.EmailTo =  "Please Select User Type";  }

  BULKSMS:any = {
    EmailTo: null,
    Subject: null,
    EmailMSG: null,
  };

  sentBulkEmail() {
    try {    
      this.bulkEmailvalidation();
      // alert(this.BULKSMS.EmailMSG); 
       if (
          this.BULKSMS.EmailTo != null &&         
          this.BULKSMS.Subject != null &&        
          this.BULKSMS.EmailMSG != null          
      ) {
        if(this.bulkEmailvalidation() == "true"){
           const BulkEmailData = {
            type:this.BULKSMS.EmailTo,
            subject:this.BULKSMS.Subject,
            message:this.BULKSMS.EmailMSG
          }
          console.log("Bulk Email DAta ",BulkEmailData);
          this.userService.bulkEmail(BulkEmailData).subscribe((response)=>{
           if(response){
            this.toastr.success("Email sent Successfully ! ");
            // this.router.navigate(['/bulk-email'])
           }
          },
          (error:HttpErrorResponse)=>{
            if(error.status == 400 || error.status !== null){
              console.log("Error in sent bulk email",error);
              this.toastr.error(error.error.message);
            }
          }
          )
        }else{
          this.toastr.error("Please select all mandatory fields !");
        }  
      }else{
           this.toastr.error("Please select all mandatory fields !");
        }
    } catch (error) {
      console.log('Error in sent bulk sms', error);
    }
  }

  bulkEmailvalidation() {
    if (
      this.BULKSMS.EmailTo == null ||
      this.BULKSMS.EmailTo == undefined ||
      this.BULKSMS.EmailTo == '' || this.BULKSMS.EmailTo == 'Please Select User Type'
    ) {
      this.EmailToHide = true;
      this.EmailToError = 'Please Select User Type !';
    } else {
      this.EmailToHide = false;
    }
    if (
      this.BULKSMS.Subject == null ||
      this.BULKSMS.Subject == undefined ||
      this.BULKSMS.Subject == ''
    ) {
      this.SubjectHide = true;
      this.SubjectError = 'Subject field is mandatory !';
    } else {
      this.SubjectHide = false;
    }
    if (
      this.BULKSMS.EmailMSG == null ||
      this.BULKSMS.EmailMSG == undefined ||
      this.BULKSMS.EmailMSG == ''
    ) {
      this.EmailMSGHide = true;
      this.EmailMSGError = 'Email msg field is mandatory !';
    } else {
      this.EmailMSGHide = false;
    }    
    if(this.EmailMSGHide == false && this.SubjectHide == false && this.EmailToHide == false){
      return "true";
    }  else{
      return "false";
    }
  }
}
