import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from '../_services/token-storage.service';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-artist-payment-history',
  templateUrl: './artist-payment-history.component.html',
  styleUrls: ['./artist-payment-history.component.css']
})
export class ArtistPaymentHistoryComponent implements OnInit {

  constructor(private token: TokenStorageService, private userService: UserService, private route: ActivatedRoute,
    private router: Router, private httpClient: HttpClient) { }

  ngOnInit(): void {
    this.loader = false;
    this.currentUser = this.token.getUser();
    this.contact = this.token.getUser().contact;
    this.id = this.token.getUser().id;
    this.email = this.token.getUser().email;
    this.firstName = this.token.getUser().username;
    this.lastName = this.token.getUser().lastname;
    this.fetchProjectPaymentDetails();
  }

  currentUser: any;
  contact: any;
  id: any;
  firstName: any;
  lastName: any;
  email: any;
  loader: boolean = true;
  ProjectPaymentDetails: any;
 

  fetchProjectPaymentDetails() {
    this.loader = true;
    this.userService.fetchProjectPaymentDetails(this.id).subscribe(data => {
      this.ProjectPaymentDetails = data;
      if (this.ProjectPaymentDetails !== null && this.ProjectPaymentDetails !== "") {
        this.loader = false;
        this.ProjectPaymentDetails.forEach((element: {
        }) => {
        });
      }
    },
      err => {
        console.log(err);
      }
    );
  }

}
