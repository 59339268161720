<div class="dashboardbanner">
    <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
    <div class="bannerheading">Hire Artist</div>
</div>

<div class="fullwidth bg_purple">
    <!--bof: myrecording-->
    <div class="w1200">

        <div class="wedget">
            <app-sidebar></app-sidebar>
            <!-- bof:wedgetright -->
            <div class="wedgetright">

                <div class="wedgetform">

                    <!--bof:formsection-->
                    <div class="form1 payment_form">
                        <form class="formpanel">
                            <div class="formwrap">
                                <select [(ngModel)]="language" [ngModelOptions]="{standalone: true}">
                                    <option>Select Language</option>
                                    <option *ngFor="let items of languageList">{{items}}</option>
                                </select>
                            </div>
                            <div class="formwrap">

                                <select [(ngModel)]="categories" [ngModelOptions]="{standalone: true}">
                                    <option>Select Category</option>
                                    <option *ngFor="let items of categoryList">{{items}}</option>
                                </select>
                            </div>
                            <!-- <div class="formwrap">

                                <select [(ngModel)]="subCategories" [ngModelOptions]="{standalone: true}">
                                    <option>Select Sub Category</option>
                                    <option>Translation</option>
                                    <option>Voice Over</option>
                                    <option>Dubbling</option>
                                    <option>Translation</option>
                                    <option>Voice Over</option>
                                    <option>Dubbling</option>
                                    <option>Translation</option>
                                    <option>Voice Over</option>
                                    <option>Dubbling</option>
                                    <option>Translation</option>
                                    <option>Voice Over</option>
                                </select>
                            </div> -->
                            <div class="formwrapfull">
                                <textarea placeholder="Enter your script here..."
                                    [attr.disabled]="words >5000 ? '' : null" (keyup)="wordCounter()" #text
                                    id="wmd-input" name="post-text"
                                    class="wmd-input s-input bar0 js-post-body-field processed" data-post-type-id="2"
                                    cols="92" rows="15" tabindex="101" data-min-length="" oncopy="return oncopy;"
                                    onpaste="return oncopy;" oncut="return false;"></textarea>
                            </div>
                            <div class="formwrap">
                                <p> <b>Word Count:</b> <span id="wordCount">{{ words }}</span></p>
                            </div>
                            <div class="formwrapfull">
                                <textarea
                                    placeholder="Any kind of instruction that you want to share with the artist write here. Do not insert any personal information or links.."></textarea>
                            </div>
                            <div class="formwrap">
                                <div class="submitdiv">
                                    <input type="submit" value="Get Quote"
                                        (click)="paymentFinal(language, categories, subCategories, words)">
                                </div>
                            </div>
                        </form>
                    </div>
                    <!--eof:formsection-->

                </div>
                <div class="form2">
                    <div class="formwrap">
                        <p>Price</p>
                    </div>
                    <div class="price_cont">
                        <div class="price_box_row">
                            <div class="price_col">
                                <p class="price">Best Price: <span>Rs.5188</span></p>
                            </div>
                            <div class="price_col">
                                <p class="price">Taxes: <span>Rs.5188</span></p>
                            </div>
                            <div class="price_full_col">
                                <p class="price">Total Price: <span>Rs.5188</span></p>
                            </div>
                        </div>
                    </div>

                    <div class="total_amt">
                        <p class="totaltxt">Total Amount: <span>Rs. 5510</span></p>
                    </div>

                    <!-- <div class="wallet_check">
                        <input type="checkbox" id="wallet_bal" name="neft" value="Redeem via NEFT"><label
                            for="wallet_bal">Wallet <span>(Available Balance: Rs. 992)</span></label>
                    </div> -->
                    <div class="pay_btn_box">
                        <div class="pay_btn_row">
                            <div class="pay_btn_col">
                                <a href="#" class="pay_btn">Pay Now</a>
                            </div>
                            <div class="pay_btn_col">
                                <p>Secured Transactions</p>
                            </div>
                        </div>
                    </div>


                    <p class="pay_msg">Your payment is held securely in escrow and is released to the voice actor any
                        after you mark the project is satisfied.</p>
                </div>

            </div>
        </div>
        <!--eof: myrecording-->
    </div>
</div>