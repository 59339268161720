import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

//const AUTH_API = 'http://localhost:8080/api/auth/';
const AUTH_API = 'https://api.pinnaklebiz.com/the-voice-mall/api/auth/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient) { }



  login(email: string, password: string): Observable<any> {
    return this.http.post(AUTH_API + 'signin', {
      email,
      password
    }, httpOptions);
  }

  registerUser(username: string, role: string[], last_name: string, company_name: string, country: string, contact: string,
    email: string, password: string, company_address: string, gst: string): Observable<any> {
    return this.http.post(AUTH_API + 'signup', {
      username,
      role: [role],
      last_name,
      company_name,
      country,
      contact,
      email,
      password,
      company_address,
      gst
    }, httpOptions);
  }

  sendEmailLink(email: string): Observable<any> {
    return this.http.post(AUTH_API + 'sendMail', {
      email,
    }, httpOptions);
  }

  changePassword(email: string, password: string): Observable<any> {
    return this.http.post(AUTH_API + 'updatePassword', {
      email,
      password
    }, httpOptions);
  }

  // Change user password

ChangeUserPassword(data:any){
  return this.http.post(AUTH_API+ 'changePassword',data);
}

}
