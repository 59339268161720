<app-loader *ngIf="loader"></app-loader>

<!-- Banner -->
<div class="dashboardbanner">
    <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
    <div class="bannerheading">Upload Voice Samples</div>
</div>

<div class="fullwidth bg_purple">
    <!--bof: myrecording-->
    <div class="w1200">
        <div class="pointgrid">
            <div class="pointcols">
                <a>
                    <span routerLink="/editprofile"><i class="active"></i></span>
                    <strong>Personal Info</strong></a>
            </div>
            <div class="pointcols">
                <span (click)="reloadEditVoice()"><i class="active"></i></span>
                <strong>Voice Profile</strong>
            </div>
            <div class="pointcols">
                <span routerLink="/pricedelivery"><i class="active"></i></span>
                <strong>Price, Delivery time &amp; Availability</strong>
            </div>
            <div class="pointcols">
                <span (click)="reloadEqipExp()"><i class="active"></i></span>
                <strong>Equipments &amp; Experience</strong>
            </div>
            <div class="pointcols">
                <span><i class="active"></i></span>
                <strong>Voice &amp; Sample</strong>
            </div>
        </div>
        <div class="wedget">
            <app-sidebar></app-sidebar>
            <!-- bof:wedgetright -->
            <div class="wedgetright">
                <div class="wedgetform">
                    <!--bof:formsection-->
                    <div class="form1">
                        <!-- <h3 class="prof">Upload Voice Sample</h3> -->
                        <form class="formpanel">
                            <div class="formwrap">
                                <label>Enter title of sample voice<span>*</span></label>
                                <input type="text" [(ngModel)]="voiceSampleFrom.sampleVoice"
                                    placeholder="Enter Your title of sample voice" [ngModelOptions]="{standalone: true}"
                                    maxlength="50">
                                <div class="alert" *ngIf="sampleVoiceHide">{{sampleVoiceError}}</div>
                            </div>

                            <div class="formwrap">
                                <label>Language<span>*</span></label>
                                <select data-placeholder="Choose Languages" [(ngModel)]="voiceSampleFrom.language"
                                    [ngModelOptions]="{standalone: true}">
                                    <option [ngValue]="null" selected disabled>Select Language</option>
                                    <option *ngFor="let data of languageList" [label]="data.language"
                                        [value]="data.language"></option>
                                </select>
                                <div class="alert" *ngIf="languageHide">{{languageError}}</div>

                            </div>
                            <div class="formwrap">
                                <label>Category<span>*</span></label>
                                <select data-placeholder="Choose Categories" [(ngModel)]="voiceSampleFrom.category_name"
                                    [ngModelOptions]="{standalone: true}">
                                    <option [ngValue]="null" selected disabled>Select Category</option>
                                    <option *ngFor="let data of categoriesList" [label]="data.category_name"
                                        [value]="data.category_name">
                                    </option>
                                </select>
                                <div class="alert" *ngIf="categoryHide">{{categoryError}}</div>
                            </div>

                            <div class="formwrap">
                                <label>Accent</label>
                                <select [(ngModel)]="voiceSampleFrom.accent" [ngModelOptions]="{standalone: true}">
                                    <option [ngValue]="null" selected disabled>Select Accent</option>
                                    <option *ngFor="let data of accentList" [label]="data.accent" [value]="data.accent">
                                    </option>
                                </select>
                            </div>
                            <div class="formwrap">
                                <label>Role</label>
                                <select [(ngModel)]="voiceSampleFrom.role_name" [ngModelOptions]="{standalone: true}">
                                    <option [ngValue]="null" selected disabled>Select Role</option>
                                    <option *ngFor="let data of roleList" [label]="data.role_name"
                                        [value]="data.role_name"></option>
                                </select>
                            </div>
                            <div class="formwrap">
                                <label>Style</label>
                                <select [(ngModel)]="voiceSampleFrom.style_name" [ngModelOptions]="{standalone: true}">
                                    <option [ngValue]="null" selected disabled>Select Style</option>
                                    <option *ngFor="let data of styleList" [label]="data.style_name"
                                        [value]="data.style_name"></option>
                                </select>
                            </div>
                            <div class="formwrap">
                                <label>Upload<span>*</span></label>
                                <div class="custom-file-upload">
                                    <input id="" type="file" name="file" accept="audio/mp3"
                                        (change)="onImageUpload($event)">
                                </div>
                                <p class="alertmessage">File format should be .mp3 or .wav</p><br>
                                <p class="alertmessage">Sample size should not exceed 10 MB</p><br>
                                <div class="alert" *ngIf="nameHide">{{nameError}}</div>
                                <p>I solemnly declare that voice samples, I'm uploading have been created/recorded
                                    solely in my own voice.</p>
                            </div>

                            <div class="formwrap">
                                <label class="customcheck">Agree to Terms & conditions<span>*</span><br>(<a
                                        (click)="openModal()">Click to view terms and conditions</a>)
                                    <input type="checkbox" name="checkbox" value="check" [(ngModel)]="ifChecked"
                                        [ngModelOptions]="{standalone: true}">
                                    <span class="checkmark"></span>
                                </label>
                            </div>

                            <div #outsideElement>
                                <div class="modal-view-container">
                                    <div #modalView class="modal view">
                                        <div class="modal-view-details">
                                            <p style="text-align: center;margin-top: 0; margin-bottom: 0">
                                                <object data="/assets/pdf/ArtistTerms.pdf" type="application/pdf"
                                                    width="100%" height="592px"></object>
                                            </p>
                                            <!-- <button (click)="closeModal()">Close</button> -->
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="formwrapfull">

                                <div class="submitdiv">
                                    <input type="submit" value="Save" (click)="voiceSampleUpload()">
                                </div>
                                <!-- <strong>Items marked with <span>*</span> are mendatory</strong> -->
                            </div>
                        </form>
                    </div>
                    <!--eof:formsection-->

                </div>
            </div>
        </div>
        <!--eof: myrecording-->
    </div>
</div>
