<app-loader *ngIf="loader"></app-loader>

<div class="dashboardbanner">
    <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
    <div class="bannerheading">Add Payment Details</div>
</div>

<div class="fullwidth bg_purple">
    <!--bof: myrecording-->
    <div class="w1200">
        <div class="wedget">
            <app-sidebar></app-sidebar>
            <!-- bof:wedgetright -->
            <div class="wedgetright">
                <div class="wedgetform">
                    <!--bof:formsection-->
                    <div class="form1">
                        <!-- <h3 class="prof">Edit Profile</h3> -->
                        <form class="formpanel" name="form" (ngSubmit)="f.form.valid && paymentDetails()" #f="ngForm">

                            <div class="formwrap" *ngIf="this.country === 'India'">
                                <label>Enter your name <span>*</span></label>
                                <input type="text" class="form-control" name="artistName" placeholder="Enter your name"
                                    [(ngModel)]="addPaymentDetails.artistName" [ngModelOptions]="{standalone: true}"
                                    maxlength="80" required #artistName="ngModel">
                                <div class="alert-danger" *ngIf="artistName.errors && f.submitted">
                                    <div *ngIf="artistName.errors.required">Name is mandatory</div>
                                </div>
                            </div>

                            <div class="formwrap" *ngIf="this.country === 'India'">
                                <label>Enter your address</label>
                                <input type="text" class="form-control" placeholder="Enter your address" name="address"
                                    [(ngModel)]="addPaymentDetails.address" [ngModelOptions]="{standalone: true}"
                                    maxlength="180" #address="ngModel">
                            </div>


                            <div class="formwrap" *ngIf="this.country === 'India'">
                                <label>Enter your PAN</label>
                                <input type="text" class="form-control"
                                    placeholder="Please share your PAN or 20% TDS will be deducted" name="panNumber"
                                    [(ngModel)]="addPaymentDetails.panNumber" [ngModelOptions]="{standalone: true}"
                                    maxlength="10" #panNumber="ngModel">
                            </div>

                            <div class="viewprofiles" *ngIf="this.country === 'India'">
                                <h2 class="text-center">Upload your PAN</h2>
                                <p class="alertmessage">File format should be .jpeg or .png</p><br>
                                <p class="alertmessage">Sample size should not exceed 1 MB</p><br>
                                <div class="Insideview">
                                    <input type="file" (change)="onImageUpload($event)" placeholder="Select image"
                                        accept="image/*">
                                    <input type="button" (click)="panUploadAction()" value="Upload Image"
                                        class="image-upload">
                                    <output *ngIf=successResponseForPan
                                        class="success"><b>{{successResponseForPan}}</b></output>
                                </div>
                            </div>

                            <div class="formwrap" *ngIf="this.country === 'India'">
                                <label>Enter your GSTIN</label>
                                <input type="text" class="form-control"
                                    placeholder="Enter your GSTIN to claim your GST input" name="gstNumber"
                                    [(ngModel)]="addPaymentDetails.gstNumber" [ngModelOptions]="{standalone: true}"
                                    maxlength="15" #gstNumber="ngModel">
                            </div>

                            <div class="viewprofiles" *ngIf="this.country === 'India'">
                                <h2 class="text-center">Upload your GSTIN</h2>
                                <p class="alertmessage">File format should be .jpeg or .png</p><br>
                                <p class="alertmessage">Sample size should not exceed 1 MB</p><br>
                                <div class="Insideview">
                                    <input type="file" (change)="onImageUpload($event)" placeholder="Select image"
                                        accept="image/*">
                                    <input type="button" (click)="gstUploadAction()" value="Upload Image"
                                        class="image-upload">
                                    <output *ngIf=successResponseForGst
                                        class="success"><b>{{successResponseForGst}}</b></output>
                                </div>
                            </div>

                            <div class="formwrap">
                                <label>Account holder name<span>*</span></label>
                                <input type="text" class="form-control" placeholder="Account holder name"
                                    name="accountHolderName" [(ngModel)]="addPaymentDetails.accountHolderName"
                                    [ngModelOptions]="{standalone: true}" maxlength="80" required
                                    #accountHolderName="ngModel">

                                <div class="alert-danger" *ngIf="accountHolderName.errors && f.submitted">
                                    <div *ngIf="accountHolderName.errors.required">Name is mandatory</div>
                                </div>
                            </div>

                            <div class="formwrap">
                                <label>Account number<span>*</span></label>
                                <input type="number" class="form-control" placeholder="Account number"
                                    name="accountNumber" [(ngModel)]="addPaymentDetails.accountNumber"
                                    [ngModelOptions]="{standalone: true}" onKeyPress="if(this.value.length==20) return false;" required
                                    #accountNumber="ngModel">
                                <div class="alert-danger" *ngIf="accountNumber.errors && f.submitted">
                                    <div *ngIf="accountNumber.errors.required">Account Number is mandatory</div>
                                </div>
                            </div>

                            <div class="formwrap">
                                <label>Bank name<span>*</span></label>
                                <input type="text" class="form-control" placeholder="Enter bank name" name="bankName"
                                    [(ngModel)]="addPaymentDetails.bankName" [ngModelOptions]="{standalone: true}"
                                    maxlength="50" required #bankName="ngModel">
                                <div class="alert-danger" *ngIf="bankName.errors && f.submitted">
                                    <div *ngIf="bankName.errors.required">bank Name is mandatory</div>
                                </div>
                            </div>

                            <div class="formwrap" *ngIf="this.country === 'India'">
                                <label>IFSC Code<span>*</span></label>
                                <input type="text" class="form-control" placeholder="Enter IFSC code" name="ifscCode"
                                    [(ngModel)]="addPaymentDetails.ifscCode" [ngModelOptions]="{standalone: true}"
                                    maxlength="15" required #ifscCode="ngModel">
                                <div class="alert-danger" *ngIf="ifscCode.errors && f.submitted">
                                    <div *ngIf="ifscCode.errors.required">IFSC Code is mandatory</div>
                                </div>
                            </div>

                            <div class="formwrap">
                                <label>Branch name<span>*</span></label>
                                <input type="text" class="form-control" placeholder="Enter your branch name" name="branch"
                                    [(ngModel)]="addPaymentDetails.branch" [ngModelOptions]="{standalone: true}"
                                    maxlength="100" required #branch="ngModel">
                                <div class="alert-danger" *ngIf="branch.errors && f.submitted">
                                    <div *ngIf="branch.errors.required">Branch name is mandatory</div>
                                </div>
                            </div>
                           <!--Start from here to add domestics payment details -->

                            <div class="formwrap" *ngIf="this.country !== 'India'">
                                <label>Enter IBAN</label>
                                <input type="text" class="form-control" placeholder="Enter your IBAN" name="iban"
                                    [(ngModel)]="addPaymentDetails.iban" [ngModelOptions]="{standalone: true}"
                                    maxlength="50" required #iban="ngModel">
                            </div>
                            <div class="formwrap" *ngIf="this.country !== 'India'">
                                <label>Swift code<span>*</span></label>
                                <input type="text" class="form-control" placeholder="Enter your Swift code" name="swiftCode"
                                    [(ngModel)]="addPaymentDetails.swiftCode" [ngModelOptions]="{standalone: true}"
                                    maxlength="20" required #swiftCode="ngModel">
                                <div class="alert-danger" *ngIf="swiftCode.errors && f.submitted">
                                    <div *ngIf="swiftCode.errors.required">Swift code is mandatory</div>
                                </div>
                            </div>

                            <div class="formwrap" *ngIf="this.country !== 'India'">
                                <label>Country<span>*</span></label>
                                <input type="text" class="form-control" placeholder="Enter your Country" name="country"
                                    [(ngModel)]="addPaymentDetails.country" [ngModelOptions]="{standalone: true}"
                                    maxlength="50" required #country="ngModel">
                                <div class="alert-danger" *ngIf="country.errors && f.submitted">
                                    <div *ngIf="country.errors.required">Country is mandatory</div>
                                </div>
                            </div>

                            <div class="formwrap" *ngIf="this.country !== 'India'">
                                <label>City<span>*</span></label>
                                <input type="text" class="form-control" placeholder="Enter your City" name="city"
                                    [(ngModel)]="addPaymentDetails.city" [ngModelOptions]="{standalone: true}"
                                    maxlength="50" required #city="ngModel">
                                <div class="alert-danger" *ngIf="city.errors && f.submitted">
                                    <div *ngIf="city.errors.required">City is mandatory</div>
                                </div>
                            </div>
                            <div class="formwrap" *ngIf="this.country !== 'India'">
                                <label>Branch address<span>*</span></label>
                                <input type="text" class="form-control" placeholder="Enter your branch address" name="branchAddress"
                                    [(ngModel)]="addPaymentDetails.branchAddress" [ngModelOptions]="{standalone: true}"
                                    maxlength="100" required #branchAddress="ngModel">
                                <div class="alert-danger" *ngIf="branchAddress.errors && f.submitted">
                                    <div *ngIf="branchAddress.errors.required">Branch address is mandatory</div>
                                </div>
                            </div>
                            
                            <div class="formwrapfull">
                                <div class="submitdiv">
                                    <input type="submit" value="Save">
                                </div>
                                <strong>Items marked with <span>*</span> are mendatory</strong>
                            </div>

                        </form>
                    </div>
                    <!--eof:formsection-->
                </div>
            </div>
        </div>
    </div>
</div>