import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../_services/auth.service';
import { TokenStorageService } from '../_services/token-storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  loginform: any = {
    email: null,
    password: null,
  };
  registerform: any = {
    username: null,
    last_name: null,
    company_name: null,
    country: null,
    contact: null,
    company_address: null,
    gst: null,
  };

  isSignUpFailed = false;
  popup = false;
  roles: string[] = [];
  isLoggedIn = false;
  isLoginFailed = false;
  loginErrorMessage = '';
  IsApproved: any;
  loader: boolean = true;

  constructor(
    private authService: AuthService,
    private tokenStorage: TokenStorageService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    // if (this.tokenStorage.getToken()) {
    //   this.roles = this.tokenStorage.getUser().roles;
    // }
    this.roles = this.tokenStorage.getUser().roles;
    this.IsApproved = this.tokenStorage.getUser().is_admin_approved;
    if (this.roles !== null && this.roles !== undefined) {
      if (
        this.IsApproved !== null &&
        this.IsApproved !== false &&
        this.IsApproved !== undefined &&
        this.IsApproved !== 0
      ) {
        if (this.roles.includes('ROLE_ARTIST')) {
          this.router.navigate(['/editprofile']);
        } else if (this.roles.includes('ROLE_CUSTOMER')) {
          this.router.navigate(['/customerdashboard']);
        } else if (
          this.roles !== null &&
          this.roles !== undefined &&
          this.roles.length > 0
        ) {
          this.router.navigate(['/admin']);
        } else {
          this.router.navigate(['/']);
        }
      }else if(
        this.IsApproved !== null &&
        this.IsApproved !== false &&
        this.IsApproved !== undefined &&
        this.IsApproved !== 0 &&  this.IsApproved !== 1  &&  this.IsApproved == 2
      ){
        this.toastr.error('Request Rejected by admin');;
      }else{
        this.toastr.error('Registration request is panding');
      }
    }
  }

  loginUser(): void {
    const { email, password } = this.loginform;
    if (
      this.loginform.email !== null &&
      this.loginform.email !== '' &&
      this.loginform.password !== null &&
      this.loginform.password !== ''
    ) {
      this.authService
        .login(this.loginform.email, this.loginform.password)
        .subscribe(
          (data) => {
            this.isSignUpFailed = false;
            this.IsApproved = data.is_admin_approved;
            if (
              this.IsApproved !== null &&
              this.IsApproved !== false &&
              this.IsApproved !== undefined &&
              this.IsApproved !== 0 &&  this.IsApproved !== 2
            ) {
              this.tokenStorage.saveToken(data.accessToken);
              this.tokenStorage.saveUser(data);
              this.isLoggedIn = data.enabled;
              this.roles = this.tokenStorage.getUser().roles;
              if (this.roles.includes('ROLE_ARTIST')) {
                this.router.navigate(['/editprofile']);
              } else if (this.roles.includes('ROLE_CUSTOMER')) {
                this.router.navigate(['/customerdashboard']);
              } else if (
                this.roles !== null &&
                this.roles !== undefined &&
                this.roles.length > 0
              ) {
                this.router.navigate(['/admin']);
              } else {
                this.router.navigate(['/']);
              }
              setTimeout(() => {
                {
                  this.reloadPage();
                }
              }, 10);
            } else if(
              this.IsApproved !== null &&
              this.IsApproved !== false &&
              this.IsApproved !== undefined &&
              this.IsApproved !== 0 &&  this.IsApproved !== 1  &&  this.IsApproved == 2
            ){
              this.toastr.error('Request Rejected by admin');;
            }else{
              this.toastr.error('Registration request is panding');
            }
          },
          (err) => {
            this.loginErrorMessage = err.error.message;
            this.isLoginFailed = true;
          }
        );
    } else {
      this.toastr.error('Please enter your username and password!!', '');
    }
  }

  sendEmailLink(): void {
    const { email } = this.registerform;
    if (
      this.registerform.email !== null &&
      this.registerform.email !== '' &&
      this.registerform.email !== undefined
    ) {
      this.authService.sendEmailLink(email).subscribe();
      this.toastr.info(
        'Reset password link has been sent on your Email Id!!',
        ''
      );
      setTimeout(() => {
        this.toastr.info(
          'Your request is in process. And you will receive an email within 2-3 minutes!!',
          ''
        );
      }, 2000);
    } else {
      this.toastr.error('Email id is mandatory!!', '');
    }
  }

  reloadPage(): void {
    window.location.reload();
  }
}
