import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { UserService } from '../_services/user.service';
import { Ng2SearchPipe, Ng2SearchPipeModule } from 'ng2-search-filter';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
  @Component({
  selector: 'app-all-customer-details',
  templateUrl: './all-customer-details.component.html',
  styleUrls: ['./all-customer-details.component.css']
})
export class AllCustomerDetailsComponent implements OnInit {

  allCutmrDetails: any;
  allUserDetails: any
  checkApproved:any;
  set searchParam(value:any){
    if(value != null && value !='')
    this.allUserDetails=Ng2SearchPipe.prototype.transform(this.allCutmrDetails,value);
  else
  this.allUserDetails = this.allCutmrDetails;
  this.totCustNo = this.allUserDetails.length;
  this.page = 1

  }
  totCustNo:any;
  page: number = 1;
  constructor(private userService: UserService,private renderer: Renderer2,private el: ElementRef,private http:HttpClient,private toastr:ToastrService ) { }

  ngOnInit(): void {
    this.getallUserDetails();
    
    
   }

 


  isFirstPage(): boolean {
    return this.page != 1;
  }

  isLastPage(): any {        
    if(this.page == Math.ceil(this.allUserDetails.length / 10)){
      return false;
    } else {
      return true;
    }    
  }

  onPageChange(event: number): void {
    this.page = event;
  }

  pageChanged(event: any): void {
    const button = this.el.nativeElement.querySelector('.pagination-button');
    this.page = event;
    // Perform any actions with the new page number here
  }


  goToFirstPage() {
    this.page = 1; // Assuming 1-based indexing
}

goToLastPage() {
    // Calculate the last page based on the total number of items and items per page
    const totalPages = Math.ceil(this.allUserDetails.length / 10); // Assuming 45 items per page
    this.page = totalPages;
}


  getallUserDetails() {
    this.userService.getallUserDetails().subscribe((data: any) => {
      this.allUserDetails = data;
      this.allCutmrDetails = data;
       this.totCustNo = this.allCutmrDetails.length;
      this.allUserDetails.forEach((element: {}) => {
        data.length
      });
    },
      err => {
        console.log(err);
      }
    );
  }


  ChangeUserStatus(user_id:any, status:any,remark:any){
    try {
      if(user_id !== null && user_id !== '' && user_id !== undefined && status !== null && status !== undefined && status !== ''){
        const URL  = '?user_id='+user_id+'&status='+status+'&remark='+remark;    
        console.log("All Data " , URL);
           this.userService.changeUserStatus(URL).subscribe((response)=>{
              if(response){
                this.toastr.success("Status Changes Successsfully",'');
                setTimeout(()=>{
                  window.location.reload();
                },200);
             }else{
              this.toastr.error("Somehting went wrong",'');
             }
           },
           (error:HttpErrorResponse)=>{
            if(error.status == 400 || error.status !== null){
              console.log("I have error in call of change user status ",error);
             this.toastr.error(error.error.message,'');
            }
           }
           )
      }else{
        this.toastr.error("something went wrong",'');
      }
    } catch (error) {
      console.log("Error in approve or reject user", error);
    }
  }


}
