import { Component, ElementRef, ViewChildren, OnInit, VERSION, ViewChild } from '@angular/core';
import { category, language, service } from '../modals/countries.modal';
import { UserService } from '../_services/user.service';
import { Country } from 'country-state-city';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from '../_services/token-storage.service';
import { ToastRef, ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  content?: string;
  serviceList: any;
  languageList: any;
  categoryList: any;
  searchVoiceList: any;
  service!: service;
  language!: language;
  category!: category;
  gender: any;
  event: any;
  userId: any;
  pages: { page: number; limit: number; };
  artistid: any;
  finalPrice: any;
  loader: boolean = true;


  

  //Start from here For word count
  wordCount: any;
  @ViewChild("text")
  text!: ElementRef;
  words: any;
  roles: any;
  isLoggedIn = false;
  page: any = 1;

  wordCounter() {
    //alert(this.text.nativeElement.value)
    this.wordCount = this.text ? this.text.nativeElement.value.split(/\s+/) : 0;
    this.words = this.wordCount ? this.wordCount.length : 0;
  }
  //end 

  servicefrom: any = {
    service_name: null,
  };

  languagefrom: any = {
    language: null,
    langCode: null
  };

  categoriesfrom: any = {
    category_name: null,
  };

  artistProfileForm: any = {
    languageList: null,
    categoryList: null
  };


  @ViewChild('country')
  country!: ElementRef;
  name = 'Angular ' + VERSION.major;
  countries = Country.getAllCountries();
  selectedCountry: any;


  constructor(private userService: UserService, private router: Router, private route: ActivatedRoute,
    private tokenStorage: TokenStorageService, private toastr: ToastrService) {
    this.pages = {
      page: 0,
      limit: 1000,
    }
  }
  


  goToFirstPage() {
    this.page = 1; // Assuming 1-based indexing
}

goToLastPage() {
    // Calculate the last page based on the total number of items and items per page
    const totalPages = Math.ceil(this.searchVoiceList.length / 45); // Assuming 45 items per page
    this.page = totalPages;
}


  ngOnInit(): void {
    this.tokenStorage.getToken();
    this.roles = this.tokenStorage.getUser().roles;
    this.loader = false;
    this.route.paramMap.subscribe(params => {
      this.servicefrom.service_name = params.get('queryParams');
      this.languagefrom.language = params.get('language');
      this.categoriesfrom.category_name = params.get('category');
      this.gender = params.get('gender');

    });
    this.getServiceList();
    this.getCategoryList();
    this.getLanguageList();
    this.getSearchVoiceList();
    this.loadScript();
  }

  @ViewChildren('audio') audioElms!: ElementRef[];

  ngAfterViewInit() {
  }

  onPaly(elm: HTMLAudioElement) {
    this.audioElms.forEach(({ nativeElement: e }) => {
      if (e !== elm) {
        e.pause();
      }
    })
  }


  isFirstPage(): boolean {
    return this.page != 1;
  }

  isLastPage(): boolean {
    if (this.searchVoiceList && this.searchVoiceList.length) {
        const totalPages = Math.ceil(this.searchVoiceList.length / 45);
        return this.page === totalPages;
    }
    return false; // Return false if searchVoiceList is null or empty
}


  onPageChange(event: number): void {
    this.page = event;
  }

  getServiceList() {
    this.loader = true;
    this.userService.getServiceList().subscribe(data => {
      this.serviceList = data;
      if (this.serviceList !== null && this.serviceList !== "") {
        this.loader = false;
        this.serviceList.forEach((element: { service_name: string; }) => {
        });
      }
    },
      err => {
        console.log(err);
      }
    );
  }

  getLanguageList() {
    this.loader = true;
    this.userService.getLanguageList().subscribe(data => {
      this.languageList = data;
      if (this.languageList !== null && this.languageList !== "") {
        this.loader = false;
        this.languageList.forEach((element: { langCode: string; language: string; }) => {
        });
      }
    },
      err => {
        console.log(err);
      }
    );
  }

  getCategoryList() {
    this.loader = true;
    this.userService.getCategoryList().subscribe(data => {
      this.categoryList = data;
      if (this.categoryList !== null && this.categoryList !== "") {
        this.loader = false;
        this.categoryList.forEach((element: { category_name: string; }) => {
        });
      }
    },
      err => {
        console.log(err);
      }
    );
  }
  
  getSearchVoiceList() {
    this.userService.getSearchVoiceList(this.pages, this.categoriesfrom.category_name, this.servicefrom.service_name, this.languagefrom.language, this.gender).subscribe(data => {
      this.searchVoiceList = data.items;
      // set page value 1 start from 15/04/2024 by govind gurjar
      this.page = 0;
      if (this.searchVoiceList !== null && this.searchVoiceList !== "" && this.searchVoiceList !== "undefined" && this.searchVoiceList.status === true) {
        this.searchVoiceList.forEach((element: {
          userId: string; sampleVoice: string; name: string; language: string;
          role: string; style: string; accent: string; category: string;
          category_name: string; role_name: string; style_name: string;
        }) => {
        });
       }
    },
      err => {
        console.log(err);
      }
    );
  }

  checkUserIsLoggedIn(userId: any) {
    if (this.roles !== null && this.roles !== "" && this.roles !== undefined) {
      this.getprofileDetails(userId);
    } else {
      this.toastr.error('Please sign in/Register as a customer !!', '')
    }
  }

  //fetching Edit Voice Details 
  getprofileDetails(userId: any) {
    this.userService.getprofileDetails(userId).subscribe(data => {
      if (data !== null && data !== "") {
        this.router.navigate(['/searchArtist', { queryParams: userId }]);
      }
    },
      err => {
        console.log(err);
      }
    );
  }

  //Get Quote for Artist
  getQuote() {
    if (this.roles !== null && this.categoriesfrom.category_name !== null && this.languagefrom.language !== null && this.wordCount !== undefined && this.gender !== "undefined" && this.gender !== null) {
      this.userService.getQuote(this.categoriesfrom.category_name, this.languagefrom.language, this.wordCount.length, this.userId, this.gender).subscribe(data => {
        this.searchVoiceList = data;
        if (this.searchVoiceList !== null && this.searchVoiceList !== "") {
          this.searchVoiceList.forEach((element: {
            finalPrice: String;
          }) => {
          });
        }
      },
        err => {
          console.log(err);
        });
    } else {
      this.toastr.error('Please fill all the mendatory fields marked with *', '')
    }
  }

  contact() {
    window.scroll(0, 0);
    this.router.navigate(['/contact']);
  }

  public loadScript() {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = '/assets/js/dropdown.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

}

