import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../_services/user.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-business-deatils',
  templateUrl: './business-deatils.component.html',
  styleUrls: ['./business-deatils.component.css'],
})
export class BusinessDeatilsComponent implements OnInit {
  constructor(
    private toastr: ToastrService,
    private userService: UserService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {}

  addBusinessDetails: any = {
    companyName: null,
    companyAddress: null,
    gstNumber: null,
  };

  updateBusinessDeatils() {
    if (
      this.addBusinessDetails.companyName !== null ||
      this.addBusinessDetails.companyAddress !== null ||
      this.addBusinessDetails.gstNumber !== null
    ) {
      const Data = {
        company: this.addBusinessDetails.companyName,
        gst: this.addBusinessDetails.gstNumber,
        companyaddress: this.addBusinessDetails.companyAddress,
      };
     this.userService.updateBusinessDeatils(Data).subscribe((response)=>{
       if(response !== null || response !== undefined){
        this.addBusinessDetails.companyName = '';
        this.addBusinessDetails.companyAddress = '';
        this.addBusinessDetails.gstNumber = '';
        this.toastr.success("Business Deatils Update Successfully !",'');        
       }
     },
     (error:HttpErrorResponse)=>{
      console.log("Error While Update Business Deatils",error);
      this.toastr.error(error.error.message,'');
     }
     )

    }else{
      this.toastr.error("Please fill any one field");
    }
  }
}
