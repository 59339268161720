<!-- Banner -->
<app-loader *ngIf="loader"></app-loader>

<div class="dashboardbanner">
    <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
    <div class="bannerheading">Hire Artist</div>
</div>
<div class="fullwidth bg_purple">
    <!--bof: myrecording-->
    <div class="w1200">

        <div class="wedget">
            <!-- bof:wedgetleft -->
            <app-sidebar></app-sidebar>
            <!-- bof:wedgetright -->
            <div class="wedgetright">
                <div class="wedgetform pay_next_form">
                    <!--bof:formsection-->
                    <div class="form1 payment_form pay_next">
                        <form class="formpanel" (ngSubmit)="f.form.valid && getFinalPrice()" #f="ngForm" novalidate>

                            <div class="formwrap">
                                <select name="languageName" #language="ngModel" [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="languageName" required>
                                    <option value="undefined" disabled selected>-- Select Language --</option>
                                    <option *ngFor="let items of languageList" [label]="items" [value]="items">{{items}}
                                    </option>
                                </select>
                                <div class="alert-danger" *ngIf="language.errors && f.submitted">
                                    <div *ngIf="language.errors.required">Select your language</div>
                                </div>
                            </div>

                            <div class="formwrap">
                                <select name="categoryName" #category="ngModel" [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="categoryName" required>
                                    <option value="undefined" disabled selected>-- Select Categories --</option>
                                    <option *ngFor="let items of categoryList" [label]="items" [value]="items">{{items}}
                                    </option>
                                </select>
                                <div class="alert-danger" *ngIf="category.errors && f.submitted">
                                    <div *ngIf="category.errors.required">Select your category</div>
                                </div>
                            </div>

                            <div class="formwrapfull">
                                <textarea placeholder="Enter your script here..."
                                    [attr.disabled]="words >5000 ? '' : null" (keyup)="wordCounter()"  (input)="wordCounter()"   #text
                                    id="wmd-input" name="post-text"
                                    class="wmd-input s-input bar0 js-post-body-field processed" data-post-type-id="2"
                                    cols="92" rows="50" tabindex="101" data-min-length=""     #scriptData="ngModel"  [(ngModel)]="script" required>
                                </textarea>
                                <div class="alert-danger" *ngIf="scriptData.errors && f.submitted">
                                    <div *ngIf="scriptData.errors.required">Script is require</div>
                                </div>
                            </div>

                            <div class="formwrap">
                                <p> <b>Word Count:</b> <span id="wordCount">{{ words }}</span></p>
                            </div>
                            <div class="formwrap">
                                <div class="searchdiv">
                                    <input type="submit" value="Get Quote">
                                </div>
                            </div>
                            <div class="formwrapfull">
                                <textarea
                                    placeholder="Any kind of instruction that you want to share with the artist write here. Do not insert any personal information or links.."
                                    [(ngModel)]="instruction" [ngModelOptions]="{standalone: true}"></textarea>
                            </div>
                        </form>
                    </div>

                    <div class="form2" *ngIf="paymentPage">
                        <div class="formwrap">
                            <p>Price</p>
                        </div>

                        <div class="price_cont">
                            <div class="price_box_row">
                                <div class="price_col">
                                    <p class="price">Base Price: <span>Rs.{{finalPrice}}</span></p>
                                </div>
                                <div class="price_col">
                                    <p class="price">Taxes: <span>Rs.{{customerPriceWithTax}}</span></p>
                                </div>
                                <!-- <div class="price_full_col">
                                    <p class="price">Total Price: <span>Rs.5188</span></p>
                                </div> -->
                            </div>
                        </div>

                        <div class="total_amt">
                            <p class="totaltxt">Total Amount: <span>Rs. {{customerFinalPrice}}</span></p>
                        </div>

                        <!-- <div class="wallet_check">
                            <input type="checkbox" id="wallet_bal" name="neft" value="Redeem via NEFT"><label
                                for="wallet_bal">Wallet <span>(Available Balance: Rs. 992)</span></label>
                        </div> -->
                        <div class="pay_btn_box">
                            <div class="pay_btn_row">
                                <div class="pay_btn_col">
                                    <a class="pay_btn" (click)="getPayablePriceForCustomer()">Pay Now</a>
                                </div>
                                <div class="pay_btn_col">
                                    <p>Secured Transactions</p>
                                </div>
                            </div>
                        </div>
                        <p class="pay_msg">Your payment is held securely and its released to the voice actor only once
                            you mark the project complete satisfactorly.</p>
                    </div>
                    <!--eof:formsection-->
                </div>
            </div>
        </div>
        <!--eof: myrecording-->
    </div>
</div>