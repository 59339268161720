<!-- bof:wedgetleft -->
<div class="wedgetleft">
  <div class="profsection">
    <div class="imgrow">
      <div class="img_box"><img class="img" src="https://api.pinnaklebiz.com/thevoicemall/profile/{{profilePic}}" alt="profile" /> </div>
      <i class="fa fa-camera upload-button" (click)="popup = true" src="https://api.pinnaklebiz.com/thevoicemall/profile/add-image.png" alt="profile"></i>
      <ul>
        <li>VM{{this.id}}</li>
      </ul>
    </div>

    <div class="overlay" *ngIf="popup">
      <div class="content">
        <!--bof: ProfilePopup-->
        <div class="viewprofilesection">
          <div class="viewprofiles">
            <span class="material-icons-outlined revisionclose" (click)="popup = false">close</span>
            <h2 class="text-center">Upload your profile pic</h2><br>
            <h4 class="text-center" style="margin-top: 10px;">Note: Image size should be upto 1 mb</h4><br>
            <h4 class="text-center">File format should be .jpeg or .png</h4><br>
            <div class="Insideview">
              <input type="file" class="profilepic" (change)="onImageUpload($event)" placeholder="Select image" accept="image/*">
              <input type="button" (click)="imageUploadAction()" value="Upload Image" class="image-upload">
              <output *ngIf=successResponse class="success"><b>{{successResponse}}</b></output>
            </div>
          </div>
        </div>
        <!--eof: ProfilePopup-->
      </div>
    </div>

    <ul>
      <li><a><i class="fa fa-envelope" aria-hidden="true"><span class="align">{{currentUser.email}}</span></i></a></li>
      <li><a><i class="fa fa-phone" aria-hidden="true"></i><span  class="align"> +91{{this.contact}}</span></a></li>
    </ul>
  </div>
  
  <ul class="tabs" *ngIf="this.roles == 'ROLE_ARTIST'">
    <li [ngClass]="{'selected': selectedTab === '/editprofile'}"><a (click)="editProfile()"><i class="artistperson"></i> <span>My Profile</span></a></li>
    <li [ngClass]="{'selected': selectedTab === '/all-sample'}"><a (click)="allSample()"><i class="acountset"></i> <span>My Uploaded Sample</span></a></li>
    <li [ngClass]="{'selected': selectedTab === '/uploadsamples'}"><a (click)="uploadSample()"><i class="transhistroy"></i><span>Upload Sample</span></a></li>
    <li [ngClass]="{'selected': selectedTab === '/artistdashboard'}"><a (click)="artistDashboard()"><i class="wallet"></i> <span>My Project</span></a></li>
    <li [ngClass]="{'selected': selectedTab === '/artist-bank-details'}"><a (click)="paymentDashboard()"><i class="bank_icon"></i> <span>Bank Details</span></a></li>
    <li [ngClass]="{'selected': selectedTab === '/fetch-Project-Payment-Details'}"><a (click)="paymentHistory()"><i class="payment_icon"></i> <span>Payment History</span></a></li>

    <!-- <li><a href="#tab4"><i class="acountset"></i><span> Account Settings</span></a></li>
         <li><a href="#tab6"><i class="notification"></i><span> Notifications</span></a></li>  -->
  </ul>

  <ul *ngIf="this.roles == 'ROLE_CUSTOMER'">
    <li [ngClass]="{'selected': selectedTab === '/customerdashboard'}"><a (click)="customerDashboard()"><i class="artistrecord"></i> <span>Artists &amp;
          Recordings</span> </a></li>
    <li [ngClass]="{'selected': selectedTab === '/transtion-history'}" ><a (click)="transactionHistory()"><i class="transhistroy"></i><span>Transaction History</span></a></li>
    <li [ngClass]="{'selected': selectedTab === '/business-deatils'}" ><a (click)="businessDeatils()"><i class="business"></i><span>Business Deatils</span></a></li>
    <!-- <li><a href="wallet.html"><i class="wallet"></i> <span>Wallet</span></a></li> -->
    <!-- <li><a href="#"><i class="acountset"></i><span> Account Settings</span></a></li>
    <li><a href="#"><i class="notification"></i><span> Notifications</span></a></li> -->
  </ul>

</div>