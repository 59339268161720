<app-loader *ngIf="loader"></app-loader>

<div id="maincontainer">
  <!-- Banner -->
  <div class="dashboardbanner">
    <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
    <div class="bannerheading">Voice Samples</div>
  </div>

  <div class="fullwidth bg_purple">
    <!--bof: myrecording-->
    <div class="w1200">
      <div class="dashboardwedget">
        <app-admin-sidebar></app-admin-sidebar>
        <!-- bof:wedgetright -->
        <div class="wedgetright">
            <h3>Voice Samples</h3>
          <div class="dashwed">
            <ul class="homelink">
                <li><a class="active" routerLink="/all-voice-samples"><i class="fa fa-home fa-2x dashboardicon" aria-hidden="true"></i></a></li>
                <li><a routerLink="/all-voice-samples">Voice Samples</a></li>
            </ul>
          </div>
          
          <div class="artistboard">
            <div class="searchrow">
              <div class="pagesdroplist">
                <span>Total Voice Sample: {{ this.TotVoiceSample }}</span>
              </div>
              <div class="searchinput"><input type="text" id="myInput" [(ngModel)]="searchParam" placeholder="Search">
              </div>
            </div>

            <!--boftablestructure  -->
            <div class="atrtable">
              <div class="row">
                <div class="col"><span class="first1">Artist Id</span></div>
                <div class="col"><span class="first2">Artist Name</span></div>
                <div class="col"><span class="first2">Sample Title</span></div>
                <div class="col"><span class="first3">Language</span></div>
                <div class="col"><span class="first4">Category</span></div>
                <div class="col"><span class="first5">Role</span></div>
                <div class="col"><span class="first3">Service</span></div>
                <div class="col"><span class="first3">Accent</span></div>
                <div class="col"><span class="first3">Gender</span></div>
                <div class="col"><span class="first3">Uploaded Date </span></div>
                <div class="col"><span class="three9" style="width: 330px !important;">Uploaded Sample</span></div>
                <div class="col"><span class="first3">Sample Status</span></div>
                <div class="col"><span class="three9" style="width: 196px !important;">Operation </span></div>
              </div>
              <ng-container>
                <div id="myList"
                  *ngFor="let data of allArtistDetailsfiltered | paginate: { itemsPerPage: 10, currentPage: page }">
                  <div class="row">
                    <div class="col"><span class="first1"
                            style="white-space: pre-wrap;">VM{{data.userId}}</span></div>
                    <div class="col"><span class="first2"
                            style="white-space: pre-wrap;">{{data.username}}</span></div>
                    <div class="col"><span class="first2"
                            style="white-space: pre-wrap;">{{data.sampleVoice}}</span></div>
                    <div class="col"><span class="first3"
                            style="white-space: pre-wrap;">{{data.language}}</span></div>
                    <div class="col"><span class="first4"
                            style="white-space: pre-wrap;">{{data.category}}</span></div>
                    <div class="col"><span class="first5"
                            style="white-space: pre-wrap;">{{data.role}}</span></div>
                    <div class="col"><span class="first3"
                            style="white-space: pre-wrap;">{{data.service}}</span></div>
                    <div class="col"><span class="first3"
                            style="white-space: pre-wrap;">{{data.accent}}</span></div>
                    <div class="col"><span class="first3"
                            style="white-space: pre-wrap;">{{data.gender}}</span></div>
                    <div class="col"><span class="first3"
                            style="white-space: pre-wrap;">{{data.updatedDate | date}} </span></div>
                    <div class="col"><span class="three9" style="white-space: pre-wrap;width: 330px !important;"> <audio
                                controls #audio (play)="onPaly(audio)" class="audio">
                                <source
                                    src="https://api.pinnaklebiz.com/thevoicemall/sample/{{data.name}}">
                            </audio></span></div>
                            <div class="col" *ngIf="data.status == true">
                                <span class="first3" style="white-space: pre-wrap; color: green;">Approved</span>
                            </div>
                            <div class="col" *ngIf="data.status == false || data.status == null">
                                <span class="first3" style="white-space: pre-wrap; color: red;">Pending</span>
                            </div>      
                    <div class="col"><span class="three9" style="white-space: pre-wrap;  width: 196px !important;">
                            <a class="activebtn" *ngIf="data.status === false || data.status === null"
                                (click)="approveSample(data)"><i>&#10004;</i> Accept</a>

                            <a class="rejectbtn" (click)="deleteSample(data)"  *ngIf="data.status === false || data.status === null "><i>&#10006;</i> Reject</a>
                            <a style="white-space: pre-wrap; color: green;" *ngIf="data.status === true">NA</a>
 
                            <!-- <div class="overlay" *ngIf="popup">
                            <div class="popup">
                                <a class="close" (click)="popup = false">&times;</a>
                                <div class="content">
                                     <b> Are you sure ? </b>
                                    <p class="text"> Do you really want to delete this sample? This process cannot be undone. </p>
                                </div>
                                <div class="modal-footer justify-content-center">
                                    <button type="button" class="button1" (click)="popup = false">Cancel</button>
                                    <button type="button" class="button2" (click)="deleteSample(data)">Delete</button>
                                </div>
                            </div>
                        </div> -->

                        </span>
                    </div>
                </div>
                </div>
              </ng-container>
            </div>


            <!-- <div class="paginations">
                            <pagination-controls (pageChange)="page = $event"></pagination-controls>
                        </div> -->
            <div class="paginations">
              <div class="custom-pagination-wrapper">
                <button class="pagination-button margincorrect" [ngClass]="{ 'active': isFirstPage() }" (click)="goToFirstPage()">First</button>                    
                <pagination-controls (pageChange)="onPageChange($event)" ></pagination-controls>                                                                        
                <button class="pagination-button "  [ngClass]="{ 'lastactive': isLastPage(), 'lastdeactive': !isLastPage() }" (click)="goToLastPage()">Last</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--eof: myrecording-->

  </div>
</div>
