import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

//const API_URL = 'http://localhost:8080/api/test/';
const API_URL = 'https://api.pinnaklebiz.com/the-voice-mall/api/test/';
const Country_list = "country";
const Service_list = "services";
const Language_list = "language";
const Category_list = "categories";
const EDIT_PROFILE = "editProfile";
const EDIT_VOICE_PROFILE = "editVoiceProfile";
const Service_Offered_list = "serviceOffered";
const Education_list = "education";
const Search_Voice_List = "search";
const Recording_Setup_list = "recordingSetup";
const Voice_Role_list = "voiceRole";
const Accent_list = "accent";
const Style_list = "style";
const Voice_Age_list = "ageRange";
const PriceDeliveryTime = "priceDeliveryTime";
const TIME_AVAILABILITY = "availability";
const EQUIPMENT_EXPERIENCE = "equipmentExperience";
const CONTACT = "contact";
const SUBSCRIBE = "subscribe";
const VOICE_SAMPLE = "voiceSample";
const EDIT_PROFILE_DETAILS = "editProfile/";
const EDIT_VOICE_PROFILE_DETAILS = "editVoiceProfile/";
const PRICE_TIME_DETAILS = "priceDeliveryTime/";
const Availability_DETAILS = "availability/";
const EQUIPMENT_DETAILS = "equipmentExperience/";
const VOICE_DETAILS = "voiceSample/";
const ARTIST_PROFILE_DETAILS = "searchVoice/";
const GETQuOTE = "getQuote";
const GETFINALPRICE = "getFinalPrice";
const GETPAYABLEPRICEFORCUSTOMER = "getOrderDetailsOnReferenceId";
const GETCUSTOMERBOOKINGDETAILS = "getOrderDetails";
const GET_USER_DETAILS = "getUserDetails";
const GET_ARTIST_DETAILS = "getArtistDetails";
const DELETE_SAMPLE = "deleteSample/";
const REJECT_SAMPLE = "reject-revised-samples/";
const IMAGE_UPLOAD = "upload/image/";
const GET_PROJECT_PAYMENT_DETAILS_FOR_ARTIST = "fetch-Project-Payment-Details";
const ALL_PROJECT_PAYMENT_DETAILS_FOR_ARTIST = "all-Project-Payment-Details";
const GET_REVIEW_AND_RATING = "fetch-review-rating/";
const SEARCHVOICEALL = "searchVoiceAll/";
const GET_ALL_ROLES = "getRoles";
const GETROLEBYID = "getRoles/";
const DELETE_ROLE_BY_ID = 'deleterole';
const CREATROLE = 'addRole';
const GET_ALL_PERMISSION = 'getPermission';
const EDIT_ROLE = 'editRole';
const GETALLUSERS = 'getUsers';
const ADDUSER = 'addUser';
const GETUSERBYID = 'getUser/'
const EDIT_USER = 'editUser';
const userStatus = "apprRejectUser";
const GET_NOTIFICATION = 'getNotification';
const UPDATE_NOTIFICATION_VIEW = 'updateNotification/'

// below api are not currented and not made by jeetendra sir so this is testing name
 const UPDATE_CHAT_STATUS = 'updateChat';
 const GET_ALL_CHATS = 'getConvers';
 const GET_CHAT_BY_PROJECT_ID = 'getConvers/';
 const SENT_BULK_EMAIL = 'senbulkEmail';
 const SAVE_CHAT = 'addChat';
 const GET_REVENUE_REPORT = 'revenue-report';
 const GET_REVENUE_REPORTByDATE = 'revenue-report?';
 const UpdateBusiness = 'update_business';

 

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class UserService {
  id: any;
  status: any;
  errorMessage: any;
  constructor(private http: HttpClient) { }

  getUserBoard(): Observable<any> {
    return this.http.get(API_URL + 'user', { responseType: 'text' });
  }

  getCountryList(): Observable<any> {
    return this.http.get(API_URL + Country_list);
  }

  getServiceList(): Observable<any> {
    return this.http.get(API_URL + Service_list);
  }

  getLanguageList(): Observable<any> {
    return this.http.get(API_URL + Language_list);
  }

  getCategoryList(): Observable<any> {
    return this.http.get(API_URL + Category_list);
  }

  getRecordingSetupList(): Observable<any> {
     return this.http.get(API_URL + Recording_Setup_list);
  }

  getVoiceAgeList(): Observable<any> {
    return this.http.get(API_URL + Voice_Age_list);
  }

  getAccentList(): Observable<any> {
    return this.http.get(API_URL + Accent_list);
  }

  getVoiceRoleList(): Observable<any> {
    return this.http.get(API_URL + Voice_Role_list);
  }

  getStyleList(): Observable<any> {
    return this.http.get(API_URL + Style_list);
  }

  getServiceOfferedList(): Observable<any> {
    return this.http.get(API_URL + Service_Offered_list);
  }

  getEducationList(): Observable<any> {
    return this.http.get(API_URL + Education_list);
  }





  //GET Search Voice Details..
  getSearchVoiceList(pages: any, category_name: any, service_name: any, language: any, gender: any): Observable<any> {
    const option = {
      params: new HttpParams().set('page', pages.page).set('limit', pages.limit)
    }
    if (category_name) {
      option.params = option.params.append('category', category_name);
    }
    if (service_name) {
      option.params = option.params.append('service', service_name);
    }
    if (language) {
      option.params = option.params.append('language', language);
    }
    if (gender) {
      option.params = option.params.append('gender', gender);
    }
    return this.http.get(API_URL + Search_Voice_List, option);
  }

  //GET Search Voice Details for home Page
  getArtistVoiceList(pages: any): Observable<any> {
    const option = {
      params: new HttpParams().set('page', pages.page).set('limit', pages.limit)
    }
    return this.http.get(API_URL + Search_Voice_List, option);
  }


  editProfile(editProfileForm: FormData): Observable<any> {
    return this.http.post(API_URL + EDIT_PROFILE, editProfileForm);
  }

  editVoiceProfile(language: string[] = [], category: string[] = [], recording_Setup: string[] = [], voice_age: string[] = [],
    accents: string[] = [], role: string[] = [], style: string[] = [], service: string, userId: string): Observable<any> {   
    return this.http.post(API_URL + EDIT_VOICE_PROFILE, {
      
      language, category, recording_Setup, voice_age, accents, role, style, service, userId
    }, httpOptions);
  }

  priceDeliveryTime(fields: any, userId: string): Observable<any> {
    return this.http.post(API_URL + PriceDeliveryTime, {
      fields, userId
    }, httpOptions);
  }



  
  imageUpload(imageFormData: any, userId: string): Observable<any> {
    return this.http.post(API_URL + IMAGE_UPLOAD, {
      imageFormData, userId
    }, httpOptions);
  }

  availability(daySunday: boolean, dayMonday: boolean, dayTuesday: boolean, dayWednesday: boolean, dayThursday: boolean, dayFriday: boolean, daySaturday: boolean,
    userId: string): Observable<any> {
    return this.http.post(API_URL + TIME_AVAILABILITY, {
      daySunday, dayMonday, dayTuesday, dayWednesday, dayThursday, dayFriday, daySaturday, userId
    }, httpOptions);
  }

  equipmentExperience(microphoneUsed: string, recordingEditing: string, socialEquipment: string, experience: string,
    serviceOffered: string[] = [], clientWorkedFor: string, education: string, socialMediaLinkFB: string, socialMediaLinkTwitter: string,
    socialMediaLinkLinked: string, userId: string): Observable<any> {
    return this.http.post(API_URL + EQUIPMENT_EXPERIENCE, {
      microphoneUsed, recordingEditing, socialEquipment, experience, serviceOffered, clientWorkedFor, education, socialMediaLinkFB, socialMediaLinkTwitter,
      socialMediaLinkLinked, userId
    }, httpOptions);
  }

  contact(first_name: string, last_name: string, email: string, contact: string, message: string): Observable<any> {
    return this.http.post(API_URL + CONTACT, {
      first_name, last_name, email, contact, message
    }, httpOptions);
  }

  subscribeUs(email: string): Observable<any> {
    return this.http.post(API_URL + SUBSCRIBE, {
      email
    }, httpOptions);
  }

  voiceSampleUpload(voiceSampleFromData: FormData): Observable<any> {
    return this.http.post(API_URL + VOICE_SAMPLE, voiceSampleFromData);
  }

  //getting details for Artist Edit Profile

  getEditProfile(userId: string): Observable<any> {
    return this.http.get(API_URL + EDIT_PROFILE_DETAILS + userId);
  }

  getEditVoiceProfile(userId: string): Observable<any> {
    return this.http.get(API_URL + EDIT_VOICE_PROFILE_DETAILS + userId);
  }

  getpriceDeliveryTime(userId: string): Observable<any> {
    return this.http.get(API_URL + PRICE_TIME_DETAILS + userId);
  }

  getAvailability(userId: string): Observable<any> {
    return this.http.get(API_URL + Availability_DETAILS + userId);
  }

  getEquipmentExperience(userId: string): Observable<any> {
    return this.http.get(API_URL + EQUIPMENT_DETAILS + userId);
  }

  getvoiceUploadDetails(userId: string): Observable<any> {
    return this.http.get(API_URL + VOICE_DETAILS + userId);
  }
  getprofileDetails(userId: string): Observable<any> {
    return this.http.get(API_URL + ARTIST_PROFILE_DETAILS + userId);
  }


  getprofileDetailsById(userId: string): Observable<any> {
    return this.http.get(API_URL + SEARCHVOICEALL + userId);
  }


  
  getQuote(categoryName: string, language: string, wordCount: string, userId: string, gender: any): Observable<any> {
    return this.http.post(API_URL + GETQuOTE, {
      categoryName,
      language,
      wordCount,
      userId,
      gender
    }, httpOptions);
  }

  getFinalPrice(categoryName: string, language: string, wordCount: string, userId: string, customerId: string, script: string, instruction: string): Observable<any> {
    return this.http.post(API_URL + GETFINALPRICE, {
      categoryName,
      language,
      wordCount,
      userId,
      customerId,
      script,
      instruction
    }, httpOptions);
  }

  getPayablePriceForCustomer(referenceId: string): Observable<any> {
    return this.http.get(API_URL + GETPAYABLEPRICEFORCUSTOMER, {
      params: {
        referenceId: referenceId,
      },
    });
  }

  getCustomerBookingDetailsById(customerId: string): Observable<any> {
    return this.http.get(API_URL + GETCUSTOMERBOOKINGDETAILS, {
      params: {
        customerId: customerId,
      },
    });
  }

  getCustomerBookingDetailsOnReferenceId(referenceId: string) {
    return this.http.get(API_URL + GETPAYABLEPRICEFORCUSTOMER, {
      params: {
        referenceId: referenceId,
      },
    });
  }

  fetchProjectPaymentDetails(artistId: string) {
    return this.http.get(API_URL + GET_PROJECT_PAYMENT_DETAILS_FOR_ARTIST, {
      params: {
        artistId: artistId,
      },
    });
  }

  allProjectPaymentDetails() {
    return this.http.get(API_URL + ALL_PROJECT_PAYMENT_DETAILS_FOR_ARTIST, {
    });
  }

  getallUserDetails() {
    return this.http.get(API_URL + GET_USER_DETAILS, {
    });
  }

  getallArtistDetails() {
    return this.http.get(API_URL + GET_ARTIST_DETAILS, {
    });
  }

  remove(id: any) {
   return  this.http.delete(API_URL + DELETE_SAMPLE + id)     
  }

  rejectSample(id: any) {
    this.http.get(API_URL + REJECT_SAMPLE + id)
      .subscribe({
        next: data => {
          this.status = 'Reject successful';
        },
        error: error => {
          this.errorMessage = error.message;
          console.error('There was an error!', error);
        }
      });
  }

  getReviewAndRating(artistId: any) {
    return this.http.get(API_URL + GET_REVIEW_AND_RATING + artistId, {
    });
  }


  // ------------------------------ user & role with notification functions -------------------

  // New Api 
  //  date : 25/04/2024 
  // We are add apis for roles & permissions , user ect. 
//   By Govind 


// get all roles for show role list 
getAllRolesList(){
  return this.http.get(API_URL + GET_ALL_ROLES);
}

// get  roles for edit by role id 
GetRoleByID(id:any){
  return this.http.get(API_URL + GETROLEBYID + id);
}

// create new role
CreateRole(data:any){
  return this.http.post(API_URL + CREATROLE , data);
}

// delete role 
deleteRoleById(id:number){
  return this.http.delete(API_URL + DELETE_ROLE_BY_ID);
}

// Get All Permission
GetAllPermissions(){
  return this.http.get(API_URL+GET_ALL_PERMISSION);
}

// Edit Role Data
editRole(data:any){
  return this.http.put(API_URL + EDIT_ROLE , data);
}

// Get All Admin Users 
getAllUsers(){
  return this.http.get(API_URL + GETALLUSERS);
}

// Add new users 
AddNewUser(data:any){
  return this.http.post(API_URL + ADDUSER ,data );
}

// get User By User Id
GetUserByID(id:number){
  return this.http.get(API_URL + GETUSERBYID + id);
}

// edit users by user id 
EditUSER(data:any){
  return this.http.put(API_URL + EDIT_USER, data);
}

// Approve or reject customer or artitst detiels 
changeUserStatus(data:any){
  return  this.http.get(API_URL + userStatus+  data);
}


// get New  records like new customer , artist and other 
newNotifications(){
return this.http.get(API_URL+GET_NOTIFICATION);
}

// update view notification status
updateNotificationView(menu:any){
  return this.http.get(API_URL + UPDATE_NOTIFICATION_VIEW + menu);

}


// ------------------------------------- chat function -------------------------------------


// approvbe or reject usesr chat message
updateChatStatus(data:any){
  return this.http.put(API_URL+UPDATE_CHAT_STATUS,data);
}

// Get All Chats
GetAllChats(){
  return this.http.get(API_URL + GET_ALL_CHATS );
}

// get convertion by project 
GetAllChatsByProjectId(pId:any){
  return this.http.get(API_URL + GET_CHAT_BY_PROJECT_ID +pId);
}

// insert Chat data
SaveChat(data:any){
  return this.http.post(API_URL+SAVE_CHAT,data);
}




// Sent Bulk Email 
bulkEmail(data:any){
return this.http.post(API_URL+SENT_BULK_EMAIL ,data);
}






// -------------------------------------- Reports --------------------
 GetAllRevenueData(){
  return this.http.get(API_URL+GET_REVENUE_REPORT)
 }

 GetFiltredRevenuereport(dates:any){
  return this.http.get(API_URL +GET_REVENUE_REPORTByDATE + dates);
 }


 updateBusinessDeatils(data:any){
  return this.http.post(API_URL + UpdateBusiness,data);
 }

}
