import { Component, OnInit } from '@angular/core';
import { UserService } from '../_services/user.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Ng2SearchPipe } from 'ng2-search-filter';
// import {provideNativeDateAdapter} from '@angular/material/core';
import {
  NativeDateAdapter,
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-revenue-report',
  templateUrl: './revenue-report.component.html',
  styleUrls: ['./revenue-report.component.css'],
  providers: [
    { provide: DateAdapter, useClass: NativeDateAdapter },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    DatePipe,
  ],
})
export class RevenueReportComponent implements OnInit {
  AllRevenueReport: any;
  AllFiltredRevenuereport: any;
  set searchParam(value: any) {
    if (value !== null && value !== '') {
      this.AllFiltredRevenuereport = Ng2SearchPipe.prototype.transform(
        this.AllRevenueReport,
        value
      );
    } else {
      this.AllFiltredRevenuereport = this.AllRevenueReport;
      this.page = 1;
      this.Totalrecord = this.AllFiltredRevenuereport.length;
    }
  }
  page: any = 1;
  Totalrecord: any;

  constructor(
    private http: HttpClient,
    private userSerivces: UserService,
    private toastr: ToastrService,
    private datepip: DatePipe
  ) {}

  ngOnInit(): void {
    this.getAllRevenueReport();
    // this.getAllRevenueDataByDate();
  }

  isFirstPage(): boolean {
    return this.page != 1;
  }

  isLastPage(): any {
    if (this.page == Math.ceil(this.AllFiltredRevenuereport.length / 10)) {
      return false;
    } else {
      return true;
    }
  }

  onPageChange(event: number): void {
    this.page = event;
  }

  pageChanged(event: any): void {
    this.page = event;
    // Perform any actions with the new page number here
  }

  goToFirstPage() {
    this.page = 1; // Assuming 1-based indexing
  }

  goToLastPage() {
    // Calculate the last page based on the total number of items and items per page
    const totalPages = Math.ceil(this.AllFiltredRevenuereport.length / 10); // Assuming 45 items per page
    this.page = totalPages;
  }

  getAllRevenueReport() {
    try {
      this.userSerivces.GetAllRevenueData().subscribe(
        (response) => {
          if (response !== null && response !== '') {
            this.AllRevenueReport = response;
            this.AllFiltredRevenuereport = response;
            this.Totalrecord = this.AllRevenueReport.length;
          }
        },
        (error: HttpErrorResponse) => {
          if (error.status == 400 || error.status !== null) {
            console.log('Error in gettting revenue report ', error);
            this.toastr.error(error.error.message, '');
          }
        }
      );
    } catch (error) {
      console.log('Error in get All revenue  report data , error');
    }
  }

  SelectDate: any = {
    StartDate: null,
    EndDate: null,
  };

  onEndDateSelected(event:any): void {
      this.getAllRevenueDataByDate();
  }

  getAllRevenueDataByDate() {
    try {
      if (
        this.SelectDate.StartDate !== null &&       
        this.SelectDate.EndDate !== null  
      ) {
        let Stardate1 = new Date(this.SelectDate.StartDate);
        let Enddate1 = new Date(this.SelectDate.EndDate);
        this.SelectDate.StartDate = this.datepip.transform(Stardate1,'yyyy-MM-dd');
        this.SelectDate.EndDate = this.datepip.transform(Enddate1, 'yyyy-MM-dd');         
        const date_url =
          'start_date=' +
          this.SelectDate.StartDate +
          '&' +
          'end_date=' +
          this.SelectDate.EndDate;
          this.userSerivces.GetFiltredRevenuereport(date_url).subscribe(
          (response) => {
            if (response !== null || response !== '') {
              this.AllFiltredRevenuereport = response;
              this.Totalrecord = this.AllFiltredRevenuereport.length;
             }
          },
          (error: HttpErrorResponse) => {
            if (error.status == 400 || error.status !== null) {
              console.log(
                'Error in getting revenue date by date range ',
                error
              );
              this.toastr.error(error.error.message, '');
            }
          }
        );
      } else {
        // this.toastr.error('Please Select date range');
      }
    } catch (error) {
      console.log('Error In get revenue data by date range', error);
    }
  }
}
