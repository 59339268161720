import { Component, ElementRef, ViewChildren, OnInit, VERSION, ViewChild } from '@angular/core';
import { category, language, service } from '../modals/countries.modal';
 import { UserService } from '../_services/user.service';
import { Country } from 'country-state-city';
import { Router } from '@angular/router';
import { TokenStorageService } from '../_services/token-storage.service';
import { ToastrService } from 'ngx-toastr';

  
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

   // Options for the Owl Carousel
  //  customOptions: OwlOptions = {
  //   loop: true,
  //   autoplay: true,
  //   autoplayTimeout: 1000, // Adjust as needed
  //   autoplayHoverPause: true,
  //   items: 3,
  //   nav: false,
  //   dots: false,
  //   responsive: {
  //     0: {
  //       items: 1
  //     },
  //     600: {
  //       items: 3
  //     }
  //   }
  // };

  content?: string;
  serviceList: any;
  languageList: any;
  categoryList: any;
  service!: service;
  language!: language;
  category!: category;
  gender: any;


  servicefrom: any = {
    service_name: null,
  };


  languagefrom: any = {
    language: null,
    langCode: null
  };

  categoriesfrom: any = {
    category_name: null,
  };

  pages: { page: number; limit: number; };
  loader: boolean = true;
  searchVoiceList: any;

  @ViewChild('country')
  country!: ElementRef;
  name = 'Angular ' + VERSION.major;
  countries = Country.getAllCountries();
  selectedCountry: any;
  roles: any;

  constructor(private userService: UserService, private router: Router, private tokenStorage: TokenStorageService, private toastr: ToastrService) {
    this.pages = {
      page: 0,
      limit: 1000,
    }
  }

  ngOnInit(): void {



    
    this.loadScript('/assets/js/multiselect-dropdown.js');
    this.tokenStorage.getToken();
    this.roles = this.tokenStorage.getUser().roles;
    this.loader = false;
    this.getServiceList();
    this.getCategoryList();
    this.getLanguageList();
    this.getArtistVoiceList();
  }

  @ViewChildren('audio') audioElms!: ElementRef[];

  // ngAfterViewInit() {
  //   console.log(this.audioElms)
  // }

  onPaly(elm: HTMLAudioElement) {
    this.audioElms.forEach(({ nativeElement: e }) => {
      if (e !== elm) {
        e.pause();
      }
    })
  }

  getServiceList() {
    this.userService.getServiceList().subscribe(data => {
      this.serviceList = data;
      if (this.serviceList !== null && this.serviceList !== "") {
        this.serviceList.forEach((element: { service_name: string; }) => {
          //this.service.service_name === element.service_name;
        });
      }
    },
      err => {
        console.log(err);
      }
    );
  }

  getLanguageList() {
    this.userService.getLanguageList().subscribe(data => {
      this.languageList = data;
      if (this.languageList !== null && this.languageList !== "") {
        this.languageList.forEach((element: { langCode: string; language: string; }) => {
          //this.language.langCode = element.langCode;
          //this.language.language = element.language;
        });
      }
    },
      err => {
        console.log(err);
      }
    );
  }

  getCategoryList() {
    this.userService.getCategoryList().subscribe(data => {
      this.categoryList = data;
      if (this.categoryList !== null && this.categoryList !== "") {
        this.categoryList.forEach((element: { category_name: string; }) => {
        });
      }
    },
      err => {
        console.log(err);
      }
    );
  }

  // onCountryChange($event: any): void {
  //   this.selectedCountry = JSON.parse(this.country.nativeElement.value);
  //   this.countryfrom.country = JSON.parse(this.country.nativeElement.value).name;  }

  search(service_name: any, language: any, category_name: any, gender: any) {
    if (service_name !== null || language !== null || category_name !== null || gender !== undefined) {
      this.router.navigate(['/search', { queryParams: service_name, language: language, category: category_name, gender }]);
    } else {
      this.toastr.error('Please select atleast one filter !!', '');
    }
  }

  //Get Artist List for booking
  getArtistVoiceList() {
    this.userService.getArtistVoiceList(this.pages).subscribe(data => {
      this.searchVoiceList = data.items;
      if (this.searchVoiceList !== null && this.searchVoiceList !== "") {
        this.searchVoiceList.forEach((element: {
          userId: string; sampleVoice: string; name: string; language: string;
          role: string; style: string; accent: string; category: string;
          category_name: string; role_name: string; style_name: string;
        }) => {
        });
      }
    },
      err => {
        console.log(err);
      }
    );
  }

  checkUserIsLoggedIn(userId: any) {
    if (this.roles !== null && this.roles !== "" && this.roles !== undefined) {
      this.getprofileDetails(userId);
    } else {
      this.toastr.error('Please Sign in for continue booking !!', '')
    }
  }

  //fetching Edit Voice Details 
  getprofileDetails(userId: any) {
    this.loader = true;
    this.userService.getprofileDetails(userId).subscribe(data => {
      if (data !== null && data !== "") {
        this.loader = false;
        window.scroll(0, 0);
        this.router.navigate(['/searchArtist', { queryParams: userId }]);
      }
    },
      err => {
        console.log(err);
      }
    );
  }

  public loadScript(url: any) {
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(node);
}


}
