import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from '../_services/token-storage.service';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-voice-sample',
  templateUrl: './voice-sample.component.html',
  styleUrls: ['./voice-sample.component.css']
})
export class VoiceSampleComponent implements OnInit {
  //Fetch User log in details
  currentUser: any;
  contact: any;
  id: any;
  userId: any;
  languageList: any;
  categoriesList: any;
  accentList: any;
  roleList: any;
  styleList: any;
  voiceList: any;
  content: any;
  image!: File;
  ifChecked: Boolean = false;
  languageError: any;
  languageHide!: boolean;
  categoryError: any;
  categoryHide!: boolean;
  sampleVoiceError: any;
  sampleVoiceHide!: boolean;
  loader: boolean = true;
  format: any;
  nameError: any;
  nameHide!: boolean;

  //Open Modal for terms & conditions
  @ViewChild("outsideElement", { static: true })
  outsideElement!: ElementRef;
  @ViewChild('modalView', { static: true })
  modalView$!: ElementRef;

  constructor(private userService: UserService, private toastr: ToastrService, private token: TokenStorageService, private router: Router) { }

  ngOnInit(): void {
    this.loader = false;
    this.currentUser = this.token.getUser();
    this.contact = this.token.getUser().contact;
    this.id = this.token.getUser().id;
    this.getLanguageList();
    this.getCategoryList();
    this.getAccentList();
    this.getVoiceRoleList();
    this.getStyleList();
    // this.getvoiceUploadDetails();
  }

  voiceSampleFrom: any = {
    sampleVoice: null,
    language: null,
    category_name: null,
    accent: null,
    role_name: null,
    style_name: null,
    name: null
  }

  public onImageUpload(event: any) {
    this.image = event.target.files[0];
     
    if(this.image.name !== '' && this.image.name !== null){
      this.nameHide = false;
      this.nameError = "";
    }  
    this.format = this.image.type;
  }

  voiceSampleUpload(): void {


    this.validate();
    // if (this.voiceSampleFrom.name === null || this.voiceSampleFrom.name === "" || this.voiceSampleFrom.name === undefined) {
    //   this.nameHide = true;
    //   this.nameError = "Sample is mandatory.";
    // } else {
    //   this.nameHide = false;
    //   this.nameError = "";

    // }

    if(this.image.name == '' && this.image.name == null){
      this.nameHide = true;
      this.nameError = "Sample is mandatory.";
    } else{
      this.nameHide = false;
    }
    
 
    if (this.voiceSampleFrom.sampleVoice !== null && this.voiceSampleFrom.language !== null && this.voiceSampleFrom.category_name !== null) {
      if (this.ifChecked !== true) {

        this.toastr.error('You have to be agree with term and condition.', '');
        this.loader = false;
      } else if (this.image.size > 10485760) {
        this.toastr.error('Sample size should be upto 10 MB', '');
        this.loader = false;
      } else if (this.format === "audio/mpeg" || this.format === "audio/wav") {        
        const fromData = new FormData();
        const voiceSampleFromData: any = {
          sampleVoice: this.voiceSampleFrom.sampleVoice,
          language: this.voiceSampleFrom.language,
          category_name: this.voiceSampleFrom.category_name,
          accent: this.voiceSampleFrom.accent,
          role_name: this.voiceSampleFrom.role_name,
          style_name: this.voiceSampleFrom.style_name,
          name: this.image.name,
          userId: this.id
        };
        this.loader = true;
        fromData.append("voiceSampleModal", JSON.stringify(voiceSampleFromData));
        fromData.append("file", this.image, this.image.name);
        this.userService.voiceSampleUpload(fromData).subscribe();
        setTimeout(() => {
          this.loader = false;
          this.toastr.success('Your profile is under approval. Please wait for 24-48 hours. To add more samples, go to upload samples.', '');
        }, 3000);
      }
      else {
        this.toastr.error('File format should be .mp3 or .wav', '');
        this.loader = false;
      }
    }
    else {
      this.toastr.error('*please fill all mandatory fields', '');
    }
  }

  reload() {
    window.location.reload();
  }

  getLanguageList() {
    this.loader = true;
    this.userService.getLanguageList().subscribe(data => {
      this.languageList = data;
      if (this.languageList !== null && this.languageList !== "") {
        this.loader = false;
        this.languageList.forEach((element: { language: string; }) => {
        });
      }
    },
      err => {
        console.log(err);
      }
    );
  }

  getCategoryList() {
    this.loader = true;
    this.userService.getCategoryList().subscribe(data => {
      this.categoriesList = data;
      if (this.categoriesList !== null && this.categoriesList !== "") {
        this.loader = false;
        this.categoriesList.forEach((element: { category_name: string; }) => {
        });
      }
    },
      err => {
        console.log(err);
      }
    );
  }

  getVoiceRoleList() {
    this.loader = true;
    this.userService.getVoiceRoleList().subscribe(data => {
      this.roleList = data;
      if (this.roleList !== null && this.roleList !== "") {
        this.loader = false;
        this.roleList.forEach((element: { role_name: string; }) => {
        });
      }
    },
      err => {
        console.log(err);
      }
    );
  }

  getAccentList() {
    this.loader = true;
    this.userService.getAccentList().subscribe(data => {
      this.accentList = data;
      if (this.accentList !== null && this.accentList !== "") {
        this.loader = false;
        this.accentList.forEach((element: { accent: string; }) => {
        });
      }
    },
      err => {
        console.log(err);
      }
    );
  }

  getStyleList() {
    this.loader = true;
    this.userService.getStyleList().subscribe(data => {
      this.styleList = data;
      if (this.styleList !== null && this.styleList !== "") {
        this.loader = false;
        this.styleList.forEach((element: { style_name: string; }) => {
        });
      }
    },
      err => {
        console.log(err);
      }
    );
  }

  openModal() {
    this.modalView$.nativeElement.classList.add('visible');
  }

  closeModal() {
    this.modalView$.nativeElement.classList.remove('visible');
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: any) {
    const outsideElement = this.outsideElement.nativeElement.contains(targetElement);
    if (outsideElement) {
      this.modalView$.nativeElement.classList.remove('visible');
    }
  }

  validate() {
    if (this.voiceSampleFrom.sampleVoice === null || this.voiceSampleFrom.sampleVoice === "" || this.voiceSampleFrom.sampleVoice === undefined) {
      this.sampleVoiceHide = true;
      this.sampleVoiceError = "Title is mandatory.";
    } else {
      this.sampleVoiceHide = false;

    } if (this.voiceSampleFrom.language === null || this.voiceSampleFrom.language === "" || this.voiceSampleFrom.language === undefined) {
      this.languageHide = true;
      this.languageError = "Language is mandatory.";
    } else {
      this.languageHide = false;
    }
    if (this.voiceSampleFrom.category_name === null || this.voiceSampleFrom.category_name === "" || this.voiceSampleFrom.category_name === undefined) {
      this.categoryHide = true;
      this.categoryError = "Category is mandatory.";
    } else {
      this.categoryHide = false;
    }
    if (this.voiceSampleFrom.name === null || this.voiceSampleFrom.name === "" || this.voiceSampleFrom.name === undefined) {
      this.nameHide = true;
      this.nameError = "Sample is mandatory.";
    } else {
      this.nameHide = false;
    }


  }

  reloadEditVoice() {
    this.router.navigate(['/editvoice']);
  }

  reloadEqipExp() {
    this.router.navigate(['/equipment']);
  }


}
