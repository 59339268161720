<div id="maincontainer">
    <!-- Banner -->
    <div class="dashboardbanner">
        <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
        <div class="bannerheading">Artist Payment</div>
    </div>

    <div class="fullwidth bg_purple">
        <!--bof: myrecording-->
        <div class="w1200">
            <div class="dashboardwedget">
                <app-admin-sidebar></app-admin-sidebar>
                <!-- bof:wedgetright -->
                <div class="wedgetright">
                    <h3>Artist Payment</h3>
                    <div class="dashwed">
                        <ul class="homelink">
                            <li><a class="active" routerLink="/all-artist-payment-details">
                                    <i class="fa fa-home fa-2x dashboardicon" aria-hidden="true">
                                    </i></a></li>
                            <li><a routerLink="/all-artist-payment-details">Artist Payment</a></li>
                        </ul>
                    </div>
                    <div class="artistboard">

                        <div class="searchrow">
                            <div class="pagesdroplist">
                                <span>Total Payments : {{ NoOfPaymentData }}</span>
                            </div>
                            <div class="searchinput"><input type="text" id="myInput" [(ngModel)]="searchParam"
                                    placeholder="Search"></div>
                        </div>

                        <!--boftablestructure  -->
                        <div class="atrtable">
                            <div class="row">
                                <div class="col"><span class="first4">Order Id</span></div>
                                <div class="col"><span class="first3">Total Word</span></div>
                                <div class="col"><span class="first3">Artist Id</span></div>
                                <div class="col"><span class="first4">Amount To Be Paid (INR)</span></div>
                                <div class="col"><span class="first4">Completion Date</span></div>
                                <div class="col"><span class="first4">Status</span></div>
                                <div class="col"><span class="first4">Operation</span></div>
                            </div>

                            <div id="myList"
                                *ngFor="let data of allProjectPaymentDetails | filter:searchParam | paginate: { itemsPerPage: 10, currentPage: page }">
                                <div class="row">
                                    <div class="col"><span class="first4"
                                            style="white-space: pre-wrap;">{{data.referenceId}}</span></div>
                                    <div class="col"><span class="first3"
                                            style="white-space: pre-wrap;">{{data.totalWord}}</span></div>
                                    <div class="col"><span class="first3"
                                            style="white-space: pre-wrap;">VM{{data.artistId}}</span></div>
                                    <div class="col"><span class="first4"
                                            style="white-space: pre-wrap;">{{data.artistPrice}}</span></div>
                                    <div class="col"><span class="first4"
                                            style="white-space: pre-wrap;">{{data.updatedDate | date}}</span></div>
                                    <div class="col" *ngIf="data.paymentStatus == true"><span class="first4"
                                            style="color: blue;">Paid</span></div>
                                    <div class="col" *ngIf="data.paymentStatus != true"><span class="first4"
                                            style="color: red;">Pending</span></div>
                                    <div class="col"><span class="first4" style="white-space: pre-wrap;"><a
                                                class="button" (click)="fetchArtistBankDetails(data)">View
                                                Detail</a></span></div>
                                </div>
                            </div>
                            
                        </div>

                        <!-- start pop up-->
                        <div class="overlay" *ngIf="popup">
                            <div class="content">
                                <!--bof: ProfilePopup-->
                                <div class="viewprofilesection">
                                    <div class="viewprofiles">
                                        <span class="material-icons-outlined revisionclose"
                                            (click)="popup = false">close</span>
                                        <h2>View Details</h2>
                                        <div class="insideview">
                                            <ul>
                                                <li><b>Artist Id :</b>
                                                    <span>VM{{fetchArtistPaymentDetails.artistId}}</span>
                                                </li>
                                                <li><b>Artist Name :</b>
                                                    <span>{{fetchArtistPaymentDetails.artistName}}</span>
                                                </li>
                                                <li><b>Address :</b> <span>{{fetchArtistPaymentDetails.address}}</span>
                                                </li>
                                                <li><b>Pan Number :</b>
                                                    <span>{{fetchArtistPaymentDetails.panNumber}}</span>
                                                </li>
                                                <li><b>GST Number :</b>
                                                    <span>{{fetchArtistPaymentDetails.gstNumber}}</span>
                                                </li>
                                                <li><b>Account Holder Name :</b>
                                                    <span>{{fetchArtistPaymentDetails.accountHolderName}}</span>
                                                </li>
                                                <li><b>Account Number :</b>
                                                    <span>{{fetchArtistPaymentDetails.accountNumber}}</span>
                                                </li>
                                                <li><b>Bank Name :</b>
                                                    <span>{{fetchArtistPaymentDetails.bankName}}</span>
                                                </li>
                                                <li><b>IFSC Code :</b>
                                                    <span>{{fetchArtistPaymentDetails.ifscCode}}</span>
                                                </li>
                                                <li><b>Branch :</b> <span>{{fetchArtistPaymentDetails.branch}}</span>
                                                </li>
                                                <li><b>Pan Image :</b>
                                                    <span>{{fetchArtistPaymentDetails.panImage}}</span>
                                                </li>
                                                <li><b>GST Image :</b>
                                                    <span>{{fetchArtistPaymentDetails.gstImage}}</span>
                                                </li>
                                                <li><b>IBAN :</b><span>{{fetchArtistPaymentDetails.iban}} </span></li>
                                                <li><b>SWift Code :</b>
                                                    <span>{{fetchArtistPaymentDetails.swiftCode}}</span>
                                                </li>
                                                <li><b>Country :</b> <span>{{fetchArtistPaymentDetails.country}}</span>
                                                </li>
                                                <li><b>City :</b> <span>{{fetchArtistPaymentDetails.city}}</span>
                                                <li><b>Branch Address :</b>
                                                    <span>{{fetchArtistPaymentDetails.branchAddress}}</span>
                                                </li><br>
                                                <img class="img" src="https://api.pinnaklebiz.com/thevoicemall/kyc/{{fetchArtistPaymentDetails.panImage}}" alt="profile" /> 
                                                <li><b>Enter Payment Details</b></li>
                                            </ul>

                                            <textarea rows="4" cols="50" placeholder="Please enter payment details."
                                                [(ngModel)]="paymentDetails"
                                                [ngModelOptions]="{standalone: true}"></textarea>
                                            <br>
                                            <div>
                                                <button class="button" style="font-size:1.4em !important;" (click)="submitPaymentDetails()">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--eof: ProfilePopup-->
                                
                            </div>
                        </div>
                        <div class="paginations">
                            <div class="custom-pagination-wrapper">
                                <button class="pagination-button  margincorrect" [ngClass]="{ 'active': isFirstPage() }" (click)="goToFirstPage()">First</button>                    
                                <pagination-controls (pageChange)="onPageChange($event)" ></pagination-controls>                                                                        
                                <button class="pagination-button  margincorrect" [ngClass]="{ 'lastactive': isLastPage(), 'lastdeactive': !isLastPage() }" (click)="goToLastPage()">Last</button>
                            </div>    
                        </div>
                        <!-- <div class="paginations">
                            <pagination-controls (pageChange)="page = $event"></pagination-controls>
                        </div> -->
                     

                    </div>
                </div>
            </div>
        </div>
        <!--eof: myrecording-->
    </div>
</div>