import { Component, ElementRef, ViewChildren, OnInit } from '@angular/core';
import { OrderService } from '../_services/order.service';
import { UserService } from '../_services/user.service';
import { Ng2SearchPipe, Ng2SearchPipeModule } from 'ng2-search-filter';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-recording-request',
  templateUrl: './recording-request.component.html',
  styleUrls: ['./recording-request.component.css']
})
export class RecordingRequestComponent implements OnInit {


  uploadArtistVoiceDetails: any;
  allArtistDetails: any;
  allArtistDetailsfiltered: any;
  singleBookingDetails: any;
  editProfileForm: any;
  editVoiceProfileForm: any;
  availabilityForm: any;
  equipmentExperienceForm: any;
  finalPricewithTax: any;
  status: any;
  popup = false;
  loader: boolean = true;
  set searchParam(value:any){
    if(value != null && value !='')
    this.allArtistDetailsfiltered=Ng2SearchPipe.prototype.transform(this.allArtistDetails,value);
  else
  this.allArtistDetailsfiltered = this.allArtistDetails;

  this.page = 1
  this.NoOfRecording = this.allArtistDetailsfiltered.length;
  }
  page: number = 1;
  NoOfRecording: any;
  @ViewChildren('audio') audioElms!: ElementRef[];


  constructor(private userService: UserService,private orderService: OrderService) { }

  ngOnInit(): void {
    this.loader = false
    this.getAllVoicesample();
  }


  goToFirstPage() {
    this.page = 1; // Assuming 1-based indexing
}

goToLastPage() {
    // Calculate the last page based on the total number of items and items per page
    const totalPages = Math.ceil(this.allArtistDetailsfiltered.length / 10); // Assuming 45 items per page    
    this.page = totalPages;
}

isFirstPage(): boolean {
  return this.page != 1;
}

isLastPage(): any {        
  if(this.page == Math.ceil(this.allArtistDetailsfiltered.length / 10)){
    return false;
  } else {
    return true;
  }    
}

onPageChange(event: number): void {
  this.page = event;
}



onPaly(elm: HTMLAudioElement) {
  this.audioElms.forEach(({ nativeElement: e }) => {
    if (e !== elm) {
      e.pause();
    }
  })
}



    //Get Customer Booking Details on reference Id
    onClickReferencerId(data: any) {
      this.userService.getCustomerBookingDetailsOnReferenceId(data.referenceId).subscribe(data => {
        this.singleBookingDetails = data;
        if (this.singleBookingDetails !== null && this.singleBookingDetails !== "" && this.singleBookingDetails !== undefined) {
          this.popup = true;
          this.finalPricewithTax = this.singleBookingDetails.finalPrice + this.singleBookingDetails.tax
          if (this.singleBookingDetails.status == true) {
            this.status = "Completed"
          } else if (this.singleBookingDetails.status == true && this.singleBookingDetails.status == null) {
            this.status = "Ongoing"
          }
          ((element: {
          }) => {
          });
        }
        this.getuploadArtistVoice(data);
      },
        err => {
          console.log(err);
        }
      );
    }


    //start from here to get Uploaded voice from artis side
  getuploadArtistVoice(data: any) {
    this.orderService.getuploadArtistVoice(data.referenceId).subscribe(data => {
      this.uploadArtistVoiceDetails = data;
      if (this.uploadArtistVoiceDetails !== null && this.uploadArtistVoiceDetails !== "" && this.uploadArtistVoiceDetails !== undefined) {
        this.popup = true;
        this.uploadArtistVoiceDetails.forEach((element: {
        }) => {
        });
      }
    },
      err => {
        console.log(err);
      }
    );

  }






getAllVoicesample() {
    this.orderService.getAllOrderDetails().subscribe(data => {
      this.allArtistDetails = data;
      this.allArtistDetailsfiltered = data;
      this.NoOfRecording = this.allArtistDetailsfiltered.length;
      this.allArtistDetails.forEach((element: {}) => {
      });
    },
      err => {
        console.log(err);
      }
    );
  }

  


}