<div id="maincontainer">
    <!-- Banner -->
    <div class="dashboardbanner">
        <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
        <div class="bannerheading">Customer Dashboard</div>
    </div>

    <div class="fullwidth bg_purple">
        <!--bof: myrecording-->
        <div class="w1200">
            <div class="dashboardwedget">
                <app-admin-sidebar></app-admin-sidebar>
                <!-- bof:wedgetright -->
                <div class="wedgetright">
                    <h3>Customer Details</h3>
                    <div class="dashwed">
                        <ul class="homelink">
                            <li><a class="active" routerLink="/all-customer-details"><i
                                        class="fa fa-home fa-2x dashboardicon" aria-hidden="true"></i></a></li>
                            <li><a routerLink="/all-customer-details">Customer Details</a></li>
                        </ul>
                    </div>
                    <div class="artistboard">
                        <div class="searchrow">
                            <!-- <div class="pagesdroplist">
                                <span>Show</span>
                                <select>
                                    <option>25</option>
                                    <option>50</option>
                                    <option>75</option>
                                    <option>100</option>
                                </select>
                                <span>entries</span>
                            </div> -->
                            <div class="pagesdroplist">
                                <span>Total Customer: {{ totCustNo }}</span>
                            </div>
                            <div class="searchinput"><input type="text" id="myInput" [(ngModel)]="searchParam"
                                    placeholder="Search"></div>
                        </div>

                        <!--boftablestructure  -->
                        <div class="atrtable">
                            <div class="row">
                                <div class="col"><span class="first1" style="width: 88px  !important;">Customer
                                        Id</span></div>
                                <div class="col"><span class="first2">Name</span></div>
                                <div class="col"><span class="first3">Contact No</span></div>
                                <div class="col"><span class="first4">Email Address</span></div>
                                <div class="col"><span class="first3">Company Name</span></div>
                                <div class="col"><span class="first3">Registration Date</span></div>
                                <div class="col"><span class="first3">Verification Status</span></div>
                                <div class="col"><span class="first3" style="width: 250px  !important;">Operation</span></div>

                            </div>

                            <div id="myList"
                                *ngFor="let data of allUserDetails | filter:searchParam | paginate: { itemsPerPage: 10, currentPage: page }">
                                <div class="row">
                                    <div class="col"><span class="first1"
                                            style="white-space: pre-wrap;width: 88px  !important">VM{{data.id}}</span>
                                    </div>
                                    <div class="col"><span class="first2"
                                            style="white-space: pre-wrap;">{{data.username}} {{data.last_name}}</span>
                                    </div>
                                    <div class="col"><span class="first3"
                                            style="white-space: pre-wrap;">{{data.contact}}</span></div>
                                    <div class="col"><span class="first4"
                                            style="white-space: pre-wrap;">{{data.email}}</span></div>
                                    <div class="col"><span class="first3"
                                            style="white-space: pre-wrap;">{{data.company_name}}</span></div>
                                    <div class="col">
                                        <span class="first3" style="white-space: pre-wrap;">{{data.createdDate |
                                            date}}</span>
                                    </div>
                                    <div class="col" *ngIf="data.enabled == true">
                                        <span class="first3" style="white-space: pre-wrap; color: green;">Active</span>
                                    </div>
                                    <div class="col" *ngIf="data.enabled == false">
                                        <span class="first3" style="white-space: pre-wrap; color: red;">Pending</span>
                                    </div>
<br>
                                    <div class="col" >
                                        <span class="three9" style="white-space: pre-wrap;width: 250px  !important;" *ngIf="data.is_admin_approved !== 1 && data.is_admin_approved !== 2">
                                            <a class="activebtn"   style="height: 31px !important;"  (click)="ChangeUserStatus(data.id, 1, '')"><i>&#10004;</i> Approve</a>
                                            <a class="rejectbtn"  (click)="ChangeUserStatus(data.id, 2, '')" ><i>&#10006;</i> Reject</a>
                                        </span>
                                        <span *ngIf="data.is_admin_approved == 1" class="three9" style="white-space: pre-wrap;width: 250px  !important;color: green;" >Approved</span>
                                        <span *ngIf="data.is_admin_approved == 2" class="three9" style="white-space: pre-wrap;width: 250px  !important;color: red;" >Rejected</span>
                                    
                                    </div>

                                </div>
                            </div>

                        </div>


                        <!-- <pagination-controls (pageChange)="page = $event"></pagination-controls> -->
                        <div class="paginations">
                            <div class="custom-pagination-wrapper ">
                                <button class="pagination-button margincorrect" [ngClass]="{ 'active': isFirstPage() }"
                                    (click)="goToFirstPage()">First</button>
                                <pagination-controls (pageChange)="onPageChange($event)"></pagination-controls>
                                <button class="pagination-button margincorrect"
                                    [ngClass]="{ 'lastactive': isLastPage(), 'lastdeactive': !isLastPage() }"
                                    (click)="goToLastPage()">Last</button>

                            </div>
                        </div>

 
                    </div>
                </div>
            </div>
        </div>
        <!--eof: myrecording-->
    </div>
</div>

 
    