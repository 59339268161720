import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { BoardUserComponent } from './board-user/board-user.component';
import { BoardModeratorComponent } from './board-moderator/board-moderator.component';
import { BoardAdminComponent } from './board-admin/board-admin.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { FaqComponent } from './faq/faq.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { EquipmentExperienceComponent } from './equipment-experience/equipment-experience.component';
import { EditVoiceProfileComponent } from './edit-voice-profile/edit-voice-profile.component';
import { PriceDeliveryTimeComponent } from './price-delivery-time/price-delivery-time.component';
import { ThankYouMessageComponent } from './thank-you-message/thank-you-message.component';
import { TermsComponent } from './terms/terms.component';
import { PolicyComponent } from './policy/policy.component';
import { HowWeWorkComponent } from './how-we-work/how-we-work.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { VoiceSampleComponent } from './voice-sample/voice-sample.component';
import { SearchComponent } from './search/search.component';
import { ArtistProfileComponent } from './artist-profile/artist-profile.component';
import { PaymentReviewComponent } from './payment-review/payment-review.component';
import { PaymentFinalComponent } from './payment-final/payment-final.component';
import { UploadSamplesComponent } from './upload-samples/upload-samples.component';
import { CustomerDashboardComponent } from './customer-dashboard/customer-dashboard.component';
import { AuthguardGuard } from '../app/auth/authguard.guard';
import { TranstionHistoryComponent } from './transtion-history/transtion-history.component';
import { ClientBookingDetailsComponent } from './client-booking-details/client-booking-details.component';
import { ArtistBookingDetailsComponent } from './artist-booking-details/artist-booking-details.component';
import { ArtistDashboardComponent } from './artist-dashboard/artist-dashboard.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AllCustomerDetailsComponent } from './all-customer-details/all-customer-details.component';
import { AllArtistDetailsComponent } from './all-artist-details/all-artist-details.component';
import { RecordingRequestComponent } from './recording-request/recording-request.component';
import { AllVoiceSamplesComponent } from './all-voice-samples/all-voice-samples.component';
import { AllUserContactDetailsComponent } from './all-user-contact-details/all-user-contact-details.component';
import { SampleDetailsComponent } from './sample-details/sample-details.component';
import { RevisedVoiceSamplesComponent } from './revised-voice-samples/revised-voice-samples.component';
import { RevisionCommentsComponent } from './revision-comments/revision-comments.component';
import { ArtistBankDetailsComponent } from './artist-bank-details/artist-bank-details.component';
import { AllArtistPaymentDetailsComponent } from './all-artist-payment-details/all-artist-payment-details.component';
import { ArtistPaymentHistoryComponent } from './artist-payment-history/artist-payment-history.component';
import { CustomerReviewRatingComponent } from './customer-review-rating/customer-review-rating.component';
import { ViewRolesComponent } from './view-roles/view-roles.component';
import { AddRoleComponent } from './add-role/add-role.component';
import { EditRoleComponent } from './edit-role/edit-role.component';
import { UsersComponent } from './users/users.component';
import { AddUserComponent } from './add-user/add-user.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { TokenStorageService } from './_services/token-storage.service'; 
import { UpdateUserPasswordComponent } from './update-user-password/update-user-password.component';
import { UserEngagementComponent } from './user-engagement/user-engagement.component';
 import { BulkEmailComponent } from './bulk-email/bulk-email.component';
import { RevenueReportComponent } from './revenue-report/revenue-report.component';
import { AboutCustomersComponent } from './about-customers/about-customers.component';
import { AboutArtistComponent } from './about-artist/about-artist.component';
import { RegisterPageComponent } from './register-page/register-page.component';
import { ArtistRegisterComponent } from './artist-register/artist-register.component';
import { BusinessDeatilsComponent } from './business-deatils/business-deatils.component';

export function getCurrentRole(tokenService:TokenStorageService){
  return tokenService.getUser ? tokenService.getUser().roles:[];
}

const RoleByToken = getCurrentRole(new TokenStorageService);
 
const routes: Routes = [
  { path: '', component: HomeComponent },
  {path:'home',component:HomeComponent},
  { path: 'about', component: AboutComponent },
  { path: 'login', component: LoginComponent },
  { path: 'customer-register', component: RegisterComponent },
  {path:'artist-register',component:ArtistRegisterComponent},
  { path: 'register', component: RegisterPageComponent },

  { path: 'profile', component: ProfileComponent },
  { path: 'user', component: BoardUserComponent },
  { path: 'mod', component: BoardModeratorComponent },
  { path: 'admin', component: BoardAdminComponent, canActivate: [AuthguardGuard], data: { roles: RoleByToken }},
   { path: 'contact', component: ContactComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'editprofile', component: EditProfileComponent, canActivate: [AuthguardGuard], data: { roles: ['ROLE_ARTIST'] } },
  { path: 'equipment', component: EquipmentExperienceComponent, canActivate: [AuthguardGuard], data: { roles: ['ROLE_ARTIST'] } },
  { path: 'editvoice', component: EditVoiceProfileComponent, canActivate: [AuthguardGuard], data: { roles: ['ROLE_ARTIST'] } },
  { path: 'pricedelivery', component: PriceDeliveryTimeComponent, canActivate: [AuthguardGuard], data: { roles: ['ROLE_ARTIST'] } },
  { path: 'thankyou', component: ThankYouMessageComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'policy', component: PolicyComponent },
  { path: 'howwework', component: HowWeWorkComponent },
  { path: 'disclaimer', component: DisclaimerComponent },
  { path: 'sidebar', component: SidebarComponent },
  { path: 'voicesample', component: VoiceSampleComponent },
  { path: 'search', component: SearchComponent },
  { path: 'searchArtist', component: ArtistProfileComponent },
  { path: 'paymentreview', component: PaymentReviewComponent, canActivate: [AuthguardGuard], data: { roles: ['ROLE_CUSTOMER'] } },
  { path: 'paymentfinal', component: PaymentFinalComponent, canActivate: [AuthguardGuard], data: { roles: ['ROLE_CUSTOMER'] } },
  { path: 'uploadsamples', component: UploadSamplesComponent, canActivate: [AuthguardGuard], data: { roles: ['ROLE_ARTIST'] } },
  { path: 'customerdashboard', component: CustomerDashboardComponent, canActivate: [AuthguardGuard], data: { roles: ['ROLE_CUSTOMER'] } },
  { path: 'clientbookingdetails', component: ClientBookingDetailsComponent, canActivate: [AuthguardGuard], data: { roles: ['ROLE_CUSTOMER'] }},
  {path:'business-deatils', component:BusinessDeatilsComponent,canActivate:[AuthguardGuard],data:{roles:['ROLE_CUSTOMER']}},
  { path: 'artistbookingdetails', component: ArtistBookingDetailsComponent, canActivate: [AuthguardGuard], data: { roles: ['ROLE_ARTIST'] }},
  { path: 'artistdashboard', component: ArtistDashboardComponent, canActivate: [AuthguardGuard], data: { roles: ['ROLE_ARTIST'] } },
  { path: 'transtion-history', component: TranstionHistoryComponent},
  { path: 'change-password', component: ChangePasswordComponent},
  { path: 'all-customer-details', component: AllCustomerDetailsComponent, canActivate: [AuthguardGuard] , data: { roles: RoleByToken  }},
  { path: 'all-artist-details', component: AllArtistDetailsComponent, canActivate: [AuthguardGuard], data: { roles: RoleByToken  }},
  { path: 'recording-request', component: RecordingRequestComponent, canActivate: [AuthguardGuard], data: { roles: RoleByToken  }},
  { path: 'all-voice-samples', component: AllVoiceSamplesComponent, canActivate: [AuthguardGuard], data: { roles: RoleByToken  }},
  { path: 'all-user-contact-details', component: AllUserContactDetailsComponent, canActivate: [AuthguardGuard], data: { roles: RoleByToken  }},
  { path: 'customer-review-rating', component: CustomerReviewRatingComponent},
  { path: 'all-sample', component: SampleDetailsComponent},
  { path: 'revised-samples', component: RevisedVoiceSamplesComponent, canActivate: [AuthguardGuard], data: { roles: RoleByToken  }},
  {path : 'view-roles' , component : ViewRolesComponent, canActivate: [AuthguardGuard], data:{roles:RoleByToken }},
  {path : 'add-role',component: AddRoleComponent, canActivate:[AuthguardGuard], data:{roles:RoleByToken }},
  {path: 'edit-role/:id', component: EditRoleComponent,canActivate: [AuthguardGuard], data: { roles: RoleByToken }},  
  { path: 'revised-comments', component: RevisionCommentsComponent, canActivate: [AuthguardGuard],data:{ roles: RoleByToken  }},
  { path: 'artist-bank-details', component: ArtistBankDetailsComponent, canActivate: [AuthguardGuard], data: { roles: ['ROLE_ARTIST']}},
  { path: 'all-artist-payment-details', component: AllArtistPaymentDetailsComponent, canActivate: [AuthguardGuard], data: { roles: RoleByToken }},
  { path: 'fetch-Project-Payment-Details', component: ArtistPaymentHistoryComponent, canActivate: [AuthguardGuard], data: { roles: ['ROLE_ARTIST']}},
  {path: 'view-users', component:UsersComponent, canActivate: [AuthguardGuard],data: { roles: RoleByToken }},
  {path:'add-user',component:AddUserComponent, canActivate: [AuthguardGuard],data: { roles: RoleByToken }},
  {path: 'edit-user/:id',component:EditUserComponent, canActivate: [AuthguardGuard], data: {roles: RoleByToken }},
  {path:'user-password',component:UpdateUserPasswordComponent,canActivate:[AuthguardGuard],data:{roles:RoleByToken}},
  {path:'users-chat',component:UserEngagementComponent,canActivate:[AuthguardGuard], data:{roles:RoleByToken}},
  {path:'bulk-email', component:BulkEmailComponent,canActivate:[AuthguardGuard],data:{roles:RoleByToken}},
  {path:'revenue-report', component:RevenueReportComponent,canActivate:[AuthguardGuard], data:{roles:RoleByToken}},
  {path:'about-artist',component:AboutArtistComponent},
  {path:'about-customers', component:AboutCustomersComponent},
  { path: '', redirectTo: '', pathMatch: 'full'},
]
@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: false})],
  exports: [RouterModule],
  // providers: [AuthguardGuard]
})
export class AppRoutingModule { }
