import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from '../_services/token-storage.service';
import { Location } from '@angular/common';
import { UserService } from '../_services/user.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.css'],
})
export class AdminSidebarComponent implements OnInit {
  currentUser: any;
  contact: any;
  id: any;
  roles: any;
  selectTab: any;
  activeSubMenu: boolean = false;
  activeSubMenu1: boolean = false;
  Checkpermission: any;
  permission: any;
  pdata: any;
  pdata1: any[] = [];
  notify: any;
  constructor(
    private router: Router,
    private token: TokenStorageService,
    private toastr: ToastrService,
    private location: Location,
    private userService: UserService,
    private http:HttpClient
  ) {
    this.selectTab = this.location.path();
    console.log('this is my current path', this.selectTab);
  }

  ngOnInit(): void {
    this.Notificatation();
    this.currentUser = this.token.getUser();
    this.Checkpermission = this.token.getUser().permission;
    if (this.Checkpermission.permissions.length > 0) {
      this.permission = this.Checkpermission.permissions;
      this.pdata1 = [];
      for (let i = 0; i < this.permission.length; i++) {
        this.pdata1.push(this.permission[i].permission_name);
      }
    }
   

    this.contact = this.token.getUser().contact;
    this.id = this.token.getUser().id;
    this.roles = this.token.getUser().roles;
   
  }

  toggleSubMenu() {
    this.activeSubMenu = !this.activeSubMenu;
  }

  toggleSubMenu1() {
    this.activeSubMenu1 = !this.activeSubMenu1;
  }


  Notificatation() {
    try {
      this.userService.newNotifications().subscribe((response) => {
        if (response) {
          this.notify = response;
       
          }
      },
      (error:HttpErrorResponse)=>{
        if(error.status == 400 || error.status !== null){
          this.toastr.error(error.error.message,'');
          console.log("Error in Notification API ", error);
        }
      }
      );
    } catch (error) {
      console.log('ERROR IN GET NOTIFY. ', error);
    }
  }

UpdateNotificationView(menu:any){
  try {
    this.userService.updateNotificationView(menu).subscribe((response)=>{
   if(response){
    console.log("notification status update successfully ! ",response);
    }
    },
    (error:HttpErrorResponse)=>{
      if(error.status == 400 || error.status !== null){
        this.toastr.error(error.error.message,'');
        console.log("ERROR IN UPDATE NOTIFI. STATUS", error);
      }
    }
    )
    
  } catch (error) {
    console.log("ERROR IN Update Notification View ", error);
  }
}



  admindashboard() {
    setTimeout(() => {
      this.router.navigate(['/admin']);
    }, 200);
  }


  userdashboard() {
    this.UpdateNotificationView('customer');
    this.router.navigate(['/all-customer-details']);
  }

  artistdashboard() {
    this.UpdateNotificationView('artist');
    this.router.navigate(['/all-artist-details']);
  }

  recordingdashboard() {
    this.UpdateNotificationView('order');
    this.router.navigate(['/recording-request']);
  }

  sampledashboard() {
    this.UpdateNotificationView('sample');
    this.router.navigate(['/all-voice-samples']);
  }

  contactdashboard() {
    this.UpdateNotificationView('contact_us');
    this.router.navigate(['/all-user-contact-details']);
  }

  revisedSamples() {
    this.UpdateNotificationView('recording');
    this.router.navigate(['/revised-samples']);
  }

  revisedComments() {
    this.UpdateNotificationView('revision_comment');
    this.router.navigate(['/revised-comments']);
  }

  reviewAndRating() {    
    this.UpdateNotificationView('review_rating');
    this.router.navigate(['/customer-review-rating']);
  }
  Artistpayment() {
    this.router.navigate(['/all-artist-payment-details']);
  }

  viewRoles() {
    this.router.navigate(['/view-roles']);
  }

  users() {
    this.router.navigate(['view-users']);
  }

changePassword(){
  this.router.navigate(['/user-password']);
}

userEngagementChat(){
  this.UpdateNotificationView('chat');
  this.router.navigate(['/users-chat']);
}

RevenueReport(){
  this.router.navigate(['revenue-report']);
}


bulkEmail(){
  this.router.navigate(['/bulk-email']);
}



}
