import { Component, ElementRef, ViewChildren, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OrderService } from '../_services/order.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { UserService } from '../_services/user.service';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-artist-booking-details',
  templateUrl: './artist-booking-details.component.html',
  styleUrls: ['./artist-booking-details.component.css']
})
export class ArtistBookingDetailsComponent implements OnInit {

  content: any;
  script: any;
  language: any;
  category: any;
  artistPrice: any;
  updatedDate: any;
  uploadArtistVoiceDetails: any;
  voiceUploaded: boolean = true;
  revisedCommentsDetailsOnOrderId: any;
  comments: any;

  constructor(private token: TokenStorageService, private userService: UserService, private route: ActivatedRoute,
    private orderService: OrderService, private toastr: ToastrService,private http:HttpClient) { }


  currentUser: any;
  contact: any;
  id: any;
  firstName: any;
  lastName: any;
  email: any;
  loader: boolean = true;
  customerId: any;
  artistId: any;
  totalWords: any;
  recordingPrice: any;
  customerBookingDetails: any
  referenceId: any
  instruction: any;
  format: any;
  AllChats:any;
dataNotFound!:boolean;


  ngOnInit(): void {
    this.loader = false;
    this.currentUser = this.token.getUser();
    this.contact = this.token.getUser().contact;
    this.id = this.token.getUser().id;
    this.email = this.token.getUser().email;
    this.firstName = this.token.getUser().username;
    this.lastName = this.token.getUser().lastname;
    this.route.paramMap.subscribe(params => {
      this.referenceId = params.get('referenceId');
    });
    this.getCustomerBookingDetailsOnReferenceId();
    this.getuploadArtistVoice();
    this.fetchAllRevisedCommentsOnOrderId();
    this.getAllChatByProjectId();
  }

  @ViewChildren('audio') audioElms!: ElementRef[];

  ngAfterViewInit() {
    console.log(this.audioElms)
  }

  onPaly(elm: HTMLAudioElement) {
    this.audioElms.forEach(({ nativeElement: e }) => {
      if (e !== elm) {
        e.pause();
      }
    })
  }


  getAllChatByProjectId(){
    try {
      if(this.referenceId !== null && this.referenceId !== undefined && this.referenceId !== ''){
         this.userService.GetAllChatsByProjectId(this.referenceId).subscribe((response)=>{
          if(response && response !== null){          
            this.AllChats = response;
            console.log("All Chat DAta",this.AllChats);
          }else{
            this.dataNotFound = true;
          }
         },
         (error:HttpErrorResponse)=>{
          this.toastr.error(error.error.message);
          console.log("Error in getting chats by order id ", error);
         }
         )
       }else{
        this.toastr.error("something went wrong in found order id")
       }
    } catch (error) {
     console.log("Error in getting chat by project",error); 
    }
  }
  
  ChatMessag:any = {
    msg:null,
  }
  
  
  insertChat(){
  try {
    // alert(this.ChatMessag.msg);
    if(this.referenceId !== null &&  this.referenceId !== undefined &&  this.referenceId !== '' && this.ChatMessag.msg !== null && this.ChatMessag.msg !== undefined && this.ChatMessag.msg !== ''){
      const ChatData = {
        order_id : this.referenceId,
        msg:this.ChatMessag.msg,
        type:"Artist"
      }
      this.userService.SaveChat(ChatData).subscribe((response)=>{
     if(response){
      console.log("chat data  ",ChatData)
      console.log("response ",response)
      this.toastr.success("Chat Addedd Successfully");
      this.ChatMessag.msg = '';
     }
      })
    }else{
      this.toastr.error("Please write message first");
    }
  } catch (error) {
    console.log("Error in insert chat by customer",error);
  }
  }
  

  
  getCustomerBookingDetailsOnReferenceId() {
    this.loader = true;
    this.userService.getCustomerBookingDetailsOnReferenceId(this.referenceId).subscribe(data => {
      this.customerBookingDetails = data;
      if (this.customerBookingDetails !== null && this.customerBookingDetails !== "") {
        this.loader = false;
        this.customerId = this.customerBookingDetails.customerId;
        this.artistId = this.customerBookingDetails.artistId;
        this.category = this.customerBookingDetails.category;
        this.language = this.customerBookingDetails.language;
        this.artistPrice = this.customerBookingDetails.artistPrice;
        this.script = this.customerBookingDetails.script;
        this.updatedDate = this.customerBookingDetails.updatedDate;
        this.instruction = this.customerBookingDetails.instruction;
      }
    },
      err => {
        console.log(err);
      }
    );
  }

  //Fetch all revised comments details on orderId
  fetchAllRevisedCommentsOnOrderId() {
    this.orderService.getAllRevisedCommentsOnOrderId(this.referenceId).subscribe(data => {
      this.revisedCommentsDetailsOnOrderId = data;
      if (this.revisedCommentsDetailsOnOrderId !== null && this.revisedCommentsDetailsOnOrderId.length > 0 && this.revisedCommentsDetailsOnOrderId !== "") {
        this.revisedCommentsDetailsOnOrderId.forEach((element: {
        }) => {
        });
      }
    },
      err => {
        console.log(err);
      });
  }

  //Start from here for order upload from artist side for customer

  // Variable to store shortLink from api response
  shortLink: string = "";
  loading: boolean = false; // Flag variable
  file: any; // Variable to store file

  // On file Select
  onChange(event: any) {
    this.uploadedVoice = event.target.files[0];
    this.format = this.uploadedVoice.type;
  }

  uploadedVoice: any = null;
  uploadArtistVoiceForm: any = {
    customerId: null,
    artistId: null,
    referenceId: null,
    uploadedVoice: null
  };

  uploadArtistVoice(): void {
    this.loader = true
   if(this.uploadedVoice === null){
    this.toastr.error('Please select your file', '');
    this.loader = false
   }
     else if (this.uploadedVoice.size > 50000000) {
      this.toastr.error('Sample size should be upto 50 MB', '');
      this.loader = false;
    } else if (this.format === "audio/mpeg" || this.format === "audio/wav") {
      const fromData = new FormData();
      const uploadArtistVoiceForm: any = {
        customerId: this.customerId,
        artistId: this.artistId,
        referenceId: this.referenceId,
        uploadedVoice: this.uploadArtistVoiceForm.uploadedVoice
      };
      fromData.append("orderUploadedModal", JSON.stringify(uploadArtistVoiceForm));
      fromData.append("file", this.uploadedVoice, this.uploadedVoice.name);
      this.orderService.uploadArtistVoice(fromData).subscribe();
      setTimeout(() => {
        this.loader = false;
        this.toastr.success('File uploaded successfully.', '');
      }, 3000);
    } else {
      this.toastr.error('File format should be .mp3 or .wav', '');
      this.loader = false;
    }
  }

  getuploadArtistVoice() {
    this.loader = true;
    this.voiceUploaded = true;
    this.orderService.getuploadArtistVoice(this.referenceId).subscribe(data => {
      this.uploadArtistVoiceDetails = data;
      if (this.uploadArtistVoiceDetails !== null && this.uploadArtistVoiceDetails.length > 0 && this.uploadArtistVoiceDetails !== "") {
        this.loader = false;
        this.voiceUploaded = false;
        this.uploadArtistVoiceDetails.forEach((element: {
        }) => {
        });
      }
    },
      err => {
        console.log(err);
      });
  }

  reload() {
    window.location.reload();
  }
}
