import { Component, ElementRef, ViewChildren, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { OrderService } from '../_services/order.service';
import { UserService } from '../_services/user.service';
import { Ng2SearchPipe } from 'ng2-search-filter';

@Component({
  selector: 'app-revised-voice-samples',
  templateUrl: './revised-voice-samples.component.html',
  styleUrls: ['./revised-voice-samples.component.css']
})
export class RevisedVoiceSamplesComponent implements OnInit {allArtistDetails: any;
  allArtistDetailsfiltered: any;
  editProfileForm: any;
  editVoiceProfileForm: any;
  availabilityForm: any;
  equipmentExperienceForm: any;
  popup = false;
  loader: boolean = true;
  set searchParam(value:any){
    if(value != null && value !='')
    this.allArtistDetailsfiltered=Ng2SearchPipe.prototype.transform(this.allArtistDetails,value);
  else
  this.allArtistDetailsfiltered = this.allArtistDetails;

  this.page = 1
  this.NoOfSamples = this.allArtistDetailsfiltered.length;
  }
  page: number = 1;
  NoOfSamples: any;
  @ViewChildren('audio') audioElms!: ElementRef[];


  constructor(private userService: UserService,private orderService: OrderService,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.loader = false
    this.getAllVoicesample();
  }


  goToFirstPage() {
    this.page = 1; // Assuming 1-based indexing
}

goToLastPage() {
    // Calculate the last page based on the total number of items and items per page
    const totalPages = Math.ceil(this.allArtistDetailsfiltered.length / 10); // Assuming 45 items per page    
    this.page = totalPages;
}


isFirstPage(): boolean {
  return this.page != 1;
}

isLastPage(): any {        
  if(this.page == Math.ceil(this.allArtistDetailsfiltered.length / 10)){
    return false;
  } else {
    return true;
  }    
}

onPageChange(event: number): void {
  this.page = event;
}






onPaly(elm: HTMLAudioElement) {
  this.audioElms.forEach(({ nativeElement: e }) => {
    if (e !== elm) {
      e.pause();
    }
  })
}

 //update Status to customer for order complete
 approveRevisedSample(data: any) {
  this.orderService.approveRevisedSample(data.id).subscribe(data => {
    this.toastr.success('Revised Sample has been approve Successfully!! ', '')
    window.location.reload();
  },
    err => {
      console.log(err);
    }
  );
}

// f
rejectSample(data: any) {
  this.userService.rejectSample(data.id);
  this.toastr.success('Revised uploaded sample reject Successfully!! ', '')
}



getAllVoicesample() {
  this.orderService.getAllRevisedVoicesample().subscribe(data => {
      this.allArtistDetails = data;
      this.allArtistDetailsfiltered = data;    
      this.NoOfSamples = this.allArtistDetails.length;  
      this.allArtistDetails.forEach((element: {}) => {
      });
    },
      err => {
        console.log(err);
      }
    );
  }
}