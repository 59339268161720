<!-- Banner -->

<div class="dashboardbanner">
  <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
  <div class="bannerheading">
    <h1>About Us</h1>
  </div>
</div>

<div class="fullwidth  bg_purple">
  <!--bof: Contact Us-->
  <div class="w1200">
    <div class="aboutus">
      <h3> About Us</h3>
      <p>
        The <strong>Voice mall</strong> is a platform, carefully designed as a one-stop solution for all the voice over,
        video production, content writing, and translation needs of clients from all sorts of backgrounds.
        You could be searching for a voice over artist, want to create an audio book, or need a jingle for your next
        project or a movie.
        The <strong>Voice mall</strong> has <strong> plenty of professional voice over recording services</strong> by
        which you can get what you want in the easiest ways possible.

      </p>
      <p>

        The <strong>Voice mall</strong> team has worked a day in and day out to create this user-friendly platform. We
        have helped more than 10000 professional voiceovers, video production artists, content writers, and translators
        to get to a stage where they can showcase their skills.
        These artists can provide voice over in more than 40 languages. Our platform also features a one-of-a-kind
        rating and review system. This allows our clients to get initial insights on what to expect from the
        <strong>professional voice</strong> talents at the <strong>Voice mall.</strong>

      </p>
      <p>

        The <strong>Voice mall</strong> is the leading <strong>voice over recording service</strong> providing platform
        in India.
        We connect clients from all sorts of backgrounds to countless creative professionals.
        Because of the quality of the services we provide, our clients rely on our services alone for all of their
        future voiceover projects.

      </p>
      <p>
        The <strong>Voice mall</strong> being the leading <strong>voice over agency in India </strong> wants to create a
        safe platform for clients as well as the artists, where talent meets opportunity efficiently.



      </p>
      <h3>The Vision</h3>
      <p>
        The <strong>Voice mall</strong> as video production, voiceover, content writing, and translation service
        providing platform, strives to create an ecosystem where clients can securely search for the best voiceover
        artists for all kinds of needs.
        By constantly focusing on innovation, we have empowered the <strong>Voice mall</strong> platform with all kinds
        of features carefully designed to promote security.
      </p>
      <p>
        The <strong>Voice mall</strong> platform is an automated platform, equipped with all the modern-day technologies
        like AI.
        By employing such revolutionizing technologies in our platform we have eliminated middle-men of the conventional
        markets. We have the vision to continuously make the hiring process as seamless as possible by allowing
        listening to voice demos, price comparison capabilities, and quicker delivery times.
      </p>
      <h3> Minimizing Project Time</h3>
      <p>

        Our whole platform is designed in a way to promote quicker turnaround times. We understand that the needs of our
        clients can be diverse. You might need a voiceover, video production, content, or translation services for their
        YouTube channel, advertisement, radio program, movie, etc.
        In all such cases, a client has the ability to compare the turnaround times of all the different artists. We
        also make sure that the artists respect the deadlines of the projects.
      </p>
      <h3> Promoting Professionalism</h3>
      <p>
        The <strong>Voice mall</strong> online platform is solely designed for the purpose of promoting professionalism
        in the servicer industry i.e. voice over, video production, content writing, and translation solutions. We make
        sure that you get the most professional artists in the industry. The artists we feature have all the necessary
        experience in working in radio, television, and movies. Our artists have produced highly professional television
        and radio advertisements, movie voiceovers, documentaries, audio book narration, etc. We have empowered our
        clients with the ability to listen to the voice demos and see the sample of the services uploaded by the artists
        in their <strong>Voice mall</strong> profiles.
      </p>
      <h3> Quality Centric Attitude</h3>
      <p>
        We make sure that you get your <strong>professional voice over recordings, best video production,</strong> and
        the <strong>best content writing and translation services.</strong>
        We promote industry-level clarity in our services. You can listen and review the recordings, video samples, and
        content as many times as you want. You can also suggest appropriate changes.
        During the review phase, we do not transfer the payment you made to the artist and only transfer the payment
        after you get completely satisfied. The finished product will also be owned by you only and the artist will not
        have any property rights over any of the recordings, videos, or content.
      </p>
      <h3> Feedback Mechanism</h3>
      <p>
        At <strong>Voice mall</strong>, we have developed a very sophisticated feedback mechanism. With this mechanism,
        a
        client can register his/her valuable feedback for an artist. This feedback can be used by the artist to improve
        the current project.
        You can send your feedback to the artist as many times as you want. If you face any issue regarding the behavior
        or the execution of an artist you can easily contact our customer service team.
      </p>
      <h3>Payment</h3>
      <p>
        The <strong>Voice mall</strong>, being the leading voice over, video production, translation, and content
        writing
        agency in India pay extra attention to increasing the security during payments. All the transactions that happen
        through our site are based on the most secure payment protocols in the industry. We have developed a payment
        system in which the money stays dormant in our escrow account, promoting the artist to produce high-quality work
        on the terms of the clients.
        In our escrow accounts, the funds stay safe until the completion of a project.
      </p>
      <h3> Why Choose Us?</h3>
      <p>
        We are the <strong>top voice over agency </strong>and the <strong>leading video production agency.</strong> We
        also provide the <strong>best content writing services</strong> along with <strong> translation services
        </strong>in India. We strive to eradicate ambiguity with professionalism by promoting transparency in the hiring
        process. Our whole team is available 24x7 to safeguard the interests of our users.
        As far as the artists are concerned, we at <strong>Voice mall</strong> provide them immense opportunities in
        terms of work. Our AI-backed project finding tool helps the artists to find the best work according to their
        skills and abilities. We give talented individuals the stage they deserve and protect them from the middle-men
        malpractices of the traditional entertainment industry. Our voice demo, video production, translation, and
        content samples upload feature enables these artists to showcase their best skills.
      </p>
      <h3>Our Values</h3>
      <p>
        <strong> 1. Flexibility:</strong> Our entire team at the <strong>Voice mall</strong> stays flexible to tackle
        the
        most unexpected challenges.
        Through our flexibility only we have successfully served countless clients in the most seamless ways possible.
      </p>
      <p>
        <strong> 2. Quality: </strong>We believe in promoting quality in all aspects of our work. We promote utmost
        professionalism in order to enhance the quality of the projects happening through our platform.
      </p>
      <p>
        <strong> 3. Cost-Effectiveness: </strong>We try our best to provide this seamless hiring and working environment
        to our clients as well as our artists at the minimum costs possible. Even though our services are at lower costs
        than other service providers, we do not compromise on the quality of the assistance we are providing.

      </p>
      <p>
        <strong> 4. Innovation:</strong>Since the inception of the <strong>Voice mall,</strong> all our team leaders
        focused on bringing innovation to the table. Innovative technologies like AI, Machine Learning, etc. are largely
        employed during the development of our tools.
      </p>
      <p>
        <strong> 5. Problem Resolving: </strong> As a service-providing platform, we face several challenges and
        problems daily. Our team is highly dedicated to finding the solutions to these challenges as swiftly as
        possible. Our customer service team is also dedicated to resolving the issues our users face.
      </p>

      <p><br>We at <strong>Voice mall,</strong> one of <strong>the top voice over agencies in India</strong> are waiting
        to be the most reliable companion in your voice over the journey. Contact us now!
      </p>
    </div>
  </div>
  <!--eof: Contact Us-->
</div>