<!-- Banner -->

<div class="banner clientbanner">
  <img src="/assets/images/banner.jpg" alt="Banner">
</div>

<div class="fullwidth bg_purple">
  <div class="container2">
    <!--bof:personalinfo-->
    <div class="personal">
      <!-- <h2>Personal </h2>-->

      <div class="row">
        <div class="pinfo">
          <div class="infoimg"><img src="/assets/images/infoimg.jpg" alt="infoimg" /></div>
        </div>
        <div class="pdetails">
          <h3>Benjamin Sirge</h3>
          <strong>vs4210647</strong>
          <div class="pdetailbx">
            <div class="categorie">
              <strong>Categories</strong>
              <ul>
                <li>Advertisement</li>
                <li>Animation </li>
                <li>Audiobooks </li>
                <li>child </li>
                <li>Corporate </li>
                <li>IVR </li>
                <li>Documenatry </li>
                <li>Elearning </li>
                <li>Explainer Video </li>
                <li>Jingle </li>
                <li>Radio </li>
                <li>TVC </li>
                <li>Video Game</li>
              </ul>
              <strong>Language</strong>
              <ul>
                <li>English-India</li>
                <li>English-Neutral </li>
                <li>English-UK </li>
                <li>English-USA </li>
                <li>Hindi </li>
                <li>Punjabi </li>
                <li>Sanskrit </li>
              </ul>
              <div class="editprofile"><a href="#">Edit Profile</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--eof:personalinfo-->



  </div>

  <!--bof:artistrecording-->




  <!--bof: myrecording-->
  <div class="w1200">
    <div class="wedget">
      <!-- bof:wedgetleft -->
      <div class="wedgetleft">
        <div class="profsection">
          <div class="imgrow">
            <img src="/assets/images/infoimg.jpg" alt="profile">
            <strong>VS582809</strong>
          </div>
          <ul>
            <li><a href="#"><i class="fa fa-envelope" aria-hidden="true"></i> shubham@gmail.com</a></li>
            <li><a href="#"><i class="fa fa-phone" aria-hidden="true"></i> +91 9876543210</a></li>
          </ul>
        </div>
        <ul class="tabs">
          <li class="active"><a href="#tab1"><i class="artistperson"></i> <span>My Profile</span> </a></li>
          <li><a href="#tab2"><i class="transhistroy"></i><span> My Task</span></a></li>
          <li><a href="#tab3"><i class="wallet"></i> <span>My Wallet</span></a></li>
          <li><a href="#tab4"><i class="acountset"></i><span> Account Settings</span></a></li>
          <li><a href="#tab6"><i class="notification"></i><span> Notifications</span></a></li>
        </ul>
      </div>

      <!-- bof:wedgetright -->
      <div class="wedgetright">
        <div class="sampledash">
          <h2><i></i>Sample Voice</h2>
          <div class="row">
            <div class="col">
              <strong>Audio Book</strong>
              <ul>
                <li><i class="hindiicon"></i> Hindi</li>
                <li><i class="audiobookicon"></i> Audio Book</li>
              </ul>
              <div class="audiospace">
                <div class="audioinside"><img src="/assets/images/images1.jpg" alt="playaudio"></div>
              </div>
            </div>
            <div class="col">
              <strong>Animation Book</strong>
              <ul>
                <li><i class="hindiicon"></i> Hindi</li>
                <li><i class="audiobookicon"></i> Animation Book</li>
              </ul>
              <div class="audiospace">
                <div class="audioinside"><img src="/assets/images/images1.jpg" alt="playaudio"></div>
              </div>
            </div>
            <div class="col">
              <strong>Advertisment Book</strong>
              <ul>
                <li><i class="hindiicon"></i> Hindi</li>
                <li><i class="audiobookicon"></i> Advertisment Book</li>
              </ul>
              <div class="audiospace">
                <div class="audioinside"><img src="/assets/images/images1.jpg" alt="playaudio"></div>
              </div>
            </div>
            <div class="col">
              <strong>Audio Book</strong>
              <ul>
                <li><i class="hindiicon"></i> Hindi</li>
                <li><i class="audiobookicon"></i> Audio Book</li>
              </ul>
              <div class="audiospace">
                <div class="audioinside"><img src="/assets/images/images1.jpg" alt="playaudio"></div>
              </div>
            </div>
            <div class="col">
              <strong>Animation Book</strong>
              <ul>
                <li><i class="hindiicon"></i> Hindi</li>
                <li><i class="audiobookicon"></i> Animation Book</li>
              </ul>
              <div class="audiospace">
                <div class="audioinside"><img src="/assets/images/images1.jpg" alt="playaudio"></div>
              </div>
            </div>
            <div class="col">
              <strong>Advertisment Book</strong>
              <ul>
                <li><i class="hindiicon"></i> Hindi</li>
                <li><i class="audiobookicon"></i> Advertisment Book</li>
              </ul>
              <div class="audiospace">
                <div class="audioinside"><img src="/assets/images/images1.jpg" alt="playaudio"></div>
              </div>
            </div>
            <div class="col">
              <strong>Audio Book</strong>
              <ul>
                <li><i class="hindiicon"></i> Hindi</li>
                <li><i class="audiobookicon"></i> Audio Book</li>
              </ul>
              <div class="audiospace">
                <div class="audioinside"><img src="/assets/images/images1.jpg" alt="playaudio"></div>
              </div>
            </div>
            <div class="col">
              <strong>Animation Book</strong>
              <ul>
                <li><i class="hindiicon"></i> Hindi</li>
                <li><i class="audiobookicon"></i> Animation Book</li>
              </ul>
              <div class="audiospace">
                <div class="audioinside"><img src="/assets/images/images1.jpg" alt="playaudio"></div>
              </div>
            </div>
            <div class="col">
              <strong>Advertisment Book</strong>
              <ul>
                <li><i class="hindiicon"></i> Hindi</li>
                <li><i class="audiobookicon"></i> Advertisment Book</li>
              </ul>
              <div class="audiospace">
                <div class="audioinside"><img src="/assets/images/images1.jpg" alt="playaudio"></div>
              </div>
            </div>

          </div>
          <div class="pagination">
            <ul>
              <li><a href="#">Prev</a></li>
              <li><a class="active" href="#">1</a></li>
              <li><a href="#">2</a></li>
              <li><a href="#">3</a></li>
              <li><a href="#">Next</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--eof: myrecording-->