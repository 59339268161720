import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from '../_services/token-storage.service';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-customer-dashboard',
  templateUrl: './customer-dashboard.component.html',
  styleUrls: ['./customer-dashboard.component.css']
})
export class CustomerDashboardComponent implements OnInit {
  content: any;

  constructor(private token: TokenStorageService, private userService: UserService, private route: ActivatedRoute,
    private router: Router, private httpClient: HttpClient) { }

  currentUser: any;
  contact: any;
  id: any;
  firstName: any;
  lastName: any;
  email: any;
  loader: boolean = true;
  customerId: any;
  artistId: any;
  totalWords: any;
  recordingPrice: any;
  customerBookingDetails: any;
  referenceId: any;
  page: number = 1;
  postResponse: any;
  profilePic: any;

  ngOnInit(): void {
    this.loader = false;
    this.currentUser = this.token.getUser();
    this.contact = this.token.getUser().contact;
    this.id = this.token.getUser().id;
    this.email = this.token.getUser().email;
    this.firstName = this.token.getUser().username;
    this.lastName = this.token.getUser().lastname;
    this.getCustomerBookingDetails();
    this.viewImage();
    this.route.paramMap.subscribe(params => {
      this.referenceId = params.get('referenceId');
    });
  }

  getCustomerBookingDetails() {
    this.userService.getCustomerBookingDetailsById(this.id).subscribe(data => {
      this.customerBookingDetails = data;
      if (this.customerBookingDetails !== null && this.customerBookingDetails !== "" && this.customerBookingDetails !== undefined) {
        this.customerBookingDetails.forEach((element: {
        }) => {
        });
      }
    },
      err => {
        console.log(err);
      }
    );
  }

  viewDetails(referenceId: any) {
    this.router.navigate(['/clientbookingdetails', { referenceId: referenceId }]);
  }

  viewImage() {
    // this.httpClient.get('http://192.168.1.23/api/test/get/image/info/' + this.image)
    // this.httpClient.get('https://api.pinnaklebiz.com/thevoicemall/profile/' + this.id)
    this.httpClient.get('https://api.pinnaklebiz.com/the-voice-mall/api/test/image/info/' + this.id)
      .subscribe(
        res => {
          this.postResponse = res;
          this.profilePic = this.postResponse.name;
        }
      );
  }

  

}

