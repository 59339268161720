import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../_services/user.service';
import { Router } from '@angular/router';
import { TokenStorageService } from '../_services/token-storage.service';
declare var $: any;
@Component({
  selector: 'app-equipment-experience',
  templateUrl: './equipment-experience.component.html',
  styleUrls: ['./equipment-experience.component.css']
})
export class EquipmentExperienceComponent implements OnInit {
  serviceOfferedList: any;
  content: any;
  educationList: any;

  //Fetch User log in details
  currentUser: any;
  contact: any;
  id: any;
  userId: any;
  loader: boolean = true;
  serviceOffered: string[] = [];

  constructor(private userService: UserService, private toastr: ToastrService, private router: Router, private token: TokenStorageService,) {
  }

  ngOnInit(): void {
    this.getServiceOfferedList();
    this.getEducationList();
    this.currentUser = this.token.getUser();
    this.contact = this.token.getUser().contact;
    this.id = this.token.getUser().id;
    this.loader = false;
    this.getEquipmentExperience();
    setTimeout(() => {
      this.loadScript();
    }, 300);
  }

  equipmentExperienceForm: any = {
    microphoneUsed: null,
    recordingEditing: null,
    socialEquipment: null,
    experience: null,
    serviceOffered: null,
    clientWorkedFor: null,
    education: null,
    socialMediaLinkFB: null,
    socialMediaLinkTwitter: null,
    socialMediaLinkLinked: null
  };

  reloadEditVoice() {
    this.router.navigate(['/editvoice']);
  }

  reloadEqipExp() {
    this.router.navigate(['/equipment']);
  }

  newFunction() {
    var inputs = $("#language1 .search-choice");
    for (var i = 0; i < inputs.length; i++) {
      this.serviceOffered.push(inputs[i].innerText)
    }
  }

  equipmentExperience(): void {
    this.newFunction();
    this.loader = true;
    const { microphoneUsed, recordingEditing, socialEquipment, experience, clientWorkedFor, education, socialMediaLinkFB, socialMediaLinkTwitter,
      socialMediaLinkLinked } = this.equipmentExperienceForm;
    this.userService.equipmentExperience(microphoneUsed, recordingEditing, socialEquipment, experience, this.serviceOffered, clientWorkedFor, education, socialMediaLinkFB, socialMediaLinkTwitter,
      socialMediaLinkLinked, this.userId = this.id).subscribe();
    if (this.equipmentExperienceForm !== null && this.equipmentExperienceForm !== "") {
      this.loader = false;
      this.toastr.success('Equipment & Experience have been updated successfully', '');
      this.router.navigateByUrl('/voicesample');
    }
    else {
      this.toastr.error('Error', '');
    }
  }

  //Fetch EquipmentExperience Details
  getEquipmentExperience() {
    this.userService.getEquipmentExperience(this.userId = this.id).subscribe(data => {
      this.equipmentExperienceForm = data;
      if (this.equipmentExperienceForm !== null && this.equipmentExperienceForm !== "") {
        ((element: {
          microphoneUsed: string, recordingEditing: string, socialEquipment: string, experience: string,
          serviceOffered: string, clientWorkedFor: string, education: string, socialMediaLinkFB: string, socialMediaLinkTwitter: string, socialMediaLinkLinked: string, userId: string
        }) => {
        });
      }
    },
      err => {
        console.log(err);
      }
    );
  }

  getServiceOfferedList() {
    this.loader = true;
    this.userService.getServiceOfferedList().subscribe(data => {
      this.serviceOfferedList = data;
      if (this.serviceOfferedList !== null && this.serviceOfferedList !== "") {
        this.loader = false;
        ((element: { service_offered: string; }) => {
        });
      }
    },
      err => {
        console.log(err);
      }
    );
  }

  getEducationList() {
    this.loader = true;
    this.userService.getEducationList().subscribe(data => {
      this.educationList = data;
      if (this.educationList !== null && this.educationList !== "") {
        this.loader = false;
        this.educationList.forEach((element: { education: string; }) => {
        });
      }
    },
      err => {
        console.log(err);
      }
    );
  }

  thankyou() {
    this.router.navigateByUrl('/thankyou');
  }

  public loadScript() {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = '/assets/js/chosen.jquery.min.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

}
