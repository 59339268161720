//import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from '../_services/order.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { UserService } from '../_services/user.service';
import { ToastrService } from 'ngx-toastr';

declare var Razorpay: any;
@Component({
  selector: 'app-payment-final',
  templateUrl: './payment-final.component.html',
  styleUrls: ['./payment-final.component.css']
})
export class PaymentFinalComponent implements OnInit {
  content: any;
  sampleList: any;
  languageList: any;
  language: any;
  categories: any;
  categoryList: any;
  userId: any;
  searchVoiceList: any;
  categoryName: any;
  languageName: any;
  loader: boolean = true;
  loading = false;
  customerPrice: any;
  finalPrice: any
  customerPriceWithTax: any;
  customerFinalPrice: any;
  instruction: any;
  referenceId: any;

  //oredr flow variable
  form: any = {};
  paymentId: any;
  error: any;
  paymentPage: boolean = false;
  redirect_url: any

  payablePriceForCustomer: any


  //Start for validation
  languageError: any;
  languageHide!: boolean;
  categoryError: any;
  categoryHide!: boolean;
  textError: any;
  textHide!: boolean;

  isQuoteSuccess = false;
  isQuoteFailed = false;
  quoteErrorMessage = '';
  username: any;
  lastname: any;
  email: any;
  contact: any;
  customerId: any;
  script: any;
  //end for validation

  constructor(private userService: UserService, private route: ActivatedRoute, private router: Router,
    private tokenStorage: TokenStorageService, private orderService: OrderService,private toastr:ToastrService) { }

  ngOnInit(): void {
    this.loader = false;
    this.route.paramMap.subscribe(params => {
      this.userId = params.get('queryParams');
      this.languageList = params.get('language');
      this.categoryList = params.get('categories');
      // this.script = params.get('script');
      // this.instruction = params.get('instruction');
      this.getprofileDetails();
    });

    //load customer details
    setTimeout(() => {
      if (this.tokenStorage.getToken()) {
        this.username = this.tokenStorage.getUser().username;
        this.lastname = this.tokenStorage.getUser().lastname;
        this.email = this.tokenStorage.getUser().email;
        this.contact = this.tokenStorage.getUser().contact;
        this.customerId = this.tokenStorage.getUser().id;
      }
    }, 5000);
  }


  //Start from here For word count
  wordCount: any;
  @ViewChild("text")
  text!: ElementRef;
  words: any;
  wordCounter() {
    // alert("test");
    console.log("test");
    this.wordCount = this.text ? this.text.nativeElement.value.split(/\s+/) : 0;
    this.words = this.wordCount ? this.wordCount.length : 0;
  }
  //end 


  //fetching Edit Voice Details 
  getprofileDetails() {
    this.userService.getprofileDetails(this.userId).subscribe(data => {
      this.sampleList = data;
      if (this.sampleList !== undefined) {
        this.languageList = data.languageList;
        this.categoryList = data.categoryList;
        this.sampleList = data.sampleList;
      }
    },
      err => {
        this.sampleList = JSON.parse(err.error).message;
      }
    );
  }


  //Get Quote for Artist

  getFinalPrice() {
    this.paymentPage = false;
    this.userService.getFinalPrice(this.categoryName, this.languageName, this.wordCount.length,
      this.userId = this.userId, this.customerId = this.customerId, this.script, this.instruction).subscribe(data => {
        if (data !== null && data !== "") {
          console.log("Data in true condtios" , data);
          this.paymentPage = true;
          this.isQuoteSuccess = true;
          this.isQuoteFailed = false;
          this.finalPrice = data.finalPrice;
          this.customerPriceWithTax = data.customerPriceWithTax;
          this.customerFinalPrice = data.customerFinalPrice;
          this.referenceId = data.referenceId;
        }else{
          if(data == null){
            this.toastr.error('Price is not available for this category.','');
          }
         }
      },
        err => {
          this.content = JSON.parse(err.error).message;
          this.quoteErrorMessage = err.error.message;
          this.isQuoteFailed = true;
          console.log(err);
        }
      );
  }

  getPayablePriceForCustomer() {
    this.userService.getPayablePriceForCustomer(this.referenceId).subscribe(data => {
      if (data !== null && data !== "") {
        this.payablePriceForCustomer = data.customerPayPriceWithTax;
        this.createOrder();
      }
    },
      err => {
        this.content = JSON.parse(err.error).message;
        this.quoteErrorMessage = err.error.message;
        this.isQuoteFailed = true;
        console.log(err);
      }
    );
  }

  //Generate order flow from here 
  options = {
    "key": "rzp_live_MH6LiRCSwmRIqv",
    "amount": "",
    "name": "Abhishek Vishwakarma",
    "description": "The Voice Mall",
    "image": "/assets/images/thevoicemalllogo.jpg",
    "order_id": "",
    "callback_url": "https://thevoicemall.com/",
    "handler": function (response: any) {
      var event = new CustomEvent("payment.success",
        {
          detail: response,
          bubbles: true,
          cancelable: true
        }
      );
      // alert(response.razorpay_payment_id);
      // alert(response.razorpay_order_id);
      // alert(response.razorpay_signature)
      window.dispatchEvent(event);
    },
  };



  createOrder(): void {
    this.loader = true;
    this.paymentId = '';
    this.error = '';
    this.orderService.createOrder(this.payablePriceForCustomer, this.username, this.email, this.contact, this.userId, this.customerId, this.referenceId).subscribe(
      data => {
        this.loader = false;
        this.options.key = data.secretKey;
        this.options.order_id = data.razorpayOrderId;
        this.options.amount = this.payablePriceForCustomer; //paise
        this.options.name = this.username;
        var rzp1 = new Razorpay(this.options);
        rzp1.open();

        rzp1.on('payment.failed', (response: any) => {
          // Todo - store this information in the server
          console.log(response.error.code);
          console.log(response.error.description);
          console.log(response.error.source);
          console.log(response.error.step);
          console.log(response.error.reason);
          console.log(response.error.metadata.order_id);
          console.log(response.error.metadata.payment_id);
          this.error = response.error.reason;
        }
        );
      },
      err => {
        this.error = err.error.message;
      }
    );
  }

  @HostListener('window:payment.success', ['$event'])
  onPaymentSuccess(event: any): void {
    console.log("Under of payment Success function");
     this.orderService.updateOrder(event.detail).subscribe(
      data => {
        this.paymentId = data.message;
            console.log("data message after payement success" , data.message);
         setTimeout(() =>{
          this.router.navigateByUrl('/thankyou');
         }, 3000)
        // if (this.paymentId !== null) {
        //   //alert('payment_id: ' + this.paymentId);
        //          }
      },
      err => {
        this.error = err.error.message;
        console.log("Error in payment success function ", this.error.code)
       }
    );
  }

}