<app-loader *ngIf="loader"></app-loader>
<!--bof:top-->
<div class="topbase">
    <div class="container">
        <ul class="signpart" *ngIf="isLoggedIn==false">
            <li><a routerLink="/login"><i class="fa fa-sign-in" aria-hidden="true"></i> Sign In</a>
            </li>
            <li class="borernone"><a routerLink="/register"><i class="fa fa-user-circle-o" aria-hidden="true"></i>
                    Register</a></li>
        </ul>
      
        
        <ul class="signpart" *ngIf="isLoggedIn==true">
            <Button
                style="background-color: transparent; color: #fff; font-weight: 700; border: none; margin-right: 10px; border-right: 1px solid #fff; padding-right: 10px; cursor: pointer;"
                (click)="logout()"> <i class="fa fa-sign-out" aria-hidden="true"></i> Logout</Button>
            <Button style="background-color: transparent; color: #fff; font-weight: 700; border: none; cursor: pointer;"
                (click)="myProfile()"><i class="fa fa-user-circle-o" aria-hidden="true"></i> My Profile</Button>
        </ul>

        <!-- <div class="registernav">
            <ul>
                <li><a class="voiceartist">As a voice artist</a></li>
                <li><a class="voiceartist" href="javascript:void(0)">As a voice customer</a></li>
                <li><a class="voiceartist" href="javascript:void(0)">For Translation services</a></li>
                <li><a class="voiceartist" href="javascript:void(0)">For Content writing services</a></li>
            </ul>
        </div>  -->
    </div>
</div>
<!--eof:top-->

<div id="maincontainer">

    <!--header-->
    <div class="container">
        <div class="voiceheader">
            <div class="logo"><a (click)="home()"><img src="/assets/images/logo.png" alt="The Voice Mall" class="logoImageCss"></a></div>
            <!-- NavigagionContainer -->
            <div class="navcontainer">
                <!--Navigation-->
                <div class="wraphead">
                    <!--bof:message from cco-->
                    <!--eof:message from cco-->
                    <!--bof:navheader-->
                    <div class="navheader">
                        <div class="resize-pnl">
                            <!--bof:Navigation-->
                            <div class="navigation desktop-nav">
                                <ul class="drop-nav">
                                    <li>
                                        <a (click)="home()" routerLink="/home" class="active home">
                                            <i class="fa fa-home fa-2x" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                    <li><a (click)="about()">About Us</a></li>
                                    <li><a (click)="aboutArtist()">Artist</a></li>
                                    <li><a (click)="aboutCustomers()">Customer</a></li>
                                    <li class="dropdown_links" (click)="clickEvent()">
                                        <span id="drop_link">Services
                                            <i class="fa fa-angle-down" aria-hidden="true"></i>
                                        </span>
                                        <div class="dropdown-data" [ngClass]="status ? 'open' : 'close'">
                                            <li (click)="searchpage('Voice Over',undefined)"><a >Voice over</a></li>
                                    <li><a>Translation</a></li>
                                    <li><a>Content writing</a></li>
                                    <li><a>Video production</a></li>
                            </div>
                            </li>
                           
                            <li><a (click)="howwework()">How we work</a></li>
                            <li><a (click)="contactus()">Contact</a></li>
                            </ul>
                        </div>
                        <!--eof:Navigation-->
                        <div class="clr"></div>
                    </div>
                </div>
                <!--eof:navheader-->
                <!--bof:mobile navheader-->
                <div class="mnavheader">
                    <div class="resize-pnl">
                        <div id="mySidenav" class="sidenav">
                            <!-- mobile navigation close button -->
                            <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">
                                <span class="material-icons">
                                    close
                                </span>
                            </a>
                            <div class="tophead">
                                <!--bof:Navigation-->
                                <div class="navigation mobile-nav">
                                    <!--bof:Mobile Navigation-->
                                    <ul class="drop">
                                        <li><a (click)="home()">Home</a></li>
                                        <li><a (click)="about()">About Us</a></li>
                                        <li><a (click)="aboutArtist()">Artist</a></li>
                                        <li><a (click)="aboutCustomers()">Customer</a></li>
                                        <li><a (click)="mobClick()">Services
                                                <i class="fa fa-angle-down" aria-hidden="true"></i></a>
                                            <div [ngClass]="dropacd ? 'open' : 'close'">
                                                <div><a (click)="search('Voice Over',undefined)">Voice over</a></div>
                                                <div><a>Video production</a></div>
                                                <div><a>Translation</a></div>
                                                <div><a>Content writing</a></div>
                                            </div>
                                        </li>
                                        <li><a (click)="howwework()">How we work</a></li>
                                        <li><a (click)="contactus()">Contact</a></li>
                                    </ul>
                                </div>
                                <!--eof:Navigation-->
                            </div>
                        </div>

                        <div id="main">
                            <span style="font-size:30px;cursor:pointer" onClick="openNav()">&#9776;</span>
                        </div>
                        <div class="clr"></div>
                    </div>
                </div>
                <!--eof:Mobile navheader-->
            </div>
        </div>
    </div>
</div>
</div>