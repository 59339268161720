<app-loader *ngIf="loader"></app-loader>

<!-- 
<h1>language</h1>
<div n *ngFor="let data of languageList"> 
<p>{{  data.language }}</p>
</div>

<h1>category</h1>
<div n *ngFor="let data of categoryList"> 
<p>{{  data.category_name }}</p>
</div>


<h1>recording</h1>
<div n *ngFor="let data of recordingSetupList"> 
<p>{{  data.recordingSetup }}</p>
</div>


<h1>age </h1>
<div n *ngFor="let data of voiceAgeList"> 
<p>{{  data.age_range }}</p>
</div>

<h1>accents</h1>
<div n *ngFor="let data of accentList"> 
<p>{{  data.accent }}</p>
</div>


<h1>voice role</h1>
<div n *ngFor="let data of voiceRoleList"> 
<p>{{  data.role_name }}</p>
</div>

<h1>style</h1>
<div n *ngFor="let data of styleList"> 
<p>{{  data.style_name }}</p>
</div>
 -->

 <!-- Banner -->
<div class="dashboardbanner">
  <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
  <div class="bannerheading">Voice Portfolio</div>
</div>

<div class="fullwidth bg_purple">
  <!--bof: myrecording-->
  <div class="w1200">
    <div class="pointgrid">
      <div class="pointcols">
        <span><i class="active" routerLink="/editprofile"></i></span>
        <strong>Personal Info</strong>
      </div>
      <div class="pointcols">
        <span><i class="active" (click)="reloadCurrentRoute()"></i></span>
        <strong>Voice Profile</strong>
      </div>
      <div class="pointcols">
        <span><i routerLink="/pricedelivery"></i></span>
        <strong>Price, Delivery time &amp; Availability</strong>
      </div>
      <div class="pointcols">
        <span><i (click)="reloadEqipExp()"></i></span>
        <strong>Equipments &amp; Experience</strong>
      </div>
      <div class="pointcols">
        <span><i routerLink="/voicesample"></i></span>
        <strong>Voice &amp; Sample</strong>
      </div>
    </div>
    <div class="wedget">
      <!-- bof:wedgetleft -->
      <app-sidebar></app-sidebar>
      <!-- bof:wedgetright -->
      <div class="wedgetright">
        <div class="wedgetform">
          <!-- <h3>Voice &amp; Portfolio</h3> -->
          <!--bof:formsection-->
          <div class="form1">
            <form class="formpanel" *ngIf="!isEditVoice" name="form" (ngSubmit)="f.form.valid && editVoiceProfile()"
              #f="ngForm" novalidate>
              <div class="formwrap" id="language1">
                <label>Languages<span>*</span></label>
                <p class="paragraph">Select language you can record in you may select than one</p>
                <select data-placeholder="Choose Languages" class="form-control chosen-select"
                  [(ngModel)]="editVoiceProfileForm.language" [ngModelOptions]="{standalone: true}" required
                  #language="ngModel" multiple>
                  <option *ngFor="let data of languageList" [label]="data.language" [value]="data.language">
                    {{data.language}}
                  </option>
                </select>
                <div class="alert alert-danger" *ngIf="language.errors && f.submitted">
                  <div *ngIf="language.errors.required">Language is mandatory</div>
                </div>
              </div>
              <div class="formwrap" id="language2">
                <label>Select Categories<span>*</span></label>
                <p class="paragraph">Record in as many categories as you like.</p>
                <select data-placeholder="Choose Categories" class="form-control chosen-select"
                  [(ngModel)]="editVoiceProfileForm.category" [ngModelOptions]="{standalone: true}" multiple
                  #category_name="ngModel" required>
                  <option *ngFor="let data of categoryList" [label]="data.category_name" [value]="data.category_name">
                    {{data.category_name}}
                  </option>
                </select>
                <div class="alert alert-danger" *ngIf="category_name.errors && f.submitted">
                  <div *ngIf="category_name.errors.required">Categories is mandatory</div>
                </div>
              </div>

              <div class="formwrap" id="language3">
                <label>Select Recording Setup <span>*</span></label>
                <p class="paragraph">Where do you make your recordings? Choose the best one for you.</p>
                <select data-placeholder="Choose Recording Setup" class="form-control chosen-select"
                  [(ngModel)]="editVoiceProfileForm.recording_Setup" [ngModelOptions]="{standalone: true}" multiple
                  #recordingSetup="ngModel" required>
                  <option *ngFor="let data of recordingSetupList" [label]="data.recordingSetup"
                    [value]="data.recordingSetup">{{data.recordingSetup}}</option>
                </select>
                <div class="alert alert-danger" *ngIf="recordingSetup.errors && f.submitted">
                  <div *ngIf="recordingSetup.errors.required">Recording SetUp is mandatory</div>
                </div>
              </div>

              <div class="formwrap" id="language4">
                <label>Select Voice Ages</label>
                <p class="paragraph">You can select multiple age(s) for which you want to record.</p>
                <select data-placeholder="Choose Voice Ages" class="form-control chosen-select"
                  [(ngModel)]="editVoiceProfileForm.voice_age" [ngModelOptions]="{standalone: true}" multiple>
                  <option *ngFor="let data of voiceAgeList" [label]="data.age_range" [value]="data.age_range">
                    {{data.age_range}}</option>
                </select>
              </div>

              <div class="formwrap" id="language5">
                <label>Select Voice Accents</label>
                <p class="paragraph">You can choose multiple accents from the range below to record in.</p>
                <select data-placeholder="Choose Voice Accents" class="form-control chosen-select"
                  [(ngModel)]="editVoiceProfileForm.accents" [ngModelOptions]="{standalone: true}" multiple>
                  <option *ngFor="let data of accentList" [label]="data.accent" [value]="data.accent">
                    {{data.accent}}
                  </option>
                </select>
              </div>

              <div class="formwrap" id="language6">
                <label>Select Roles</label>
                <p class="paragraph">Do you play any roles? Several options are available to you.</p>
                <select data-placeholder="Choose Roles" class="form-control chosen-select"
                  [(ngModel)]="editVoiceProfileForm.role" [ngModelOptions]="{standalone: true}" multiple>
                  <option *ngFor="let data of voiceRoleList" [label]="data.role_name" [value]="data.role_name">
                    {{data.role_name}}</option>
                </select>
              </div>

              <div class="formwrap" id="language7">
                <label>Select Style</label>
                <p class="paragraph">You can choose a range of styles that want to record in.</p>
                <select data-placeholder="Choose Style" class="form-control chosen-select"
                  [(ngModel)]="editVoiceProfileForm.style" [ngModelOptions]="{standalone: true}" multiple>
                  <option *ngFor="let data of styleList" [label]="data.style_name" [value]="data.style_name">
                    {{data.style_name}}</option>
                </select>
              </div>

              <div class="formwrap">
                <label>Service</label>
                <!-- <p class="paragraph">Please select the desired service.</p> -->
                <select name="service_name" disabled>
                  <option selected>Voice Over</option>
                </select>
              </div>

              <div class="formwrapfull">
                <div class="submitdiv">
                  <input type="submit" value="Save and Next">
                  <i class="fa fa-angle-right" aria-hidden="true"></i>
                </div>
                <strong style="top: 0;">Items marked with <span>*</span> are mandatory</strong>
              </div>
            </form>
            <!--eof:formsection-->

          </div>
          <!--eof: form-->
        </div>
      </div>
    </div>
    <!--eof: myrecording-->
  </div>
</div>