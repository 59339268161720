<app-loader *ngIf="loader"></app-loader>

<div id="maincontainer">

  <div class="dashboardbanner">
    <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
    <div class="bannerheading">Recording Request Dashboard</div>
  </div>

  <div class="fullwidth bg_purple">
    <div class="w1200">
      <div class="dashboardwedget">
        <app-admin-sidebar></app-admin-sidebar>        
        <div class="wedgetright">
            <h3>Manage Recording Request</h3>
          <div class="dashwed">
            <ul class="homelink">
                <li><a class="active" routerLink="/recording-request"><i
                    class="fa fa-home fa-2x dashboardicon" aria-hidden="true"></i></a></li>
                <li><a routerLink="/recording-request">Recording Request Details</a></li>
            </ul>
          </div>
          <div class="artistboard">
            <div class="searchrow"> 
              <div class="pagesdroplist">   
                <span>Total Record : {{ NoOfRecording }}</span>
                </div>              
              <div class="searchinput"><input type="text" id="myInput" [(ngModel)]="searchParam" placeholder="Search">
              </div>              
            </div>

            <!--boftablestructure  -->
            <div class="atrtable">
                <div class="row">
                    <div class="col"><span class="first1">Customer Id</span></div>
                    <div class="col"><span class="first2">Artist Id</span></div>
                    <div class="col"><span class="first3">Category</span></div>
                    <div class="col"><span class="first3">Language</span></div>
                    <div class="col"><span class="first3">Request Date</span></div>
                    <div class="col"><span class="first3">Status</span></div>
                    <div class="col"><span class="three9" style="width: 210px  !important;">Operation</span></div>
             </div>
              <ng-container>
                <div id="myList"
                  *ngFor="let data of allArtistDetailsfiltered | paginate: { itemsPerPage: 10, currentPage: page }">
                  <div class="row">
                    <div class="col"><span class="first1">VM{{ data.customerId }}</span></div>
                    <div class="col"><span class="first2">VM{{ data.artistId }}</span></div>
                    <div class="col"><span class="first3">{{ data.category }}</span></div>
                    <div class="col"><span class="first3">{{ data.language }}</span></div>
                    <div class="col"><span class="first3">{{ data.updatedDate | date}}</span></div>

                    <div class="col" *ngIf="data.status == true"><span class="first3" style="white-space: pre-wrap; color: green;">Completed</span>
                    </div>
                    <div class="col" *ngIf="data.status == false || data.status == null"><span class="first3" style="white-space: pre-wrap; color: red;">Ongoing</span>
                    </div>
                    <div class="col"><span class="three9" style="width: 210px  !important">
                            <a class="viewprofilebtn" (click)="onClickReferencerId(data)"><i
                                    class="fa fa-window-maximize" aria-hidden="true"></i> View Request
                                Details</a>
                        </span>
                    </div>

                </div>
                </div>
              </ng-container>
            </div>
            <div class="paginations">
              <div class="custom-pagination-wrapper">
                <button class="pagination-button margincorrect" [ngClass]="{ 'active': isFirstPage() }" (click)="goToFirstPage()">First</button>                    
                <pagination-controls (pageChange)="onPageChange($event)" ></pagination-controls>                                                                        
                <button class="pagination-button margincorrect" [ngClass]="{ 'lastactive': isLastPage(), 'lastdeactive': !isLastPage() }" (click)="goToLastPage()">Last</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- start pop up-->
<div class="overlay" *ngIf="popup">
    <div class="content">
        <!--bof: ProfilePopup-->
        <div class="viewprofilesection">
            <div class="viewprofiles">
                <span class="material-icons-outlined revisionclose" (click)="popup = false">close</span>
                <h2>View Details</h2>
                <div class="insideview">
                    <ul>
                        <li><b>Customer Id :</b><span>VM{{singleBookingDetails.customerId}}</span></li>
                        <li><b>Customer Price (INR) :</b><span>{{singleBookingDetails.finalPrice}}</span></li>
                        <li><b>Artist Id :</b><span>VM{{singleBookingDetails.artistId}}</span></li>
                        <li><b>Artist Price (INR) :</b><span>{{singleBookingDetails.artistPrice}}</span></li>
                        <li><b>Customer Tax (INR) :</b><span>{{singleBookingDetails.tax}}</span></li>

                    </ul>
                    <ul>
                        <li><b>Category :</b><span>{{singleBookingDetails.category}}</span></li>
                        <li><b>Language :</b><span>{{singleBookingDetails.language}}</span></li>
                        <li><b>Request Date :</b><span>{{singleBookingDetails.updatedDate}}</span></li>
                        <li><b>Request Status :</b><span>{{singleBookingDetails.status}}</span></li>
                        <li><b>Final Price Inclusive Tax (INR) :</b><span>{{singleBookingDetails.finalPrice +
                                singleBookingDetails.tax}}</span>
                        <li><b>Script Details :</b><span>{{singleBookingDetails.script}}</span>
                        <li><b>Total Words In Script :</b><span>{{singleBookingDetails.totalWord}}</span></li>
                        <li><b>Script Instruction :</b><span>{{singleBookingDetails.instruction}}</span>
                        <li><b>Status :</b><span>{{this.status}}</span>
                        </li>
                        <div class="invoicedescription" *ngFor="let data of uploadArtistVoiceDetails">
                            <strong>Recording Uploaded date: <span>{{data.updatedDate | date: 'dd/MM/yyyy hh:mm
                                    a'}}</span></strong>
                            <audio controls #audio (play)="onPaly(audio)" class="audio">
                                <source src="https://api.pinnaklebiz.com/thevoicemall/sample/{{data.uploadedVoice}}">
                            </audio>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
        <!--eof: ProfilePopup-->
    </div>
</div>
