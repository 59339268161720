<div class="loginbase " *ngIf="!isLoggedIn">
<div class="loginbox">
  <div class="leftpart">
      <strong>Sign in</strong>
      <form name="form" (ngSubmit)="f.form.valid && loginUser()" #f="ngForm" novalidate>
          <div class="formwrap">
              <div class="wrap email">
                  <input type="email" placeholder="Enter User Name*" [(ngModel)]="loginform.email" required email
                      #email="ngModel" [ngModelOptions]="{standalone: true}" maxlength="40" />
                  <span class="bottomline">
                  </span>
                  <div class="alert-danger" *ngIf="email.errors && f.submitted">
                      <div *ngIf="email.errors.required">Email is mandatory</div>
                      <div *ngIf="email.errors.email">
                          Email must be a valid email address
                      </div>
                  </div>
              </div>

              <div class="wrap lock">
                  <input type="password" placeholder="Enter Password*" [(ngModel)]="loginform.password" required
                      minlength="6" #password="ngModel" [ngModelOptions]="{standalone: true}" maxlength="30" />
                  <span class="bottomline">
                  </span>
                  <div class="alert-danger" *ngIf="password.errors && f.submitted">
                      <div *ngIf="password.errors.required">Password is mandatory</div>
                      <div *ngIf="password.errors.minlength">
                          Password must be at least 6 characters
                      </div>
                  </div>
              </div>

              <div class="wrap"><label class="customcheck"><input type="checkbox"><span
                          class="checkmark"></span>Remember Me</label>
                  <a (click)="popup = true" class="forgot">Forgot Password?</a>
              </div>

              <div class="wrap">
                  <button class="registerbtn">Sign in</button>
              </div>
              <label class="alertmessage">Not a member yet? <a routerLink="/register">Click here to Register</a> </label>


              <h1 class="alert-danger">{{this.loginErrorMessage}}</h1>

              <div class="wrap">
                  <ul>
                      <!-- <select [(ngModel)]="registerform.roles" [ngModelOptions]="{standalone: true}">
                          <option>As voice artist</option>
                      </select> -->
                      <!-- <li><a href="#">As voice artist</a></li> -->
                      <!-- <li><a href="#">As Customer</a></li>
                      <li><a href="#">As a Translator</a></li>
                      <li><a href="#">As a Content Writer</a></li> -->
                  </ul>
              </div>
          </div>
      </form>
  </div>

  <div class="rightpart">
      <img src="/assets/images/loginimg.jpg">
      <div class="wemake">
          <img src="/assets/images/logo.png" alt="The Voice Mall">
          <!-- <strong>We Make Spectacular</strong> -->
          <p><b>Please sign in to view your dashboard and complete your profile.
                  Our interactive registration pages are specially designed keeping in mind your comfort and
                  ease.</b></p>
      </div>
      <div class="loginsocial">
          <span>Visit our Social Media Pages</span>
          <ul>
              <li><a href="https://www.facebook.com/thevoicemall" target="_blank"><i class="fa fa-facebook"
                          aria-hidden="true"></i></a>
              </li>
              <li><a href="https://www.instagram.com/thevoicemallofficial/" target="_blank"><i
                          class="fa fa-instagram" aria-hidden="true"></i></a></li>

              <li><a href="https://www.linkedin.com/in/the-voice-mall-0b4614244/" target="_blank"><i
                          class="fa fa-linkedin" aria-hidden="true"></i></a></li>
          </ul>
      </div>
  </div>

  <div class="overlay" *ngIf="popup">
      <div class="popup">
          <h2 class="text-center">Forgot Password?</h2>
          <p>You can reset your password here.</p>
          <a class="close" (click)="popup = false">&times;</a>
          <div class="wrap">
              <input type="email" name="email" placeholder="Enter your email address*"
                  [(ngModel)]="registerform.email" required email #email="ngModel"
                  [ngModelOptions]="{standalone: true}" maxlength="40">
              <input name="submit" class="btn btn-lg btn-primary btn-block" value="Submit" type="submit"
                  (click)="sendEmailLink()">
          </div>
      </div>
  </div>
</div>
</div>