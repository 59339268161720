<app-loader *ngIf="loader"></app-loader>

<div id="maincontainer">

  <div class="dashboardbanner">
    <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
    <div class="bannerheading">Contact Details</div>
  </div>

  <div class="fullwidth bg_purple">
    <div class="w1200">
      <div class="dashboardwedget">
        <app-admin-sidebar></app-admin-sidebar>        
        <div class="wedgetright">
          <h3>Contact Details</h3>
          <div class="dashwed">
            <ul class="homelink">
              <li><a class="active" routerLink="/all-user-contact-details"><i class="fa fa-home fa-2x dashboardicon"
                    aria-hidden="true"></i></a></li>
              <li><a routerLink="/all-user-contact-details">Contact Details</a></li>
            </ul>
          </div>
          <div class="artistboard">
            <div class="searchrow">      
              <div class="pagesdroplist">
                <span>Total Contact : {{ NoOfContact }}</span>
              </div>        
              <div class="searchinput"><input type="text" id="myInput" [(ngModel)]="searchParam" placeholder="Search">
              </div>
            </div>

            <!--boftablestructure  -->
            <div class="atrtable">
              <div class="row">
                <div class="col"><span class="first1">S No</span></div>
                <div class="col"><span class="first2">Name</span></div>
                <div class="col"><span class="first4">Email</span></div>
                <div class="col"><span class="first4">Contact</span></div>
                <div class="col"><span class="first4">Message</span></div>
                <div class="col"><span class="first3">Date </span></div>
              </div>
              <ng-container>
                <div id="myList"
                  *ngFor="let data of allArtistDetailsfiltered |  paginate: { itemsPerPage: 10, currentPage: page }; let i = index"
                  [attr.data-index]="i + (page - 1) * 10 + 1  ">
                  <div class="row">
                    <div class="col"><span class="first1">{{ i + (page - 1) * 10 + 1 }}</span></div>
                    <div class="col"><span class="first2">{{ data.first_name }} {{ data.last_Name }} </span>
                    </div>
                    <div class="col"><span class="first4">{{ data.email }}</span></div>
                    <div class="col"><span class="first4">{{ data.contact }}</span></div>
                    <div class="col"><span class="first4">{{ data.message }}</span></div>
                    <div class="col"><span class="first3">{{ data.updatedDate | date }}</span></div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="paginations">
              <div class="custom-pagination-wrapper">
                <button class="pagination-button margincorrect" [ngClass]="{ 'active': isFirstPage() }" (click)="goToFirstPage()">First</button>                    
                <pagination-controls (pageChange)="onPageChange($event)" ></pagination-controls>                                                                        
                <button class="pagination-button margincorrect" [ngClass]="{ 'lastactive': isLastPage(), 'lastdeactive': !isLastPage() }" (click)="goToLastPage()">Last</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
