<!-- Banner -->

<div class="dashboardbanner">
    <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
    <div class="bannerheading">
      <h1>Registration</h1>
    </div>
  </div>
  
  <div class="fullwidth  bg_purple">
    <!--bof: Contact Us-->
    <div class="w1200">
    <div class="maindiv">
        <div class="artistdiv">
        <h2>Artist</h2>
        <div class="pragraph">
            <p   class="psize">Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus, libero? 
            </p>
            <br> <br>
            <ul class="box-main">
              <li class="box-text">Lorem ipsum dolor sit amet.</li>
              <li class="box-text">Lorem ipsum dolor sit amet.</li>
              <li class="box-text">Lorem ipsum dolor sit amet.</li>
              <li class="box-text">Lorem ipsum dolor sit amet.</li>
              <li class="box-text">Lorem ipsum dolor sit amet.</li>
              <li class="box-text">Lorem ipsum dolor sit amet.</li>
            </ul>
        </div>
        <br>
        <!-- <br> -->
        <div style="text-align: center;" class="buttondiv"><button  routerLink="/artist-register"  class="button">Sign-Up</button></div>
        </div>
        
        <div class="customerdiv">
             <h2>Customer</h2>
             <div class="pragraph">
              <p class="psize">Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus, libero? 
              </p>
              <br> <br>
              <ul class="box-main">
                <li class="box-text">Lorem ipsum dolor sit amet.</li>
                <li class="box-text">Lorem ipsum dolor sit amet.</li>
                <li class="box-text">Lorem ipsum dolor sit amet.</li>
                <li class="box-text">Lorem ipsum dolor sit amet.</li>
                <li class="box-text">Lorem ipsum dolor sit amet.</li>
                <li class="box-text">Lorem ipsum dolor sit amet.</li>
              </ul>
          </div>
             <br>
        <div style="text-align: center;  padding-bottom: 14px; "><button  routerLink="/customer-register"  class="button">Sign-Up</button></div>
        </div>
    </div>
    </div>
    <!--eof: Contact Us-->
  </div>