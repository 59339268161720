<div class="maincontainer">
    <div class="dashboardbanner">
        <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
        <div class="bannerheading">
            Bulk Email
        </div>
    </div>

    <div class="fullwidth  bg_purple">
        <div class="w1200">
            <div class="dashboardwedget">
                <app-admin-sidebar></app-admin-sidebar>
                <div class="wedgetright">
                    <h3>Bulk Emails</h3>
                    <div class="dashwed">
                        <ul class="homelink">
                            <li><a class="active" routerLink="/bulk-email"><i class="fa fa-home fa-2x dashboardicon"
                                        aria-hidden="true"></i></a></li>
                            <li><a routerLink="/user-password">Bulk Emails</a></li>
                        </ul>
                    </div>
                    <div class="artistboard">
                        <div class="wedgetform" style="padding-left: 44px !important;">
                            <div class="form1">
                                <form  #userform="ngForm"    class="formpanel">
                                    <div class="formwrap">
                                      <label for="f_name">Email To </label> <span style="color: red;">*</span>
                                      <select name="type" id="f_name" required [(ngModel)]="BULKSMS.EmailTo" [ngModelOptions]="{standalone:true}">
                                        <option value="{{ BULKSMS.EmailTo }}"  disabled selected>{{ BULKSMS.EmailTo }}</option>
                                        <option value="Artist">Artist</option>
                                        <option value="Customer">Customer</option>
                                      </select>
                                       <div class="alert" style="color: red;" *ngIf="EmailToHide">{{ EmailToError }}</div>
                                    </div>
                                    <div class="formwrap">
                                        <label for="subject">Email Subject </label> <span style="color: red;">*</span>
                                        <input type="text" name="subject" id="subject"   placeholder="Email Subject"required  [(ngModel)]="BULKSMS.Subject"  [ngModelOptions]="{standalone:true}" [maxlength]="100">
                                        <div class="alert" style="color: red;" *ngIf="SubjectHide">{{ SubjectError }}</div>
                                      </div>
                                    <div class="formwrap">
                                        <label for="Resolution">Message </label> <span style="color: red;">*</span>

                                        <ckeditor [(ngModel)]="BULKSMS.EmailMSG" [ngModelOptions]="{standalone:true}" [maxlength]="100"  id="Resolution" [editor]="Editor"  style="height: 300px !important;" class="test"></ckeditor>
                                             <div class="alert" style="color: red;" *ngIf="EmailMSGHide">{{ EmailMSGError }}</div>
                                    </div>
                                    

                                    <div class="formwrapfull">
                                        <div class="submitdiv">
                                          <input type="submit" value="Sent"  (click)="sentBulkEmail()" >
                                          <i class="fa fa-angle-right" aria-hidden="true"></i>
                                        </div> 
                                        <strong style="top: 14px !important;">Items marked with <span>*</span> are mandatory</strong>
                                      </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>



        </div>
       


    </div>