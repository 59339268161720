import { ChangeDetectorRef, Component, ElementRef, OnInit, VERSION, ViewChild, HostListener } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { UserService } from '../_services/user.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Country } from 'country-state-city';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  content?: string;
  

  isLoggedIn = false;
  isLoginFailed = false;
  loginErrorMessage = '';
  roles: string[] = [];
  role: string[] = [];

  //Email send for forgot password
  email: any;
  password: any;
  userName: any;
  contact: any;
  userid: any

  data: any;
  popup = false;
  loader: boolean = true;
  ifChecked: Boolean = false;
  sessiondata:any ;
  constructor(private authService: AuthService, private tokenStorage: TokenStorageService,
    private cdr: ChangeDetectorRef,
    private tokenStorageService: TokenStorageService, private userService: UserService, private router: Router, private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.loader = false;
    setTimeout(() => {
      if (this.tokenStorage.getToken()) {
        this.isLoggedIn = true;
        this.roles = this.tokenStorage.getUser().roles;
      }
    }, 500);

 
 
 
    // this.userService.getPublicContent().subscribe(
    //   data => {
    //     this.content = data;
    //   },
    //   err => {
    //     this.content = JSON.parse(err.error).message;
    //   }
    // );

    // this.getCountryList();

  }

  // //hide and show input text box Set on Radio button 

  // private selectedLink: string = "Male";

  // setradio(e: string): void {
  //   this.selectedLink = e;
  // }
  // isSelected(name: string): boolean {

  //   if (!this.selectedLink) { // if no radio button is selected, always return false so every nothing is shown  
  //     return false;
  //   }
  //   return (this.selectedLink === name); // if current radio button is selected, return true, else return false  
  // }

  reloadPage(): void {
    window.location.reload();
  }

  logout(): void {
    this.tokenStorageService.signOut();
    this.toastr.success('You have logged out', '')
    this.router.navigate(['/']);
    setTimeout(() => {
      {
        this.reloadPage();
      }
    }, 10);
  }

  home() {
    window.scroll(0, 0);
    this.router.navigate(['/']);
    setTimeout(() => {
      {
        this.reloadPage();
      }
    }, 10);
  }

  myFunction(){ 
    this.cdr.detectChanges();
  }

  about() {
    window.scroll(0, 0);
    this.router.navigate(['/about']);    
    this.closeNav();
  }

aboutCustomers(){
  window.scroll(0, 0)
  this.router.navigate(['/about-customers']);
  this.closeNav();
}

aboutArtist(){
  window.scroll(0 ,0);
  this.router.navigate(['/about-artist']);
  this.closeNav();
}


  howwework() {
    window.scroll(0, 0);
    this.router.navigate(['/howwework']);
    this.closeNav();
  }

  contactus() {
    window.scroll(0, 0);
    this.router.navigate(['/contact']);
    this.closeNav();
  }

  search(service_name:any,gender:any) {
    this.router.navigate(['/search',{ queryParams: service_name, language: this.language, category: this.category_name, gender  }]);
    this.closeNav();
    // setTimeout(() => {
    //   {
    //     this.reloadPage();
    //   }
    // }, 10);
  }

  closeNav(): void {
    // Code to close the mobile navigation menu
    const sideNav = document.getElementById('mySidenav');
    if (sideNav) {
      sideNav.style.width = '0';
    }
  }

  policy() {
    window.scroll(0, 0);
    this.router.navigate(['/policy']);
    this.closeNav();
  }

  terms() {
    window.scroll(0, 0);
    this.router.navigate(['/terms']);
    this.closeNav();

  }

  myProfile() {
    if (this.roles.includes('ROLE_ARTIST')) {
      this.router.navigate(['/editprofile']);

    } else if (this.roles.includes('ROLE_CUSTOMER')) {
      this.router.navigate(['/customerdashboard']);

    } else {
      this.router.navigate(['/admin']);
      // this.router.navigate(['/user-password']);
    }
  }




// drop down here

status: boolean = false;

clickEvent() {
  this.status = !this.status;
}

@HostListener('document:click', ['$event'])
onDocumentClick(event: MouseEvent) {
  const dropdown = document.getElementById('drop_link'); 
  if (dropdown && !dropdown.contains(event.target as Node)) {
    this.status = false;
  }
}


dropacd: boolean = false;

mobClick() {
  this.dropacd = !this.dropacd;
}


language:any = null;
   category_name:any = null;
  searchpage(service_name:any,gender:any){
    if (service_name !== null) {
     
      this.router.navigate(['/search',{ queryParams: service_name, language: this.language, category: this.category_name, gender }]);
    } else {
      this.toastr.error('Please select atleast one filter !!', '');
    }
  }


}
