<app-loader *ngIf="loader"></app-loader>
<!-- Banner -->
<div class="dashboardbanner">
    <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
    <div class="bannerheading">Equipments & Experience</div>
</div>

<div class="fullwidth bg_purple">
    <!--bof: myrecording-->
    <div class="w1200">
        <div class="pointgrid">
            <div class="pointcols">
                <span><i class="active" routerLink="/editprofile"></i></span>
                <strong>Personal Info</strong>
            </div>
            <div class="pointcols">
                <span><i class="active" (click)=" reloadEditVoice()"></i></span>
                <strong>Voice Profile</strong>
            </div>
            <div class="pointcols">
                <span><i class="active" routerLink="/pricedelivery"></i></span>
                <strong>Price, Delivery time &amp; Availability</strong>
            </div>
            <div class="pointcols">
                <span><i (click)="reloadEqipExp()" class="active"></i></span>
                <strong>Equipments &amp; Experience</strong>
            </div>
            <div class="pointcols">
                <span><i routerLink="/voicesample"></i></span>
                <strong>Voice &amp; Sample</strong>
            </div>
        </div>
        <div class="wedget">
            <!-- bof:wedgetleft -->
            <app-sidebar></app-sidebar>
            <!-- bof:wedgetright -->
            <div class="wedgetright">

                <div class="wedgetform">

                    <!-- <h3>Equipments &amp; Experience</h3> -->
                    <!--bof:formsection-->
                    <div class="form1">
                        <form class="formpanel">
                            <div class="formwrap">
                                <label>Microphone used</label>
                                <p>If you are currently using one, mention it.</p>
                                <input type="text" [(ngModel)]="equipmentExperienceForm.microphoneUsed"
                                    [ngModelOptions]="{standalone: true}" maxlength="80">
                            </div>
                            <div class="formwrap">
                                <label>Recording/Editing Software</label>
                                <p>Do you edit/record your own material? What software do
                                    you employ?</p>
                                <input type="text" [(ngModel)]="equipmentExperienceForm.recordingEditing"
                                    [ngModelOptions]="{standalone: true}" maxlength="80">
                            </div>
                            <div class="formwrap">
                                <label>Special Equipment</label>
                                <p>Do you have any specialised equipment?</p>
                                <input type="text" [(ngModel)]="equipmentExperienceForm.socialEquipment"
                                    [ngModelOptions]="{standalone: true}" maxlength="80">
                            </div>
                            <div class="formwrap">
                                <label>Experience</label>
                                <p>How long have you been making recordings?</p>
                                <input type="text" [(ngModel)]="equipmentExperienceForm.experience"
                                    [ngModelOptions]="{standalone: true}" maxlength="80">
                            </div>

                            <div class="formwrap">
                                <label>Clients worked for </label>
                                <p>We'd like to know with whom you have worked in the past. </p>
                                <input type="text" [(ngModel)]="equipmentExperienceForm.clientWorkedFor"
                                    [ngModelOptions]="{standalone: true}" maxlength="80">
                            </div>

                            <div class="formwrap">
                                <label>Select Education </label>
                                <p>We understand that your artistic expression is all that matters!</p>
                                <select [(ngModel)]="equipmentExperienceForm.education"
                                    [ngModelOptions]="{standalone: true}">
                                    <option *ngFor="let data of educationList" [label]="data.education"
                                        [value]="data.education"></option>
                                </select>
                            </div>

                            <div class="formwrap" id="language1">
                                <label>Services Offered </label>
                                <p>What services exactly do you provide? You may select more than one.</p>
                                <select data-placeholder="Choose Services" class="form-control chosen-select"
                                [(ngModel)]="equipmentExperienceForm.serviceOffered" [ngModelOptions]="{standalone: true}"
                                  #serviceOffered="ngModel" multiple>
                                  <option *ngFor="let data of serviceOfferedList" [label]="data.serviceOffered"
                                  [value]="data.serviceOffered">{{data.serviceOffered}}
                                  </option>
                                </select>
                              </div>

                            <div class="formwrap">
                                <label>Social Media Profile (Facebook)</label>
                                <p>Please provide your facebook profile link.</p>
                                <input type="text" [(ngModel)]="equipmentExperienceForm.socialMediaLinkFB"
                                    [ngModelOptions]="{standalone: true}" maxlength="50">
                            </div>

                            <div class="formwrap">
                                <label>Social Media Profile (Twitter)</label>
                                <p>Please provide your twitter profile link.</p>
                                <input type="text" [(ngModel)]="equipmentExperienceForm.socialMediaLinkTwitter"
                                    [ngModelOptions]="{standalone: true}" maxlength="50">
                            </div>

                            <div class="formwrap">
                                <label>Social Media Profile (LinkedIn)</label>
                                <p>Please provide your linkedIn profile link.</p>
                                <input type="text" [(ngModel)]="equipmentExperienceForm.socialMediaLinkLinked"
                                    [ngModelOptions]="{standalone: true}" maxlength="50">
                            </div>

                            <div class="formwrapfull">

                                <div class="submitdiv">
                                    <input type="submit" value="Submit" (click)="equipmentExperience()">
                                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                                </div>
                            </div>
                        </form>
                        <!--eof:formsection-->

                    </div>
                    <!--eof: form-->
                </div>
            </div>
        </div>
        <!--eof: myrecording-->
    </div>
</div>