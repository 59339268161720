<!-- <app-loader *ngIf="loader"></app-loader> -->

<div class="dashboardbanner">
    <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
    <div class="bannerheading">Add Business Details</div>
</div>

<div class="fullwidth bg_purple">
    <!--bof: myrecording-->
    <div class="w1200">
        <div class="wedget">
            <app-sidebar></app-sidebar>
            <!-- bof:wedgetright -->
            <div class="wedgetright">
                <div class="wedgetform">
                    <!--bof:formsection-->
                    <div class="form1">
                        <!-- <h3 class="prof">Edit Profile</h3> -->
                        <form class="formpanel" name="form" (ngSubmit)="f.form.valid " #f="ngForm">
                            <div class="formwrap">
                                <label>Enter your GSTIN</label>
                                <input type="text" class="form-control"
                                    placeholder="Enter your GSTIN to claim your GST input" name="gstNumber"
                                    [(ngModel)]="addBusinessDetails.gstNumber" [ngModelOptions]="{standalone: true}"
                                    maxlength="15" #gstNumber="ngModel">
                            </div>
                            <div class="formwrap">
                                <label>Company Name </label>
                                <input type="text" class="form-control" placeholder="Company name"
                                    name="accountHolderName" [(ngModel)]="addBusinessDetails.companyName"
                                    [ngModelOptions]="{standalone: true}" maxlength="80" required
                                    #accountHolderName="ngModel">                                
                            </div>

                            <div class="formwrap">
                                <label>Company Address</label>
                                <input type="number" class="form-control" placeholder="Company Address"
                                    name="accountNumber" [(ngModel)]="addBusinessDetails.companyAddress"
                                    [ngModelOptions]="{standalone: true}"
                                    onKeyPress="if(this.value.length==20) return false;" required
                                    #accountNumber="ngModel">                                
                            </div>
                            <div class="formwrapfull">
                                <div class="submitdiv">
                                    <input type="submit" value="Save" (click)="updateBusinessDeatils()">
                                </div>
                                <!-- <strong>Items marked with <span>*</span> are mendatory</strong> -->
                            </div>

                        </form>
                    </div>
                    <!--eof:formsection-->
                </div>
            </div>
        </div>
    </div>
</div>