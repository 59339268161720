import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

//const API_URL = 'http://localhost:8080/api/test/';
const API_URL = 'https://api.pinnaklebiz.com/the-voice-mall/api/test/';
const UPLOAD_ARTIST_VOICE = "order-uploaded";
const GETUPLOADARTISTVOICE = "order-uploaded";
const ORDER_COMPLET_STATUS = "updateStatus";
const GET_ALL_ORDER_DETAILS = "getAllOrderDetails";
const GET_ALL_VOICE_SAMPLE = "voiceSample";
const GET_ALL_CONTACT_SAMPLE = "contactDetails";
const APPROVE_SAMPLE = "approveSample/";
const REVISION_COMMENTS = "revision-comments";
const GET_ALL_REVISED_VOICE_SAMPLE = "revisedSamples";
const APPROVE_REVISED_SAMPLE = "approveRevisedSample/";
const GET_ALL_REVISED_COMMENTS_ON_ORDER_ID = "revision-comments";
const GET_ALL_REVISED_COMMENTS = "all-revision-comments";
const APPROVE_REVISED_COMMENTS = "approve-revision-comments/";
const ADD_PAYMENT_DETAILS = "payment-details";
const FETCH_PAYMENT_DETAILS = "fetch-payment-details";
const FETCH_ALL_ARTIST_PAYMENT_DETAILS = "fetch-all-artist-payment-details";
const MAKE_ARTIST_PAYMENT = "make-artist-payment";
const GET_REVIEW_AND_RATING = "fetch-all-review-rating";
const REVIEW_AND_RATING = "review-rating";
const ACCEPT_REVIEW_AND_RATING = "update-review-rating-status/";

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
declare var Razorpay: any;

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  status: any;
  errorMessage: any;

  constructor(private http: HttpClient) { }

  createOrder(customerFinalPrice: any, username: any, email: any, contact: any, userId: any, customerId: any, referenceId: any): Observable<any> {
    return this.http.post(API_URL + 'orders', {
      customerName: username,
      email: email,
      phoneNumber: contact,
      amount: customerFinalPrice,
      artistId: userId,
      customerId: customerId,
      referenceId: referenceId
    }, httpOptions);
  }

  paymentDetails(artistId: any, artistName: any, address: any, panNumber: any, gstNumber: any, accountHolderName: any, accountNumber: any, bankName: any, ifscCode: any, branch: any, iban: any, swiftCode: any, country: any, city: any, branchAddress: any): Observable<any> {
    return this.http.post(API_URL + ADD_PAYMENT_DETAILS, {
      artistId: artistId,
      artistName: artistName,
      address: address,
      panNumber: panNumber,
      gstNumber: gstNumber,
      accountHolderName: accountHolderName,
      accountNumber: accountNumber,
      bankName: bankName,
      ifscCode: ifscCode,
      branch: branch,
      iban: iban,
      swiftCode: swiftCode,
      country: country,
      city: city,
      branchAddress: branchAddress
    }, httpOptions);
  }

  fetchArtistBankDetails(artistId: string) {
    return this.http.get(API_URL + FETCH_PAYMENT_DETAILS, {
      params: {
        artistId: artistId,
      },
    });
  }

  updateOrder(order: any): Observable<any> {
    return this.http.put(API_URL + 'orders', {
      razorpayOrderId: order.razorpay_order_id,
      razorpayPaymentId: order.razorpay_payment_id,
      razorpaySignature: order.razorpay_signature
    }, httpOptions);
  }

  uploadArtistVoice(uploadArtistVoiceForm: FormData): Observable<any> {
    return this.http.post(API_URL + UPLOAD_ARTIST_VOICE, uploadArtistVoiceForm);
  }

  //update order status
  orderComplete(referenceId: string, status: boolean): Observable<any> {
    return this.http.post(API_URL + ORDER_COMPLET_STATUS, {
      referenceId,
      status
    });
  }

  //Post review and rating 
  reviewAndRating(userRatingValue: any, referenceId: string, ratingComments: Text, artistId: string, customerId: string, ratingStatus: boolean): Observable<any> {
    return this.http.post(API_URL + REVIEW_AND_RATING, {
      userRatingValue,
      referenceId,
      ratingComments,
      artistId,
      customerId,
      ratingStatus
    });
  }

  revisionComments(referenceId: string, comments: Text, artistId: string, customerId: string, revisionStatus: boolean): Observable<any> {
    return this.http.post(API_URL + REVISION_COMMENTS, {
      referenceId,
      comments,
      artistId,
      customerId,
      revisionStatus
    });
  }

  getuploadArtistVoice(referenceId: string) {
    return this.http.get(API_URL + GETUPLOADARTISTVOICE, {
      params: {
        referenceId: referenceId,
      },
    });
  }

  getAllOrderDetails() {
    return this.http.get(API_URL + GET_ALL_ORDER_DETAILS, {
    });
  }

  getAlluploadArtistVoice() {
    return this.http.get(API_URL + GET_ALL_VOICE_SAMPLE, {
    });
  }
  getAllContactdetails() {
    return this.http.get(API_URL + GET_ALL_CONTACT_SAMPLE, {
    });
  }

  //update sample status
  approveSample(id: any): Observable<any> {
    return this.http.post(API_URL + APPROVE_SAMPLE + id, {
      id
    });
  }

  getAllRevisedVoicesample() {
    return this.http.get(API_URL + GET_ALL_REVISED_VOICE_SAMPLE, {
    });
  }

  //fetch all revised comments details on order id
  getAllRevisedCommentsOnOrderId(referenceId: string) {
    return this.http.get(API_URL + GET_ALL_REVISED_COMMENTS_ON_ORDER_ID, {
      params: {
        referenceId: referenceId,
      },
    });
  }

  //fetch revised comments details
  getAllRevisedComments() {
    return this.http.get(API_URL + GET_ALL_REVISED_COMMENTS, {
    });
  }

  //update revised comments details
  updateRevisedComments(id: any): Observable<any> {
    return this.http.post(API_URL + APPROVE_REVISED_COMMENTS + id, {
      id
    });
  }

  approveRevisedSample(id: any): Observable<any> {
    return this.http.post(API_URL + APPROVE_REVISED_SAMPLE + id, {
      id
    });
  }

  submitPaymentDetails(referenceId: string, paymentDetails: Text): Observable<any> {
    return this.http.post(API_URL + MAKE_ARTIST_PAYMENT, {
      referenceId: referenceId,
      paymentDetails: paymentDetails
    });
  }

  customerReviewRatingList() {
    return this.http.get(API_URL + GET_REVIEW_AND_RATING, {
    });
  }

  acceptReviewAndRating(id: any, ratingComments: Text) {
    return this.http.put(API_URL + ACCEPT_REVIEW_AND_RATING + id, {
      ratingComments: ratingComments
    });
  }

}
