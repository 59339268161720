import { Component, OnInit } from '@angular/core';
import { UserService } from '../_services/user.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-board-admin',
  templateUrl: './board-admin.component.html',
  styleUrls: ['./board-admin.component.css']
})
export class BoardAdminComponent implements OnInit {
  content?: string;
  Checkpermission:any;
  permission:any;
  pdata:any;
  pdata1:any[] = [];
  notify:any;

  constructor(private userService: UserService,private token: TokenStorageService,private toastr:ToastrService,private http:HttpClient,private router:Router) { }

  ngOnInit(): void {
    this.Notificatation();
    this.Checkpermission = this.token.getUser().permission;
    if(this.Checkpermission.permissions.length > 0){
      this.permission = this.Checkpermission.permissions;
      this.pdata1 = [];
         for(let i =  0; i < this.permission.length; i++){
            this.pdata1.push(this.permission[i].permission_name);
        }
 
     }
   
  
  }


  Notificatation() {
    try {
      this.userService.newNotifications().subscribe((response) => {
        if (response) {
          this.notify = response;
          this.notify[0].recording_new = 1;
          }
      },
      (error:HttpErrorResponse)=>{
        if(error.status == 400 || error.status !== null){
          this.toastr.error(error.error.message,'');
          console.log("Error in Notification API ", error);
        }
      }
      );
    } catch (error) {
      console.log('ERROR IN GET NOTIFY. ', error);
    }
  }


  
UpdateNotificationView(menu:any){
  try {
    this.userService.updateNotificationView(menu).subscribe((response)=>{
   if(response){
    console.log("notification status update successfully ! ",response);
    }
    },
    (error:HttpErrorResponse)=>{
      if(error.status == 400 || error.status !== null){
        this.toastr.error(error.error.message,'');
        console.log("ERROR IN UPDATE NOTIFI. STATUS", error);
      }
    }
    )
    
  } catch (error) {
    console.log("ERROR IN Update Notification View ", error);
  }
}



userdashboard() {
  this.UpdateNotificationView('customer');
  this.router.navigate(['/all-customer-details']);
}

artistdashboard() {
  this.UpdateNotificationView('artist');
  this.router.navigate(['/all-artist-details']);
}

recordingdashboard() {
  this.UpdateNotificationView('order');
  this.router.navigate(['/recording-request']);
}

sampledashboard() {
  this.UpdateNotificationView('sample');
  this.router.navigate(['/all-voice-samples']);
}

contactdashboard() {
  this.UpdateNotificationView('contact_us');
  this.router.navigate(['/all-user-contact-details']);
}



revisedComments() {
  this.UpdateNotificationView('revision_comment');
  this.router.navigate(['/revised-comments']);
 
}

reviewAndRating() {  
  this.UpdateNotificationView('review_rating');
  this.router.navigate(['/customer-review-rating']);
}
Artistpayment() {
  this.router.navigate(['/all-artist-payment-details']);
}
}
