import { Component, OnInit } from '@angular/core';
import { UserService } from '../_services/user.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
interface Permission {
  id: number;
  checked: boolean;
}

@Component({
  selector: 'app-edit-role',
  templateUrl: './edit-role.component.html',
  styleUrls: ['./edit-role.component.css'],
})
export class EditRoleComponent implements OnInit {
  loader: boolean = true;
  inputValue: any;
  AllPermissionsDetails: any;
  roleName: any[] = [];
  permissions: any[] = [];
  permissionIds: number[] = [];
  isChecked: boolean = false;
  RoleHide!: boolean;
  RoleError: any;
  PermissionsHide!: boolean;
  PermissionsError: any;
  roleId: any;
  AllPermissionsDetailsById: any;

  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private router: Router,
    private routepera: ActivatedRoute,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.routepera.params.subscribe((param) => {
      this.roleId = +param['id'];
    });
    this.loader = false;
    this.GetAllPermission();
    this.GetPermissionByRole();
  }

  EditRoleForm: any = {
    roleName: null,
    permissions: null,
  };

  // this is create for get role & permission data by role id
  GetPermissionByRole() {
    try {
      const selectAllCheckbox = document.getElementById(
        'selectAll'
      ) as HTMLInputElement;

      this.userService.GetRoleByID(this.roleId).subscribe((response) => {
        if (response) {
          this.AllPermissionsDetailsById = response;
          const RoleHasPermissions = this.AllPermissionsDetailsById.permissions;
          console.log(RoleHasPermissions);
          this.AllPermissionsDetails.forEach(
            (Permission: Permission) => {
              const FindPer = RoleHasPermissions.find(
                (p: Permission) => p.id === Permission.id
              );
              if (FindPer) {
                Permission.checked = true;
                console.log('role 1', this.AllPermissionsDetails.length);
                console.log('role ', RoleHasPermissions.length);
                if (
                  this.AllPermissionsDetails.length == RoleHasPermissions.length
                ) {
                  console.log('i am here');
                  selectAllCheckbox.checked = true;
                }
              }
            },
            (error: HttpErrorResponse) => {
              if (error.status == 400 || error.status !== null) {
                this.toastr.error(error.error.message,'');
              }
            }
          );
        } else {
          console.log('We can`t get permission data in api');
          this.toastr.error('Something went wrong', '');
        }
      });
    } catch (error) {
      console.log('Error', error);
    }
  }

  GetAllPermission() {
    try {
      this.userService.GetAllPermissions().subscribe((responsedata) => {
        if (responsedata) {
          this.AllPermissionsDetails = responsedata;
        } else {
          console.log('We can`t get permission data in api');
          this.toastr.error('Something went wrong', '');
        }
      },
      (error:HttpErrorResponse)=>{
        if(error.status ==  400 || error.status !== null){
          this.toastr.error(error.error.message,'');
        }
      }
      );
    } catch (error) {
      console.log('Error', error);
    }
  }

  selectAll() {
    const selectAllCheckbox = document.getElementById(
      'selectAll'
    ) as HTMLInputElement;
    const isChecked = selectAllCheckbox.checked;
    this.AllPermissionsDetails.forEach((Permission: Permission) => {
      Permission.checked = isChecked;
    });
    this.permissionIds = this.AllPermissionsDetails.filter(
      (Permission: Permission) => Permission.checked
    ).map((Permission: Permission) => Permission.id);
  }

  EditRoles(data: any) {
    if (
      this.permissionIds == null ||
      this.permissionIds == undefined ||
      this.permissionIds.length == 0
    ) {
      this.permissionIds = this.AllPermissionsDetails.filter(
        (Permission: Permission) => Permission.checked
      ).map((Permission: Permission) => Permission.id);
    }
    this.EditRoleForm.permissions = this.permissionIds;
    this.EditRoleForm.roleName = data.role;
    this.validate();
   
    if (
      this.EditRoleForm.roleName != undefined &&
      this.EditRoleForm.roleName !== null &&
      this.EditRoleForm.roleName !== '' &&
      this.EditRoleForm.permissions !== undefined &&
      this.EditRoleForm.permissions !== null &&
      this.EditRoleForm.permissions !== '' && this.EditRoleForm.permissions.length > 0
    ) {      
      const RoleData = {
        roleName: this.EditRoleForm.roleName,
        permissionIds: this.EditRoleForm.permissions,
        roleId: this.roleId,
      };
      this.userService.editRole(RoleData).subscribe((response) => {
        if (response) {
          this.toastr.success('Role Edit Successfully');
          setTimeout(() => {
             this.router.navigate(['view-roles']);
          }, 50);
        }
      },
      (error:HttpErrorResponse)=>{
        if(error.status == 400 || error.status !== null){
          this.toastr.error(error.error.message,'');
        }
      }
      );
    } else {
      this.toastr.error('Please Fill All Mandatory Fields');
    }
  }

  validate() {
    if (
      this.EditRoleForm.roleName == null ||
      this.EditRoleForm.roleName == '' ||
      this.EditRoleForm.roleName == undefined
    ) {
      this.RoleHide = true;
      this.RoleError = 'Role is mandatory';
    } else {
      this.RoleHide = false;
    }
    if (
      this.EditRoleForm.permissions == null ||
      this.EditRoleForm.permissions == '' ||
      this.EditRoleForm.permissions == undefined
    ) {
      this.PermissionsHide = true;
      this.PermissionsError = 'Permissions is mandatory';
    } else {
      this.PermissionsHide = false;
    }
  }
}
