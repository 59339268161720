import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from '../_services/token-storage.service';
import { UserService } from '../_services/user.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  content: any;
  roles: any;
  userName: any;
  selectedTab:string;
  popup = false;
  


  constructor(private token: TokenStorageService, private userService: UserService, private tokenStorage: TokenStorageService,
    private location: Location,
    private router: Router, private httpClient: HttpClient, private toastr: ToastrService,) {

      this.selectedTab = this.location.path();
      console.log(this.selectedTab);

     }

  ngOnInit(): void {
    this.currentUser = this.token.getUser();
    this.contact = this.token.getUser().contact;
    this.id = this.token.getUser().id;
    this.roles = this.tokenStorage.getUser().roles;
    this.viewImage();
    // this.getEditProfile();
  }

  currentUser: any;
  contact: any;
  id: any;
  customerBookingDetails: any;


  //for image upload
  uploadedImage: any;
  dbImage: any;
  profilePic: any;
  postResponse: any;
  successResponse: any;
  image: any;
  loader: boolean = true;
  default: any;

  editProfileForm: any = {
    dob: null,
    gender: null,
    address1: null,
    country: null,
    state: null,
    city: null,
    pinCode: null,
    timeZone: null,
    about: null,
    name: null
  };

  //Get EditProfile Details
  getEditProfile() {
    this.userService.getEditProfile(this.id).subscribe(data => {
      this.editProfileForm = data;
      if (this.editProfileForm !== null && this.editProfileForm !== "") {
        ((element: {
          dob: string, gender: string, address1: string, country: string,
          state: string, city: string, pinCode: string, timeZone: string, about: string, name: string, userId: string,
        }) => {
        });
      }
    },
      err => {
        console.log(err);
      }
    );
  }

  public onImageUpload(event: any) {
    this.image = event.target.files[0];
  }

  imageUploadAction() {
    const imageFormData = new FormData();
    imageFormData.append('image', this.image, this.image.name);
    if(this.image.size > 1000000){
      this.toastr.error('Image size should be upto 1 mb', '');
      this.loader = false;
    }else if(this.image.type === "image/jpeg" || this.image.type === "image/png"){
      imageFormData.append('userId', this.id);
      this.httpClient.post('https://api.pinnaklebiz.com/the-voice-mall/api/test/upload/image/', imageFormData, { observe: 'response' })
        .subscribe((response) => {
          if (response.status === 200) {
            this.postResponse = response;
            this.successResponse = this.postResponse.body.message;
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            this.successResponse = 'Image not uploaded due to some error!';
          }
        }
       );
    }else{
      this.toastr.error('File format should be .jpeg or .png', '');
    }
  }

  viewImage() {
    //this.httpClient.get('http://localhost:8080/api/test/image/info/' + this.image)
    this.httpClient.get('https://api.pinnaklebiz.com/the-voice-mall/api/test/image/info/' + this.id)
      .subscribe(
     
        res => {
          console.log(this.httpClient);
          this.postResponse = res;
          this.profilePic = this.postResponse.name;
        }
      );
  }

  editProfile() {
    window.scroll(0, 0);
    this.router.navigate(['/editprofile']);
  }

  allSample() {
    window.scroll(0, 0);
    this.router.navigate(['/all-sample']);

  }

  uploadSample() {
    window.scroll(0, 0);
    this.router.navigate(['/uploadsamples']);
  }


  paymentDashboard() {
    window.scroll(0, 0);
    this.router.navigate(['/artist-bank-details']);

  }

  artistDashboard() {
    window.scroll(0, 0);
    this.router.navigateByUrl('/', { skipLocationChange: false }).then(() => {
      this.router.navigate(['/artistdashboard']);
    });
    setTimeout(() => {
      window.location.reload();
    }, 5);
  }


  customerDashboard() {
    window.scroll(0, 0);
    this.router.navigate(['/customerdashboard']);
  }

  transactionHistory() {
    window.scroll(0, 0);
    this.router.navigate(['/transtion-history']);
  }

  paymentHistory() {
    window.scroll(0, 0);
    this.router.navigate(['/fetch-Project-Payment-Details']);
  }


  businessDeatils(){
    window.scroll(0, 0);
    this.router.navigate(['/business-deatils']);
  }

 


  getCustomerBookingDetails() {
    this.loader = true;
    this.userService.getCustomerBookingDetailsById(this.id).subscribe(data => {
      this.customerBookingDetails = data;
      if (this.customerBookingDetails !== null && this.customerBookingDetails !== "" && this.customerBookingDetails !== undefined) {
        this.loader = false;
        this.customerBookingDetails.forEach((element: {
        }) => {
        });
      }
    },
      err => {
        console.log(err);
      }
    );
  }
}
