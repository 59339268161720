<app-loader *ngIf="loader"></app-loader>
<div class="banner clientbanner">
    <img src="/assets/images/banner.jpg" alt="Banner">
    <div class="bannerheading">Artist Dashboard</div>
</div>

<div class="fullwidth bg_purple">
    <!--bof:artistrecording-->

    <!--bof: myrecording-->
    <div class="w1200">
        <div class="wedget">
            <!-- bof:wedgetleft -->
            <app-sidebar></app-sidebar>

            <!-- bof:wedgetright -->
            <div class="wedgetright">
                <!--artistrecording-->
                <div class="tab_content">
                    <h3>Artist Recording</h3>
                    <div class="artistrow">
                        <div class="cols"
                            *ngFor="let data of customerBookingDetails | paginate: { itemsPerPage: 12, currentPage: page }">
                            <div class="imgsection"><img src="/assets/images/imgnew.jpg" alt=""></div>
                            <div class="artistdetail">
                                <strong><i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                    VM{{data.customerId}}</strong>
                                <div class="noword">No. Words:  {{data.totalWord}}</div>
                                <div class="noword">Recording Prices: <span>₹</span> {{data.artistPrice}}</div>
                                <div class="noword">Order Id:  {{data.referenceId}}</div>
                                <div class="btngrp"
                                    *ngIf="data.status == false || data.status == null || data.status == undefined">
                                    <ul>
                                        <li><a class="ongoing">Ongoing</a></li>
                                        <li><a class="view" (click)="viewDetails(data.referenceId)">View</a></li>
                                    </ul>
                                </div>
                                <div class="btngrp" *ngIf="data.status == true">
                                    <ul>
                                        <li><a class="complete">Completed</a></li>
                                        <li><a class="view" (click)="viewDetails(data.referenceId)">View</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <h1 *ngIf="customerBookingDetails?.length == 0">No Data found !</h1>
                    </div>

                    <div class="paginations">
                        <pagination-controls (pageChange)="page = $event"></pagination-controls>
                    </div>

                </div>
                <!--artistrecording-->
            </div>
        </div>
    </div>
</div>
<!--eof: myrecording-->
