import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../_services/user.service';
import { Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { TokenStorageService } from '../_services/token-storage.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-price-delivery-time',
  templateUrl: './price-delivery-time.component.html',
  styleUrls: ['./price-delivery-time.component.css']
})
export class PriceDeliveryTimeComponent implements OnInit {
  name: any;
  fields: any;
  currentUser: any;
  contact: any;
  // categoryList: any;

  id: any;
  userId: any;
  content: any;
  country: any;
  loader: boolean = true;

  daySunday: boolean = true;
  dayMonday: boolean = true;
  dayTuesday: boolean = true;
  dayWednesday: boolean = true;
  dayThursday: boolean = true;
  dayFriday: boolean = true;
  daySaturday: boolean = true;

  userForm = new FormGroup({
   });


  Data: any = {
    value1: null,
    value2: null,
    value77: null,
    value78: null,
    value4: null,
    value5: null,
    value6: null,
    value7: null,
    value9: null,
    value10: null,
    value11: null,
    value12: null,
    value14: null,
    value15: null,
    value16: null,
    value17: null,
    value18: null,
    value19: null,
    value21: null,
    value22: null,
    value23: null,
    value24: null,
    value26: null,
    value27: null,
    value28: null,
    value29: null,
    value30: null,
    value31: null,
    value32: null,
    value33: null,
    value34: null,
    value35: null,
    value37: null,
    value38: null,
    value39: null,
    value40: null,
    value41: null,
    value42: null,
    value44: null,
    value45: null,
    value46: null,
    value47: null,
    value48: null,
    value49: null,
    value51: null,
    value52: null,
    value53: null,
    value54: null,
    value55: null,
    value56: null,
    value58: null,
    value59: null,
    value60: null,
    value61: null,
    value62: null,
    value64: null,
    value65: null,
    value66: null,
    value67: null,
    value68: null,
    value70: null,
    value71: null,
    value72: null,
    value73: null,
    value74: null,
    value75: null,
    value76: null
  }
  http: any;


  constructor(private userService: UserService, private toastr: ToastrService, private router: Router, private fb: FormBuilder, private token: TokenStorageService) { }

  ngOnInit() {
    this.loader = false;
    this.currentUser = this.token.getUser();
    this.contact = this.token.getUser().contact;
    this.id = this.token.getUser().id;
    this.country = this.token.getUser().country;
    this.getAvailability();
    this.getpriceDeliveryTime();
    this.loadScript();
    // this.getSelectedCategoryList();
    this.priceData();
    this.userForm.patchValue({
      price_data: this.fields.price_data.map((item: any) => ({ id: item.id, value: item.value }))
    });
    
  }

  priceData() {
    this.fields = {
      userId: this.id,      
      price_data: [
        {
          id: 'price_1',
          value: this.Data.value1
        },
        {
          id: 'price_2',
          value: this.Data.value2
        },
        {
          id: 'price_77',
          value: this.Data.value77
        },
        {
          id: 'price_78',
          value: this.Data.value78
        },
        {
          id: 'price_4',
          value: this.Data.value4
        },
        {
          id: 'price_5',
          value: this.Data.value5
        },
        {
          id: 'price_6',
          value: this.Data.value6
        },
        {
          id: 'price_7',
          value: this.Data.value7
        },
        {
          id: 'price_9',
          value: this.Data.value9
        },
        {
          id: 'price_10',
          value: this.Data.value10
        },
        {
          id: 'price_11',
          value: this.Data.value11
        },
        {
          id: 'price_12',
          value: this.Data.value12
        },

        {
          id: 'price_14',
          value: this.Data.value14
        },
        {
          id: 'price_15',
          value: this.Data.value15
        },
        {
          id: 'price_16',
          value: this.Data.value16
        },
        {
          id: 'price_17',
          value: this.Data.value17
        },
        {
          id: 'price_18',
          value: this.Data.value18
        },
        {
          id: 'price_19',
          value: this.Data.value19
        },

        {
          id: 'price_21',
          value: this.Data.value21
        },
        {
          id: 'price_22',
          value: this.Data.value22
        },
        {
          id: 'price_23',
          value: this.Data.value23
        },
        {
          id: 'price_24',
          value: this.Data.value24
        },

        {
          id: 'price_26',
          value: this.Data.value26
        },
        {
          id: 'price_27',
          value: this.Data.value27
        },
        {
          id: 'price_28',
          value: this.Data.value28
        },
        {
          id: 'price_29',
          value: this.Data.value29
        },
        {
          id: 'price_30',
          value: this.Data.value30
        },
        {
          id: 'price_31',
          value: this.Data.value31
        },
        {
          id: 'price_32',
          value: this.Data.value32
        },
        {
          id: 'price_33',
          value: this.Data.value33
        },
        {
          id: 'price_34',
          value: this.Data.value34
        },
        {
          id: 'price_35',
          value: this.Data.value35
        },

        {
          id: 'price_37',
          value: this.Data.value37
        },
        {
          id: 'price_38',
          value: this.Data.value38
        },
        {
          id: 'price_39',
          value: this.Data.value39
        },
        {
          id: 'price_40',
          value: this.Data.value40
        },
        {
          id: 'price_41',
          value: this.Data.value41
        },
        {
          id: 'price_42',
          value: this.Data.value42
        },

        {
          id: 'price_44',
          value: this.Data.value44
        },
        {
          id: 'price_45',
          value: this.Data.value45
        },
        {
          id: 'price_46',
          value: this.Data.value46
        },
        {
          id: 'price_47',
          value: this.Data.value47
        },
        {
          id: 'price_48',
          value: this.Data.value48
        },
        {
          id: 'price_49',
          value: this.Data.value49
        },

        {
          id: 'price_51',
          value: this.Data.value51
        },
        {
          id: 'price_52',
          value: this.Data.value52
        },
        {
          id: 'price_53',
          value: this.Data.value53
        },
        {
          id: 'price_54',
          value: this.Data.value54
        },
        {
          id: 'price_55',
          value: this.Data.value55
        },
        {
          id: 'price_56',
          value: this.Data.value56
        },

        {
          id: 'price_58',
          value: this.Data.value58
        },
        {
          id: 'price_59',
          value: this.Data.value59
        },
        {
          id: 'price_60',
          value: this.Data.value60
        },
        {
          id: 'price_61',
          value: this.Data.value61
        },
        {
          id: 'price_62',
          value: this.Data.value62
        },

        {
          id: 'price_64',
          value: this.Data.value64
        },
        {
          id: 'price_65',
          value: this.Data.value65
        },
        {
          id: 'price_66',
          value: this.Data.value66
        },
        {
          id: 'price_67',
          value: this.Data.value67
        },
        {
          id: 'price_68',
          value: this.Data.value68
        },

        {
          id: 'price_70',
          value: this.Data.value70
        },
        {
          id: 'price_71',
          value: this.Data.value71
        },
        {
          id: 'price_72',
          value: this.Data.value72
        },
        {
          id: 'price_73',
          value: this.Data.value73
        },
        {
          id: 'price_74',
          value: this.Data.value74
        },
        {
          id: 'price_75',
          value: this.Data.value75
        },
        {
          id: 'price_76',
          value: this.Data.value76
        }

      ]

    };
    this.userForm = this.fb.group({
      price_data: this.fb.array([])
    });
    this.patch()
    this.priceDeliveryTime()
  }
  submit(value: any) {
  }

  patch() {
    const control = <FormArray>this.userForm.get('price_data');
    this.fields.price_data.forEach((x: any) => {
      control.push(this.patchValues(x.id, x.value))
    })
  }

  
  patchValues(id: any, value: any) {
    return this.fb.group({
      id: [id],
      value: [value]
    })
  }

  priceDeliveryTimeForm: any = {
    value: null,
    status: null
  };

  priceDeliveryTime(): void {
    // this is for Turn around Time input required and not submit form 
    if(this.Data.value76 == 0 ||  this.Data.value76 =='' ){        
      this.toastr.error('Please fill in the Price/Turn around Time option', '');      
      this.router.navigate(['/pricedelivery']);
      return;
    }
    this.userService.priceDeliveryTime(this.fields, this.userId = this.id).subscribe();
    if (this.Data.value1 !== null && this.Data.value1 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value2 !== null && this.Data.value2 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value77 !== null && this.Data.value77 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value4 !== null && this.Data.value4 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value5 !== null && this.Data.value5 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value6 !== null && this.Data.value6 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value9 !== null && this.Data.value9 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value10 !== null && this.Data.value10 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value11 !== null && this.Data.value11 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value12 !== null && this.Data.value12 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value14 !== null && this.Data.value14 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value15 !== null && this.Data.value15 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value16 !== null && this.Data.value16 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value21 !== null && this.Data.value21 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value22 !== null && this.Data.value22 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value23 !== null && this.Data.value23 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value24 !== null && this.Data.value24 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value31 !== null && this.Data.value31 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value32 !== null && this.Data.value32 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value33 !== null && this.Data.value33 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value34 !== null && this.Data.value34 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value37 !== null && this.Data.value37 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value38 !== null && this.Data.value38 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value39 !== null && this.Data.value39 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value40 !== null && this.Data.value40 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value41 !== null && this.Data.value41 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value42 !== null && this.Data.value42 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value44 !== null && this.Data.value44 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value45 !== null && this.Data.value45 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value46 !== null && this.Data.value46 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value47 !== null && this.Data.value47 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value48 !== null && this.Data.value48 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value51 !== null && this.Data.value51 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value52 !== null && this.Data.value52 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value53 !== null && this.Data.value53 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value54 !== null && this.Data.value54 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value55 !== null && this.Data.value55 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value58 !== null && this.Data.value58 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value59 !== null && this.Data.value59 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value60 !== null && this.Data.value60 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value61 !== null && this.Data.value61 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value64 !== null && this.Data.value64 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value65 !== null && this.Data.value65 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value66 !== null && this.Data.value66 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value67 !== null && this.Data.value67 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value70 !== null && this.Data.value70 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value71 !== null && this.Data.value71 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined ||
      this.Data.value72 !== null && this.Data.value72 !== undefined && this.Data.value76 !== null && this.Data.value76 !== undefined) {
      window.scroll(0, 0);
      this.availability();
      this.router.navigate(['/equipment']);
       
    } 
    // else {
    //   this.toastr.error('Please fill in the Price/Turn around Time option', '');
    // }
  }

  //Get priceDeliveryTime Details
  getpriceDeliveryTime() {
    this.userService.getpriceDeliveryTime(this.userId = this.id).subscribe(data => {
      this.Data = data;
      if (this.Data !== null && this.Data !== "") {
        ((element: { value1: string, value2: string }) => {
        });
      }
    },
      err => {
        console.log(err);
      }
    );
  }



  availability(): void {
 
     console.log('Days before API call:', this.daySunday, this.dayMonday, this.dayTuesday, this.dayWednesday, this.dayThursday, this.dayFriday, this.daySaturday);

    this.userService.availability(this.daySunday, this.dayMonday, this.dayTuesday, this.dayWednesday, this.dayThursday,
      this.dayFriday, this.daySaturday, this.userId).subscribe(
        data => {
          
          console.log('API response:', data);

          if (data !== null && data !== "") {
            this.toastr.success('Price, Delivery time & Availability have been updated successfully', '');
          }
        },
        err => {
          this.toastr.error('Something went wrong..', '');
          console.log(err);
        }
      );
  }

  //Get Availability Details
  getAvailability() {
    this.userService.getAvailability(this.userId = this.id).subscribe(data => {
      this.dayMonday = data['dayMonday'];
      this.dayFriday = data['dayFriday'];
      this.daySaturday = data['daySaturday'];
      this.daySunday = data['daySunday'];
      this.dayThursday = data['dayThursday'];
      this.dayTuesday = data['dayTuesday'];
      this.dayWednesday = data['dayWednesday'];
    },
      err => {
        console.log(err);
      }
    );
  }

  public loadScript() {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = '/assets/js/dropdown.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }
  ErrorMessag:any = '';
  numberOnly(event: any): boolean {      
    if(this.Data.value76 == 0 ||  this.Data.value76 =='' ){
      this.ErrorMessag = "Turn around Time is required";    
      // this.toastr.error('Please fill Turn around Time(in hours)','');       
    }else{
      this.ErrorMessag = "";           

    }
    const charCode = (event.which) ? event.which : event.keyCode;    
    if (charCode >= 48 && charCode <= 57) {
      return true;
    } else {
      return false;
    }

    /* if (charCode > 31 && (charCode < 48 || charCode > 57)) {
       return false;
     }
     return true;*/

  }

  reloadEditVoice() {
    this.router.navigate(['/editvoice']);
  }

  reloadEqipExp() {
    // window.scroll(0, 0);
    // this.router.navigateByUrl('/', { skipLocationChange: false }).then(() => {
    //   this.router.navigate(['/equipment']);
    // });
    // setTimeout(() => {
    //   window.location.reload();
    // }, 10);
    this.router.navigate(['/equipment']);
  }



  // getProfile(): Observable<any> {
  //   return this.http.get('<https://api.pinnaklebiz.com/thevoicemall/profile/>')
  //     .pipe(
  //       catchError((error: HttpErrorResponse) => {
  //         console.error(error);
  //         return throwError('Something went wrong; please try again later.');
  //       })
  //     );
  // }

  

  // this is for selected category data 
 

  
  //get selecting category 
  //  getSelectedCategoryList() {
  //   this.userService.getprofileDetails(this.userId).subscribe(data => {
  //     this.categoryList = data;
  //      if (this.categoryList !== undefined) {        
  //       this.categoryList = data.categoryList;         
  //       }
  //   },
  //     err => {
  //       this.categoryList = JSON.parse(err.error).message;
  //     }
  //   );
  // }


  
}
