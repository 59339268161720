import { Component, OnInit } from '@angular/core';
import { UserService } from '../_services/user.service';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from '../_services/token-storage.service';
import { AuthService } from '../_services/auth.service';

@Component({
  selector: 'app-update-user-password',
  templateUrl: './update-user-password.component.html',
  styleUrls: ['./update-user-password.component.css']
})
export class UpdateUserPasswordComponent implements OnInit {
  CurrentPassswordHide!: boolean;
  CurrentPassswordError: any = '';
  NewPasswordHide!: boolean;
  NewPasswordError: any = '';
  ConfirmPasswordHide!: boolean;
  ConfirmPasswordError: any = '';
  Email:any;
 
  constructor(private userService:UserService, private toastr:ToastrService,private http:HttpClient,private token:TokenStorageService ,private authService:AuthService) { }

  ngOnInit(): void {
  }

  PasswordData: any = {
    CPassword: null,
    Password: null,
    ConfirmPassword: null,    
  };


   
changePassword(){
  try {
  this.PasswordValidation();
    const UData = this.token.getUser();
    this.Email = UData.email;   
      

     if(this.PasswordData.CPassword !== null && this.PasswordData.CPassword !== '' && this.PasswordData.Password !== null && this.PasswordData.Password !== '' && this.Email !== null && this.Email !== undefined && this.Email !== ''){
      
      if(this.PasswordValidation() == "true"){
        const Pdata = {
          currentPassword : this.PasswordData.CPassword,
          password: this.PasswordData.Password,    
          email: this.Email
        };
        this.authService.ChangeUserPassword(Pdata).subscribe((response)=>{
          if(response){
            this.toastr.success("Password Update Successfully !");
            this.PasswordData.CPassword = '';
            this.PasswordData.Password = '';
            this.PasswordData.ConfirmPassword = '';

          }
        },
        (error:HttpErrorResponse)=>{
          if(error.status == 400 || error.status !== null){
            this.toastr.error(error.error.message);
            console.log("Error in Update Password" ,error);
          }
        }
        )
      }else{
      this.toastr.error("Please check all condtions and errors !");
      }      
    }else{
      this.toastr.error("Please fill all mandatory fields !");
    }
  } catch (error) {
    console.log("Error in update user password",error);
  }
}

PasswordValidation(){  
  if(this.PasswordData.CPassword == null || this.PasswordData.CPassword == undefined || this.PasswordData.CPassword == '' ){
    this.CurrentPassswordHide = true;
    this.CurrentPassswordError = "Current Password is mandatory !";
  }else{
    this.CurrentPassswordHide = false;
  }

  if(this.PasswordData.Password == null || this.PasswordData.Password == undefined || this.PasswordData.Password == '' ){
    this.NewPasswordHide = true;
    this.NewPasswordError = "New Password is mandatory !";
  }else{
    this.NewPasswordHide = false;
    if(this.PasswordData.CPassword == this.PasswordData.Password){
      this.NewPasswordHide = true;
      this.NewPasswordError = "Old password and new password cannot be same !";
    }
  }
  if(this.PasswordData.ConfirmPassword == null || this.PasswordData.ConfirmPassword == undefined || this.PasswordData.ConfirmPassword == '' ){ 
     this.ConfirmPasswordHide = true;
    this.ConfirmPasswordError = "Confirm Password is mandatory !";   
  }else{
    this.ConfirmPasswordHide = false;
    if(this.PasswordData.Password !== this.PasswordData.ConfirmPassword){
      this.ConfirmPasswordHide = true;
      this.ConfirmPasswordError = "Confirm password does not match !";
    }
  }

if(this.ConfirmPasswordHide  == false && this.NewPasswordHide == false && this.CurrentPassswordHide == false){
  return "true";
}else{
  return "false";
}

}


}
