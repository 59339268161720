<app-loader *ngIf="loader"></app-loader>

<div id="maincontainer">
    <div class="dashboardbanner">
        <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
        <div class="bannerheading">
          Edit Role
        </div>
          </div>
          <div class="fullwidth bg_purple">
            <div class="w1200">
          <div class="dashboardwedget">
              <app-admin-sidebar></app-admin-sidebar>
              <div class="wedgetright">
                  <h3>Roles</h3>
                  <div class="dashwed">
                      <ul class="homelink">
                          <li><a   class="active" routerLink="/view-roles"><i class="fa fa-home fa-2x dashboardicon" aria-hidden="true"></i></a></li>
                          <li><a routerLink="/view-roles">Edit Role</a></li>
                          
                      </ul>
                  </div>
                  <div class="artistboard">
                     
                        <div class="wedgetform">
                          <!--bof:formsection-->
                          <div class="form1">
                            <!-- <h3 class="prof">Edit Profile</h3> -->
                            <form class="formpanel" name="form" #editrole="ngForm"  (ngSubmit)="EditRoles(editrole.value)">
                         <div class=" ">
                            <div class="formwrap">
                                <label>Role Name <span style="color: red;">*</span></label>
                                <input type="text"  name="role"  [value]="AllPermissionsDetailsById.name"   value="{{ AllPermissionsDetailsById.name }}"   [(ngModel)]="AllPermissionsDetailsById.name" required [maxlength]="30" >
                                
                                  <div class="alert"  style="color: red;" *ngIf="RoleHide">{{RoleError}}</div>
                              </div>
                              <h3>Permissions <span style="color: red;">*</span></h3>
                                   <input type="checkbox" class="inputclass"  id="selectAll" (change)="selectAll()" (click)="selectAll()" style="margin-left: 11px;">  <label  class="inputlabel">Select All</label>                  
                              <div class="main" id="permis" >
                               <div class="col1" *ngFor="let Permission of AllPermissionsDetails; let i = index">                               
                                <input type="checkbox"   class="inputclass getvalue"   [value]="Permission.id"     [(ngModel)]= "Permission.checked"      name="permissions[{{ Permission.id }}]"     >
                                <label class="inputlabel">{{ Permission.permission_display_name }}</label>
                               </div>  

                              </div>
                              <div class="alert" style="color: red;" *ngIf="PermissionsHide">  <br> {{PermissionsError}}</div>
                              
                               <br> <br>
                              <div class="formwrapfull">
                                <div class="submitdiv">
                                  <input type="submit" value="Edit">
                                  <i class="fa fa-angle-right" aria-hidden="true"></i>
                                </div><br>
                                <strong>Items marked with <span>*</span> are mandatory</strong>
                              </div>
                            </div>
                
                            </form>
                          


                          <!--eof:formsection-->
                        </div>
                      </div>
</div>