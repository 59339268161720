<div id="maincontainer">
    <!-- Banner -->
    <div class="dashboardbanner">
        <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
        <div class="bannerheading">Order Revised Comments</div>
    </div>

    <div class="fullwidth bg_purple">

        <!--bof: myrecording-->
        <div class="w1200">
            <div class="dashboardwedget">
                <app-admin-sidebar></app-admin-sidebar>
                <!-- bof:wedgetright -->
                <div class="wedgetright">
                    <h3>Order Revised Comments</h3>
                    <div class="dashwed">
                        <ul class="homelink">
                            <li><a class="active" routerLink="/revised-comments"><i
                                        class="fa fa-home fa-2x dashboardicon" aria-hidden="true"></i></a></li>
                            <li><a routerLink="/revised-samples">Order Revised Comments</a></li>
                        </ul>
                    </div>
                    <div class="artistboard">
                        <div class="searchrow">
                            <!-- <div class="pagesdroplist">
                                <span>Show</span>
                                <select>
                                    <option>25</option>
                                    <option>50</option>
                                    <option>75</option>
                                    <option>100</option>
                                </select>
                                <span>entries</span>
                            </div> -->
                            <div class="pagesdroplist">
                                <span>Total Comments : {{ NoOfComments }}</span>
                            </div>
                            <div class="searchinput">
                                <input type="text" id="myInput" [(ngModel)]="searchParam"
                                    placeholder="Search"></div>
                        </div>

                        <!--boftablestructure  -->
                        <div class="atrtable">
                            <div class="row">
                                <div class="col"><span class="first1">Artist Id</span></div>
                                <div class="col"><span class="first2">Customer Id</span></div>
                                <div class="col"><span class="first2">Artist Name </span></div>
                                <div class="col"><span class="first2">Customer Name </span></div>
                                <div class="col"><span class="first2">Artist Contact </span></div>
                                <div class="col"><span class="first2">Customer Contact </span></div>
                                <div class="col"><span class="three9">Comments</span></div>
                                <div class="col"><span class="first2">Order Id</span></div>
                                <div class="col"><span class="first2">Comment Date </span></div>
                                <div class="col"><span class="first3">Status</span></div>
                                <div class="col"><span class="three9" style="width: 123px  !important;">Operation </span></div>
                            </div>

                            <div id="myList"
                                *ngFor="let data of allArtistDetailsfiltered | filter:searchParam | paginate: { itemsPerPage: 10, currentPage: page }">
                                <div class="row">
                                    <div class="col"><span class="first1"
                                            style="white-space: pre-wrap;">VM{{data.artistId}}</span></div>
                                    <div class="col"><span class="first2"
                                            style="white-space: pre-wrap;">VM{{data.customerId}}</span></div>
                                    <div class="col"><span class="first2"
                                            style="white-space: pre-wrap;">{{data.artistName}}</span></div>
                                    <div class="col"><span class="first2"
                                            style="white-space: pre-wrap;">{{data.customerName}}</span></div>
                                    <div class="col"><span class="first2"
                                            style="white-space: pre-wrap;">{{data.artistContact}}</span></div>
                                    <div class="col"><span class="first2"
                                            style="white-space: pre-wrap;">{{data.customerContact}}</span></div>
                                    <div class="col"><span class="three9"
                                            style="white-space: pre-wrap;">{{data.comments}}</span></div>
                                    <div class="col"><span class="first2"
                                            style="white-space: pre-wrap;">{{data.referenceId}}</span></div>
                                    <div class="col"><span class="first2"
                                            style="white-space: pre-wrap;">{{data.commentDate | date}} </span></div>
                                            <div class="col" *ngIf="data.revisionStatus == true">
                                                <span class="first3" style="white-space: pre-wrap; color: green;">Approved</span>
                                            </div>
                                            <div class="col" *ngIf="data.revisionStatus == false || data.revisionStatus == null">
                                                <span class="first3" style="white-space: pre-wrap; color: red;">Pending</span>
                                            </div>    
                                    <div class="col"><span class="three9" style="white-space: pre-wrap;width: 123px  !important;">
                                            <a class="activebtn"
                                                *ngIf="data.revisionStatus === false || data.revisionStatus === null"
                                                (click)="approveRevisedComments(data)"><i>&#10004;</i> Accept</a>

                                                <a style="white-space: pre-wrap; color: green;" *ngIf="data.revisionStatus === true">NA</a>

                                            <!-- <a class="rejectbtn" (click)="rejectSample(data)"><i >&#10006;</i> Reject</a> -->
                                        </span></div>
                                </div>
                            </div>

                        </div>

                        <!-- <div class="paginations">
                            <pagination-controls (pageChange)="page = $event"></pagination-controls>
                        </div> -->
                        <div class="paginations">
                            <div class="custom-pagination-wrapper">
                                <button class="pagination-button margincorrect" [ngClass]="{ 'active': isFirstPage() }" (click)="goToFirstPage()">First</button>                    
                                <pagination-controls (pageChange)="onPageChange($event)" ></pagination-controls>                                                                        
                                <button class="pagination-button margincorrect" [ngClass]="{ 'lastactive': isLastPage(), 'lastdeactive': !isLastPage() }" (click)="goToLastPage()">Last</button>
                            </div>    
                        </div>


                    </div>
                </div>
            </div>
        </div>
        <!--eof: myrecording-->

    </div>
</div>