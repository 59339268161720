import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { BoardAdminComponent } from './board-admin/board-admin.component';
import { BoardModeratorComponent } from './board-moderator/board-moderator.component';
import { BoardUserComponent } from './board-user/board-user.component';
import { authInterceptorProviders } from './_helpers/auth.interceptor';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { FaqComponent } from './faq/faq.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { EditVoiceProfileComponent } from './edit-voice-profile/edit-voice-profile.component';
import { PriceDeliveryTimeComponent } from './price-delivery-time/price-delivery-time.component';
import { EquipmentExperienceComponent } from './equipment-experience/equipment-experience.component';
import { ThankYouMessageComponent } from './thank-you-message/thank-you-message.component';
import { TermsComponent } from './terms/terms.component';
import { PolicyComponent } from './policy/policy.component';
import { HowWeWorkComponent } from './how-we-work/how-we-work.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { VoiceSampleComponent } from './voice-sample/voice-sample.component';
import { SearchComponent } from './search/search.component';
import { ArtistProfileComponent } from './artist-profile/artist-profile.component';
import { PaymentReviewComponent } from './payment-review/payment-review.component';
import { PaymentFinalComponent } from './payment-final/payment-final.component';
import { UploadSamplesComponent } from './upload-samples/upload-samples.component';
import { CustomerDashboardComponent } from './customer-dashboard/customer-dashboard.component';
import { LoaderComponent } from './loader/loader.component';
import { TranstionHistoryComponent } from './transtion-history/transtion-history.component';
import { ClientBookingDetailsComponent } from './client-booking-details/client-booking-details.component';
import { ArtistBookingDetailsComponent } from './artist-booking-details/artist-booking-details.component';
import { ArtistDashboardComponent } from './artist-dashboard/artist-dashboard.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AllCustomerDetailsComponent } from './all-customer-details/all-customer-details.component';
import { AllArtistDetailsComponent } from './all-artist-details/all-artist-details.component';
import { AdminSidebarComponent } from './admin-sidebar/admin-sidebar.component';
import { RecordingRequestComponent } from './recording-request/recording-request.component';
import { AllVoiceSamplesComponent } from './all-voice-samples/all-voice-samples.component';
import { AllUserContactDetailsComponent } from './all-user-contact-details/all-user-contact-details.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { SampleDetailsComponent } from './sample-details/sample-details.component';
import { RevisedVoiceSamplesComponent } from './revised-voice-samples/revised-voice-samples.component';
import { RevisionCommentsComponent } from './revision-comments/revision-comments.component';
import { ArtistBankDetailsComponent } from './artist-bank-details/artist-bank-details.component';
import { AllArtistPaymentDetailsComponent } from './all-artist-payment-details/all-artist-payment-details.component';
import { ArtistPaymentHistoryComponent } from './artist-payment-history/artist-payment-history.component';
import { CustomerReviewRatingComponent } from './customer-review-rating/customer-review-rating.component';
import { ViewRolesComponent } from './view-roles/view-roles.component';
import {MatIconModule} from '@angular/material/icon';
 import {MatButtonModule} from '@angular/material/button';
import { AddRoleComponent } from './add-role/add-role.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { EditRoleComponent } from './edit-role/edit-role.component';
import { UsersComponent } from './users/users.component';
import { AddUserComponent } from './add-user/add-user.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { UpdateUserPasswordComponent } from './update-user-password/update-user-password.component';
import { UserEngagementComponent } from './user-engagement/user-engagement.component';
import { BulkEmailComponent } from './bulk-email/bulk-email.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { RevenueReportComponent } from './revenue-report/revenue-report.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { AboutCustomersComponent } from './about-customers/about-customers.component';
import { AboutArtistComponent } from './about-artist/about-artist.component';
import { RegisterPageComponent } from './register-page/register-page.component';
import { ArtistRegisterComponent } from './artist-register/artist-register.component';
import { BusinessDeatilsComponent } from './business-deatils/business-deatils.component';
     
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    ProfileComponent,
    BoardAdminComponent,
    BoardModeratorComponent,
    BoardUserComponent,
    HeaderComponent,
    FooterComponent,
    AboutComponent,
    ContactComponent,
    FaqComponent,
    EditProfileComponent,
    EditVoiceProfileComponent,
    PriceDeliveryTimeComponent,
    EquipmentExperienceComponent,
    ThankYouMessageComponent,
    TermsComponent,
    PolicyComponent,
    HowWeWorkComponent,
    DisclaimerComponent,
    SidebarComponent,
    VoiceSampleComponent,
    SearchComponent,
    ArtistProfileComponent,
    PaymentReviewComponent,
    PaymentFinalComponent,
    UploadSamplesComponent,
    CustomerDashboardComponent,
    LoaderComponent,
    TranstionHistoryComponent,
    ClientBookingDetailsComponent,
    ArtistBookingDetailsComponent,
    ArtistDashboardComponent,
    ChangePasswordComponent,
    AllCustomerDetailsComponent,
    AllArtistDetailsComponent,
    AdminSidebarComponent,
    RecordingRequestComponent,
    AllVoiceSamplesComponent,
    AllUserContactDetailsComponent,
    SampleDetailsComponent,
    RevisedVoiceSamplesComponent,
    RevisionCommentsComponent,
    ArtistBankDetailsComponent,
    AllArtistPaymentDetailsComponent,
    ArtistPaymentHistoryComponent,
    CustomerReviewRatingComponent,
    ViewRolesComponent,
    AddRoleComponent,
    EditRoleComponent,
    UsersComponent,
    AddUserComponent,
    EditUserComponent,
    UpdateUserPasswordComponent,
    UserEngagementComponent,
    BulkEmailComponent,
    RevenueReportComponent,
    AboutCustomersComponent,
    AboutArtistComponent,
    RegisterPageComponent,
    ArtistRegisterComponent,
    BusinessDeatilsComponent,
  ],
  imports: [
     BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatSelectModule,
    ToastrModule.forRoot(),
    NgxPaginationModule,
    Ng2SearchPipeModule,
    MatIconModule,
    // MatDividerModule,
    MatButtonModule,
    MatCheckboxModule,
    CKEditorModule,
    MatDatepickerModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    MatInputModule,
 
  ],
  providers: [authInterceptorProviders],
  bootstrap: [AppComponent] 
})
export class AppModule { }
