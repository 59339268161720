<div id="maincontainer">
    <!-- Banner -->
    <div class="dashboardbanner">
        <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
        <div class="bannerheading">Projects Conversation</div>
    </div>

    <div class="fullwidth bg_purple">
        <!--bof: myrecording-->
        <div class="w1200">
            <div class="dashboardwedget">
                <app-admin-sidebar></app-admin-sidebar>
                <!-- bof:wedgetright -->
                <div class="wedgetright">
                    <h3>Conversation</h3>
                    <div class="dashwed">
                        <ul class="homelink">
                            <li><a class="active" routerLink="/users-chat"><i class="fa fa-home fa-2x dashboardicon"
                                        aria-hidden="true"></i></a></li>
                            <li><a routerLink="/users-chat">Conversation</a></li>
                        </ul>
                    </div>
                    <div class="artistboard">
                        <div class="searchrow">
                            <!-- <div class="pagesdroplist">
                                <span>Show</span>
                                <select>
                                    <option>25</option>
                                    <option>50</option>
                                    <option>75</option>
                                    <option>100</option>
                                </select>
                                <span>entries</span>
                            </div> -->
                            <div class="pagesdroplist">
                                <span>Total Users Chat: {{ totChatrequest }} </span>
                            </div>
                            <div class="searchinput"><input type="text" id="myInput" [(ngModel)]="searchParam"
                                    placeholder="Search"></div>
                        </div>

                        <!--boftablestructure  -->
                        <div class="atrtable">
                            <div class="row">
                                <div class="col"><span class="first1 centerClass" style="width: 33px  !important;">S
                                        No</span></div>
                                <div class="col"><span class="first2 centerClass"
                                        style="width: 89px !important;">Customer Id</span>
                                </div>
                                <div class="col"><span class="first3 centerClass" style="width: 81px !important;">Artist
                                        Id</span>
                                </div>
                                <div class="col"><span class="first4 centerClass" style="width: 123px !important;">
                                        Order Id</span>
                                </div>
                                <div class="col"><span class="first4 centerClass" style="width: 136px !important;">
                                        Order Date</span>
                                </div>
                                <div class="col"><span class="first3 centerClass"
                                        style="width: 81px !important;">Message From</span>
                                </div>
                                <div class="col"><span class="first3 centerClass"
                                        style="width: 161px !important;">Message</span>
                                </div>
                                <div class="col"><span class="first3 centerClass"
                                        style="width: 161px !important;">Message Date</span>
                                </div>
                                <div class="col"><span class="first3 centerClass"
                                        style="width: 77px !important;">Status</span>
                                </div>
                                <div class="col"><span class="first3 centerClass"
                                        style="width: 375px    !important;">Operation</span></div>

                            </div>
                            <div id="myList"
                                *ngFor="let chatData of AllUsersChatFilterdRequest | paginate : {itemsPerPage:10, currentPage:page}; let i = index"
                                [attr.data-index]="i+(page-1)*10+1">
                                <div class="row">
                                    <div class="col"><span class="first1 centerClass"
                                            style="width: 33px  !important;">{{
                                            i+(page-1)*10+1 }}</span></div>
                                    <div class="col"><span class="first2 centerClass"
                                            style="width: 89px !important;">VM{{
                                            chatData.customer_id }}</span></div>
                                    <div class="col"><span class="first3 centerClass"
                                            style="width: 81px !important;">VM{{
                                            chatData.artist_id }}</span></div>
                                    <div class="col"><span class="first4 centerClass"
                                            style="width: 123px !important;">{{ chatData.order_id }}</span></div>
                                    <div class="col"><span class="first4 centerClass"
                                            style="width: 136px !important;">{{ chatData.order_date }}</span></div>
                                    <div class="col"><span class="first4 centerClass" style="width: 81px !important;">{{
                                            chatData.user_type }}</span></div>

                                    <div class="col" *ngIf="chatData.status == 1 || chatData.status == 2"><textarea
                                            class="three9" readonly
                                            style="white-space: pre-wrap;width: 161px !important;padding-left: 6px; vertical-align: middle;     "
                                            [(ngModel)]="chatData.message"
                                            [ngModelOptions]="{standalone:true}">{{   chatData.message }}</textarea>
                                    </div>
                                    <div class="col" *ngIf="chatData.status != 1 && chatData.status != 2"><textarea
                                            class="three9"
                                            style="white-space: pre-wrap;width: 161px !important;padding-left: 6px;vertical-align: middle"
                                            [(ngModel)]="chatData.message"
                                            [ngModelOptions]="{standalone:true}">{{   chatData.message }}</textarea>
                                    </div>

                                    <div class="col"><span class="first4" style="width: 161px !important;">{{
                                            chatData.date_time }}</span></div>
                                    <div class="col">
                                        <span class="three9 centerClass" *ngIf="chatData.status == 1"
                                            style="white-space: pre-wrap;width: 77px  !important;color: green;">Approved</span>
                                        <span *ngIf="chatData.status == 2" class="three9 centerClass"
                                            style="white-space: pre-wrap;width: 77px  !important;color: red;">Rejected</span>
                                        <span *ngIf="chatData.status != 1 && chatData.status != 2"
                                            class="three9 centerClass"
                                            style="white-space: pre-wrap;width: 77px  !important;color: red;">Panding</span>
                                    </div>
                                    <div class="col">
                                        <span class="three9" style="white-space: pre-wrap;width: 375px    !important;"
                                            *ngIf="chatData.status != 1 && chatData.status != 2">
                                            <a class="activebtn" style="height: 31px !important;"
                                                (click)="UpdateChatStatus(chatData.id,1,chatData.message)"><i>&#10004;</i>
                                                Approve</a>
                                            <a class="rejectbtn" style="margin-left: 10px !important;"
                                                (click)="UpdateChatStatus(chatData.id,2,chatData.message)"><i>&#10006;</i>
                                                Reject</a>
                                            <a class="viewprofilebtn" style="margin-left: 7px; "
                                                (click)="ViewAllChatByprojectId(chatData.order_id)"   [ngClass]="{'disabled':CheckProjectData == true}"><i
                                                    class="fa fa-window-maximize" aria-hidden="true"></i> View
                                                Conversation</a>
                                        </span>

                                        <span class="three9" style="white-space: pre-wrap;width: 375px    !important;"  
                                            *ngIf="chatData.status == 1 || chatData.status == 2 " >
                                            <a class="viewprofilebtn" style="margin-left: 7px; " 
                                                (click)="ViewAllChatByprojectId(chatData.order_id)"  [ngClass]="{'disabled':CheckProjectData == true}"><i
                                                    class="fa fa-window-maximize" aria-hidden="true"></i> View
                                                Conversation</a>
                                        </span>

                                        


                                    </div>
                                </div>

                              

                            </div>

                            <div
                            *ngIf="totChatrequest == 0 || totChatrequest == null || totChatrequest == undefined ">
                            <p>Data not found</p>
                        </div>
                          
                            </div>

                            <div class="paginations">
                                <div class="custom-pagination-wrapper">
                                    <button class="pagination-button margincorrect"
                                        [ngClass]="{ 'active': isFirstPage() }" (click)="goToFirstPage()">First</button>
                                    <pagination-controls (pageChange)="onPageChange($event)"></pagination-controls>
                                    <button class="pagination-button margincorrect"
                                        [ngClass]="{ 'lastactive': isLastPage(), 'lastdeactive': !isLastPage() }"
                                        (click)="goToLastPage()">Last</button>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--eof: myrecording-->
        </div>
    </div>

    <!-- start pop up-->
    <div class="overlay" *ngIf="popup">
        <div class="content">
            <!--bof: ProfilePopup-->
            <div class="viewprofilesection">
                <div class="viewprofiles">
                    <span class="material-icons-outlined revisionclose" (click)="popup = false">close</span>
                    <h2>View Conversation</h2>
                    <div class="insideview">
                        <ul>
                            <li><b>Order Id :</b><span>{{ getAllConvertion[0].order_id }} </span> <b
                                    style="padding-left: 55px;">Order Date :</b><span> {{ getAllConvertion[0].order_date
                                    }} </span></li> <br>
                            <li><b>Artist Id :</b><span>VM{{ getAllConvertion[0].artist_id }}</span> <b
                                    style="padding-left: 104px;">Customer Id :</b><span> VM{{
                                    getAllConvertion[0].customer_id }} </span></li>
                        </ul>
                        <hr>
                        <br>
                        <h1>Conversation</h1>

                        <br> <br>
                        <div class="main1" *ngFor="let chats of getAllConvertion">
                            <p style="font-size: 13px;"><span style="font-weight:bold;font-size: 13pxs;">{{
                                    chats.user_type+" " }}</span>[{{ chats.date_time }}] : {{ " "+chats.message }}</p>
                            <br> <br>

                        </div>
                        <!-- <p style="color: red;font-size: 13px;">Customer[08/05/2024   01:00 PM]  -   Hi there</p> -->

                    </div>
                </div>
            </div>
            <!--eof: ProfilePopup-->
        </div>
    </div>