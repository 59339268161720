import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, ViewChildren, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from '../_services/token-storage.service';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-artist-profile',
  templateUrl: './artist-profile.component.html',
  styleUrls: ['./artist-profile.component.css']
})
export class ArtistProfileComponent implements OnInit {
  content: any;
  userId: any;
  data: any;
  myParam: any;
  sampleListItems: any;
  sampleList: any;
  languageList: any;
  categoryList: any;
  profilePic: any;
  artistUserId: any;
  loader: boolean = true;
  roles: any;
  artistTat: any;
  country: any;
  state: any;
  recordingSetup: any;
  microPhoneUsed: any;
  experience: any;
  clientWorkFor: any;
  daySunday: any;
  dayMonday: any;
  dayTuesday: any;
  dayWednesday: any;
  dayThursday: any;
  dayFriday: any;
  daySaturday: any;
  postResponse: any;
  reviewAndRatingList: any;


  constructor(private userService: UserService, private route: ActivatedRoute, private router: Router,
    private tokenStorage: TokenStorageService, private toastr: ToastrService, private httpClient: HttpClient) { }

  ngOnInit(): void {
    this.tokenStorage.getToken();
    this.roles = this.tokenStorage.getUser().roles;
    this.loader = false;
    this.route.paramMap.subscribe(params => {
      this.myParam = params.get('queryParams');
    });
    this.getprofileDetails();
    this.getReviewAndRating();
  }


  @ViewChildren('audio') audioElms!: ElementRef[];

  ngAfterViewInit() {
    console.log(this.audioElms)
  }

  onPaly(elm: HTMLAudioElement) {
    this.audioElms.forEach(({ nativeElement: e }) => {
      if (e !== elm) {
        e.pause();
      }
    })
  }

  //fetching Edit Voice Details 
  getprofileDetails() {
    window.scroll(0, 0);
    this.userService.getprofileDetails(this.myParam).subscribe(data => {
      this.sampleList = data;
      if (this.sampleList !== undefined && this.sampleList !== null && this.sampleList !== "") {
        this.languageList = data.languageList;
        this.categoryList = data.categoryList;
        this.sampleList = data.sampleList;
        this.profilePic = data.profilePic;
        this.artistUserId = data.artistUserId;
        this.artistTat = data.tat;
        this.country = data.country;
        this.state = data.state;
        this.recordingSetup = data.recordingSetup;
        this.microPhoneUsed = data.microPhoneUsed;
        this.experience = data.experience;
        this.clientWorkFor = data.clientWorkedFor;
        this.daySunday = data.daySunday;
        this.dayMonday = data.dayMonday;
        this.dayTuesday = data.dayTuesday;
        this.dayWednesday = data.dayWednesday;
        this.dayThursday = data.dayThursday;
        this.dayFriday = data.dayFriday;
        this.daySaturday = data.daySaturday;
      }
    },
      err => {
        console.log(err);
      }
    );
  }

  checkUserIsLoggedIn(userId: any) {
    if (this.roles !== null && this.roles !== "" && this.roles !== undefined && this.roles.includes('ROLE_CUSTOMER')) {
      this.bookNow(userId);
    } else {
      this.toastr.error('Please sign in/Register as a customer !!', '')
      this.router.navigate(['/login']);
    }
  }

  bookNow(userId: any) {
    this.router.navigate(['/paymentfinal', { queryParams: userId }]);
  }

  viewImage() {
    // this.httpClient.get('http://192.168.1.23/api/test/get/image/info/' + this.image)
    this.httpClient.get('http://localhost:8080/api/test/image/info/' + this.myParam)
      .subscribe(
        res => {
          this.postResponse = res;
          this.profilePic = this.postResponse.name;
        }
      );
  }

  //Get Review and rating based on artist id 
  getReviewAndRating() {
    this.userService.getReviewAndRating(this.myParam).subscribe(data => {
      this.reviewAndRatingList = data;
      if (this.reviewAndRatingList !== null && this.reviewAndRatingList !== "") {
        this.loader = false;
        this.reviewAndRatingList.forEach((element: {}) => {
        });
      }
    },
      err => {
        console.log(err);
      }
    );
  }

}
