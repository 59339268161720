<!-- Banner -->

<div class="banner clientbanner">
    <img src="/assets/images/banner.jpg" alt="Banner">
</div>

<div class="fullwidth bg_purple">
    <div class="container2">
        <!--bof:personalinfo-->
        <div class="personal">
            <!-- <h2>Personal </h2>-->
            <div class="row">
                <div class="pinfo">
                    <div class="infoimg">
                        <img class="img" src="https://api.pinnaklebiz.com/thevoicemall/profile/icon.jpg" />
                    </div>
                </div>
                <div class="pdetails">
                    <h3>{{this.firstName}} {{this.lastName}}</h3>
                    <strong>VM{{this.id}}</strong>
                    <div class="pdetailbx">
                        <div class="row">
                            <span class="addheading">Email</span>
                            <span class="adddetail">{{ currentUser.email }}</span>
                        </div>
                        <div class="row">
                            <span class="addheading">Phone </span>
                            <span class="adddetail">+91 {{ this.contact }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--eof:personalinfo-->
    </div>
    <!--bof:artistrecording-->
    <!--bof: myrecording-->
    <div class="w1200">
        <div class="wedget">
            <!-- bof:wedgetleft -->
            <app-sidebar></app-sidebar>
            <!-- bof:wedgetright -->
            <div class="wedgetright">
                <!--Transtion history-->
                <div class="tab_content t_history">
                    <h3>Transtion history</h3>
                    <!-- <div class="searchrow">
                        <div class="pagesdroplist">
                            <span>Show</span>
                            <select>
                                <option>25</option>
                                <option>50</option>
                                <option>75</option>
                                <option>100</option>
                            </select>
                            <span>entries</span>
                        </div>
                        <div class="searchinput"><input type="text" id="myInput" placeholder="Search"></div>
                    </div> -->
                    <div class="transhistory">
                        <div class="row historyhead">
                            <div class="col"><span class="transid">Transition ID</span></div>
                            <div class="col"><span class="amounthistory">Amount</span></div>
                            <!-- <div class="col"><span class="wallethistory">Wallet</span></div> -->
                            <div class="col"><span class="datehistory">Date</span></div>
                            <div class="col"><span class="statushistory">Status</span></div>
                            <div class="col"><span class="artistid">Artist ID</span></div>
                        </div>
                        <div id="myList" *ngFor="let data of customerBookingDetails">
                            <div class="row">
                                <div class="col"><span class="transid">{{data.referenceId}}</span></div>
                                <div class="col"><span class="amounthistory"> ₹ {{data.finalPrice+data.tax}}</span>
                                </div>
                                <!-- <div class="col"><span class="wallethistory">₹ 10</span></div> -->
                                <div class="col"><span class="datehistory">{{data.updatedDate | date:
                                        'dd/MM/yyyy'}}</span></div>
                                <div class="col"><span class="statushistory completed">Completed</span></div>
                                <div class="col"><span class="artistid">VM{{data.artistId}}</span></div>
                            </div>
                        </div>
                        <h1 *ngIf="customerBookingDetails?.length == 0">No Data found !</h1>
                    </div>
                    <!-- <div class="pagination">
                        <ul>
                            <li><a href="#">Prev</a></li>
                            <li><a class="active" href="#">1</a></li>
                            <li><a href="#">2</a></li>
                            <li><a href="#">3</a></li>
                            <li><a href="#">Next</a></li>
                        </ul>
                    </div> -->
                    <!--<div class="nextmore"><a href="#">Next <i class="fa fa-angle-right" aria-hidden="true"></i></a></div>-->
                </div>
                <!--Transtion history-->

            </div>
        </div>
    </div>
    <!--eof: myrecording-->
</div>