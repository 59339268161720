<!--getupdated newsleter-->
<form *ngIf="!isSubscribe" name="form" (ngSubmit)="f.form.valid && subscribe()" #f="ngForm" novalidate>
  <div class="getupdate">
    <div class="container">
      <div class="getintro">
        <div class="youinbox">
          <i></i>
          <strong>Get Updates & More<span>Thoughtfull thoughts to your inbox</span></strong>
        </div>
        <div class="subscribe">
          <input type="email" placeholder="Enter Email ID*" class="newslr" [(ngModel)]="subscribeForm.email" #email="ngModel"
            [ngModelOptions]="{standalone: true}" required email>
          <input type="submit" value="Submit">
          <div class="alert alert-danger" *ngIf="f.submitted && email.invalid">
            
          <div class="alert alert-danger" *ngIf="email?.errors?.required">Email is required</div>
          <div class="alert alert-danger" *ngIf="email?.errors?.email">Email must be a valid email address</div>

          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<!--bof:Footer-->
<div id="footerbase">
  <div class="footerinside">
    <div class="boxes">
      <strong class="head">Information <i class="arrow"><span class="material-icons-outlined">
            chevron_right
          </span></i></strong>
      <ul class="content">
        <li><a (click)="about()">About Us</a></li>
        <li><a (click)="policy()">Privacy Policy</a></li>
        <li><a (click)="terms()">Terms</a></li>
        <!-- <li><a routerLink="/faq">FAQ's</a></li> -->
        <!-- <li><a href="#">Blog</a></li>
        <li><a href="#">Category</a></li>
        <li><a href="#">Services</a></li> -->

      </ul>
    </div>
    <!-- <div class="topbase">
      <div class="container">
        <ul class="signpart">
          <li><a class="sign" href="javascript:void(0)"><i class="fa fa-sign-in" aria-hidden="true"></i> Sign In</a>
          </li>
          <li class="borernone"><a class="ragis" href="javascript:void(0)"><i class="fa fa-user-circle-o"
                aria-hidden="true"></i> Register</a></li>
        </ul>
      </div>
    </div> -->
    <div class="boxes">
      <strong class="head">Voice Talent <i class="arrow"><span class="material-icons-outlined">
            chevron_right
          </span></i></strong>
      <div class="content">
        <ul class="signpart">
          <li><a (click)="login()">Sign In</a></li>
          <li class="borernone"><a  (click)="register()">Register</a></li>
        </ul>
      </div>
    </div>
    
    <div class="boxes">
      <strong class="head">Voice Process <i class="arrow"><span class="material-icons-outlined">
            chevron_right
          </span></i></strong>
      <ul class="content">
        <li><a (click)="howwework()">How we work</a></li>
        <li><a (click)="contact()">Contact us</a></li>
      </ul>
    </div>

    <div class="boxes">
      <strong class="head">Contact Info <i class="arrow"><span class="material-icons-outlined">
            chevron_right
          </span></i></strong>
      <div class="content">
        <div class="footersocial">
          <p class="footermap"> The Voice Mall,
            G-26, LGF, Kalkaji, New Delhi,
            Delhi-110019</p>
          <span class="footerno"><a href="#">+91 9811241103</a></span>
          <span class="footeremail"><a href="#">Info@thevoicemall.com</a></span>
          <ul>
            <li><a href="https://www.facebook.com/thevoicemall" target="_blank"><i class="fa fa-facebook"
                  aria-hidden="true"></i></a>
            </li>
            <li><a href="https://www.instagram.com/thevoicemallofficial/" target="_blank"><i class="fa fa-instagram"
                  aria-hidden="true"></i></a></li>

            <li><a href="https://www.linkedin.com/in/the-voice-mall-0b4614244/" target="_blank"><i
                  class="fa fa-linkedin" aria-hidden="true"></i></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="copyright">&copy;2023 The Voice Mall</div>
</div>
<!--Eof:Footer-->