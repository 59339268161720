<app-loader *ngIf="loader"></app-loader>
<!-- Banner -->
<div class="banner clientbanner">
    <img src="/assets/images/banner.jpg" alt="Banner">
</div>

<div class="fullwidth bg_purple">
    <!--bof:artistrecording-->
    <!--bof: myrecording-->
    <div class="w1200">
        <div class="wedget">
            <!-- bof:wedgetleft -->
            <app-sidebar></app-sidebar>
            <!-- bof:wedgetright -->
            <div class="wedgetright">
                <!--Notification-->
                <div class="w1200">
                    <h3 class="invoiceheading">Order Details. </h3>
                    <div class="invoicebx">
                        <div class="invoicedetail">
                            <div class="imgpart"><img src="/assets/images/imginvoice.jpg" alt="invoice"></div>
                            <div class="detailpart">
                                <div class="iconwithdetail"><i class="fa fa-user-circle-o imgnone"
                                        aria-hidden="true"></i> VM{{this.customerId}}</div>
                                <!-- <div class="iconwithdetail iconinline"><i class="anchorarow"></i> Male</div>
                                <div class="iconwithdetail iconinline"><i class="birthdate"></i> 29</div> -->
                                <div class="iconwithdetail"><i class="caticon"></i> Category : {{this.category}}
                                </div>
                                <div class="iconwithdetail"><i class="languateicon"></i> Language : {{this.language}}
                                </div>
                                <div class="iconwithdetail"><i class="recordicon"></i> Recording :
                                    Rs.{{this.artistPrice}}</div>
                                <div class="iconwithdetail"><i class="recordicon"></i> Order Id : {{this.referenceId}}
                                </div>
                            </div>
                        </div>

                        <!--Part 1-->
                        <div class="loopreview">
                            <div class="invoicedescription">
                                <strong>Script Uploaded date: <span>{{this.updatedDate | date: 'dd/MM/yyyy hh:mm a'}}</span></strong>
                                <p>{{this.script}}</p>
                                <strong>Instruction:</strong>
                                <p>{{this.instruction}}</p>

                                <div class="uploadrecording" style="position:relative; padding-bottom:33px;">
                                    <!-- *ngIf="voiceUploaded" -->
                                    <p class="alertmessage">File format should be .mp3 or .wav</p><br>
                                    <p class="alertmessage">Sample size should not exceed 50 MB</p><br>
                                    <div class="text-center">
                                        <input class="form-control" type="file" (change)="onChange($event)" accept="audio/mp3">
                                        <button (click)="uploadArtistVoice()" class="btn btn-success">Upload</button>
                                    </div>
                                    <!--Flag variable is used here-->
                                    <div class="container" *ngIf="loader">
                                        <h3>Loading ...</h3>
                                    </div>
                                </div>
                                <!-- Shareable short link of uploaded file -->
                            </div>
                        </div>
                        <div class="invoicedescription" *ngFor="let data of uploadArtistVoiceDetails">
                            <strong>Recording Uploaded date: <span>{{data.updatedDate | date: 'dd/MM/yyyy                                    hh:mma'}}</span></strong>
                            <audio controls #audio (play)="onPaly(audio)" class="audio">
                                <source src="https://api.pinnaklebiz.com/thevoicemall/sample/{{data.uploadedVoice}}">
                                <!-- <source src="/assets/voice/{{data.uploadedVoice}}"> -->
                            </audio>
                        </div>
                        <div class="invoicedescription" *ngFor="let data of revisedCommentsDetailsOnOrderId">
                            <strong>Revision Comments:</strong>
                            <p class="commentdiv">{{data.comments}}</p>
                        </div>
                    </div>
                </div>

                
    <!--  ------------------------------------------------------------- -->
                        <!--  Message box code start -->
                        <h1>Conversation</h1>

                        <div class="container">
                            <div class="main">
                                <p *ngIf="dataNotFound" style="text-align: center; padding-top: 98px;">Start sending messages</p>
                                <div class="direct-chat-messages"
                                    style=" padding:12px"  *ngFor="let userChat of AllChats" >
                                    <br>
                                     <!-- Message to the right -->
                                    <div class="direct-chat-msg right" *ngIf="userChat.user_type == 'Artist'">
                                        <div class="direct-chat-info clearfix">
                                           
                                            <span  class="direct-chat-timestamp pull-right"
                                                style="margin-top: 37px  ;">{{ userChat.date_time }}</span>
                                                <span
                                                class="direct-chat-name pull-right"
                                                style="margin-top: 37px  ;">&nbsp;&nbsp;&nbsp;</span>
                                                <span
                                                class="direct-chat-name pull-right"
                                                style="margin-top: 37px   ;  font-weight: bold;">You</span>
                                        </div>
                                         <div class="direct-chat-text"
                                            style="margin: 5px 0px 15px 19%;background: #dbf7c5;">
                                            {{ userChat.message }}
                                        </div>
                                    </div>
    
    
                                    <!-- Message. Default to the left -->
                                    <div class="direct-chat-msg" *ngIf="userChat.user_type == 'Customer'">
                                        <div class="direct-chat-info clearfix">
                                            <!--   <span class="direct-chat-name  "></span>  -->
                                            <span class="direct-chat-name "
                                                style="font-weight: bold;">Customer</span>
                                            <span
                                                class="direct-chat-name  ">&nbsp;&nbsp;&nbsp;</span>
                                            <span
                                                class="direct-chat-timestamp  ">{{ userChat.date_time }}</span>
                                        </div>
                                        <div class="direct-chat-text"
                                            style="margin: 5px 23% 15px 0px; background: whitesmoke;">
                                            {{ userChat.message }}
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            <div >
                                    <textarea type="text" name id class="sentmsg" [(ngModel)]="ChatMessag.msg"  [ngModelOptions]="{standalone:true}"
                                        placeholder="Please drop message"></textarea>
                                    <button type="submit"
                                        class="sentbutton" (click)="insertChat()">Send</button>
                                </div>
                            
                            </div>
                        <!--  ------------------------------------------------------------- -->
                        <!--  Message box code End -->
                        <br> <br>


                <!--Notification-->
            </div>
        </div>
    </div>
    <!--eof: myrecording-->
</div>