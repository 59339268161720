import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { UserService } from '../_services/user.service';
import { Ng2SearchPipe, Ng2SearchPipeModule } from 'ng2-search-filter';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-engagement',
  templateUrl: './user-engagement.component.html',
  styleUrls: ['./user-engagement.component.css'],
})
export class UserEngagementComponent implements OnInit {
  AllUsersChatRequest: any;
  AllUsersChatFilterdRequest: any;
  checkApproved: any;
  popup: boolean = false;
  CheckProjectData:boolean =  false;
  getAllConvertion: any;
  set searchParam(value: any) {
    if (value != null && value != '')
      this.AllUsersChatFilterdRequest = Ng2SearchPipe.prototype.transform(
        this.AllUsersChatRequest,
        value
      );
    else this.AllUsersChatFilterdRequest = this.AllUsersChatRequest;
    this.totChatrequest = this.AllUsersChatFilterdRequest.length;
    this.page = 1;
  }
  totChatrequest: any;
  page: number = 1;
  constructor(
    private userService: UserService,
    private renderer: Renderer2,
    private http: HttpClient,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.GetAllChatRequest();
  }

  isFirstPage(): boolean {
    return this.page != 1;
  }

  isLastPage(): any {
    if (this.page == Math.ceil(this.AllUsersChatFilterdRequest.length / 10)) {
      return false;
    } else {
      return true;
    }
  }

  onPageChange(event: number): void {
    this.page = event;
  }

  pageChanged(event: any): void {
    this.page = event;
    // Perform any actions with the new page number here
  }

  goToFirstPage() {
    this.page = 1; // Assuming 1-based indexing
  }

  goToLastPage() {
    // Calculate the last page based on the total number of items and items per page
    const totalPages = Math.ceil(this.AllUsersChatFilterdRequest.length / 10); // Assuming 45 items per page
    this.page = totalPages;
  }

  GetAllChatRequest() {
    try {
      this.userService.GetAllChats().subscribe(
        (data: any) => {
          this.AllUsersChatFilterdRequest = data;
          console.log("This is our requesst data ",this.AllUsersChatFilterdRequest);
          this.AllUsersChatRequest = data;
          this.totChatrequest = this.AllUsersChatRequest.length;
        },
        (error: HttpErrorResponse) => {
          if (error.status == 400 || error.status !== null) {
            console.log('Error in Get users chat request', error);
            this.toastr.error(error.error.message, '');
          }
        }
      );
    } catch (error) {
      console.log('Error in Get User Chat Request ', error);
    }
  }

  UpdateChatStatus(chat_id: any, status: any, remark: any) {
    try {     
      if (
        chat_id !== null &&
        chat_id !== '' &&
        chat_id !== undefined &&
        status !== null &&
        status !== undefined &&
        status !== ''
      ) {
        // alert("remark"+remark);
        const UpdateChatSattaus = {
          chat_id: chat_id,
          msg: remark,
          status,
        };
        this.userService.updateChatStatus(UpdateChatSattaus).subscribe(
          (response) => {
            if (response) {
              this.toastr.success('Chat Status Changes Successsfully', '');
              setTimeout(() => {
                window.location.reload();
              }, 200);
            } else {
              this.toastr.error('Somehting went wrong', '');
            }
          },
          (error: HttpErrorResponse) => {
            if (error.status == 400 || error.status !== null) {
              console.log(
                'I have error in call of change user chat status ',
                error
              );
              this.toastr.error(error.error.message, '');
            }
          }
        );
      } else {
        this.toastr.error('something went wrong', '');
      }
    } catch (error) {
      console.log('Error in approve or reject user', error);
    }
  }

  ViewAllChatByprojectId(projectId: any) {
    try {
      if (projectId !== null && projectId !== undefined && projectId !== null) {
        this.popup = true;
        this.userService.GetAllChatsByProjectId(projectId).subscribe(
          (response) => {
            console.log("project by id resposne",response)
            if (response && response !== null) {
              this.getAllConvertion = response;
              console.log("data by id",this.getAllConvertion);
            }else{
              this.CheckProjectData   =  true;
            }
          },
          (error: HttpErrorResponse) => {
            if (error.status !== null) {
              console.log('Error in get all convertion chat data ', error);
              this.toastr.error(error.error.message, '');
            }
          }
        );
      }
    } catch (error) {
      console.log('Error In get Chat By project id ', error);
    }
  }
}
