<div class="mainDiv">
  <div class="cardStyle">
    <form>
      <h2 class="formTitle">
        Change Your Password
      </h2>

      <div class="inputDiv">
        <label class="inputLabel">Confirm Your Email</label>
        <input type="email" name="email" [(ngModel)]="forgotform.email" [ngModelOptions]="{standalone: true}"
          maxlength="40" required>
      </div>

      <div class="inputDiv">
        <label class="inputLabel">Enter your new Password</label>
        <input type="password" name="confirmPassword" [(ngModel)]="forgotform.password"
          [ngModelOptions]="{standalone: true}" maxlength="30">
      </div>

      <div class="buttonWrapper">
        <button type="submit" class="submitButton pure-button pure-button-primary">
          <span (click)="forgotPassword()">Continue</span>
        </button>
      </div>

    </form>
  </div>
</div>