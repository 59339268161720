<!--Voicepopup-->
<!-- class="loginbase ragisterfrm" for hide and show registration popUp-->
<div class="loginbase">
    <div class="loginbox">
        <div class="leftpart">
            <strong>Artist Registration</strong>
            <form *ngIf="!isSuccessful" name="form" (ngSubmit)="registerUser()" #f="ngForm" novalidate>

                <div class="formwrap">
                    <h4 class="alertmessage none"> Please select your registration type* </h4>
                    <div class="wrap none"  >
                        <div class="wrap-divide radio none">
                            <input type="radio" value="artist" name="Artist" [(ngModel)]="this.role" #artist="ngModel"
                                required />As a voice artist
                        </div>
                        <div class="wrap-divide radio none">
                            <input type="radio" value="customer" name="Customer" [(ngModel)]="this.role"
                                #customer="ngModel" required />As a customer
                        </div>
                        <!-- <div class="alert-danger" *ngIf="artist.errors && f.submitted">
                          <div *ngIf="artist.errors.required">Select as a you want to register</div>
                      </div>
                      <h1 class="alert-danger">{{this.registerErrorMessage}}</h1> -->
                    </div>
                    <div class="wrap">
                        <div class="wrap-divide">
                            <input type="text" placeholder="Enter First Name*" class="form-control Inputborder" name="username"
                                [(ngModel)]="registerform.username" required minlength="3" maxlength="20"
                                #username="ngModel" [ngModelOptions]="{standalone: true}" />
                            <!-- <div class="alert-danger" *ngIf="username.errors && f.submitted">
                              <div *ngIf="username.errors.required">Name is mandatory</div>
                              <div *ngIf="username.errors.minlength">
                                  Name must be at least 3 characters
                              </div>
                              <div *ngIf="username.errors.maxlength">
                                  Name must be at most 20 characters
                              </div>
                          </div> -->
                        </div>

                        <div class="wrap-divide">
                            <input type="text" placeholder="Enter Last Name" class="Inputborder" [(ngModel)]="registerform.last_name"
                                #last_name="ngModel" [ngModelOptions]="{standalone: true}" maxlength="20" required>
                        </div>
                    </div>
                    <div class="wrap">
                        <div class="wrap-divide">
                          <select #country name="country" id="country" (change)="onCountryChange($event)"  class="Inputborder"
                              (ngModel)="registerform.country" [ngModelOptions]="{standalone: true}">
                                <option value="null">Select Your Country*</option>
                              <option *ngFor="let country of countries" [value]="country | json">
                                  {{ country.name }} {{"("+country.phonecode+")"}}
                                </option>
                            </select>
                        </div>

                        <div class="wrap-divide">
                            <input type="number" placeholder="Contact No*" [(ngModel)]="registerform.contact" class="Inputborder"
                                #contact="ngModel" [ngModelOptions]="{standalone: true}"  onKeyPress="if(this.value.length==12) return false;"
                                required />
                            <!-- <div class="alert-danger" *ngIf="contact.errors && f.submitted">
                              <div *ngIf="contact.errors.required">Contact is mandatory</div>
                          </div> -->
                        </div>
                    </div>
                    <div class="wrap">
                        <input type="email" placeholder="Enter Email ID*" class="form-control Inputborder" name="email"
                            [(ngModel)]="registerform.email" required email #email="ngModel"
                            [ngModelOptions]="{standalone: true}" maxlength="40" />
                        <!-- <div class="alert-danger" *ngIf="email.errors && f.submitted">
                          <div *ngIf="email.errors.required">Email is mandatory</div>
                          <div *ngIf="email.errors.email">
                              Email must be a valid email address
                          </div>
                      </div> -->
                    </div>
                    <div class="wrap">
                        <input type="password" placeholder="Password*" class="form-control Inputborder" name="password"
                            [(ngModel)]="registerform.password" required minlength="6" #password="ngModel"
                            [ngModelOptions]="{standalone: true}" maxlength="30" />
                        <!-- <div class="alert-danger" *ngIf="password.errors && f.submitted">
                          <div *ngIf="password.errors.required">Password is mandatory</div>
                          <div *ngIf="password.errors.minlength">
                              Password must be at least 6 characters
                          </div>
                      </div> -->


                        <!-- <div class="wrap-divide">
                          <input type="password" placeholder="Confirm Password*">
                      </div> -->
                    </div>
                    <div class="wrap none">
                        <div class="wrap-divide">
                            <input type="text"  placeholder="Company Name" [(ngModel)]="registerform.company_name"
                                #company_name="ngModel" [ngModelOptions]="{standalone: true}" required
                                style="padding-left:0;" maxlength="50">
                        </div>

                        <div class="wrap-divide none">
                            <input type="text" placeholder="Enter GSTIN" [(ngModel)]="registerform.gst" #gst="ngModel"
                                [ngModelOptions]="{standalone: true}" required maxlength="15">
                        </div>
                    </div>

                    <div class="wrap none">
                        <input type="text" placeholder="Company Address" [(ngModel)]="registerform.company_address"
                            #company_address="ngModel" [ngModelOptions]="{standalone: true}" required
                            style="padding-left:0;" maxlength="100">
                    </div>


                    <div class="wrap">
                        <label class="customcheck">
                            <input type="checkbox" [(ngModel)]="ifChecked" [ngModelOptions]="{standalone: true}">
                            <span class="checkmark"></span>I agree to The Voice Mall <a
                                href="https://thevoicemall.com/terms" style="text-decoration: underline; color:darkblue"
                                target="_blank"><b>Terms of
                                    Service</b></a> and <a href="https://thevoicemall.com/policy"
                                style="text-decoration: underline; color:darkblue" target="_blank"><b>Privacy
                                    Policy</b></a></label>

                    </div>
                    <!-- <label class="alertmessage">Note: Please fill your GSTIN to claim GST input </label> -->
                    <div class="wrap">
                        <button class="registerbtn">Register</button>
                        <!-- <span class="registerline">Already registered? <a href="#">Login here</a></span> -->
                    </div>
                    <label class="alertmessage">Already Registered? <a routerLink="/login">Click here to Sign in</a>
                    </label>

                </div>
            </form>
            <div class="alert alert-success" *ngIf="isSuccessful">
                Your registration is successful! <a routerLink="/login">Click here to Sign in</a>
            </div>
        </div>
        <div class="rightpart">
            <img src="/assets/images/loginimg.jpg">
            <div class="wemake">
                <!-- <img src="/assets/images/logo.png" alt="The Voice Mall "> -->
                <strong><span>Welcome to </span>The Voice Mall</strong>
                <p>You can register on The Voice Mall for free of cost and upload your voice samples.
                    It’s as easy as it gets. So hurry up and <b>REGISTER NOW</b></p>
                    <br> <br>
                <div>
                    <iframe height="240" src="https://www.youtube.com/embed/6FpmI_f_sxc?autoplay=1" 
                        title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write;
                       encrypted-media; gyroscope; picture-in-picture" allowfullscreen ></iframe>

                </div>
            </div>


            <div class="loginsocial">

                <span>Visit our Social Media Pages</span>
                <ul>
                    <li><a href="https://www.facebook.com/thevoicemall" target="_blank"><i class="fa fa-facebook"
                                aria-hidden="true"></i></a>
                    </li>
                    <li><a href="https://www.instagram.com/thevoicemallofficial/" target="_blank"><i
                                class="fa fa-instagram" aria-hidden="true"></i></a></li>

                    <li><a href="https://www.linkedin.com/in/the-voice-mall-0b4614244/" target="_blank"><i
                                class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                </ul>
            </div>
        </div>
    </div>
</div>