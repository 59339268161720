<app-loader *ngIf="loader"></app-loader>

<div class="maincontainer">

    <div class="dashboardbanner">
        <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
        <div class="bannerheading">
            Users
        </div>
    </div>

    <div class="fullwidth bg_purple">
        <div class="w1200">
            <div class="dashboardwedget">
                <app-admin-sidebar></app-admin-sidebar>
                <div class="wedgetright">
                    <h3>Users</h3>
                    <div class="dashwed">
                        <ul class="homelink">
                            <li><a class="active" routerLink="/view-users"><i class="fa fa-home fa-2x dashboardicon"
                                        aria-hidden="true"></i></a></li>
                            <li><a routerLink="/view-users">Manage Users</a></li>
                            <li style="float: right;margin-right: 0px !important;">
                                <div class="example-button-container" routerLink="/add-user">
                                    <button mat-fab color="primary" class="addrole"
                                        aria-label="Example icon button with a delete icon">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </div>
                            </li>

                        </ul>
                    </div>
                    <div class="artistboard">
                        <div class="searchrow">
                            <div class="pagesdroplist">
                                <span>Total Users : {{  totUser }}</span>
                            </div>
                            <div class="searchinput">
                                <input type="text" id="myInput"  [(ngModel)]="searchParam"   placeholder="Search">
                            </div>
                        </div>


                        <div class="atrtable">
                            <div class="row">
                                <div class="col"><span style="width: 40px !important;" class="first1">S No</span></div>
                                <div class="col"><span style="width: 115px !important;" class="first2">Name</span></div>
                                <div class="col"><span style="width: 180px !important;" class="first3">Email</span>
                                </div>
                                <div class="col"><span style="width: 110px !important;" class="first2">Contact No</span>
                                </div>
                                <div class="col"><span style="width: 115px !important;" class="first1">Role</span></div>
                                <div class="col"><span style="width: 115px !important;" class="first1">Action</span>
                                </div>
                            </div>
                            <ng-container>
                                <div id="myList">
                                    <div class="row" *ngFor="let user of AllfiltredUsers | paginate: {itemsPerPage:10, currentPage: page }; let i = index"
                                    [attr.data-index]="i + (page -1 ) * 10 + 1">
                                        <div class="col"><span style="width: 40px !important;" class="first1">{{ i + (page -1 ) * 10 + 1 }}</span>
                                        </div>
                                        <div class="col"><span style="width: 115px !important;" class="first2">{{ user.username +' '+ user.last_name}}</span>
                                        </div>
                                        <div class="col"><span style="width: 180px !important;"
                                                class="first3">{{ user.email }}</span></div>
                                        <div class="col"><span style="width: 110px !important;"
                                                class="first2">{{ user.contact }}</span>
                                        </div>
                                        <div class="col"><span style="width: 115px !important;"
                                                class="first1" *ngFor="let role of user.roles">{{ role.name }}</span></div>
                                        <div class="col">
                                            <span style="width: 115px !important;"  class="three9">
                                                <a (click)="edituser(user.id)" class="editdesign">
                                                    <div class="example-button-container ">
                                                      <mat-icon class="editbutton">edit</mat-icon>
                                                    </div>
                                                  </a>

                                                  <a class="editdesign " style="display: none;">
                                                    <div class="example-button-container " >
                                                      <mat-icon class="editbutton">delete</mat-icon>
                                                    </div>
                                                  </a>

                                            </span>
                                        </div>
                                    </div>
                                </div>

                            </ng-container>
                        </div>
                        <div class="paginations">
                            <div class="custom-pagination-wrapper">
                              <button class="pagination-button margincorrect" [ngClass]="{ 'active': isFirstPage() }"
                                (click)="goToFirstPage()">First</button>
                              <pagination-controls (pageChange)="onPageChange($event)" ></pagination-controls>
                              <button class="pagination-button margincorrect"
                                [ngClass]="{ 'lastactive': isLastPage(), 'lastdeactive': !isLastPage() }"
                                (click)="goToLastPage()">Last</button>
                            </div>
                          </div>
                    </div>



                </div>


            </div>


        </div>




    </div>