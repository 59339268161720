<app-loader *ngIf="loader"></app-loader>
<div id="maincontainer">
  <div class="dashboardbanner">
    <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
    <div class="bannerheading">
      Roles
    </div>
  </div>

  <div class="fullwidth bg_purple">
    <div class="w1200">
      <div class="dashboardwedget">
        <app-admin-sidebar></app-admin-sidebar>
        <div class="wedgetright">
          <h3>Roles</h3>
          <div class="dashwed">
            <ul class="homelink">
              <li><a class="active" routerLink="/view-roles"><i class="fa fa-home fa-2x dashboardicon"
                    aria-hidden="true"></i></a></li>
              <li><a routerLink="/view-roles">Manage Roles</a></li>
              <li style="float: right;margin-right: 0px !important;">
                <div class="example-button-container" routerLink="/add-role">
                  <button mat-fab color="primary" class="addrole" aria-label="Example icon button with a delete icon">
                    <mat-icon>add</mat-icon>
                  </button>
                </div>
              </li>
            </ul>
          </div>
          <div class="artistboard">
            <div class="searchrow">
              <div class="pagesdroplist">
                <span>Total Roles : {{ NoOFRoles }}</span>
              </div>
              <div class="searchinput"><input type="text" id="myInput" [(ngModel)]="searchParam" placeholder="Search">
              </div>
            </div>

            <div class="atrtable">
              <div class="row">
                <div class="col"><span class="first1" style="width: 175px !important;">S No</span></div>
                <div class="col"><span class="first2" style="width: 310px !important;">Role</span></div>
                <div class="col"><span class="first3" style="width: 255px !important;">Action</span></div>
              </div>
              <ng-container>
                <div id="myList">
                  <div class="row"
                    *ngFor="let role of AllRolesfiltred |  paginate: {itemsPerPage:10, currentPage: page }; let i = index"
                    [attr.data-index]="i + (page -1 ) * 10 + 1">
                    <div class="col"><span class="first1" style="width: 175px !important;">{{ i + (page -1 ) * 10 + 1
                        }}</span></div>
                    <div class="col"><span class="first1" style="width: 310px  !important;">{{ role.name }}</span></div>
                    <div class="col">
                      <span class="three9" style="width: 255px !important;">
                        <a (click)="editRole(role.id)" class="editdesign">
                          <div class="example-button-container ">
                            <mat-icon class="editbutton">edit</mat-icon>
                          </div>
                        </a>
                        <!-- <a (click)="deleteRoleById(role.id)" style="display: none;">
                                        <div class="example-button-container">
                                           <button mat-fab color="primary" aria-label="Example icon button with a delete icon" class="actionbutton">
                                           <mat-icon>delete</mat-icon>
                                           </button> 
                                        </div>

                                    </a>   -->
                      </span>
                    </div>
                  </div>

                </div>
              </ng-container>
            </div>
            <div class="paginations">
              <div class="custom-pagination-wrapper">
                <button class="pagination-button margincorrect" [ngClass]="{ 'active': isFirstPage() }"
                  (click)="goToFirstPage()">First</button>
                <pagination-controls (pageChange)="onPageChange($event)"></pagination-controls>
                <button class="pagination-button margincorrect"
                  [ngClass]="{ 'lastactive': isLastPage(), 'lastdeactive': !isLastPage() }"
                  (click)="goToLastPage()">Last</button>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
</div>