import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { OrderService } from '../_services/order.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-artist-bank-details',
  templateUrl: './artist-bank-details.component.html',
  styleUrls: ['./artist-bank-details.component.css']
})
export class ArtistBankDetailsComponent implements OnInit {

  loader: any;
  isAddPayment = false;
  isAddPaymentFailed = false;
  AddPaymentErrorMessage: any;
  test: any;

  currentUser: any;
  contact: any;
  id: any;
  artistId: any;
  country: any;

  //for image upload
  uploadedImage: any;
  dbImage: any;
  profilePic: any;
  postResponse: any;
  successResponseForPan: any;
  successResponseForGst: any;
  image: any;
  default: any;

  constructor(private oredrService: OrderService, private token: TokenStorageService, private toastr: ToastrService, private httpClient: HttpClient) { }

  ngOnInit(): void {
    this.currentUser = this.token.getUser();
    this.country = this.token.getUser().country;
    this.contact = this.token.getUser().contact;
    this.id = this.token.getUser().id;
    this.fetchpaymentDetails();
  }

  addPaymentDetails: any = {
    artistName: null,
    address: null,
    panNumber: null,
    gstNumber: null,
    accountHolderName: null,
    accountNumber: null,
    bankName: null,
    ifscCode: null,
    branch: null,
    iban: null,
    swiftCode: null,
    country: null,
    city: null,
    branchAddress: null
  };

  paymentDetails(): void {
    const { artistName, address, panNumber, gstNumber, accountHolderName, accountNumber, bankName, ifscCode, branch, iban, swiftCode, country, city, branchAddress } = this.addPaymentDetails;
    this.oredrService.paymentDetails(this.artistId = this.id, artistName, address, panNumber, gstNumber, accountHolderName, accountNumber, bankName, ifscCode, branch, iban, swiftCode, country, city, branchAddress).subscribe();
    if (this.country === 'India') {
      if (this.addPaymentDetails.artistName !== null && this.addPaymentDetails.artistName !== undefined && this.addPaymentDetails.artistName !== "" &&
        this.addPaymentDetails.accountHolderName !== null && this.addPaymentDetails.accountHolderName !== undefined && this.addPaymentDetails.accountHolderName !== "" &&
        this.addPaymentDetails.accountNumber !== null && this.addPaymentDetails.accountNumber !== undefined && this.addPaymentDetails.accountNumber !== "" &&
        this.addPaymentDetails.bankName !== null && this.addPaymentDetails.bankName !== undefined && this.addPaymentDetails.bankName !== "" &&
        this.addPaymentDetails.ifscCode !== null && this.addPaymentDetails.ifscCode !== undefined && this.addPaymentDetails.ifscCode !== "" &&
        this.addPaymentDetails.branch !== null && this.addPaymentDetails.branch !== undefined && this.addPaymentDetails.branch !== "") {
        this.isAddPayment = true
        this.isAddPaymentFailed = false;
        this.toastr.success('Bank Details have been updated successfully', '')
      } else {
        this.AddPaymentErrorMessage = Error;
        this.isAddPaymentFailed = true;
        this.toastr.error('Please fill all the mendatory fields', '')
      }
    } else {
      if (this.addPaymentDetails.swiftCode !== null && this.addPaymentDetails.swiftCode !== undefined && this.addPaymentDetails.swiftCode !== "" &&
        this.addPaymentDetails.country !== null && this.addPaymentDetails.country !== undefined && this.addPaymentDetails.country !== "" &&
        this.addPaymentDetails.city !== null && this.addPaymentDetails.city !== undefined && this.addPaymentDetails.city !== "" &&
        this.addPaymentDetails.branchAddress !== null && this.addPaymentDetails.branchAddress !== undefined && this.addPaymentDetails.branchAddress !== "") {
        this.isAddPayment = true
        this.isAddPaymentFailed = false;
        this.toastr.success('Bank Details have been updated successfully', '')
      } else {
        this.AddPaymentErrorMessage = Error;
        this.isAddPaymentFailed = true;
        this.toastr.error('Please fill all the mendatory fields', '')
      }
    }

  }

  //Get EditProfile Details
  fetchpaymentDetails() {
    this.oredrService.fetchArtistBankDetails(this.artistId = this.id).subscribe(data => {
      this.addPaymentDetails = data;
    },
      err => {
        console.log(err);
      }
    );
  }


  public onImageUpload(event: any) {
    this.image = event.target.files[0];
  }

  panUploadAction() {
    const imageFormData = new FormData();
    imageFormData.append('image', this.image, this.image.name);
    if (this.image.size > 1000000) {
      this.toastr.error('Image size should be upto 1 mb', '');
      this.loader = false;
    } else if (this.image.type === "image/jpeg" || this.image.type === "image/png") {
      imageFormData.append('artistId', this.id);
      this.httpClient.post('https://api.pinnaklebiz.com/the-voice-mall/api/test/pan-upload/image/', imageFormData, { observe: 'response' })
        .subscribe((response) => {
          if (response.status === 200) {
            this.postResponse = response;
            this.successResponseForPan = this.postResponse.body.message;
          } else {
            this.successResponseForPan = 'Pan not uploaded due to some error!';
          }
        }
        );
    } else {
      this.toastr.error('File format should be .jpeg or .png', '');
    }

  }

  gstUploadAction() {
    const imageFormData = new FormData();
    imageFormData.append('image', this.image, this.image.name);
    if (this.image.size > 1000000) {
      this.toastr.error('Image size should be upto 1 mb', '');
      this.loader = false;
    } else if (this.image.type === "image/jpeg" || this.image.type === "image/png") {
      imageFormData.append('artistId', this.id);
      this.httpClient.post('https://api.pinnaklebiz.com/the-voice-mall/api/test/gst-upload/image/', imageFormData, { observe: 'response' })
        .subscribe((response) => {
          if (response.status === 200) {
            this.postResponse = response;
            this.successResponseForGst = this.postResponse.body.message;
          } else {
            this.successResponseForGst = 'Gst not uploaded due to some error!';
          }
        }
        );
    } else {
      this.toastr.error('File format should be .jpeg or .png', '');
    }

  }

}




