<app-loader *ngIf="loader"></app-loader>

<!-- Banner -->
<div class="dashboardbanner">
    <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
    <div class="bannerheading">Voice Samples</div>
</div>

<div class="fullwidth bg_purple">
    <div class="container">
        <div class="wedgetform word_count">
            <strong class="collapsible">Compare Prices</strong>
            <div class="colapsecontent form1">
                <form class="formpanel">

                    <div class="formwrap">
                        <select [(ngModel)]="categoriesfrom.category_name" [ngModelOptions]="{standalone: true}">
                            <option value="null" disabled selected>-- Select Categories * --</option>
                            <option *ngFor="let data of categoryList" [label]="data.category_name"
                                [value]="data.category_name">{{data.category_name}}</option>
                        </select>
                    </div>

                    <div class="formwrap">
                        <select name="language" #language="ngModel" [(ngModel)]="languagefrom.language"
                            [ngModelOptions]="{standalone: true}">
                            <option value="null" disabled selected>-- Select Language * --</option>
                            <option *ngFor="let data of languageList" [label]="data.language" [value]="data.language">
                                {{data.language}}
                            </option>
                        </select>
                    </div>

                    <div class="formwrap">
                        <textarea placeholder="Enter your script here *" [attr.disabled]="words >2000 ? '' : null"
                            (keyup)="wordCounter()" #text id="wmd-input" name="post-text"
                            class="wmd-input s-input bar0 js-post-body-field processed" data-post-type-id="2" cols="92"
                            rows="15" tabindex="101" data-min-length="" oncopy="return true;" onpaste="return true;"
                            oncut="return false;"></textarea>
                        <p> <b>Word Count:</b> <span id="wordCount">{{ words }}</span></p>
                    </div>

                    <div class="formwrap">
                        <select [(ngModel)]="gender" [ngModelOptions]="{standalone: true}">
                            <option value="undefined" disabled selected>-- Select Gender *--</option>
                            <option>male</option>
                            <option>female</option>
                        </select>
                    </div>

                    <div class="formwrap" *ngIf="this.roles == 'ROLE_CUSTOMER'">
                        <div class="searchdiv">
                            <input type="submit" value="Get Quote" (click)="getQuote()">
                        </div>
                    </div>
                </form>
                <!--eof:formsection-->
            </div>
            <!--eof: form-->
        </div>

        <div class="wedgetform">
            <div class="form1 advertise_form">
                <form class="formpanel">
                    <div class="formwrap">
                        <select name="services" #services="ngModel" [(ngModel)]="servicefrom.service_name"
                            [ngModelOptions]="{standalone: true}">
                            <option value="null" disabled selected>-- Select Service --</option>
                            <option *ngFor="let data of serviceList" [label]="data.service_name"
                                [value]="data.service_name"selected>{{data.service_name}}</option>
                        </select>
                    </div>

                    <div class="formwrap">
                        <select name="language" #language="ngModel" [(ngModel)]="languagefrom.language"
                            [ngModelOptions]="{standalone: true}">
                            <option value="null" disabled selected>-- Select Language --</option>
                            <option *ngFor="let data of languageList" [label]="data.language" [value]="data.language">
                                {{data.language}}
                            </option>
                        </select>
                    </div>

                    <div class="formwrap">
                        <select name="categories" #categories="ngModel" [(ngModel)]="categoriesfrom.category_name"
                            [ngModelOptions]="{standalone: true}">
                            <option value="null" disabled selected>-- Select Categories --</option>
                            <option *ngFor="let data of categoryList" [label]="data.category_name"
                                [value]="data.category_name">{{data.category_name}}</option>
                        </select>
                    </div>

                    <div class="formwrap">
                        <select [(ngModel)]="gender" [ngModelOptions]="{standalone: true}">
                            <option value="undefined" disabled selected>-- Select Gender --</option>
                            <!-- <option value="null" disabled selected>-- Select Gender --</option> -->
                            <option>male</option>
                            <option>female</option>
                        </select>
                    </div>

                    <div class="formwrap">
                        <div class="searchdiv">
                            <!-- <i class="fa fa-search" aria-hidden="true"></i> -->
                            <input type="submit" value="Search" (click)="getSearchVoiceList()">
                        </div>
                    </div>
                    <!-- <select [(ngModel)]="config.limit" [ngModelOptions]="{standalone: true}" (change)="getSearchVoiceList()">
                        <option>10</option>
                        <option>20</option>
                        <option>30</option>
                      </select> -->
                </form>
                <!--eof:formsection-->
            </div>
            <!--eof: form-->
        </div>

        <div class="sampledash advertise">
            <!-- <div class="paginations">
                <pagination-controls (pageChange)="page = $event"></pagination-controls>
                <button class="pagination-button custom-first" (click)="goToFirstPage()">First</button>
                <button class="pagination-button" (click)="goToLastPage()">Last</button>                
            </div> -->
  
        

            <div class="paginations">
                <div class="custom-pagination-wrapper">
                    <button class="pagination-button" [ngClass]="{ 'active': isFirstPage() }" (click)="goToFirstPage()">First</button>                    
                    <pagination-controls (pageChange)="onPageChange($event)"></pagination-controls>
                    <button class="pagination-button" [ngClass]="{ 'lastactive': isLastPage() }" (click)="goToLastPage()">Last</button>
                </div>    
            </div>

          
            
            <div class="row">
                 
                
                <div class="over_div"  *ngFor="let data of searchVoiceList | paginate: { itemsPerPage: 45, currentPage: page }">
                    
                    <div class="col zoom">
                        <div class="audio_box_cont">
                            <div class="coldetails" (click)="getprofileDetails(data.userId)">
                                <strong>VM{{data.userId}}<strong class="price">{{data.finalPrice === 0 ? "" :
                                        "₹"+data.finalPrice}}</strong></strong>
                                <p>{{data.sampleVoice}}</p>
                                <p>{{data.language}}</p>
                                <p>{{data.category}}</p>
                            </div>

                            <div class="pinfo" (click)="getprofileDetails(data.userId)">
                                <div class="infoimg" *ngIf="data.profilePic !== null && data.profilePic !== ''">
                                    <img class="img"
                                        src="https://api.pinnaklebiz.com/thevoicemall/profile/{{data.profilePic}}"
                                        alt="infoimg">
                                </div>

                                <div class="infoimg"
                                    *ngIf="data.profilePic === null || data.profilePic.trim() === '' && data.gender==='male'">
                                    <img class="img" src="https://api.pinnaklebiz.com/thevoicemall/profile/icon.jpg"
                                        alt="infoimg">
                                </div>

                                <!-- <div class="infoimg"
                                *ngIf="data.profilePic === null || data.profilePic.trim() === '' && data.gender==='female'">
                                <img class="img" src="https://api.pinnaklebiz.com/thevoicemall/profile/female.png"
                                    alt="infoimg">
                            </div> -->

                            </div>
                        </div>
                        <audio controls #audio (play)="onPaly(audio)" class="audio">
                            <source src="https://api.pinnaklebiz.com/thevoicemall/sample/{{data.name}}">
                        </audio>
                        <div class="btngroup" (click)="getprofileDetails(data.userId)">
                            <a class="book_btn" >View details</a>
                        </div>
                    </div>
                </div>
            </div>
            <h1 style="text-align: center;margin: 10px;" *ngIf="searchVoiceList?.length == 0">No Artist Found</h1>

            <!-- <div class="paginations">
                <pagination-controls (pageChange)="page = $event"></pagination-controls>
            </div> -->
            <div class="paginations">
                <div class="custom-pagination-wrapper">
                    <button class="pagination-button" [ngClass]="{ 'active': isFirstPage() }" (click)="goToFirstPage()">First</button>                    
                    <pagination-controls (pageChange)="onPageChange($event)" ></pagination-controls>
                    <button class="pagination-button" [ngClass]="{ 'lastactive': isLastPage() }" (click)="goToLastPage()">Last</button>
                </div>    
            </div>

        </div>

        <div class="requestfordemo"><a (click)="contact()">Click here to contact us</a>
        </div>
    </div>
</div>