// footer.component.ts
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  isSubscribe = false;
  isSubscribeFailed = false;
  SubscribeErrorMessage = '';

  constructor(private userService: UserService, private toastr: ToastrService, private router: Router) { }

  ngOnInit(): void {
  }

  subscribeForm: any = {
    email: null,
  }

  subscribe() {
    const { email } = this.subscribeForm;

    if (this.isEmailValid(email)) {
      this.userService.subscribeUs(email).subscribe(
        data => {
          this.isSubscribe = true;
          this.isSubscribeFailed = false;
          this.toastr.success('Thank you for subscribing to our newsletter', '', { timeOut: 3000 });
        },
        err => {
          this.toastr.error('Error', '', { timeOut: 3000 });
          this.SubscribeErrorMessage = err.error.message;
          this.isSubscribeFailed = true;
        }
      );
    } else {
      // Handle the case where the email is not valid
      this.toastr.error('Invalid email. Please provide a valid email address', '', { timeOut: 3000 });
    }
  }

  // Helper method to check email validity
  private isEmailValid(email: string): boolean {
    // Implement your email validation logic here
    // You can use a regex pattern or other validation methods
    // For simplicity, using a basic email format check
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }


  about() {
    window.scroll(0, 0);
    this.router.navigate(['/about']);
  }

  policy() {
    window.scroll(0, 0);
    this.router.navigate(['/policy']);
  }

  terms() {
    window.scroll(0, 0);
    this.router.navigate(['/terms']);
  }

  howwework() {
    window.scroll(0, 0);
    this.router.navigate(['/howwework']);
  }

  contact() {
    window.scroll(0, 0);
    this.router.navigate(['/contact']);
  }

  login() {
    window.scroll(0, 0);
    this.router.navigate(['/login']);
  }

  register() {
    window.scroll(0, 0);
    this.router.navigate(['/register']);
  }
}
