<!-- Banner -->
<app-loader *ngIf="loader"></app-loader>

<div class="banner clientbanner">
    <img src="assets/images/banner.jpg" alt="Banner">
    <div class="bannerheading">Artist Dashboard</div>
</div>

<div class="fullwidth bg_purple">
    <div class="container2">
        <!--bof:personalinfo-->
        <div class="personal">
            <!-- <h2>Personal </h2>-->
            <div class="row">
                <div class="pinfo" *ngIf="this.profilePic !== null && this.profilePic !== ''">
                    <img class="icon" src="https://api.pinnaklebiz.com/thevoicemall/profile/{{this.profilePic}}"
                        alt="pinfo">
                </div>

                <div class="pinfo" *ngIf="this.profilePic === null || this.profilePic === ''">
                    <img class="icon" src="https://api.pinnaklebiz.com/thevoicemall/profile/icon.jpg" alt="pinfo">
                </div>
                <div class="pdetails">
                    <div class="pdetailbx">
                        <div class="categorie">

                            <strong>Categories</strong>
                            <ul>
                                <li *ngFor="let items of categoryList">{{items}}</li>
                            </ul>
                            <strong>Language</strong>
                            <ul>
                                <li *ngFor="let items of languageList">{{items}}</li>
                            </ul>
                            <ul>
                                <li> <b class="text">State:</b> {{this.state}},{{this.country}}</li>
                                <li><b class="text">Recording Setup:</b>{{this.recordingSetup}}</li>
                                <li><b class="text">Micro Phone Used:</b>{{this.microPhoneUsed}}</li>
                                <li><b class="text">Experience:</b>{{this.experience}} </li>
                                <li><b class="text">Client Worked For:</b> {{this.clientWorkFor}}</li>
                            </ul>
                            <ul>
                                <li><b class="text">Day Availability:</b>Sunday: {{this.daySunday}},
                                    Monday: {{this.dayMonday }}, Tuesday: {{this.dayTuesday}}, Wednesday:
                                    {{this.dayWednesday}}, Thursday: {{this.dayThursday}}, Friday: {{this.dayFriday}},
                                    Saturday: {{this.daySaturday}}</li>

                            </ul>
                            <div class="editprofile"><a (click)="checkUserIsLoggedIn(this.artistUserId)">Book now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--eof:personalinfo-->
    </div>

    <!--bof:artistrecording-->
    <!--bof: myrecording-->
    <div class="w1200">
        <div class="wedget">
            <!-- bof:wedgetleft -->
            <div *ngIf="this.roles == 'ROLE_CUSTOMER' ||  this.roles == 'ROLE_ARTIST' ">
                <app-sidebar></app-sidebar>
            </div>
            <!-- bof:wedgetright -->
            <div class="wedgetright">
                <div class="sampledash">
                    <h2>
                        <span class="top_head"><i></i>Sample Voice</span>
                        <span class="top_headtwo">Project delivery time: {{this.artistTat}} .hrs</span>
                    </h2>
                    <div class="row">
                        <div class="col zoom" *ngFor="let items of sampleList">
                            <strong>{{items.sampleVoice}}</strong>
                            <ul>
                                <li><i class="hindiicon"></i> {{items.language}}</li>
                                <li><i class="audiobookicon"></i>{{items.category}}</li>
                            </ul>
                            <audio controls #audio (play)="onPaly(audio)" class="audio">
                                <source src="https://api.pinnaklebiz.com/thevoicemall/sample/{{items.name}}">
                            </audio>
                        </div>
                    </div>
                    <div class="sampledash linespace">
                        <h2><span><i class="reviewicon"></i>Review and Rating</span></h2>
                        <div class="row">
                            <div class="col2" *ngFor="let reviewList of reviewAndRatingList">
                                <strong>{{reviewList.username}}</strong>
                                <span class="fa fa-star" [ngClass]="{'positive' : reviewList.userRatingValue >= 1}"></span>
                                <span class="fa fa-star" [ngClass]="{'positive' : reviewList.userRatingValue >= 2}"></span>
                                <span class="fa fa-star" [ngClass]="{'positive' : reviewList.userRatingValue >= 3}"></span>
                                <span class="fa fa-star" [ngClass]="{'positive' : reviewList.userRatingValue >= 4}"></span>
                                <span class="fa fa-star" [ngClass]="{'positive' : reviewList.userRatingValue >= 5}"></span>
                                <p>{{reviewList.ratingComments}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--eof: myrecording-->
</div>