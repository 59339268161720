<div id="maincontainer">
  <!-- Banner -->
  <div class="dashboardbanner">
    <img src="/assets/images/dashboard-banner.jpg" alt="Dashboard">
    <div class="bannerheading">Dashboard</div>
  </div>

  <div class="fullwidth bg_purple">

    <!--bof: myrecording-->
    <div class="w1200">
      <div class="dashboardwedget">
        <app-admin-sidebar></app-admin-sidebar>
        <!-- bof:wedgetright -->
        <div class="wedgetright">
          <!--artistrecording-->
          <div class="tab_content" id="tab1" style="display: block;">
            <h3>Dashboard</h3>
            <div class="dashwed">
              <ul class="homelink">
                <li><a class="active" routerLink="/admin"><i class="fa fa-home fa-2x dashboardicon"
                      aria-hidden="true"></i></a>
                </li>
                <li><a routerLink="/admin">Dashboard</a></li>
              </ul>
              <br>
              <ul class="points" style="position: absolute; padding-top: 16px;">
                <li *ngIf="pdata1.includes('manage_customer')" (click)="userdashboard()">
                  <a class="userbg"> <i class="usericon2"></i> Customer <span *ngIf="notify[0].customer_new"
                      style="padding-left: 156px;" class="cutomerResponshive"> <span class="spancss">{{
                        notify[0].customer_new }}</span></span>
                  </a>
                </li>
                <li *ngIf="pdata1.includes('manage_artist')" (click)="artistdashboard()"><a class="artistbg"><i
                      class="artisticon2"></i> Artist <span *ngIf="notify[0].artist_new" style="padding-left: 194px;" class="ArtistResponshive">
                      <span class="spancss">{{
                        notify[0].artist_new }}</span></span></a></li>
                <li *ngIf="pdata1.includes('order_request')" (click)="recordingdashboard()"><a class="recordingbg"><i
                      class="recodingicon2"></i> Recording
                    Request<span *ngIf="notify[0].order_new" style="padding-left: 67px;" class="recordingResponsive"> <span class="spancss">{{
                        notify[0].order_new
                        }}</span></span></a></li>
                <li *ngIf="pdata1.includes('artist_sample')" (click)="sampledashboard()"><a class="voicebg"><i
                      class="voiceicon2"></i>Voice Samples <span *ngIf="notify[0].sample_new"
                      style="padding-left: 107px;" class="voiceresponsive"> <span class="spancss">{{ notify[0].sample_new }}</span></span></a>
                </li>
                <li *ngIf="pdata1.includes('contact')" (click)="contactdashboard()"><a class="managecontactbg"><i
                      class="contacticon2"></i>
                    Manage Contact us <span *ngIf="notify[0].contact_us_new" style="padding-left: 62px;" class="ContactResposhive"> <span
                        class="spancss">{{
                        notify[0].contact_us_new }}</span></span></a></li>
                <li *ngIf="pdata1.includes('revised_comment')" (click)="revisedComments()"><a class="redeembg"><i
                      class="reviewicon2"></i>Revised Comments <span *ngIf="notify[0].revision_comment_new"
                      style="padding-left: 58px;"  class="revisedComments"> <span class="spancss">{{ notify[0].revision_comment_new
                        }}</span></span></a></li>
                <li *ngIf="pdata1.includes('artits_payment')" (click)="Artistpayment()"><a class="reviewbg"><i
                      class="reviewicon2"></i>Artist Payment Details</a></li>
                <li *ngIf="pdata1.includes('review_rating')" (click)="reviewAndRating()"><a class="reviewbg"><i
                      class="reviewicon2"></i> Review &amp; Ratings <span *ngIf="notify[0].review_rating_new"
                       class="reviewInMobile"> <span class="spancss">{{ notify[0].review_rating_new
                        }}</span></span></a></li>

                <!-- <li><a class="redeembg" href="#"><i class="redeemicon2"></i> Redeem Request</a></li>
                <li><a class="artistprofilebg" href="#"><i class="profileicon2"></i> Artist Profile Request</a></li>-->
              </ul>
            </div>
          </div>
          <!--artistrecording-->

          <!--Transtion history-->
          <div class="tab_content t_history" id="tab2" style="display: none;">
            <h3>Transtion history</h3>
          </div>
          <!--Transtion history-->

          <!--Wallet-->
          <div class="tab_content" id="tab3" style="display: none;">
            <h3>Wallet</h3>
          </div>
          <!--Wallet-->

          <!--Account Setting-->
          <div class="tab_content" id="tab4" style="display: none;">
            <h3>Account Setting</h3>

          </div>
          <!--Account Setting-->

          <!--Invoice Setting-->
          <div class="tab_content" id="tab5" style="display: none;">
            <h3>Invoice Setting</h3>

          </div>
          <!--Invoice Setting-->

          <!--Notification-->
          <div class="tab_content" id="tab6" style="display: none;">
            <h3>Notification Setting</h3>


          </div>
          <!--Notification-->

          <!--Notification-->
          <div class="tab_content" id="tab7" style="display: none;">
            <h3>7 Setting</h3>


          </div>
          <!--Notification-->

          <!--Notification-->
          <div class="tab_content" id="tab8" style="display: none;">
            <h3>85 Setting</h3>


          </div>
          <!--Notification-->

          <!--Notification-->
          <div class="tab_content" id="tab9" style="display: none;">
            <h3>9 Setting</h3>


          </div>
          <!--Notification-->

          <!--Notification-->
          <div class="tab_content" id="tab10" style="display: none;">
            <h3>10 Setting</h3>


          </div>
          <!--Notification-->

          <!--Notification-->
          <div class="tab_content" id="tab11" style="display: none;">
            <h3>11 Setting</h3>


          </div>
          <!--Notification-->

          <!--Notification-->
          <div class="tab_content" id="tab12" style="display: none;">
            <h3>12 Setting</h3>


          </div>
          <!--Notification-->

          <!--Notification-->
          <div class="tab_content" id="tab13" style="display: none;">
            <h3>13 Setting</h3>


          </div>
          <!--Notification-->


          <!--Notification-->
          <div class="tab_content" id="tab14" style="display: none;">
            <h3>14 Setting</h3>


          </div>
          <!--Notification-->

          <!--Notification-->
          <div class="tab_content" id="tab15" style="display: none;">
            <h3>15 Setting</h3>


          </div>
          <!--Notification-->

          <!--Notification-->
          <div class="tab_content" id="tab16" style="display: none;">
            <h3>16 Setting</h3>
          </div>
          <!--Notification-->



          <!--Notification-->
          <div class="tab_content" id="tab17" style="display: none;">
            <h3>17 Setting</h3>


          </div>
          <!--Notification-->

        </div>
      </div>
    </div>
    <!--eof: myrecording-->
  </div>
</div>