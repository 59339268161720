import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-customers',
  templateUrl: './about-customers.component.html',
  styleUrls: ['./about-customers.component.css']
})
export class AboutCustomersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    // alert("Comne in my compopnent !");
  }

}
