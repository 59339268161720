import { Component, OnInit } from '@angular/core';
import { UserService } from '../_services/user.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';


interface Permission {
  id: number;
  checked: boolean;
}

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.css'],
})
export class AddRoleComponent implements OnInit {
  loader: boolean = true;
  inputValue: any;
  AllPermissionsDetails: any;
  SelectAllField: boolean = false;
  permissionIds: number[] = [];
  // permission:any;
  RoleHide!: boolean;
  RoleError: any;
  PermissionsHide!: boolean;
  PermissionsError: any;
  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private router: Router,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.loader = false;
    this.getAllpermission();
     // this.selectAll();
  }

  AddRoleForm: any = {
    roleName: null,
    permissions: null,
  };

  

  getAllpermission() {
    try {
      this.userService.GetAllPermissions().subscribe((response) => {
        if (response) {
          this.AllPermissionsDetails = response;
        } else {
          console.log('We can`t get permission data in api');
          this.toastr.error('Something went wrong', '');
        }
      },
      (error : HttpErrorResponse)=>{
        this.toastr.error(error.error.message);
    }
    );
    } catch (error) {
      console.log('Error', error);
    }
  }
  
  selectAll() {
    const selectAllCheckbox = document.getElementById(
      'selectAll'
    ) as HTMLInputElement;
    const isChecked = selectAllCheckbox.checked;
    this.AllPermissionsDetails.forEach((Permission: Permission) => {
      Permission.checked = isChecked;
    });
    this.permissionIds = this.AllPermissionsDetails.filter(
      (Permission: Permission) => Permission.checked
    ).map((Permission: Permission) => Permission.id);
  }

  saveRoles(data: any) {
    if (
      this.permissionIds == null ||
      this.permissionIds == undefined ||
      this.permissionIds.length == 0
    ) {
      this.permissionIds = this.AllPermissionsDetails.filter(
        (Permission: Permission) => Permission.checked
      ).map((Permission: Permission) => Permission.id);
    }
    this.AddRoleForm.permissions = this.permissionIds;
    this.AddRoleForm.roleName = data.role;
    this.validate();
    const RoleData = {
      roleName: this.AddRoleForm.roleName,
      permissionIds: this.AddRoleForm.permissions,
    };
    if (
      this.AddRoleForm.roleName != undefined &&
      this.AddRoleForm.roleName !== null &&
      this.AddRoleForm.roleName !== '' &&
      this.AddRoleForm.permissions !== undefined &&
      this.AddRoleForm.permissions !== null &&
      this.AddRoleForm.permissions !== '' && this.AddRoleForm.permissions.length > 0
    ) {
      this.userService.CreateRole(RoleData).subscribe((response) => {
        if (response) {
          console.log("here");
           this.toastr.success('Role Create Successfully');
          setTimeout(() => {
            this.router.navigate(['view-roles']);
          }, 50);
        }
      }, 
      (error : HttpErrorResponse)=>{
          this.toastr.error(error.error.message);
      }
      );
    } else {
      this.toastr.error('Please Fill All Mandatory Fields');
    }
  }

  validate() {
    if (
      this.AddRoleForm.roleName == null ||
      this.AddRoleForm.roleName == '' ||
      this.AddRoleForm.roleName == undefined
    ) {
      this.RoleHide = true;
      this.RoleError = 'Role is mandatory';
    } else {
      this.RoleHide = false;
    }
    if (
      this.AddRoleForm.permissions == null ||
      this.AddRoleForm.permissions == '' ||
      this.AddRoleForm.permissions == undefined
    ) {
      this.PermissionsHide = true;
      this.PermissionsError = 'Permissions is mandatory';
    } else {
      this.PermissionsHide = false;
    }
  }
}
