import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../_services/user.service';
import { Router } from '@angular/router';
import { TokenStorageService } from '../_services/token-storage.service';
import { ElementRef, VERSION, ViewChild } from '@angular/core';
import { Country, State, City } from 'country-state-city';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {

  currentUser: any;
  contact: any;
  id: any;
  userId: any;

  //For Country and state and city basis on country
  @ViewChild('country')
  country!: ElementRef;
  @ViewChild('city')
  city!: ElementRef;
  @ViewChild('state')
  state!: ElementRef;
  name = 'Angular ' + VERSION.major;
  countries = Country.getAllCountries();
  states: any;
  cities: any;
  selectedCountry: any;
  selectedState: any;
  selectedCity: any;
  //for image upload
  uploadedImage: any;
  dbImage: any;
  postResponse: any;
  successResponse: any;
  image: any;
  content: any;
  loader: boolean = true;
  default: any;
  countryCode:any;
  stateCode:any;
  getcountryNm:any;

 

  


  constructor(private userService: UserService, private toastr: ToastrService,
    private router: Router, private token: TokenStorageService, private httpClient: HttpClient) { 
      
    }

  ngOnInit(): void {
    this.loader = false
    this.currentUser = this.token.getUser();
    this.contact = this.token.getUser().contact;
    this.id = this.token.getUser().id;

    

    this.getEditProfile();        
    
  } 


  

  editProfileForm: any = {
    dob: null,
    gender: null,
    address1: null,
    country: null,
    state: null,
    city: null,
    pinCode: null,
    timeZone: null,
    about: null
  };

  isEdit = false;
  isEditFailed = false;
  EditErrorMessage = '';

  editProfile(): void {
    
    const fromData = new FormData();
    const editProfileForm: any = {
      dob: this.editProfileForm.dob,
      gender: this.editProfileForm.gender,
      address1: this.editProfileForm.address1,
      country: this.editProfileForm.country,
      state: this.editProfileForm.state,
      city: this.editProfileForm.city,
      pinCode: this.editProfileForm.pinCode,
      timeZone: this.editProfileForm.timeZone,
      about: this.editProfileForm.about,
      // name: this.image.name,
      userId: this.id
    };
    fromData.append("editProfileModal", JSON.stringify(editProfileForm));
    // fromData.append("file", this.image, this.image.name);
    this.userService.editProfile(fromData).subscribe(
      data => {
        console.log(editProfileForm);
        this.isEdit = true;
        this.isEditFailed = false;
        this.router.navigate(['/editvoice']);
        this.toastr.success('Personal Info have been updated successfully', '')
      },
      err => {
        this.EditErrorMessage = err.error.message;
        this.isEditFailed = true;
      }
    );
  }

  
  searchCountryByName(countryName: string): string | null {
    const country = (this.countries.find(country => country.name.toLowerCase() === countryName.toLowerCase()));
    return country ? country.isoCode : null;
  }


  searchStateByName(stateName: string): string {
    const state = this.states.find((state: { name: string; }) => state.name === stateName);
    return state ? state.isoCode : null;
  }


  onCountryChange($event: any): void {    
    
    if(this.country && this.country.nativeElement && this.country.nativeElement.value){
        this.countryCode = this.searchCountryByName(this.country.nativeElement.value);
    }    

    this.states = State.getStatesOfCountry(this.countryCode);    

    this.selectedCountry = JSON.parse(this.country.nativeElement.value);
    
    this.editProfileForm.country = JSON.parse(this.country.nativeElement.value).name;
    this.cities = null;
    this.selectedCity = null;
  }





  onStateChange($event: any): void {
    this.stateCode = this.searchStateByName(this.state.nativeElement.value);

    this.cities = City.getCitiesOfState(this.countryCode, this.stateCode)
    this.selectedState = JSON.parse(this.state.nativeElement.value);
    this.editProfileForm.state = JSON.parse(this.state.nativeElement.value).name;
    this.selectedCity = null;
  }

  onCityChange($event: any): void {
    this.selectedCity = JSON.parse(this.city.nativeElement.value)
    this.editProfileForm.city = JSON.parse(this.city.nativeElement.value).name;
  }

  //Get EditProfile Details
  getEditProfile() {
    
    this.userService.getEditProfile(this.userId = this.id).subscribe(data => {
      this.editProfileForm = data;      
      this.getcountryNm = this.editProfileForm.country;            
      this.countryCode = this.searchCountryByName(this.editProfileForm.country);
      this.states = State.getStatesOfCountry(this.countryCode);

      this.stateCode = this.searchStateByName(this.editProfileForm.state);      
      this.cities = City.getCitiesOfState(this.countryCode, this.stateCode)

    },
      err => {
        console.log(err);
      }
    );
  }

  reloadCurrentRoute() {
    this.router.navigate(['/editvoice']);
  }

  reloadEqipExp() {
    this.router.navigate(['/equipment']);
  }


}



;