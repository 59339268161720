import { Component, OnInit } from '@angular/core';
import { OrderService } from '../_services/order.service';
import { Ng2SearchPipe, Ng2SearchPipeModule } from 'ng2-search-filter';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-all-user-contact-details',
  templateUrl: './all-user-contact-details.component.html',
  styleUrls: ['./all-user-contact-details.component.css']
})
export class AllUserContactDetailsComponent implements OnInit {
  allArtistDetails: any;
  allArtistDetailsfiltered: any;
  editProfileForm: any;
  editVoiceProfileForm: any;
  availabilityForm: any;
  equipmentExperienceForm: any;
  popup = false;
  loader: boolean = true;
  set searchParam(value:any){
    if(value != null && value !='')
    this.allArtistDetailsfiltered=Ng2SearchPipe.prototype.transform(this.allArtistDetails,value);
  else
  this.allArtistDetailsfiltered = this.allArtistDetails;

  this.page = 1
  this.NoOfContact = this.allArtistDetailsfiltered.length;
  }
  page: number = 1;
  NoOfContact: any;


  constructor(private userService: UserService,private orderService: OrderService) { }

  ngOnInit(): void {
    this.loader = false
    this.getAllVoicesample();
  }


  goToFirstPage() {
    this.page = 1; // Assuming 1-based indexing
}

goToLastPage() {
    // Calculate the last page based on the total number of items and items per page
    const totalPages = Math.ceil(this.allArtistDetailsfiltered.length / 10); // Assuming 45 items per page    
    this.page = totalPages;
}

isFirstPage(): boolean {
  return this.page != 1;
}

isLastPage(): any {        
  if(this.page == Math.ceil(this.allArtistDetailsfiltered.length / 10)){
    return false;
  } else {
    return true;
  }    
}

onPageChange(event: number): void {
  this.page = event;
}






getAllVoicesample() {
    this.orderService.getAllContactdetails().subscribe((data: any) => {
      this.allArtistDetails = data;      
      this.allArtistDetailsfiltered = data;
      this.NoOfContact = this.allArtistDetails.length;
      this.allArtistDetails.forEach((element: {}) => {
      });
    },
      err => {
        console.log(err);
      }
    );
  }

  


}