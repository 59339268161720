<div class="w1200">
    <div class="wedget" style="background-color: #f1edec;">
        <!-- bof:wedgetleft -->
        <app-sidebar></app-sidebar>
        <!-- bof:wedgetright -->
        <div class="wedgetright">
            <div class="sampledash">
                <h2>
                    <span>Voice Samples </span><br>

                </h2>
                <!-- <h2><i></i>Sample Voice</h2> -->
                <div class="row">
                    <div class="col" *ngFor="let items of sampleList; let i = index;">
                        <ul>
                            <li>{{items.sampleVoice}}</li>
                            <li><i class="material-icons deleteicon" (click)="popup = true">delete</i></li>
                        </ul>

                        <ul>
                            <li><i class="hindiicon"></i> {{items.language}}</li>
                            <li><i class="audiobookicon"></i>{{items.category}}</li>
                        </ul>
                        <audio controls #audio (play)="onPaly(audio)" class="audio" style="width: 100%;">
                            <source src="https://api.pinnaklebiz.com/thevoicemall/sample/{{items.name}}">
                        </audio>
                        <div class="overlay" *ngIf="popup">
                            <div class="popup">
                                <a class="close" (click)="popup = false">&times;</a>
                                <div class="content">
                                    <b> Are you sure ? </b>
                                    <p class="text"> Do you really want to delete this sample? This process cannot be
                                        undone. </p>
                                </div>
                                <div class="modal-footer justify-content-center">
                                    <button type="button" class="button1" (click)="popup = false">Cancel</button>
                                    <button type="button" class="button2" (click)="deleteSample(items)">Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h1 *ngIf="sampleList?.length == 0">No Sample found !</h1>
                </div>
                <!-- <div class="pagination">
                    <ul>
                        <li><a href="#">Prev</a></li>
                        <li><a class="active" href="#">1</a></li>
                        <li><a href="#">2</a></li>
                        <li><a href="#">3</a></li>
                        <li><a href="#">Next</a></li>
                    </ul>
                </div> -->

            </div>
        </div>
    </div>
</div>