import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OrderService } from '../_services/order.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { UserService } from '../_services/user.service';

import { Ng2SearchPipe } from 'ng2-search-filter';


@Component({
  selector: 'app-all-artist-payment-details',
  templateUrl: './all-artist-payment-details.component.html',
  styleUrls: ['./all-artist-payment-details.component.css']
})
export class AllArtistPaymentDetailsComponent implements OnInit {
  // searchParam: any;
  // page: number = 1;
  currentUser: any;
  contact: any;
  id: any;
  firstName: any;
  lastName: any;
  email: any;
  loader: boolean = true;
  fetchArtistPaymentDetails: any;
  allProjectPaymentDetails: any;
  fetchAllArtistPaymentDetails: any
  popup = false;
  paymentDetails: any;
  referenceId: any;
  isPresent = false;

  set searchParam(value:any){
    if(value != null && value !='')
    this.allProjectPaymentDetails=Ng2SearchPipe.prototype.transform(this.allProjectPaymentDetails,value);
  else
  this.allProjectPaymentDetails = this.allProjectPaymentDetails;

  this.page = 1
  this.NoOfPaymentData = this.allProjectPaymentDetails.length;
  }
  page: number = 1;
  NoOfPaymentData: any;
 


  constructor(private orderService: OrderService, private token: TokenStorageService, private userService: UserService, private route: ActivatedRoute,
    private router: Router, private httpClient: HttpClient, private toastr: ToastrService) { }
  ngOnInit(): void {
    this.fetchAllProjectPaymentDetails();
  }





  fetchArtistBankDetails(data: any) {
    this.referenceId = data.referenceId;
    this.paymentDetails = data.paymentDetails;
    console.log("payment details ",data)
    if(data !== null){
      console.log("i am under of api", data.artistId);
      this.orderService.fetchArtistBankDetails(data.artistId).subscribe(data => {
        console.log("after response data ", data);
        this.fetchArtistPaymentDetails = data;
        this.popup = true;
      },
        err => {
          console.log(err);
        }
      );
    }else{
      this.toastr.error('Data not available!!', '')
    }
  }

  fetchAllProjectPaymentDetails() {
    this.loader = true;
    this.userService.allProjectPaymentDetails().subscribe(data => {
      this.allProjectPaymentDetails = data;
      this.NoOfPaymentData = this.allProjectPaymentDetails.length;
      if(this.allProjectPaymentDetails.paymentDetails !==null){
        this.isPresent = true;
      }
      this.allProjectPaymentDetails.forEach((element: {}) => {
      });
    },
      err => {
        console.log(err);
      }
    );
  }

  submitPaymentDetails() {
    this.orderService.submitPaymentDetails(this.referenceId, this.paymentDetails).subscribe(data => {
      this.toastr.success('Payment has been completed!! ', '')
      setTimeout(() => {
        window.location.reload();
      }, 500);
    },
      err => {
        console.log(err);
      }
    );
  }
  
  goToFirstPage() {
    this.page = 1; // Assuming 1-based indexing
}



goToLastPage() {
  // Calculate the last page based on the total number of items and items per page
  const totalPages = Math.ceil(this.allProjectPaymentDetails.length / 10); // Assuming 45 items per page    
  this.page = totalPages;
}


isFirstPage(): boolean {
  return this.page != 1;
}

isLastPage(): any {        
  if(this.page == Math.ceil(this.allProjectPaymentDetails.length / 10)){
    return false;
  } else {
    return true;
  }    
}

onPageChange(event: number): void {
  this.page = event;
}




}
