import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { OrderService } from '../_services/order.service';
import { UserService } from '../_services/user.service';
import { Ng2SearchPipe, Ng2SearchPipeModule } from 'ng2-search-filter';

@Component({
  selector: 'app-revision-comments',
  templateUrl: './revision-comments.component.html',
  styleUrls: ['./revision-comments.component.css']
})
export class RevisionCommentsComponent implements OnInit {
  allArtistDetails: any;
  allArtistDetailsfiltered: any;
  editProfileForm: any;
  editVoiceProfileForm: any;
  availabilityForm: any;
  equipmentExperienceForm: any;
  popup = false;
  loader: boolean = true;
  set searchParam(value:any){
    if(value != null && value !='')
    this.allArtistDetailsfiltered=Ng2SearchPipe.prototype.transform(this.allArtistDetails,value);
  else
  this.allArtistDetailsfiltered = this.allArtistDetails;

  this.page = 1
  this.NoOfComments = this.allArtistDetailsfiltered.length;
  }
  page: number = 1;
  NoOfComments: any;


  constructor(private toastr: ToastrService,private userService: UserService,private orderService: OrderService) { }

  ngOnInit(): void {
    this.loader = false
    this.getAllVoicesample();
  }


  isFirstPage(): boolean {
    return this.page != 1;
  }

  isLastPage(): any {        
    if(this.page == Math.ceil(this.allArtistDetailsfiltered.length / 10)){
      return false;
    } else {
      return true;
    }    
  }

  onPageChange(event: number): void {
    this.page = event;
  }


  goToFirstPage() {
    this.page = 1; // Assuming 1-based indexing
}

goToLastPage() {
    // Calculate the last page based on the total number of items and items per page
    const totalPages = Math.ceil(this.allArtistDetailsfiltered.length / 10); // Assuming 45 items per page    
    this.page = totalPages;
}


  //Update revised comments details.
  approveRevisedComments(data: any) {
    this.orderService.updateRevisedComments(data.id).subscribe(data => {
      this.toastr.success('Revised comments has been approve Successfully!! ', '')
      window.location.reload();
    },
      err => {
        console.log(err);
      }
    );
  }





getAllVoicesample() {
     this.orderService.getAllRevisedComments().subscribe(data => {
      this.allArtistDetails = data;
      this.allArtistDetailsfiltered = data;
      this.NoOfComments = this.allArtistDetails.length;           
      this.allArtistDetails.forEach((element: {}) => {
      });
    },
      err => {
        console.log(err);
      }
    );
  }

  


}